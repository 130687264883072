// @ts-check
export const ADMIN = 1
export const FRONT_MANAGER = 2
export const SUPERVISOR = 3
export const SALES_DIRECTOR = 4
export const MIDLE_MANAGER = 5
export const MIDLE_HEAD = 6
export const RISK_MANAGER = 7
export const RISK_HEAD = 8
export const SECURITY_SERVICE = 9
export const LEGAL_MANAGER = 10
export const LEGAL_HEAD = 11
export const FINANCIAL_MONITORING = 12
export const CEO = 13
export const BOOK_KEEPER = 14
export const MARKETING_MANAGER = 15
export const SERVICE_MANAGER = 16
export const SERVICE_HEAD = 17
export const ARCHIVIST = 18
export const LEGAL_CASE = 19
export const AUDITOR = 20
export const ControlFinancial = 21
export const DISABLED = 0
