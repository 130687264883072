<template>
  <v-dialog
    v-model="innerState"
    :value="sendOfferDialog"
    :width="choosedSendOffer && sendOfferState ? '880px' : '550px'"
    persistent>
    <div
      v-show="
        (dialogShowDelay && !choosedEmail) || (choosedEmail && editorShowDelay)
      "
      style="
        position: relative;
        padding: 15px;
        background: #ffffff !important;
        color: #00000099;
      ">
      <div
        class="mb-3 d-flex"
        style="
          color: #09487a;
          height: 30px;
          width: 100%;
          justify-content: space-between;
        ">
        <v-toolbar-title
          style="font-size: 1rem; color: #09487a; font-weight: 600">
          <v-btn
            v-show="sendOfferState"
            icon
            class="mr-2"
            density="compact"
            @click="sendOfferState = null">
            <v-tooltip bottom color="grey darken-3" text="Назад">
              <template #activator="{ props }">
                <v-icon color="rgb(252, 114, 71)" v-bind="props">
                  {{ 'mdi-arrow-left-circle' }}
                </v-icon>
              </template>
              <span>Назад</span>
            </v-tooltip>
          </v-btn>
          <span v-show="choosedSendOffer">
            {{ 'Надiслати КП' }}
          </span>
          <span v-show="choosedDownloadOffer">
            {{ 'Завантажити' }}
          </span>
        </v-toolbar-title>
        <div class="d-flex align-center">
          <div style="margin-right: 30px">
            <v-switch
              v-if="choosedSocials || choosedEmail || choosedDownloadOffer"
              v-model="advance"
              :false-value="false"
              :true-value="true"
              color="#FC7247"
              class="inline-label-start"
              style="margin-top: 0 !important"
              hide-details
              dense>
              <template #label>
                <span style="font-size: 0.82rem">
                  {{ 'Відобразити аванс' }}
                </span>
              </template>
            </v-switch>
          </div>
          <v-btn
            size="small"
            icon
            @click="
              () => {
                closeEditorActivePanel()
                $emit('update:sendOfferDialog', !sendOfferDialog)
              }
            ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 13 14"
              fill="none">
              <path
                d="M8.5 9L4.5 5M4.5 9L8.5 5M12.5 7C12.5 3.6875 9.8125 1 6.5 1C3.1875 1 0.5 3.6875 0.5 7C0.5 10.3125 3.1875 13 6.5 13C9.8125 13 12.5 10.3125 12.5 7Z"
                stroke="#C0C1C7"
                stroke-linecap="round" />
            </svg>
          </v-btn>
        </div>
      </div>
      <div style="font-size: 14px">
        <v-scroll-x-transition hide-on-leave>
          <div v-show="!sendOfferState && choosedSendOffer">
            <div style="padding: 12px 5px; font-size: 1.03rem">
              Оберіть метод відправки:
            </div>
            <v-btn
              class="justify-start"
              variant="text"
              block
              @click="sendOfferState = 'socials'">
              <v-tooltip bottom color="grey darken-3" text="Соцiальнi мережі">
                <template #activator="{ props }">
                  <PhoneIcon v-bind="props" />
                </template>
                <span>Соцiальнi мережі</span>
              </v-tooltip>
              &nbsp;Соц. мережі
            </v-btn>
            <v-btn
              class="justify-start mt-2"
              text
              block
              @click="sendOfferState = 'email'">
              <v-tooltip bottom color="grey darken-3" text="email">
                <template #activator="{ props }">
                  <EmailIcon v-bind="props" />
                </template>
                <span>Email</span>
              </v-tooltip>
              &nbsp;Email
            </v-btn>
          </div>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <div v-show="choosedSocials">
            <v-row class="pt-0 align-center mb-3">
              <v-col
                v-show="choosedSocials && choosedSendOffer"
                cols="2"
                md="2"
                sm="12"
                class="pb-0">
                <v-menu rounded offset-y>
                  <template #activator="{ props }">
                    <v-btn
                      style="
                        border: 1px solid #c0c1c7 !important;
                        background: white;
                      "
                      color="grey darken-1"
                      class="text-white mt-1 no-transform"
                      variant="outlined"
                      v-bind="props">
                      <span v-if="selectedSocial">
                        <component :is="selectedSocial.icon"></component>
                      </span>
                      &nbsp;
                      {{
                        selectedSocial
                          ? selectedSocial.text
                          : 'Оберіть мессенджер'
                      }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, key) in messangers"
                      :key="key"
                      link
                      variant="elevated"
                      :disabled="item.disabled"
                      @click="selectedSocial = item">
                      <div style="margin-top: 5px; margin-right: 12px">
                        <component :is="item.icon"></component>
                      </div>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="4" md="4" sm="12" class="pb-0">
                <span class="label">Телефон</span>
                <v-select
                  v-if="choosedSocials"
                  id="send-offer-phone"
                  v-model="phone"
                  :items="application.phones"
                  :error-messages="phoneErr"
                  item-title="phone"
                  item-value="phone"
                  placeholder="Оберіть зі списку"
                  hide-details
                  @input="phoneMask($event, 'send-offer-phone')"
                  @blur="v$.phone.$touch()">
                </v-select>
              </v-col>
              <v-col cols="6" md="6" sm="12" class="pb-0">
                <v-checkbox
                  v-if="getSafe(() => application.agentId || !phone)"
                  v-model="agent"
                  :false-value="null"
                  :true-value="getSafe(() => application.agentId, null)"
                  class="ml-3"
                  dense
                  hide-details>
                  <template #label>
                    <span style="font-size: 12px">
                      Надiслати копію агенту ({{
                        `${application?.agent?.contact.fullName}`
                      }})</span
                    >
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <div
            v-show="editorInitialized && choosedSendOffer && choosedEmail"
            ref="fakeClick">
            <div
              class="d-block"
              style="flex-direction: column; margin-bottom: 10px">
              <span class="d-block">Від кого:</span>
              <div class="user mt-2">
                <span style="font-weight: 600; color: rgb(7 55 96)">
                  {{ user.email || '---' }}
                </span>
              </div>
            </div>
            <v-row v-if="choosedEmail && choosedSendOffer" class="ma-0">
              <v-col :cols="12" :sm="8" class="pa-0">
                <v-scroll-x-transition hide-on-leave>
                  <div>
                    <v-select
                      v-model="choosedEmails"
                      :error-messages="emailErr"
                      :items="emails"
                      class="pt-2 mb-2"
                      multiple
                      dense
                      placeholder="Оберіть одержувача"
                      hide-details
                      small-chips
                      @keyup.enter="submit()"
                      @blur="v$.choosedEmails.$touch()">
                      <template #prepend>
                        <span class="prepend-slot">
                          <span style="vertical-align: sub">Кому:</span>
                        </span>
                      </template>
                    </v-select>
                  </div>
                </v-scroll-x-transition>
              </v-col>
            </v-row>
            <v-text-field
              v-model="title"
              class="mb-2"
              dense
              hide-details
              placeholder="Введіть тему повідомлення"
              @keyup.enter="submit()">
              <template #prepend>
                <span class="prepend-slot">
                  <span style="vertical-align: sub">Тема:</span>
                </span>
              </template>
            </v-text-field>
            <div class="d-flex justify-end w-100">
              <v-checkbox
                v-if="getSafe(() => application.agentId)"
                v-model="agent"
                :false-value="null"
                :true-value="getSafe(() => application.agentId, null)"
                class="mt-4 ml-3"
                dense
                color="red"
                hide-details>
                <template #label>
                  <span style="font-size: 12px">
                    Надіслати копію агенту ({{
                      `${application?.agent?.contact.fullName}`
                    }})</span
                  >
                </template>
              </v-checkbox>
            </div>
          </div>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <section-loader v-show="choosedEmail && !editorInitialized">
          </section-loader>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <div v-show="choosedEmail && editorInitialized" class="pt-4">
            <editor
              :id="editorId"
              style="border-radius: 5px"
              :api-key="key"
              :init="editorConfig(editorId, userSigns)"
              @on-init="customizeEditor($event)" />
          </div>
        </v-scroll-x-transition>
        <v-scroll-x-transition>
          <div v-if="choosedSocials" class="mt-4">
            <!--todo @input/@paste .native-->
            <span class="label">Повідомлення</span>
            <v-textarea
              v-model="messageText"
              :error-messages="messageTextErr"
              class="mt-2"
              placeholder="Введіть текст"
              :rows="4"
              hide-details
              @input="checkMesssageLength"
              @paste="checkMesssageLength"
              @blur="v$.messageText.$touch()">
            </v-textarea>
          </div>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <div
            v-show="
              (choosedDownloadOffer && sendOfferState) ||
              (choosedSendOffer &&
                (editorInitialized || choosedSocials) &&
                sendOfferState) ||
              choosedDownloadOffer
            ">
            <v-row>
              <v-col
                :cols="12"
                :sm="choosedSendOffer && choosedEmail ? 12 : 6"
                class="pb-0">
                <v-row>
                  <v-col
                    v-if="choosedSendOffer && choosedEmail"
                    :cols="12"
                    :sm="12"
                    class="mt-3 pb-0">
                    <v-file-input
                      v-model="files"
                      accept="*"
                      class="mb-5"
                      label="Файли до листа"
                      dense
                      hide-details
                      small-chips
                      multiple
                      @change="uploadExtraFiles(files)">
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="d-flex justify-end w-100">
              <v-btn
                style="min-width: 150px; background: #09487a !important"
                :height="30"
                class="no-transform text-white mt-5"
                :loading="loading"
                @click="submit()">
                {{ choosedSendOffer ? 'ВІДПРАВИТИ' : 'ЗАВАНТАЖИТИ' }}
              </v-btn>
            </div>
          </div>
        </v-scroll-x-transition>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import EmailIcon from '@/assets/svg/email-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import Editor from '@tinymce/tinymce-vue'
import Whatsapp from '@/assets/svg/whatsapp.vue'
import Viber from '@/assets/svg/viber.vue'
import Telegram from '@/assets/svg/telegram.vue'
import sectionLoader from '@/components/section-loader.vue'
import { required, minLength } from '@vuelidate/validators'
import { mapState } from 'vuex'
import { editorConfig, setHtml, getHtml } from '@/utils/tinymce-config.js'
import {
  sendClientOffer,
  urlGetClientOfferTemplates,
  urlApplicationById,
  calculationGet,
  urlUploadFiles,
} from '@/pages/request.js'
import {
  setErrHandler,
  setClientOfferCurrency,
  getManagerPhoto,
  getSafe,
} from '@/utils/helperFunc'
import { phoneMask } from '@/utils/masks'
import download from 'downloadjs'
import { createPdf } from '@/utils/make-pdf/client-offer/constructor'
import { useVuelidate } from '@vuelidate/core'
import { markRaw } from 'vue'

export default {
  components: {
    editor: Editor,
    sectionLoader,
    EmailIcon,
    PhoneIcon,
  },
  emits: ['update:sendOfferDialog'],
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    sendOfferDialog: { type: Boolean, default: false },
    clientOffer: { type: Object },
    dialogAction: { type: String },
    currency: { type: String, default: 'UAH' },
    cb: { type: Function },
  },
  validations() {
    const messageText = this.choosedSocials ? { required } : {}
    const phone =
      this.choosedSocials &&
      !(
        this.$getSafe(() => this.application.sourceTypeId) === 1 && !!this.agent
      )
        ? {
            ...(!this.application.isMissingData ? { required } : {}),
            minLength: minLength(23),
          }
        : {}

    const who =
      this.choosedEmail &&
      this.choosedSendOffer &&
      !this.application.isMissingData
        ? { required, minLength: minLength(1) }
        : {}
    return {
      messageText: messageText,
      phone: phone,
      choosedEmails: who,
    }
  },
  data: () => ({
    calculation: null,
    files: [],
    application: {},
    extraFiles: [],
    message: null,
    emails: [],
    choosedEmails: [],
    title: 'Клієнтська пропозиція від Best24',
    agents: [],
    agent: null,
    isClient: true,
    sendOfferState: null,
    whatsapp: Whatsapp,
    viber: Viber,
    telegram: Telegram,
    loading: false,
    innerState: false,
    selectedSocial: {
      text: 'viber',
      value: 1,
      icon: markRaw(Viber),
      disabled: false,
    },
    advance: true,
    editorInit: false,
    editorId: 'tiny-send-offer',
    userSigns: [],
    dialogShowDelay: false,
    editorShowDelay: false,
    messageText: null,
    phone: null,
    selectedCurrency: 'UAH',
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      selectItems: state => state.selectItems,
    }),
    userId() {
      return this.user.id
    },
    key() {
      return import.meta.env.VITE_API_KEY_TINYMCE
    },
    choosedSendOffer() {
      return this.dialogAction === 'sendOffer'
    },
    choosedDownloadOffer() {
      return this.dialogAction === 'downloadOffer'
    },
    editorInitialized() {
      return this.editorInit || window?.tinymce
    },
    messangers() {
      return [
        { text: 'viber', value: 1, icon: this.viber, disabled: false },
        { text: 'whats-up', value: 2, icon: this.whatsapp, disabled: false },
        { text: 'telegram', value: 3, icon: this.telegram, disabled: false },
      ]
    },
    choosedEmail() {
      return this.sendOfferState === 'email'
    },
    choosedSocials() {
      return this.sendOfferState === 'socials'
    },
    sendType() {
      if (this.choosedEmail) return 'email'
      if (this.choosedSocials) return 'socials'
      return null
    },
    messageTextErr() {
      return this.setErrHandler(this.v$.messageText)
    },
    phoneErr() {
      return this.setErrHandler(this.v$.phone)
    },
    emailErr() {
      return this.setErrHandler(this.v$.choosedEmails)
    },
    offerObject() {
      if (this.calculation) return JSON.parse(JSON.stringify(this.calculation))
      return JSON.parse(JSON.stringify(this.clientOffer))
    },
    sendObject() {
      return Object.assign(this.offerObject, { user: this.user })
    },
    managerSign() {
      const u = this.user
      const style = 'font-family: Helvetica, sans-serif'
      const site = 'https://www.facebook.com/bestleasingua'
      return `
        <div style="${style}">
          <div>З повагою,</div>
          <div>${u.surname} ${u.name}</div>
          <div>${u.post}</div>
          <div>__________________________</div>
          <div>Тел: ${u.phone}</div>
          <div>
            <span>Сайт:</span>
            <a target="_blank" href="${site}">${site}</a>
          </div>
          <div>
            <span>Email:</span>
            <a href="'mailto:' + ${u.email}">${u.email}</a>
          </div>
        </div>
      `
    },
  },
  methods: {
    getSafe,
    setHtml,
    getHtml,
    sendClientOffer,
    editorConfig,
    setErrHandler,
    urlGetClientOfferTemplates,
    urlApplicationById,
    urlUploadFiles,
    calculationGet,
    phoneMask,
    checkMesssageLength(e) {
      const maxLength = 1000
      let value = e?.target?.value

      if (value?.length > maxLength) {
        value = value.substring(0, maxLength)
        e.target.value = value

        const inputEvent = new InputEvent('input', {
          bubbles: true,
          cancelable: true,
          composed: true,
        })

        e.target.dispatchEvent(inputEvent)
      }
    },
    closeEditorActivePanel() {
      const editorPanel = document.querySelector('.tox-tbtn--enabled')
      if (editorPanel) {
        editorPanel.click()
      }
    },
    getIframe() {
      const closeAutocomplete = () => this.$refs.fakeClick.click()

      setTimeout(() => {
        const el = document.getElementById('tiny-send-offer_ifr')
        if (el) {
          el.contentWindow.addEventListener('click', closeAutocomplete)
        } else {
          console.error('iframe not found')
        }
      }, 500)
    },
    initializeEditor() {
      this.editorInit = true
      this.getIframe()
    },
    customizeEditor() {
      setTimeout(() => {
        setTimeout(() => {
          document.getElementById(this.editorId).style.display = 'none'
          this.editorInit = !this.editorInit
        }, 0)
      }, 0)
    },
    submit() {
      if (this.choosedSocials && !this.selectedSocial) {
        return this.$setSnackbar({
          text: 'Оберіть месенджер',
          color: 'warning',
        })
      }
      if (this.agent) {
        return this.handleOffer()
      }
      if (this.v$.$invalid) {
        this.highLightErr()
        this.v$.$anyError
      }
      return this.handleOffer()
    },
    async handleOffer() {
      if (this.choosedSendOffer && this.choosedEmail) {
        await this.postToEmail()
      } else if (this.choosedSendOffer && this.choosedSocials) {
        if (this.phone) {
          await this.postToSocials()
        } else if (this.agent) {
          await this.postToSocials(this.agent)
        }
      } else if (this.choosedDownloadOffer) {
        await this.downloadCO()
      }
    },
    getName(obj) {
      const req = obj?.requestData || obj
      const leasedAssertMarkName = req?.leasedAssertMark?.name || ''
      const leasedAssertModelName = req?.leasedAssertModel?.name || ''
      const advance = `${req.advance}%`
      const term = `${req.leasingTerm}мiс`
      const currency = req.currency || ''
      const id = obj.requestId || ''

      const fileName =
        `${leasedAssertMarkName}_${leasedAssertModelName}_${advance}_${term}_${currency}_${id}.pdf`.replace(
          /\s/g,
          '_'
        )
      return fileName
    },
    async downloadCO() {
      const name = this.getName(this.offerObject)
      const offer = await setClientOfferCurrency(this.sendObject, {
        currency: this.selectedCurrency,
      })

      if (!offer.user.photo) {
        return this.$setSnackbar({
          text: 'Для генерації КП користувач повинен мати фото',
          color: 'warning',
        })
      }

      offer.user.photo = await getManagerPhoto(offer.user.photo).catch(err => {
        this.$setSnackbar({
          text: err?.message || 'Фото не знайдено',
          color: 'error',
        })
      })

      offer.showAdvance = !!this.advance

      this.loading = true

      return this.getOffer(offer)
        .then(blob => {
          download(blob, name, 'application/pdf')
          this.innerState = false
          this.loading = false
          return blob
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    getOffer(offer) {
      if (offer.requestData && offer.requestData?.programFSN) {
        offer.programFSN = offer.requestData.programFSN
      }
      if (offer?.resultData?.maintenance) {
        offer.maintenance = offer?.resultData?.maintenance
        offer.maintenance.graph.unshift({
          n: '0',
          payment: '0',
          servicePayment: '0',
        })

        delete offer?.resultData?.maintenance
      }
      if (offer?.giftCertificate) {
        offer.calcRequestData = offer.calcRequestData || {}
        offer.calcRequestData.giftCertificate = offer.giftCertificate
      }
      console.log('offer', offer)
      return createPdf(offer)
    },
    getOfferUrl() {
      return this.sendClientOffer()
    },
    async postToEmail() {
      if (this.choosedEmails !== null) {
        try {
          this.loading = true
          let offer
          // eslint-disable-next-line prefer-const
          offer = await setClientOfferCurrency(this.sendObject, {
            currency: this.selectedCurrency,
          })
          offer.showAdvance = !!this.advance
          offer.user.photo = (await getManagerPhoto(offer.user.photo)) || null
          const data = await this.getOffer(offer)
          const reader = new FileReader()
          reader.readAsDataURL(data)
          if (this.v$.$invalid) {
            this.v$.$anyError
            this.v$.$touch()
            this.loading = false
            return Promise.resolve()
          }
          if (this.application.isMissingData) {
            this.isClient = false
          }
          reader.onload = e => {
            const base64 = e.target.result
            const sendRequest = (email = null) => {
              this.$axios
                .post(
                  this.getOfferUrl(!email),
                  this.clientOfferObj(
                    base64,
                    email,
                    this.sendType,
                    this.getHtml(this.editorId) + this.managerSign
                  )
                )
                .then(res => {
                  this.loading = false
                  this.closeDialog(email)
                  if (res.data.message) {
                    this.$setSnackbar({
                      text: res.data.message,
                      color: 'warning',
                    })
                  }
                })
                .catch(err => {
                  this.loading = false
                  if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                  ) {
                    this.$setSnackbar({
                      text: err.response.data.message,
                      color: 'error',
                    })
                  } else {
                    this.$setSnackbar({ text: 'Помилка', color: 'error' })
                  }
                })
            }
            this.loading = false
            if (this.choosedEmails.length) {
              this.choosedEmails.forEach(sendRequest)
            } else sendRequest()
          }
        } catch (err) {
          if (err) {
            this.$setSnackbar({ text: err, color: 'error' })
          }
          this.$err(err, () => (this.loading = false))
        }
      } else
        return this.$setSnackbar({
          text: 'Оберіть одержувача',
          color: 'warning',
        })
    },
    async postToSocials(agent = null) {
      try {
        this.loading = true
        const offer = await setClientOfferCurrency(this.sendObject, {
          currency: this.selectedCurrency,
        })
        offer.showAdvance = !!this.advance
        offer.user.photo = (await getManagerPhoto(offer.user.photo)) || null
        const data = await this.getOffer(offer)
        const reader = new FileReader()
        reader.readAsDataURL(data)
        if (this.v$.$invalid) {
          this.v$.$anyError
          this.v$.$touch()
          this.loading = false
          return Promise.resolve()
        }
        if (this.application.isMissingData) {
          this.isClient = false
        }
        reader.onload = e => {
          const base64 = e.target.result
          this.$axios
            .post(
              this.getOfferUrl(agent),
              this.clientOfferObj(
                base64,
                null,
                this.selectedSocial.text,
                this.messageText,
                this.phone,
                this.isClient
              )
            )
            .then(res => {
              this.$setSnackbar(
                `Комерцiйну пропозицiю на ${this.selectedSocial.text} успішно відправлено`
              )
              this.loading = false
              typeof this.cb === 'function' && this.cb()
              this.$emit('update:sendOfferDialog', false)
              if (res.data.message) {
                this.$setSnackbar({
                  text: res.data.message,
                  color: 'warning',
                })
              }
              return res
            })
            .catch(err => this.$err(err, () => (this.loading = false)))
        }
      } catch (err) {
        this.$err(err, () => (this.loading = false))
      }
    },
    uploadExtraFiles(files) {
      const formData = new FormData()
      files.forEach(f => formData.append('file[]', f))
      this.$axios
        .post(this.urlUploadFiles(), formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {
          this.extraFiles.splice(0)
          this.extraFiles.push(...res.data.url)
        })
        .catch(e => console.log(e))
    },
    async getCalculationById(id) {
      if (id) {
        return this.$axios.get(calculationGet(id)).then(res => res)
      }
    },
    clientOfferObj(base64, email, sendType, message, phone) {
      return {
        fileName: this.getName(this.offerObject).replace(/\.pdf$/, ''),
        offer: base64,
        sendType: sendType,
        email: email,
        calculationId: this.clientOffer.id,
        messageSubject: this.title,
        messageText: message,
        phone: phone,
        isClient: this.isClient,
        agentId: this.agent,
        extraFiles: this.extraFiles,
        advance: this.advance,
      }
    },
    getContacts() {
      let emails = []
      const application = this.application

      if (
        application &&
        Array.isArray(application.emails) &&
        application.emails.length === 0 &&
        this.$getSafe(() => this.application.sourceTypeId) === 3
      ) {
        if ('contact' in application || 'contact_person' in application) {
          if ([1, 3].includes(application.lesseeTypeId)) {
            emails = application.contact?.emails?.map(v => v.email) || []
          } else {
            emails = application.contact_person?.emails?.map(v => v.email) || []
          }
        } else {
          if (application.lesseeTypeId === 2) {
            const applicationContact =
              this.selectItems.applicationContacts.find(
                c => c.contactId === application.contactPersonId
              )
            emails =
              applicationContact?.contact?.emails?.map(e => e.email) || []
          } else {
            const applicationContact = this.selectItems.contacts.find(
              c => c?.id === application.contactId
            )
            emails = applicationContact?.emails?.map(e => e.email) || []
          }
        }
      } else if (application?.emails) {
        emails = application.emails.map(v => v.email)
      }

      this.emails.splice(0)
      this.choosedEmails.splice(0)
      this.emails.push(...emails)
      this.choosedEmails.push(...emails)
    },
    getUserSigns(userId) {
      return this.$axios
        .get(this.urlGetClientOfferTemplates(userId))
        .then(res => {
          this.userSigns.splice(0)
          this.userSigns.push(...this.modifySignsProperties(res.data))
          return res
        })
        .catch(this.$err)
    },
    modifySignsProperties(signs) {
      return signs.map(sign => {
        return {
          title: sign.name,
          description: '',
          content: sign.sign,
        }
      })
    },
    closeDialog(email) {
      email &&
        this.$setSnackbar(
          `Комерцiйну пропозицiю на email - ${email} успішно відправлено`,
          ''
        )
      this.loading = false
      typeof this.cb === 'function' && this.cb()
      setTimeout(() => {
        this.$emit('update:sendOfferDialog', false)
      }, 1000)
    },
    highLightErr() {
      if (this.agent) {
        return
      }
      this.v$.$anyError
      this.v$.$touch()
    },
    setApplicationPhone() {
      this.phone = this.$getSafe(() => this.application?.phones[0].phone, null)
    },
    getApplication(id) {
      return this.$axios.get(this.urlApplicationById(id)).catch(this.$err)
    },
  },
  watch: {
    userId(userId) {
      if (userId) {
        this.getUserSigns(userId)
      }
    },
    editorInit(state) {
      if (state) {
        this.setHtml('', this.editorId, this.initializeEditor, this.userSigns)
      }
    },
    clientOffer: {
      async handler(offer) {
        if (!offer || !offer.id) return
        if (offer.application) this.application = offer.application
        else
          await this.getApplication(offer.applicationId).then(
            res => (this.application = res.data)
          )
        if (Object.keys(offer).length < 20) {
          const { data } = await this.getCalculationById(offer.id)
          this.calculation = data
        }

        this.getContacts()
        this.setApplicationPhone()
      },
      immediate: true,
    },
    choosedEmail(state) {
      if (state) {
        this.setHtml('', this.editorId, this.initializeEditor, this.userSigns)
        this.v$.$reset()

        setTimeout(() => {
          this.editorShowDelay = state
        }, 300)
      }
    },
    sendOfferDialog: {
      handler(val) {
        this.innerState = val
        if (val) {
          this.userId && this.getUserSigns(this.userId)
        }
      },
      immediate: true,
    },
    innerState(state) {
      setTimeout(() => {
        this.dialogShowDelay = state
      }, 400)
      this.sendOfferState = null
      this.agent = null
      this.messageText = null

      if (!state) {
        window?.tinymce && window?.tinymce.get(this.editorId).setContent('')
      }

      this.$emit('update:sendOfferDialog', state)
    },
    currency(v) {
      this.selectedCurrency = v
    },
    'application.isMissingData': function (newValue) {
      if (newValue === true) {
        this.application.isClient = false
      }
    },
  },
  mounted() {
    if (
      this.application &&
      this.application.phones &&
      this.application.phones.length > 0
    ) {
      this.phone = this.application.phones[0].phone
      if (this.application.editorInitialized && this.choosedEmail) {
        this.setHtml('', this.editorId, this.initializeEditor, this.userSigns)
      }
    }
  },
}
</script>

<style scoped>
.v-application .prepend-slot {
  text-align: left !important;
}
</style>
