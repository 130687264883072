<template>
  <div v-if="FDAnalyze.statusId === 4">
    <div class="accept-documents">
      <div style="background: white; margin-top: 10px; padding: 13px">
        <div class="accept-documents__item mb-2">
          <div class="d-flex align-center">
            <span
              v-if="!project.questionnaireSigned"
              class="mr-2"
              style="font-size: 12px; color: #1c1d21"
              >Опитувальник (підписаний)</span
            >
            <span
              v-if="project.questionnaireSigned"
              @click="openDocument(project.questionnaireSigned[0])"
              ><ALink
                :text="'Опитувальник (підписаний)'"
                style="font-size: 0.78rem"></ALink
            ></span>
            <v-tooltip right text="Перевірено">
              <template #activator="{ props }">
                <div class="d-flex align-center" v-bind="props">
                  <v-checkbox
                    v-model="FDAnalyzeData.isSignedQuestionnaire"
                    style="margin: 0; padding: 0; margin-top: -5px"
                    hide-details
                    color="#fc7247"
                    dense
                    size="small"
                    :readonly="documentsAccepted">
                  </v-checkbox>
                </div>
              </template>
              <span>Перевірено</span>
            </v-tooltip>
          </div>
          <span v-if="!project.questionnaireSigned" class="doc-none"
            >Документ відсутній</span
          >
        </div>
        <div v-if="contractorType" class="accept-documents__item mb-2">
          <div class="d-flex align-center">
            <span
              v-if="!project.ownershipStructureSigned"
              class="mr-2"
              style="font-size: 12px; color: #1c1d21"
              >Структура власності (Підписаний)</span
            >
            <span
              v-if="project.ownershipStructureSigned"
              @click="openDocument(project.ownershipStructureSigned[0])"
              ><ALink
                :text="'Структура власності (Підписаний)'"
                style="font-size: 0.78rem"></ALink
            ></span>
            <v-tooltip right text="Перевірено">
              <template #activator="{ props }">
                <div class="d-flex align-center" v-bind="props">
                  <v-checkbox
                    v-model="FDAnalyzeData.isSignedOwnershipStructure"
                    style="margin: 0; padding: 0; margin-top: -5px"
                    hide-details
                    color="#fc7247"
                    dense
                    size="small"
                    :readonly="documentsAccepted">
                  </v-checkbox>
                </div>
              </template>
              <span>Перевірено</span>
            </v-tooltip>
          </div>
          <span v-if="!project.ownershipStructureSigned" class="doc-none"
            >Документ відсутній</span
          >
        </div>
        <div class="accept-documents__item mb-2 d-flex align-center">
          <span class="mr-2" style="font-size: 12px; color: #1c1d21">
            {{
              client.contact
                ? 'Наявність підписаного паспорта'
                : 'Наявність підписаних паспортів директора/КБВ'
            }}</span
          >
          <v-tooltip right text="Перевірено">
            <template #activator="{ props }">
              <div class="d-flex align-center" v-bind="props">
                <v-checkbox
                  v-model="FDAnalyzeData.isSignedPassport"
                  style="margin: 0; padding: 0; margin-top: -5px"
                  hide-details
                  color="#fc7247"
                  dense
                  size="small"
                  :readonly="documentsAccepted">
                </v-checkbox>
              </div>
            </template>
            <span>Перевірено</span>
          </v-tooltip>
        </div>

        <div v-if="person">
          <div class="mb-1">
            <span v-if="client?.fullName" style="font-size: 14px"
              >ФIО: {{ client?.fullName }}
            </span>
            <span v-if="!client?.fullName" class="doc-none">фіо відустнє</span>
          </div>
          <div class="mb-1">
            <div
              v-if="client.identityDocument?.document"
              class="d-flex align-center">
              <span style="font-size: 14px" class="d-block mr-1"
                >Паспортні дані:</span
              >
              <span
                v-if="
                  client &&
                  client.identityDocument &&
                  client.identityDocument.document &&
                  client.identityDocument.document.filter(
                    d => d.isSigned || d.signed
                  ).length
                "
                class="d-block"
                style="font-size: 14px; color: #838282"
                >-<span
                  v-for="doc in client.identityDocument.document.filter(
                    d => d.isSigned || d.signed
                  )"
                  :key="doc.url"
                  class="link"
                  style="font-size: 0.78rem"
                  @click="openDocument(doc)">
                  {{ doc.text }} (Підписаний)
                </span></span
              >
              <span v-else class="doc-none">Документ Відсутній</span>
            </div>
          </div>
          <div>
            <span style="font-size: 14px">Ідентифікаційний документ:</span>
            <span
              v-if="client && client.identificationNumber"
              style="font-size: 14px; color: #838282"
              ><span
                v-for="doc in client.identificationNumber"
                :key="doc.url"
                class="link"
                style="font-size: 0.78rem"
                @click="openDocument(doc)">
                {{ doc.text }}
              </span></span
            >
            <span v-else class="doc-none">Документ Відсутній</span>
          </div>
        </div>

        <div v-if="director">
          <div v-if="director">
            <span style="font-size: 14px"
              >Директор:
              {{
                getSafe(() =>
                  getContactInitials(director?.contact?.fullName, {
                    byName: true,
                  })
                )
              }}
            </span>
            <div>
              <span style="font-size: 12px; color: #1c1d21"
                >Документ, що посвідчує особу:</span
              >
              <div
                v-if="director?.contact?.identityDocument"
                class="d-flex align-center">
                <span
                  v-if="
                    director.contact &&
                    director.contact.identityDocument &&
                    director.contact.identityDocument.document &&
                    director.contact.identityDocument.document.filter(
                      d => d.isSigned || d.signed
                    ).length
                  "
                  class="d-block"
                  style="font-size: 14px; color: #838282"
                  >-<span
                    v-for="doc in director.contact.identityDocument.document.filter(
                      d => d.isSigned || d.signed
                    )"
                    :key="doc.url"
                    class="link d-block"
                    style="font-size: 0.78rem"
                    @click="openDocument(doc)">
                    {{ doc.text }} (Підписаний)
                  </span></span
                >
              </div>
              <span v-else class="doc-none">Документ відсутній</span>
            </div>
            <div>
              <span style="font-size: 12px; color: #1c1d21">ІПН:</span>
              <span
                v-if="
                  director.contact?.identificationNumber &&
                  director.contact?.identificationNumber.length
                "
                style="font-size: 14px; color: #838282"
                >-<span
                  v-for="doc in director.contact.identificationNumber || []"
                  :key="doc.url"
                  class="link"
                  style="font-size: 0.78rem"
                  @click="openDocument(doc)">
                  {{ doc.text }}
                </span></span
              >
              <span v-else class="doc-none">Документ відсутній</span>
            </div>
          </div>
        </div>
        <div v-if="beneficiarOwners" class="mt-5">
          <div class="d-flex align-center">
            <DocumentsIcon class="mr-2" />
            <span>Бенефіціарні власники</span>
          </div>
          <div v-for="b in beneficiarOwners" :key="b.id">
            <v-divider class="mb-2 mt-2"></v-divider>
            <span style="font-size: 14px"
              >КБВ:
              {{ getContactInitials(b.contact.fullName, { byName: true }) }}
            </span>
            <div>
              <span style="font-size: 12px; color: #1c1d21"
                >Документ, що посвідчує особу:</span
              >
              <div
                v-if="b?.contact?.identityDocument?.document"
                class="d-flex align-center">
                <span
                  v-if="
                    b.contact &&
                    b.contact.identityDocument &&
                    b.contact.identityDocument.document &&
                    b.contact.identityDocument.document.filter(
                      d => d.isSigned || d.signed
                    ).length
                  "
                  class="d-block"
                  style="font-size: 14px; color: #838282"
                  >-<span
                    v-for="doc in b.contact.identityDocument.document.filter(
                      d => d.isSigned || d.signed
                    )"
                    :key="doc.url"
                    class="link d-block"
                    style="font-size: 0.78rem"
                    @click="openDocument(doc)">
                    {{ doc.text }} (Підписаний)
                  </span></span
                >
              </div>
              <span v-else class="doc-none">Документ Відсутній</span>
            </div>
          </div>
        </div>
        <div v-if="collegium" class="mt-5">
          <div class="d-flex align-center">
            <GeneralInfoIcon class="mr-2" />
            <span>Колегіальная дирекція</span>
          </div>
          <div v-for="c in collegium" :key="c.id">
            <v-divider class="mb-2 mt-2"></v-divider>
            <span style="font-size: 14px">
              Директор:
              {{
                getSafe(() =>
                  getContactInitials(c?.contact?.fullName, { byName: true })
                )
              }}
            </span>
            <div>
              <span style="font-size: 12px; color: #1c1d21"
                >Документ, що посвідчує особу:</span
              >
              <div
                v-if="c?.contact?.identityDocument?.document"
                class="d-flex align-center">
                <span
                  v-if="
                    c?.contact?.identityDocument.document.filter(
                      d => d.isSigned || d.signed
                    ).length
                  "
                  class="d-block"
                  style="font-size: 14px; color: #838282"
                  >-<span
                    v-for="doc in c.contact.identityDocument.document.filter(
                      d => d.isSigned || d.signed
                    )"
                    :key="doc.url"
                    class="link d-block"
                    style="font-size: 0.78rem"
                    @click="openDocument(doc)">
                    {{ doc.text }} (Підписаний)
                  </span></span
                >
              </div>
              <span v-else class="doc-none">Документ відсутній</span>
            </div>
            <div>
              <span style="font-size: 12px; color: #1c1d21">ІПН:</span>
              <span
                v-if="
                  c?.contact?.identificationNumber &&
                  c?.contact?.identificationNumber.length
                "
                style="font-size: 14px; color: #838282"
                >-<span
                  v-for="doc in c.contact.identificationNumber"
                  :key="doc.url"
                  class="link"
                  style="font-size: 0.78rem"
                  @click="openDocument(doc)">
                  {{ doc.text }}
                </span></span
              >
              <span v-else class="doc-none">Документ відсутній</span>
            </div>
          </div>
        </div>

        <v-row v-if="!documentsAccepted" class="align-center">
          <v-col :cols="12" :sm="2" class="pb-0">
            <span
              style="
                color: rgb(131, 130, 130);
                font-weight: 500;
                font-size: 13px;
              ">
              Рівень ризику
            </span>
            <v-select
              :model-value="FDAnalyzeData.financialRiskLevelId"
              :error-messages="financialRiskLevelIdErr"
              :items="$directory.get('financialRiskLevels')"
              :loading="$loading.isLoading('financialRiskLevels')"
              item-title="name"
              item-value="id"
              placeholder="Оберіть зi списку"
              readonly
              @blur="v.FDAnalyze.financialRiskLevelId.$touch()">
            </v-select>
          </v-col>
          <v-col :cols="12" :sm="3" class="pb-0">
            <v-btn
              class="mt-2 text-white"
              color="#fc7247"
              variant="flat"
              style="text-transform: none"
              :loading="acceptDocumentsLoading"
              :disabled="
                !FDAnalyzeData.isSignedQuestionnaire ||
                !FDAnalyzeData.isSignedPassport ||
                (contractorType && !FDAnalyzeData.isSignedOwnershipStructure)
              "
              @click="$emit('acceptDocuments')">
              Підтвердити рівень ризику
            </v-btn>
          </v-col>
        </v-row>

        <v-card-title v-else> Рівень ризику підтверджено </v-card-title>
      </div>
    </div>
  </div>
</template>

<script>
import {
  setErrHandler,
  openDocument,
  getResponsibleShortName,
  getContactInitials,
  getSafe,
} from '@/utils/helperFunc'
import { formatDate, humanDate } from '@/utils/formatFunc'
import ALink from '@/components/Link.vue'
import beneficiarOwner from '@/dialogs/components/BeneficiarOwner.vue'
import GeneralInfoIcon from '@/assets/svg/general-info.vue'
import DocumentsIcon from '@/assets/svg/documents.vue'
import { fillDirectory } from '@/plugins/directory/index.ts'
import { contractor as C } from '@/type-ids'

export default {
  name: 'RiskLevel',
  components: { DocumentsIcon, GeneralInfoIcon, ALink },
  emits: ['acceptDocuments'],
  props: {
    loading: { type: Boolean, default: false },
    submitLoading: { type: Boolean, default: false },
    acceptDocumentsLoading: { type: Boolean, default: false },
    documentsAccepted: { type: Boolean, default: false },
    FDAnalyze: { type: Object },
    financialRiskLevelCache: { type: Number },
    id: { type: String },
    v: { type: Object },
  },
  computed: {
    beneficiarOwner() {
      return beneficiarOwner
    },
    selectItems() {
      return this.$store.state.selectItems
    },
    project() {
      return this.FDAnalyze.project
    },
    slider() {
      return this.FDAnalyze?.project?.slider
    },
    contact() {
      return this.FDAnalyze?.project?.lesseeTypeId === 1
    },
    isInWork() {
      return this.FDAnalyze?.statusId === 2
    },
    director() {
      if (this.isCollegium) {
        return null
      }
      const executiveAuthorities = this.client.employees?.filter(e => {
        return e.isExecutiveAuthority
      })
      if (!executiveAuthorities?.length) {
        return null
      }
      return executiveAuthorities[0]
    },
    collegium() {
      if (!this.isCollegium) {
        return null
      }
      const executiveAuthorities = this.client.employees?.filter(e => {
        return e.isExecutiveAuthority
      })
      if (!executiveAuthorities?.length) {
        return null
      }
      return executiveAuthorities
    },
    isCollegium() {
      return this.contractor.executiveAuthorityId === C.authorityType.COLLEGIUM
    },
    beneficiarOwners() {
      return this.client?.beneficiaryOwners
    },
    person() {
      return [1, 3].includes(this.FDAnalyze?.project?.lesseeTypeId)
    },
    FDAnalyzeData() {
      return this.FDAnalyze
    },
    sourceTypeId() {
      return this.FDAnalyze?.project.slider.calculations[0]?.application
        ?.sourceTypeId
    },
    client() {
      return this.FDAnalyze?.project.lessee
    },
    contractor() {
      return {
        ...(this.client || {}),
        ownershipStructure: this.slider?.ownershipStructure,
      }
    },
    financialRiskLevelIdErr() {
      return this.setErrHandler(this.v.FDAnalyze.financialRiskLevelId)
    },
    contractorType() {
      return this.FDAnalyze?.project?.lesseeTypeId === 2
    },
    frontManager() {
      const m = this.project?.frontManagerResponsible.fullName
      if (!m) return
      return m
    },
    middleManager() {
      const m = this.project?.middleManagerResponsible.fullName
      if (!m) return
      return m
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    getResponsibleShortName,
    openDocument,
    formatDate,
    humanDate,
    setErrHandler,
  },
  created() {
    fillDirectory('financialRiskLevels')
  },
}
</script>

<style scoped>
.doc-none {
  color: #838282;
  font-weight: 400;
  font-size: 13px;
}
</style>
