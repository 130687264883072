import { getGraphCount } from '../get-description'
import { style } from './styles'
import { getBeautyNum } from '@/utils/helperFunc'

const calculationTable = obj => [
  // {
  //   width: '65%',
  //   text: 'Предмет лізингу'.toUpperCase(),
  //   margin: [0, 35, 0, 1],
  //   fontSize: 13,
  //   bold: true,
  // },
  {
    margin: obj.programFSN ? [0, 0, 0, 1] : [0, 35, 0, 1],
    layout: {
      hLineColor: () => '#e0e0e0',
      vLineColor: () => '#e0e0e0',
      paddingRight: () => 0,
      paddingLeft: () => 0,
    },
    table: {
      widths: ['30%', '70%'],
      body: [
        [
          {
            text: "Об'єкт та його вартiсть",
            fillColor: style.colors.mainRed,
            color: 'white',
            margin: [5, 4],
          },
          {
            text: `${obj?.requestData?.leasedAssertMark?.name} ${
              obj?.requestData?.leasedAssertModel.name
            } / ${
              obj?.resultData[Object.keys(obj?.resultData)[0]][
                'offer-price-netto'
              ]
            }`,
            alignment: 'center',
            margin: [5, 4],
          },
        ],
      ],
    },
  },
  {
    margin: [0, 1],
    layout: {
      hLineColor: () => '#e0e0e0',
      vLineColor: () => '#e0e0e0',
      paddingRight: () => 0,
      paddingLeft: () => 0,
    },
    table: {
      widths: ['30%', '70%'],
      body: [
        [
          {
            text: 'Вартість з реєстрацією',
            fillColor: style.colors.mainRed,
            color: 'white',
            margin: [5, 4],
          },
          {
            text: `${
              obj?.resultData[Object.keys(obj?.resultData)[0]][
                'offer-price-brutto'
              ]
            }`,
            alignment: 'center',
            margin: [5, 4],
          },
        ],
      ],
    },
  },
  {
    margin: [0, 1],
    layout: {
      hLineColor: () => '#e0e0e0',
      vLineColor: () => '#e0e0e0',
      paddingRight: () => 3,
      paddingLeft: () => 3,
    },
    table: {
      widths: ['30.9%', '12.1%', '23%', '20%', '14%'],
      body: [
        [
          {
            text: 'Авансовий платiж',
            fillColor: style.colors.mainRed,
            color: 'white',
            margin: [5, 4],
          },
          {
            text: `${obj?.requestData?.advance} %`,
            alignment: 'center',
            margin: [5, 4],
          },
          {
            text: `${
              obj?.resultData[Object.keys(obj?.resultData)[0]][
                'offer-advance'
              ] || 0
            }`,
            margin: [5, 4],
            alignment: 'center',
          },
          {
            text: 'Термiн лізингу',
            margin: [5, 4],
            fillColor: style.colors.mainRed,
            color: 'white',
            alignment: 'center',
          },
          {
            text: `${obj?.requestData?.leasingTerm} мiс`,
            margin: [5, 4],
            alignment: 'center',
          },
        ],
      ],
    },
  },
  {
    margin: [0, 1],
    layout: {
      hLineColor: () => '#e0e0e0',
      vLineColor: () => '#e0e0e0',
      paddingRight: () => 3,
      paddingLeft: () => 3,
    },
    table: {
      widths: ['30%', '12%', '58%'],
      body: [
        [
          {
            text: 'Разова комiсiя',
            fillColor: style.colors.mainRed,
            color: 'white',
            margin: [5, 4],
          },
          {
            text: `${(
              parseFloat(
                obj?.resultData[Object.keys(obj?.resultData)[0]][
                  'offer-administrative-payment-per'
                ] || 0
              ) * 100
            ).toFixed(2)} %`,
            alignment: 'center',
            margin: [5, 4],
          },
          {
            text: `${
              obj?.resultData[Object.keys(obj?.resultData)[0]][
                'offer-administrative-payment'
              ]
            }`,
            alignment: 'center',
            margin: [5, 4, 50, 4],
          },
        ],
      ],
    },
  },
  {
    margin: [0, 1],
    layout: {
      hLineColor: () => '#e0e0e0',
      vLineColor: () => '#e0e0e0',
      paddingRight: () => 3,
      paddingLeft: () => 3,
    },
    table: {
      widths: ['30.6%', '35.95%', '19.7%', '13.85%'],
      body: [
        [
          {
            text: 'Валюта фiнансування',
            fillColor: style.colors.mainRed,
            color: 'white',
            margin: [5, 11],
          },
          {
            text: `${obj?.requestData?.currency}`,
            alignment: 'center',
            margin: [5, 11],
          },
          {
            text: 'Залишкова вартiсть, %',
            margin: [5, 4],
            alignment: 'center',
            fillColor: style.colors.mainRed,
            color: 'white',
          },
          {
            text: `${obj?.requestData?.leasingRest}`,
            margin: [5, 11],
            alignment: 'center',
          },
        ],
      ],
    },
  },
  {
    margin: [0, 1],
    layout: {
      hLineColor: () => '#e0e0e0',
      vLineColor: () => '#e0e0e0',
      paddingRight: () => 3,
      paddingLeft: () => 3,
    },
    table: {
      widths: [...getGraphCount(obj)],
      body: [
        [
          {
            text: 'Середньомісячний платіж',
            fillColor: style.colors.mainRed,
            color: 'white',
            margin: [5, 13, 5, 4],
            rowSpan: 3,
          },
          {
            text: 'Вид графiка',
            alignment: 'center',
            colSpan: getGraphCount(obj).length - 1,
          },
          '',
          '', // -
        ].filter((v, i) => {
          return v || i <= getGraphCount(obj).length - 1
        }),
        [
          '',
          {
            // even
            state: obj?.resultData?.even,
            value: {
              text: 'Класичний',
              alignment: 'center',
              fillColor: style.colors.mainRed,
              color: 'white',
            },
          },
          {
            // annuity
            state: obj?.resultData?.annuity,
            value: {
              text: 'Ануїтет',
              alignment: 'center',
              fillColor: style.colors.mainRed,
              color: 'white',
            },
          },
          {
            // irregular
            state: obj?.resultData?.irregular,
            value: {
              text: 'Iндивiдуальний',
              alignment: 'center',
              fillColor: style.colors.mainRed,
              color: 'white',
            },
          },
          {
            // oper-leasing
            state: obj?.resultData?.['oper-leasing'],
            value: {
              text: 'Оперативний лізинг',
              alignment: 'center',
              fillColor: style.colors.mainRed,
              color: 'white',
            },
          },
          {
            // annuity-plus
            state: obj?.resultData?.['annuity-plus'],
            value: {
              text: 'Ануїтет з посиленням',
              alignment: 'center',
              fillColor: style.colors.mainRed,
              color: 'white',
            },
          },
          {
            // even-plus
            state: obj?.resultData?.['even-plus'],
            value: {
              text: 'Класичний з посиленням',
              alignment: 'center',
              fillColor: style.colors.mainRed,
              color: 'white',
            },
          },
        ]
          .filter(v => !v || v.state)
          .map(v => v.value),
        [
          '',
          {
            // even
            state: obj?.resultData?.even,
            value: {
              text: `${obj?.resultData?.even?.['offer-month-payment']}`,
              alignment: 'center',
            },
          },
          {
            // annuity
            state: obj?.resultData?.annuity,
            value: {
              text: `${obj?.resultData?.annuity?.['offer-month-payment']}`,
              alignment: 'center',
            },
          },
          {
            // irregular
            state: obj?.resultData?.irregular,
            value: {
              text: `${obj?.resultData?.irregular?.['offer-month-payment']}`,
              alignment: 'center',
            },
          },
          {
            // oper-leasing
            state: obj?.resultData?.['oper-leasing'],
            value: {
              text: `${obj?.resultData?.['oper-leasing']?.['offer-month-payment']}`,
              alignment: 'center',
            },
          },
          {
            // annuity-plus
            state: obj?.resultData?.['annuity-plus'],
            value: {
              text: `${obj?.resultData?.['annuity-plus']?.['offer-month-payment']}`,
              alignment: 'center',
            },
          },
          {
            // even-plus
            state: obj?.resultData?.['even-plus'],
            value: {
              text: `${obj?.resultData?.['even-plus']?.['offer-month-payment']}`,
              alignment: 'center',
            },
          },
        ]
          .filter(v => !v || v.state)
          .map(v => v.value),
      ],
    },
  },
  obj.requestData.isGiftCertificate
    ? {
        margin: [0, 0, 0, 1],
        layout: {
          hLineColor: () => '#e0e0e0',
          vLineColor: () => '#e0e0e0',
          paddingRight: () => 0,
          paddingLeft: () => 0,
        },
        table: {
          widths: ['30%', '70%'],
          body: [
            [
              {
                text: 'Подарунковий сертиф.',
                fillColor: style.colors.mainRed,
                color: 'white',
                margin: [5, 4],
              },
              {
                text: `${getBeautyNum(
                  obj?.calcRequestData?.giftCertificate
                )} грн`,
                alignment: 'center',
                margin: [5, 4],
              },
            ],
          ],
        },
      }
    : {},
]

export { calculationTable }
