<template>
  <div :class="`d-block section-card mt-${mt} mb-${mb} cursor-default`">
    <div>
      <div
        class="d-flex align-center pb-0"
        :style="{
          justifyContent:
            (addAction || $slots['add-action']) &&
            !addActionTwo &&
            !addActionDisabled &&
            !showSearch
              ? 'space-between'
              : 'flex-start',
        }">
        <div class="d-flex align-center">
          <slot name="title-prepend"></slot>
          <span
            class="list-section-title"
            style="color: rgb(28, 29, 33); font-weight: 600">
            {{ title }}
          </span>
          <slot name="title-append"></slot>
        </div>
        <div v-if="addAction && addActionTwo">
          <v-menu offset-y open-on-hover :nudge-top="4">
            <template #activator="{ props }">
              <v-btn
                data-readonly="hide"
                color="grey darken-3"
                density="comfortable"
                dark
                icon
                v-bind="props">
                <AddIcon />
              </v-btn>
            </template>
            <v-list elevation="1">
              <v-list-item :disabled="addActionDisabled" @click="addAction">
                <v-list-item-title>{{ addActionTitle }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="addActionTwo"
                :disabled="addActionDisabled"
                @click="addActionTwo">
                <v-list-item-title>{{ addActionTwoTitle }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <AddBtn
          v-if="addAction && !addActionTwo && !addActionDisabled"
          data-readonly="hide"
          @click="addAction" />
        <div v-if="!addAction && !addActionDisabled && $slots['add-action']">
          <slot name="add-action"></slot>
        </div>
        <v-scroll-x-transition hide-on-leave>
          <div v-if="showSearch">
            <v-text-field
              :readonly="disableSearch"
              :label="searchLabel"
              :loading="searchLoading"
              dense
              hide-details
              @focus="$emit('focus', $event)"
              @input="$emit('input', $event)">
            </v-text-field>
          </div>
        </v-scroll-x-transition>
      </div>
      <div class="mt-2">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import AddIcon from '@/assets/svg/add-circle.vue'
import AddBtn from '@/components/AddBtn.vue'
export default {
  components: { AddIcon, AddBtn },
  emits: ['input', 'focus'],
  props: {
    title: { type: String, default: 'Назва секції' },
    searchLoading: { type: Boolean },
    addActionTitle: { type: String, default: 'Додати' },
    addAction: { type: Function },
    addActionDisabled: { type: Boolean, default: false },
    showSearch: { type: Boolean, default: false },
    disableSearch: { type: Boolean, default: false },
    searchLabel: { type: String, default: 'Додати...' },
    addActionTwoTitle: { type: String, default: 'Додати' },
    addActionTwo: { type: Function },
    mt: { type: [String, Number], default: 3 },
    mb: { type: Number, default: 2 },
  },
}
</script>
<style scoped>
.list-section-title {
  font-size: 0.95rem;
  color: slategray;
}
</style>
