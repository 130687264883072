<template>
  <div>
    <b-autocomplete
      v-model="assignor"
      return-object
      label="Первісний кредитор"
      :items="$directory.get('assignmentAssignorList')"
      :item-title="c => c.shortName || c.fullName"
      :loading="$loading.isLoading('assignmentAssignorList')"
      :error-messages="getErrorMessages(v$.assignor)"
      @blur="v$.assignor.$touch()"></b-autocomplete>
    <b-autocomplete
      v-model="lessee"
      return-object
      label="Новий кредитор"
      :items="$directory.get('assignmentLesseeList')"
      :item-title="c => c.shortName || c.fullName"
      :loading="$loading.isLoading('assignmentLesseeList')"
      :error-messages="getErrorMessages(v$.lessee)"
      @blur="v$.lessee.$touch()"></b-autocomplete>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios.js'
import { urlAssignmentCreate } from '@/pages/request'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory } from '@/plugins/directory'

export default {
  name: 'AssignmentCreate',
  methods: { getErrorMessages },
  setup() {
    const assignor = ref()
    const lessee = ref()
    const rules = {
      assignor: { required },
      lessee: { required },
    }

    const v$ = useVuelidate(rules, { assignor, lessee })

    const submit = () => {
      const getTypeId = c => {
        switch (true) {
          case !!c.shortName:
            return 2
          case !!c.fullName && !!c.isFop:
            return 3
          case !!c.fullName && !c.isFop:
            return 1
        }
      }

      const assignment = {
        assignorId: assignor.value.id,
        assignorTypeId: getTypeId(assignor.value),
        lesseeId: lessee.value.id,
        lesseeTypeId: getTypeId(lessee.value),
      }
      return axios.post(urlAssignmentCreate(), assignment)
    }

    fillDirectory([
      { name: 'assignmentAssignorList', force: true },
      { name: 'assignmentLesseeList', force: true },
    ])

    return { v$, submit, assignor, lessee }
  },
}
</script>

<style scoped></style>
