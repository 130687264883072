<template>
  <v-col :cols="12" :md="9" class="pr-0">
    <div>
      <div class="pl-0 pr-3">
        <v-tabs
          v-model="currentTab"
          class="contact-tab"
          background-color="grey darken-2"
          show-arrows
          color="white"
          dark
          slider-color="red"
          dense>
          <v-tab
            replace
            :to="{
              name: 'contact-common-info',
              params: { id: $route.params.id },
            }">
            Контакти
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'contact-legal-data',
              params: { id: $route.params.id },
            }">
            Юридичні дані
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'contact-finance-data',
              params: { id: $route.params.id },
            }">
            Фінансові дані
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'contact-careers-education',
              params: { id: $route.params.id },
            }">
            Карьєра/Освіта
          </v-tab>
          <v-tab
            v-if="contact.applications"
            replace
            :to="{ name: 'contact-links', params: { id: $route.params.id } }">
            Зв'язки
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'contact-documents',
              params: { id: $route.params.id },
            }">
            Документи
          </v-tab>
          <v-tab
            v-if="contact.projects"
            replace
            :to="{
              name: 'contact-projects',
              params: { id: $route.params.id },
            }">
            Проекти
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'contact-agreements',
              params: { id: $route.params.id },
            }">
            Договори
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'contact-activity',
              params: { id: $route.params.id },
            }">
            Активностi
          </v-tab>
          <v-tab
            replace
            :to="{ name: 'contact-changes', params: { id: $route.params.id } }">
            Iсторiя змiн
          </v-tab>
        </v-tabs>
        <div class="pl-0 pr-0">
          <RouterView
            :readonly="readonly"
            :checkPhone="checkPhone"
            :checkEmail="checkEmail"
            :contact="contact"
            :queds="queds"
            :v="v"
            @table-change="$emit('tableChange')"
            @open-dialog="$emit('openDialog', $event)"
            @phones="$emit('update:phones', $event)"
            @emails="$emit('update:emails', $event)"
            @queds="$emit('update:queds', $event)">
          </RouterView>
        </div>
      </div>
    </div>
    <slot name="action-buttons"></slot>
  </v-col>
</template>
<script>
export default {
  emits: [
    'tableChange',
    'openDialog',
    'update:phones',
    'update:emails',
    'update:queds',
  ],
  props: {
    readonly: { type: Boolean },
    contact: { type: Object },
    queds: { type: Array },
    v: { type: Object },
    temporaryPhones: { type: Array },
    temporaryEmails: { type: Array },
    computedEmails: { type: Array },
    computedPhones: { type: Array },
    checkPhone: { type: Function },
    checkEmail: { type: Function },
  },
  data: () => ({ currentTab: 0 }),
  computed: {
    contactProvider() {
      return this.$array(() => this.contact.contactTypeId).includes(5)
    },
    providerRoute() {
      return this.$route.name === 'contact-provider'
    },
  },
  watch: {
    contactProvider(state) {
      !state && this.providerRoute && this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
  text-transform: uppercase;
}
</style>
