import { style } from './styles'

const customText = obj => {
  const isGift = obj.requestData?.isGiftCertificate

  const giftCertificate = isGift
    ? [
        {
          text: 'НОВОРІЧНА ПРОПОЗИЦІЯ',
          color: style.colors.mainRed,
          bold: true,
        },
        {
          text: ' з ',
        },
        {
          text: 'ПОДАРУНКОВИМ СЕРТИФІКАТОМ',
          color: style.colors.mainRed,
        },
      ]
    : [{ text: 'Комерційна пропозиція', bold: true }]

  const leasingType = obj.resultData['oper-leasing']
    ? 'оперативного'
    : 'фінансового'
  const date = new Date(obj['updated_at']).toLocaleDateString('ru-RU')
  const requestId = obj?.requestId || ''

  return [
    ...giftCertificate,
    ` з ${leasingType} лізингу від ${date} №${requestId}`,
  ]
}

const header = obj => [
  {
    image: obj.requestData?.isGiftCertificate
      ? 'backgroundNewYear'
      : 'background',
    width: '595',
    // height: '295',
    absolutePosition: { x: 0, y: 0 },
  },
  {
    width: '125',
    // margin: [0, 0, 0, 30],
    svg: `<svg viewBox="25 0 512 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="#ffffff">
      <path fill="transparent" opacity="1.00" d=" M 60.47 48.93 C 62.75 48.29 65.06 47.70 67.37 47.14 C 70.80 55.99 73.60 65.10 77.40 73.80 C 81.06 65.05 83.79 55.93 87.57 47.22 C 89.80 47.88 92.03 48.54 94.26 49.23 C 89.97 60.90 85.43 72.47 80.81 84.01 C 78.38 84.00 75.94 83.98 73.51 83.90 C 69.32 72.18 64.62 60.66 60.47 48.93 Z" />
      <path fill="transparent" opacity="1.00" d=" M 44.11 61.35 C 45.93 59.87 47.78 58.41 49.66 57.00 C 53.36 65.60 57.01 74.21 60.45 82.92 C 58.98 84.60 57.91 86.89 55.83 87.89 C 47.09 86.96 38.41 85.37 29.71 84.08 C 30.43 81.92 31.20 79.79 31.98 77.66 C 38.59 78.15 45.05 79.70 51.65 80.26 C 49.43 73.84 46.45 67.72 44.11 61.35 Z" />
      <path fill="transparent" opacity="1.00" d=" M 105.10 57.00 C 106.94 58.38 108.76 59.79 110.58 61.19 C 108.41 67.65 105.35 73.77 103.14 80.21 C 109.45 79.72 115.63 78.18 121.94 77.75 C 123.91 79.13 124.13 82.02 125.13 84.13 C 117.06 85.25 109.03 86.67 100.97 87.83 C 97.52 88.89 96.21 84.82 94.29 82.80 C 97.70 74.12 101.37 65.55 105.10 57.00 Z" />
      <path fill="transparent" opacity="1.00" d=" M 29.56 99.57 C 61.46 99.44 93.36 99.49 125.26 99.54 C 125.24 101.29 125.23 103.03 125.21 104.78 C 93.35 104.73 61.50 104.75 29.65 104.76 C 29.59 103.03 29.57 101.30 29.56 99.57 Z" />
      </g>
      <g id="#ffffff">
      <path fill="#de6967" opacity="1.00" d=" M 18.46 13.04 C 57.56 12.96 96.67 13.00 135.77 13.02 C 135.73 51.93 135.77 90.84 135.76 129.76 C 96.66 129.84 57.55 129.56 18.46 129.96 C 18.52 90.99 18.52 52.01 18.46 13.04 M 60.47 48.93 C 64.62 60.66 69.32 72.18 73.51 83.90 C 75.94 83.98 78.38 84.00 80.81 84.01 C 85.43 72.47 89.97 60.90 94.26 49.23 C 92.03 48.54 89.80 47.88 87.57 47.22 C 83.79 55.93 81.06 65.05 77.40 73.80 C 73.60 65.10 70.80 55.99 67.37 47.14 C 65.06 47.70 62.75 48.29 60.47 48.93 M 44.11 61.35 C 46.45 67.72 49.43 73.84 51.65 80.26 C 45.05 79.70 38.59 78.15 31.98 77.66 C 31.20 79.79 30.43 81.92 29.71 84.08 C 38.41 85.37 47.09 86.96 55.83 87.89 C 57.91 86.89 58.98 84.60 60.45 82.92 C 57.01 74.21 53.36 65.60 49.66 57.00 C 47.78 58.41 45.93 59.87 44.11 61.35 M 105.10 57.00 C 101.37 65.55 97.70 74.12 94.29 82.80 C 96.21 84.82 97.52 88.89 100.97 87.83 C 109.03 86.67 117.06 85.25 125.13 84.13 C 124.13 82.02 123.91 79.13 121.94 77.75 C 115.63 78.18 109.45 79.72 103.14 80.21 C 105.35 73.77 108.41 67.65 110.58 61.19 C 108.76 59.79 106.94 58.38 105.10 57.00 M 29.56 99.57 C 29.57 101.30 29.59 103.03 29.65 104.76 C 61.50 104.75 93.35 104.73 125.21 104.78 C 125.23 103.03 125.24 101.29 125.26 99.54 C 93.36 99.49 61.46 99.44 29.56 99.57 Z" />
      <path fill="#de6967" opacity="1.00" d=" M 158.22 99.26 C 271.14 99.21 384.06 99.27 496.98 99.23 C 496.99 101.23 497.00 103.23 497.01 105.23 C 384.08 105.25 271.15 105.31 158.22 105.20 C 158.22 103.22 158.22 101.24 158.22 99.26 Z" />
      </g>
      <g id="#ffffff">
      <path fill="black" opacity="1.00" d=" M 252.01 13.00 C 276.50 13.00 300.99 13.00 325.48 13.00 C 325.44 17.25 325.59 21.49 325.82 25.73 C 305.81 25.80 285.79 25.71 265.77 25.77 C 265.74 31.77 265.75 37.77 265.77 43.76 C 284.93 43.75 304.08 43.74 323.24 43.77 C 323.26 47.84 323.26 51.92 323.25 56.00 C 304.08 56.01 284.92 55.99 265.76 56.00 C 265.75 62.75 265.74 69.49 265.77 76.23 C 285.85 76.44 305.92 75.81 326.00 76.00 C 326.00 80.18 326.01 84.35 325.97 88.53 C 301.26 88.48 276.55 88.50 251.83 88.52 C 251.17 72.69 251.63 56.84 251.50 41.00 C 251.46 31.66 251.43 22.32 252.01 13.00 Z" />
      <path fill="black" opacity="1.00" d=" M 342.16 17.09 C 347.86 13.89 354.56 13.28 360.96 13.00 C 377.82 13.01 394.68 12.98 411.53 13.02 C 411.76 17.32 411.77 21.62 411.79 25.93 C 398.20 26.55 384.59 26.13 371.00 26.25 C 364.33 26.36 357.36 25.70 351.02 28.18 C 348.35 29.25 347.64 32.40 347.71 34.99 C 347.71 37.41 347.57 40.62 350.06 41.96 C 353.36 43.82 357.29 43.39 360.93 43.66 C 371.62 43.91 382.33 43.61 393.02 43.81 C 399.88 44.17 407.50 45.32 412.38 50.65 C 416.56 54.96 416.90 61.38 416.78 67.05 C 416.74 72.75 415.37 78.98 410.87 82.87 C 405.37 87.48 397.91 88.43 390.98 88.51 C 372.73 88.52 354.48 88.37 336.24 88.76 C 336.24 84.45 336.25 80.13 336.23 75.82 C 354.04 75.15 371.88 75.70 389.70 75.46 C 393.25 75.23 397.18 75.38 400.18 73.15 C 402.58 71.25 402.79 67.87 402.77 65.05 C 402.77 62.52 402.04 59.34 399.24 58.59 C 393.99 56.94 388.40 57.65 382.99 57.50 C 372.50 57.31 361.93 58.16 351.51 56.56 C 346.54 55.67 341.16 54.25 337.85 50.14 C 333.69 45.10 333.48 38.17 333.79 31.95 C 334.09 26.11 336.80 19.94 342.16 17.09 Z" />
      <path fill="black" opacity="1.00" d=" M 419.58 13.00 C 445.28 13.01 470.99 12.97 496.69 13.02 C 496.67 17.42 496.68 21.81 496.63 26.21 C 486.05 26.30 475.46 26.23 464.88 26.25 C 464.78 47.09 464.94 67.92 464.79 88.76 C 460.31 88.75 455.84 88.76 451.38 88.75 C 451.32 67.91 451.46 47.08 451.31 26.24 C 440.73 26.26 430.15 26.26 419.57 26.24 C 419.52 21.83 419.53 17.41 419.58 13.00 Z" />
      <path fill="black" opacity="1.00" d=" M 158.23 14.01 C 178.84 13.98 199.46 14.01 220.07 13.99 C 226.34 14.02 233.35 15.34 237.46 20.53 C 241.55 25.76 241.25 32.82 240.83 39.08 C 240.74 42.78 238.22 45.65 235.84 48.20 C 238.30 50.34 241.24 52.31 242.38 55.52 C 244.00 59.84 243.78 64.53 243.77 69.06 C 243.74 73.27 243.17 77.92 240.13 81.11 C 236.71 84.96 231.69 87.00 226.68 87.65 C 216.80 88.82 206.86 87.55 196.97 88.18 C 184.06 88.46 171.14 88.12 158.23 87.99 C 158.25 63.33 158.25 38.67 158.23 14.01 M 172.01 25.91 C 171.99 31.94 172.00 37.97 172.00 44.00 C 187.70 43.99 203.41 44.02 219.11 43.99 C 221.85 43.89 225.43 43.49 226.69 40.63 C 227.75 36.95 227.85 32.93 226.90 29.22 C 225.39 25.93 221.13 25.93 218.03 25.76 C 202.69 25.84 187.35 25.57 172.01 25.91 M 172.00 56.04 C 172.00 62.78 172.00 69.51 172.00 76.24 C 188.35 76.25 204.70 76.26 221.05 76.25 C 223.92 76.17 227.43 75.79 229.09 73.10 C 230.38 70.61 230.12 67.67 230.14 64.96 C 229.96 62.13 229.93 58.34 226.82 57.09 C 221.74 55.28 216.24 56.31 210.97 55.80 C 197.98 55.77 184.98 55.57 172.00 56.04 Z" />
      <path fill="black" opacity="1.00" d=" M 288.50 116.25 C 289.83 116.25 291.16 116.25 292.50 116.25 C 292.51 124.66 292.49 133.07 292.52 141.49 C 299.07 141.51 305.63 141.51 312.19 141.48 C 312.22 142.90 312.24 144.32 312.27 145.75 C 304.34 145.76 296.41 145.76 288.48 145.75 C 288.52 135.91 288.49 126.08 288.50 116.25 Z" />
      <path fill="black" opacity="1.00" d=" M 318.24 116.25 C 326.51 116.24 334.77 116.27 343.03 116.23 C 342.97 117.63 342.91 119.03 342.85 120.44 C 336.03 120.58 329.21 120.46 322.40 120.51 C 322.25 123.15 322.25 125.79 322.47 128.43 C 329.17 128.59 335.87 128.43 342.58 128.54 C 342.50 129.95 342.42 131.37 342.34 132.78 C 335.67 132.75 329.01 132.70 322.36 132.82 C 322.23 135.71 322.33 138.61 322.52 141.51 C 329.35 141.50 336.18 141.48 343.02 141.52 C 343.00 142.94 342.98 144.36 342.97 145.78 C 334.73 145.72 326.48 145.77 318.24 145.76 C 318.24 135.92 318.25 126.08 318.24 116.25 Z" />
      <path fill="black" opacity="1.00" d=" M 362.28 116.18 C 363.98 116.23 365.68 116.28 367.38 116.34 C 371.94 126.18 376.83 135.85 381.41 145.68 C 379.92 145.74 378.43 145.81 376.94 145.89 C 375.58 143.57 374.92 140.65 372.90 138.84 C 367.23 138.58 361.55 138.84 355.87 138.75 C 354.72 141.05 353.61 143.38 352.54 145.72 C 351.02 145.74 349.49 145.75 347.98 145.76 C 352.64 135.85 357.33 125.94 362.28 116.18 M 364.65 120.95 C 362.42 125.44 360.11 129.89 358.09 134.48 C 362.44 134.51 366.79 134.52 371.15 134.47 C 369.15 129.89 367.02 125.35 364.65 120.95 Z" />
      <path fill="black" opacity="1.00" d=" M 389.68 132.20 C 382.45 130.13 383.69 117.23 391.20 116.52 C 398.35 115.89 405.56 116.44 412.73 116.22 C 412.75 117.63 412.78 119.03 412.81 120.44 C 405.87 120.74 398.89 120.10 391.97 120.82 C 387.98 120.84 387.79 128.14 391.85 128.21 C 398.37 129.20 405.17 127.46 411.55 129.51 C 418.48 132.40 416.82 145.39 408.96 145.64 C 401.57 146.05 394.16 145.61 386.77 145.78 C 386.76 144.43 386.75 143.10 386.75 141.76 C 393.94 141.10 401.18 141.91 408.36 141.28 C 412.67 141.21 413.13 133.48 408.86 133.09 C 402.51 132.14 396.00 133.47 389.68 132.20 Z" />
      <path fill="black" opacity="1.00" d=" M 420.83 116.23 C 422.14 116.24 423.45 116.24 424.77 116.25 C 424.75 126.09 424.75 135.92 424.76 145.75 C 423.44 145.76 422.11 145.76 420.79 145.77 C 420.30 135.94 420.32 126.06 420.83 116.23 Z" />
      <path fill="black" opacity="1.00" d=" M 430.49 116.22 C 433.29 116.56 437.12 115.19 438.88 118.10 C 444.49 125.19 449.83 132.49 455.65 139.40 C 456.23 131.69 455.92 123.96 456.02 116.23 C 457.33 116.23 458.65 116.23 459.98 116.23 C 460.00 126.09 460.04 135.95 459.96 145.80 C 458.38 145.77 456.80 145.74 455.23 145.71 C 448.33 137.33 441.98 128.49 434.90 120.26 C 434.30 128.77 434.62 137.31 434.42 145.83 C 433.09 145.79 431.77 145.74 430.45 145.70 C 430.55 135.87 430.48 126.05 430.49 116.22 Z" />
      <path fill="black" opacity="1.00" d=" M 465.56 120.61 C 467.11 116.45 472.09 115.87 475.93 115.99 C 481.50 116.25 487.45 114.89 492.71 117.32 C 495.85 118.63 496.14 122.36 497.00 125.21 C 495.71 125.23 494.43 125.25 493.16 125.27 C 491.98 123.51 491.41 120.46 488.77 120.52 C 482.60 120.17 476.14 119.41 470.13 121.13 C 467.74 124.96 468.65 129.74 468.51 134.02 C 468.68 136.69 468.50 140.93 472.10 141.25 C 477.67 141.71 483.33 141.65 488.91 141.23 C 492.05 141.30 492.11 137.51 492.59 135.27 C 488.21 135.24 483.83 135.24 479.45 135.27 C 479.47 133.84 479.49 132.42 479.52 130.99 C 485.29 131.02 491.07 130.95 496.84 131.05 C 496.60 134.98 497.02 139.33 494.79 142.80 C 492.88 145.61 489.14 145.76 486.07 145.76 C 480.43 145.64 474.61 146.64 469.15 144.81 C 466.31 143.71 464.78 140.67 464.60 137.77 C 464.29 132.07 463.71 126.12 465.56 120.61 Z" />
      <path fill="black" opacity="1.00" d=" M 24.13 136.77 C 27.10 135.96 30.22 136.26 33.16 137.03 C 30.89 140.16 26.44 137.96 23.57 140.17 C 26.19 141.22 29.19 141.07 31.70 142.41 C 33.03 144.07 32.82 147.00 30.73 147.92 C 27.78 148.95 24.60 148.45 21.58 148.08 C 22.85 143.89 28.75 147.96 30.52 144.29 C 28.26 143.89 25.93 143.87 23.70 143.28 C 21.31 141.82 21.50 137.85 24.13 136.77 Z" />
      <path fill="black" opacity="1.00" d=" M 64.84 136.52 C 68.21 136.41 71.59 136.45 74.97 136.50 C 74.96 137.04 74.94 138.13 74.93 138.68 C 72.11 138.92 69.27 139.01 66.47 139.41 C 68.21 142.23 72.38 140.43 74.37 143.10 C 71.81 144.08 68.04 142.85 66.51 145.65 C 69.37 146.71 74.45 144.36 75.46 148.17 C 71.92 148.57 68.35 148.60 64.81 148.32 C 64.72 144.38 64.68 140.45 64.84 136.52 Z" />
      <path fill="black" opacity="1.00" d=" M 83.55 138.58 C 85.68 134.63 94.11 135.80 93.51 140.96 C 93.24 143.95 89.69 144.58 87.59 145.97 C 89.68 146.01 91.76 146.05 93.85 146.19 C 93.50 147.77 92.55 148.52 91.00 148.45 C 88.20 148.67 85.39 148.47 82.61 148.14 C 82.96 143.82 87.63 143.08 90.82 141.58 C 90.83 140.95 90.85 139.69 90.86 139.06 C 89.39 139.08 87.88 138.85 86.44 139.22 C 85.54 140.26 84.70 141.36 83.82 142.41 C 83.51 141.16 82.64 139.80 83.55 138.58 Z" />
      <path fill="black" opacity="1.00" d=" M 98.55 136.64 C 100.83 136.16 103.58 135.95 105.53 137.46 C 107.14 139.47 106.85 142.31 106.59 144.71 C 106.37 150.32 95.23 149.94 95.99 143.99 C 95.94 141.48 95.49 137.57 98.55 136.64 M 98.67 138.95 C 98.55 141.26 98.56 143.58 98.72 145.90 C 100.44 145.93 102.17 145.93 103.89 145.89 C 104.04 143.61 104.08 141.33 104.05 139.06 C 102.25 138.99 100.46 138.96 98.67 138.95 Z" />
      <path fill="black" opacity="1.00" d=" M 111.55 136.64 C 113.83 136.16 116.59 135.95 118.53 137.47 C 120.14 139.48 119.85 142.31 119.59 144.71 C 119.38 150.32 108.23 149.94 108.99 143.99 C 108.96 141.49 108.52 137.57 111.55 136.64 M 111.69 138.96 C 111.58 141.27 111.57 143.58 111.71 145.89 C 113.45 145.92 115.20 145.93 116.94 145.90 C 117.11 143.59 117.16 141.27 117.08 138.96 C 115.28 138.94 113.49 138.94 111.69 138.96 Z" />
      <path fill="black" opacity="1.00" d=" M 121.81 136.51 C 124.90 136.36 128.00 136.41 131.10 136.50 C 131.13 137.05 131.20 138.16 131.23 138.72 C 128.64 138.91 126.04 139.04 123.47 139.42 C 125.25 142.60 130.16 140.03 131.86 143.36 C 133.17 145.94 130.65 148.67 128.00 148.47 C 125.68 148.45 121.99 149.39 121.39 146.23 C 124.43 145.90 127.65 146.41 130.46 144.93 C 127.81 143.40 124.71 143.78 121.79 143.66 C 121.53 141.28 121.51 138.89 121.81 136.51 Z" />
      <path fill="black" opacity="1.00" d=" M 34.86 136.98 C 35.35 136.80 36.34 136.43 36.84 136.25 C 37.09 140.37 37.08 144.50 36.84 148.62 C 36.34 148.45 35.35 148.10 34.85 147.93 C 34.24 144.33 34.26 140.58 34.86 136.98 Z" />
      <path fill="black" opacity="1.00" d=" M 39.78 148.75 C 39.61 144.48 38.67 139.88 40.55 135.85 C 42.95 138.20 45.12 140.78 47.37 143.28 C 47.48 141.08 47.53 138.88 47.82 136.70 C 48.33 136.69 49.36 136.67 49.87 136.66 C 50.07 140.76 50.43 144.94 49.44 148.98 C 46.68 146.80 44.46 144.07 42.15 141.45 C 41.57 143.91 43.27 148.16 39.78 148.75 Z" />
      <path fill="black" opacity="1.00" d=" M 53.16 145.83 C 50.83 142.73 52.51 137.88 56.15 136.72 C 58.24 136.01 60.34 136.93 62.31 137.57 C 62.41 138.21 62.61 139.49 62.71 140.13 C 60.45 139.93 57.74 137.98 55.78 139.84 C 53.71 141.56 54.66 145.11 57.16 145.92 C 59.24 145.65 61.27 145.06 63.35 144.72 C 61.99 149.25 55.57 149.62 53.16 145.83 Z" />
      </g>
    </svg>`,
  },
  // header leasing-card title. Static
  {
    margin: obj.requestData?.isGiftCertificate
      ? [0, 10, 0, -10]
      : [0, 30, 0, 10],
    columns: [
      {
        width: '52.4%',
        fontSize: 14,
        text: customText(obj),
        columnGap: 10,
        color: style.colors.darkGrey,
        bold: true,
        lineHeight: 1.3,
      },
    ],
  },
  {
    margin: obj.resultData['oper-leasing'] ? [0, 5, 0, 2] : [0, 15, 0, 2],
    text: [
      {
        text: `${
          obj.resultData['oper-leasing']
            ? 'Нульовий лiзiнговий платiж:'
            : 'Аванс:'
        }`,
        margin: [0, 12, 0, 0],
        bolditalics: true,
      },
      {
        text: ` ${obj?.requestData?.advance}%`,
        margin: [0, 12, 0, 0],
        color: '#d7514f',
        bold: true,
      },
    ],
  },
  {
    margin: [0, 4],
    text: [
      {
        text: 'Термiн лізингу:',
        margin: [0, 12, 0, 0],
      },
      {
        text: ` ${obj?.requestData?.leasingTerm} мiс`,
        margin: [0, 12, 0, 0],
        bold: true,
        color: '#d7514f',
      },
    ],
  },
  {
    margin: [0, 4],
    text: [
      {
        text: `${
          obj.resultData['oper-leasing']
            ? 'Щомiсячний платiж'
            : 'Середньомiсячний платiж:'
        }`,
        margin: [0, 12, 0, 0],
      },
      {
        text: ` ${
          obj?.resultData[Object.keys(obj?.resultData)[0]][
            'offer-month-payment'
          ]
        }`,
        margin: [0, 12, 0, 0],
        bold: true,
        color: '#d7514f',
      }, // 215, 81, 79
    ],
  },
]

const fsnHeader = obj => [
  {
    image: 'fsnBackground',
    width: '595',
    // height: '295',
    absolutePosition: { x: 0, y: 80 },
  },
  {
    width: '125',
    // margin: [0, 0, 0, 30],
    svg: `<svg viewBox="25 0 512 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="#ffffff">
      <path fill="transparent" opacity="1.00" d=" M 60.47 48.93 C 62.75 48.29 65.06 47.70 67.37 47.14 C 70.80 55.99 73.60 65.10 77.40 73.80 C 81.06 65.05 83.79 55.93 87.57 47.22 C 89.80 47.88 92.03 48.54 94.26 49.23 C 89.97 60.90 85.43 72.47 80.81 84.01 C 78.38 84.00 75.94 83.98 73.51 83.90 C 69.32 72.18 64.62 60.66 60.47 48.93 Z" />
      <path fill="transparent" opacity="1.00" d=" M 44.11 61.35 C 45.93 59.87 47.78 58.41 49.66 57.00 C 53.36 65.60 57.01 74.21 60.45 82.92 C 58.98 84.60 57.91 86.89 55.83 87.89 C 47.09 86.96 38.41 85.37 29.71 84.08 C 30.43 81.92 31.20 79.79 31.98 77.66 C 38.59 78.15 45.05 79.70 51.65 80.26 C 49.43 73.84 46.45 67.72 44.11 61.35 Z" />
      <path fill="transparent" opacity="1.00" d=" M 105.10 57.00 C 106.94 58.38 108.76 59.79 110.58 61.19 C 108.41 67.65 105.35 73.77 103.14 80.21 C 109.45 79.72 115.63 78.18 121.94 77.75 C 123.91 79.13 124.13 82.02 125.13 84.13 C 117.06 85.25 109.03 86.67 100.97 87.83 C 97.52 88.89 96.21 84.82 94.29 82.80 C 97.70 74.12 101.37 65.55 105.10 57.00 Z" />
      <path fill="transparent" opacity="1.00" d=" M 29.56 99.57 C 61.46 99.44 93.36 99.49 125.26 99.54 C 125.24 101.29 125.23 103.03 125.21 104.78 C 93.35 104.73 61.50 104.75 29.65 104.76 C 29.59 103.03 29.57 101.30 29.56 99.57 Z" />
      </g>
      <g id="#ffffff">
      <path fill="#de6967" opacity="1.00" d=" M 18.46 13.04 C 57.56 12.96 96.67 13.00 135.77 13.02 C 135.73 51.93 135.77 90.84 135.76 129.76 C 96.66 129.84 57.55 129.56 18.46 129.96 C 18.52 90.99 18.52 52.01 18.46 13.04 M 60.47 48.93 C 64.62 60.66 69.32 72.18 73.51 83.90 C 75.94 83.98 78.38 84.00 80.81 84.01 C 85.43 72.47 89.97 60.90 94.26 49.23 C 92.03 48.54 89.80 47.88 87.57 47.22 C 83.79 55.93 81.06 65.05 77.40 73.80 C 73.60 65.10 70.80 55.99 67.37 47.14 C 65.06 47.70 62.75 48.29 60.47 48.93 M 44.11 61.35 C 46.45 67.72 49.43 73.84 51.65 80.26 C 45.05 79.70 38.59 78.15 31.98 77.66 C 31.20 79.79 30.43 81.92 29.71 84.08 C 38.41 85.37 47.09 86.96 55.83 87.89 C 57.91 86.89 58.98 84.60 60.45 82.92 C 57.01 74.21 53.36 65.60 49.66 57.00 C 47.78 58.41 45.93 59.87 44.11 61.35 M 105.10 57.00 C 101.37 65.55 97.70 74.12 94.29 82.80 C 96.21 84.82 97.52 88.89 100.97 87.83 C 109.03 86.67 117.06 85.25 125.13 84.13 C 124.13 82.02 123.91 79.13 121.94 77.75 C 115.63 78.18 109.45 79.72 103.14 80.21 C 105.35 73.77 108.41 67.65 110.58 61.19 C 108.76 59.79 106.94 58.38 105.10 57.00 M 29.56 99.57 C 29.57 101.30 29.59 103.03 29.65 104.76 C 61.50 104.75 93.35 104.73 125.21 104.78 C 125.23 103.03 125.24 101.29 125.26 99.54 C 93.36 99.49 61.46 99.44 29.56 99.57 Z" />
      <path fill="#de6967" opacity="1.00" d=" M 158.22 99.26 C 271.14 99.21 384.06 99.27 496.98 99.23 C 496.99 101.23 497.00 103.23 497.01 105.23 C 384.08 105.25 271.15 105.31 158.22 105.20 C 158.22 103.22 158.22 101.24 158.22 99.26 Z" />
      </g>
      <g id="#ffffff">
      <path fill="black" opacity="1.00" d=" M 252.01 13.00 C 276.50 13.00 300.99 13.00 325.48 13.00 C 325.44 17.25 325.59 21.49 325.82 25.73 C 305.81 25.80 285.79 25.71 265.77 25.77 C 265.74 31.77 265.75 37.77 265.77 43.76 C 284.93 43.75 304.08 43.74 323.24 43.77 C 323.26 47.84 323.26 51.92 323.25 56.00 C 304.08 56.01 284.92 55.99 265.76 56.00 C 265.75 62.75 265.74 69.49 265.77 76.23 C 285.85 76.44 305.92 75.81 326.00 76.00 C 326.00 80.18 326.01 84.35 325.97 88.53 C 301.26 88.48 276.55 88.50 251.83 88.52 C 251.17 72.69 251.63 56.84 251.50 41.00 C 251.46 31.66 251.43 22.32 252.01 13.00 Z" />
      <path fill="black" opacity="1.00" d=" M 342.16 17.09 C 347.86 13.89 354.56 13.28 360.96 13.00 C 377.82 13.01 394.68 12.98 411.53 13.02 C 411.76 17.32 411.77 21.62 411.79 25.93 C 398.20 26.55 384.59 26.13 371.00 26.25 C 364.33 26.36 357.36 25.70 351.02 28.18 C 348.35 29.25 347.64 32.40 347.71 34.99 C 347.71 37.41 347.57 40.62 350.06 41.96 C 353.36 43.82 357.29 43.39 360.93 43.66 C 371.62 43.91 382.33 43.61 393.02 43.81 C 399.88 44.17 407.50 45.32 412.38 50.65 C 416.56 54.96 416.90 61.38 416.78 67.05 C 416.74 72.75 415.37 78.98 410.87 82.87 C 405.37 87.48 397.91 88.43 390.98 88.51 C 372.73 88.52 354.48 88.37 336.24 88.76 C 336.24 84.45 336.25 80.13 336.23 75.82 C 354.04 75.15 371.88 75.70 389.70 75.46 C 393.25 75.23 397.18 75.38 400.18 73.15 C 402.58 71.25 402.79 67.87 402.77 65.05 C 402.77 62.52 402.04 59.34 399.24 58.59 C 393.99 56.94 388.40 57.65 382.99 57.50 C 372.50 57.31 361.93 58.16 351.51 56.56 C 346.54 55.67 341.16 54.25 337.85 50.14 C 333.69 45.10 333.48 38.17 333.79 31.95 C 334.09 26.11 336.80 19.94 342.16 17.09 Z" />
      <path fill="black" opacity="1.00" d=" M 419.58 13.00 C 445.28 13.01 470.99 12.97 496.69 13.02 C 496.67 17.42 496.68 21.81 496.63 26.21 C 486.05 26.30 475.46 26.23 464.88 26.25 C 464.78 47.09 464.94 67.92 464.79 88.76 C 460.31 88.75 455.84 88.76 451.38 88.75 C 451.32 67.91 451.46 47.08 451.31 26.24 C 440.73 26.26 430.15 26.26 419.57 26.24 C 419.52 21.83 419.53 17.41 419.58 13.00 Z" />
      <path fill="black" opacity="1.00" d=" M 158.23 14.01 C 178.84 13.98 199.46 14.01 220.07 13.99 C 226.34 14.02 233.35 15.34 237.46 20.53 C 241.55 25.76 241.25 32.82 240.83 39.08 C 240.74 42.78 238.22 45.65 235.84 48.20 C 238.30 50.34 241.24 52.31 242.38 55.52 C 244.00 59.84 243.78 64.53 243.77 69.06 C 243.74 73.27 243.17 77.92 240.13 81.11 C 236.71 84.96 231.69 87.00 226.68 87.65 C 216.80 88.82 206.86 87.55 196.97 88.18 C 184.06 88.46 171.14 88.12 158.23 87.99 C 158.25 63.33 158.25 38.67 158.23 14.01 M 172.01 25.91 C 171.99 31.94 172.00 37.97 172.00 44.00 C 187.70 43.99 203.41 44.02 219.11 43.99 C 221.85 43.89 225.43 43.49 226.69 40.63 C 227.75 36.95 227.85 32.93 226.90 29.22 C 225.39 25.93 221.13 25.93 218.03 25.76 C 202.69 25.84 187.35 25.57 172.01 25.91 M 172.00 56.04 C 172.00 62.78 172.00 69.51 172.00 76.24 C 188.35 76.25 204.70 76.26 221.05 76.25 C 223.92 76.17 227.43 75.79 229.09 73.10 C 230.38 70.61 230.12 67.67 230.14 64.96 C 229.96 62.13 229.93 58.34 226.82 57.09 C 221.74 55.28 216.24 56.31 210.97 55.80 C 197.98 55.77 184.98 55.57 172.00 56.04 Z" />
      <path fill="black" opacity="1.00" d=" M 288.50 116.25 C 289.83 116.25 291.16 116.25 292.50 116.25 C 292.51 124.66 292.49 133.07 292.52 141.49 C 299.07 141.51 305.63 141.51 312.19 141.48 C 312.22 142.90 312.24 144.32 312.27 145.75 C 304.34 145.76 296.41 145.76 288.48 145.75 C 288.52 135.91 288.49 126.08 288.50 116.25 Z" />
      <path fill="black" opacity="1.00" d=" M 318.24 116.25 C 326.51 116.24 334.77 116.27 343.03 116.23 C 342.97 117.63 342.91 119.03 342.85 120.44 C 336.03 120.58 329.21 120.46 322.40 120.51 C 322.25 123.15 322.25 125.79 322.47 128.43 C 329.17 128.59 335.87 128.43 342.58 128.54 C 342.50 129.95 342.42 131.37 342.34 132.78 C 335.67 132.75 329.01 132.70 322.36 132.82 C 322.23 135.71 322.33 138.61 322.52 141.51 C 329.35 141.50 336.18 141.48 343.02 141.52 C 343.00 142.94 342.98 144.36 342.97 145.78 C 334.73 145.72 326.48 145.77 318.24 145.76 C 318.24 135.92 318.25 126.08 318.24 116.25 Z" />
      <path fill="black" opacity="1.00" d=" M 362.28 116.18 C 363.98 116.23 365.68 116.28 367.38 116.34 C 371.94 126.18 376.83 135.85 381.41 145.68 C 379.92 145.74 378.43 145.81 376.94 145.89 C 375.58 143.57 374.92 140.65 372.90 138.84 C 367.23 138.58 361.55 138.84 355.87 138.75 C 354.72 141.05 353.61 143.38 352.54 145.72 C 351.02 145.74 349.49 145.75 347.98 145.76 C 352.64 135.85 357.33 125.94 362.28 116.18 M 364.65 120.95 C 362.42 125.44 360.11 129.89 358.09 134.48 C 362.44 134.51 366.79 134.52 371.15 134.47 C 369.15 129.89 367.02 125.35 364.65 120.95 Z" />
      <path fill="black" opacity="1.00" d=" M 389.68 132.20 C 382.45 130.13 383.69 117.23 391.20 116.52 C 398.35 115.89 405.56 116.44 412.73 116.22 C 412.75 117.63 412.78 119.03 412.81 120.44 C 405.87 120.74 398.89 120.10 391.97 120.82 C 387.98 120.84 387.79 128.14 391.85 128.21 C 398.37 129.20 405.17 127.46 411.55 129.51 C 418.48 132.40 416.82 145.39 408.96 145.64 C 401.57 146.05 394.16 145.61 386.77 145.78 C 386.76 144.43 386.75 143.10 386.75 141.76 C 393.94 141.10 401.18 141.91 408.36 141.28 C 412.67 141.21 413.13 133.48 408.86 133.09 C 402.51 132.14 396.00 133.47 389.68 132.20 Z" />
      <path fill="black" opacity="1.00" d=" M 420.83 116.23 C 422.14 116.24 423.45 116.24 424.77 116.25 C 424.75 126.09 424.75 135.92 424.76 145.75 C 423.44 145.76 422.11 145.76 420.79 145.77 C 420.30 135.94 420.32 126.06 420.83 116.23 Z" />
      <path fill="black" opacity="1.00" d=" M 430.49 116.22 C 433.29 116.56 437.12 115.19 438.88 118.10 C 444.49 125.19 449.83 132.49 455.65 139.40 C 456.23 131.69 455.92 123.96 456.02 116.23 C 457.33 116.23 458.65 116.23 459.98 116.23 C 460.00 126.09 460.04 135.95 459.96 145.80 C 458.38 145.77 456.80 145.74 455.23 145.71 C 448.33 137.33 441.98 128.49 434.90 120.26 C 434.30 128.77 434.62 137.31 434.42 145.83 C 433.09 145.79 431.77 145.74 430.45 145.70 C 430.55 135.87 430.48 126.05 430.49 116.22 Z" />
      <path fill="black" opacity="1.00" d=" M 465.56 120.61 C 467.11 116.45 472.09 115.87 475.93 115.99 C 481.50 116.25 487.45 114.89 492.71 117.32 C 495.85 118.63 496.14 122.36 497.00 125.21 C 495.71 125.23 494.43 125.25 493.16 125.27 C 491.98 123.51 491.41 120.46 488.77 120.52 C 482.60 120.17 476.14 119.41 470.13 121.13 C 467.74 124.96 468.65 129.74 468.51 134.02 C 468.68 136.69 468.50 140.93 472.10 141.25 C 477.67 141.71 483.33 141.65 488.91 141.23 C 492.05 141.30 492.11 137.51 492.59 135.27 C 488.21 135.24 483.83 135.24 479.45 135.27 C 479.47 133.84 479.49 132.42 479.52 130.99 C 485.29 131.02 491.07 130.95 496.84 131.05 C 496.60 134.98 497.02 139.33 494.79 142.80 C 492.88 145.61 489.14 145.76 486.07 145.76 C 480.43 145.64 474.61 146.64 469.15 144.81 C 466.31 143.71 464.78 140.67 464.60 137.77 C 464.29 132.07 463.71 126.12 465.56 120.61 Z" />
      <path fill="black" opacity="1.00" d=" M 24.13 136.77 C 27.10 135.96 30.22 136.26 33.16 137.03 C 30.89 140.16 26.44 137.96 23.57 140.17 C 26.19 141.22 29.19 141.07 31.70 142.41 C 33.03 144.07 32.82 147.00 30.73 147.92 C 27.78 148.95 24.60 148.45 21.58 148.08 C 22.85 143.89 28.75 147.96 30.52 144.29 C 28.26 143.89 25.93 143.87 23.70 143.28 C 21.31 141.82 21.50 137.85 24.13 136.77 Z" />
      <path fill="black" opacity="1.00" d=" M 64.84 136.52 C 68.21 136.41 71.59 136.45 74.97 136.50 C 74.96 137.04 74.94 138.13 74.93 138.68 C 72.11 138.92 69.27 139.01 66.47 139.41 C 68.21 142.23 72.38 140.43 74.37 143.10 C 71.81 144.08 68.04 142.85 66.51 145.65 C 69.37 146.71 74.45 144.36 75.46 148.17 C 71.92 148.57 68.35 148.60 64.81 148.32 C 64.72 144.38 64.68 140.45 64.84 136.52 Z" />
      <path fill="black" opacity="1.00" d=" M 83.55 138.58 C 85.68 134.63 94.11 135.80 93.51 140.96 C 93.24 143.95 89.69 144.58 87.59 145.97 C 89.68 146.01 91.76 146.05 93.85 146.19 C 93.50 147.77 92.55 148.52 91.00 148.45 C 88.20 148.67 85.39 148.47 82.61 148.14 C 82.96 143.82 87.63 143.08 90.82 141.58 C 90.83 140.95 90.85 139.69 90.86 139.06 C 89.39 139.08 87.88 138.85 86.44 139.22 C 85.54 140.26 84.70 141.36 83.82 142.41 C 83.51 141.16 82.64 139.80 83.55 138.58 Z" />
      <path fill="black" opacity="1.00" d=" M 98.55 136.64 C 100.83 136.16 103.58 135.95 105.53 137.46 C 107.14 139.47 106.85 142.31 106.59 144.71 C 106.37 150.32 95.23 149.94 95.99 143.99 C 95.94 141.48 95.49 137.57 98.55 136.64 M 98.67 138.95 C 98.55 141.26 98.56 143.58 98.72 145.90 C 100.44 145.93 102.17 145.93 103.89 145.89 C 104.04 143.61 104.08 141.33 104.05 139.06 C 102.25 138.99 100.46 138.96 98.67 138.95 Z" />
      <path fill="black" opacity="1.00" d=" M 111.55 136.64 C 113.83 136.16 116.59 135.95 118.53 137.47 C 120.14 139.48 119.85 142.31 119.59 144.71 C 119.38 150.32 108.23 149.94 108.99 143.99 C 108.96 141.49 108.52 137.57 111.55 136.64 M 111.69 138.96 C 111.58 141.27 111.57 143.58 111.71 145.89 C 113.45 145.92 115.20 145.93 116.94 145.90 C 117.11 143.59 117.16 141.27 117.08 138.96 C 115.28 138.94 113.49 138.94 111.69 138.96 Z" />
      <path fill="black" opacity="1.00" d=" M 121.81 136.51 C 124.90 136.36 128.00 136.41 131.10 136.50 C 131.13 137.05 131.20 138.16 131.23 138.72 C 128.64 138.91 126.04 139.04 123.47 139.42 C 125.25 142.60 130.16 140.03 131.86 143.36 C 133.17 145.94 130.65 148.67 128.00 148.47 C 125.68 148.45 121.99 149.39 121.39 146.23 C 124.43 145.90 127.65 146.41 130.46 144.93 C 127.81 143.40 124.71 143.78 121.79 143.66 C 121.53 141.28 121.51 138.89 121.81 136.51 Z" />
      <path fill="black" opacity="1.00" d=" M 34.86 136.98 C 35.35 136.80 36.34 136.43 36.84 136.25 C 37.09 140.37 37.08 144.50 36.84 148.62 C 36.34 148.45 35.35 148.10 34.85 147.93 C 34.24 144.33 34.26 140.58 34.86 136.98 Z" />
      <path fill="black" opacity="1.00" d=" M 39.78 148.75 C 39.61 144.48 38.67 139.88 40.55 135.85 C 42.95 138.20 45.12 140.78 47.37 143.28 C 47.48 141.08 47.53 138.88 47.82 136.70 C 48.33 136.69 49.36 136.67 49.87 136.66 C 50.07 140.76 50.43 144.94 49.44 148.98 C 46.68 146.80 44.46 144.07 42.15 141.45 C 41.57 143.91 43.27 148.16 39.78 148.75 Z" />
      <path fill="black" opacity="1.00" d=" M 53.16 145.83 C 50.83 142.73 52.51 137.88 56.15 136.72 C 58.24 136.01 60.34 136.93 62.31 137.57 C 62.41 138.21 62.61 139.49 62.71 140.13 C 60.45 139.93 57.74 137.98 55.78 139.84 C 53.71 141.56 54.66 145.11 57.16 145.92 C 59.24 145.65 61.27 145.06 63.35 144.72 C 61.99 149.25 55.57 149.62 53.16 145.83 Z" />
      </g>
    </svg>`,
  },
  // header leasing-card title. Static
  {
    fontSize: 16,
    text: `Комерційна пропозиція від ${new Date(obj['updated_at'])
      .toLocaleString('ru-RU')
      .substring(0, 10)} №${obj?.requestId}`,
    margin: [0, 25, 0, 0],
    columnGap: 10,
    color: style.colors.darkGrey,
    bold: true,
    lineHeight: 1.3,
  },
  {
    width: '100%',
    fontSize: 16,
    text: '«Доступний фінансовий лізинг 5-7-9%»',
    columnGap: 10,
    color: style.colors.mainRed,
    bold: true,
    lineHeight: 1.3,
  },
  {
    text: 'Аванс:',
    margin: [0, 22, 0, 0],
    bold: true,
  },
  {
    text: ` ${obj?.requestData?.advance}%`,
    margin: [0, 6, 0, 0],
    color: '#d7514f',
    bold: true,
  },
  {
    text: 'Термiн лізингу:',
    margin: [0, 12, 0, 0],
    bold: true,
  },
  {
    text: ` ${obj?.requestData?.leasingTerm} мiс`,
    margin: [0, 6, 0, 0],
    bold: true,
    color: '#d7514f',
  },
  {
    text: 'Середньомiсячний платiж:',
    margin: [0, 12, 0, 0],
    bold: true,
  },
  {
    text: ` ${
      obj?.resultData[Object.keys(obj?.resultData)[0]]['offer-month-payment']
    }`,
    margin: [0, 6, 0, 0],
    bold: true,
    color: '#d7514f',
  },
]

const benefitHeader = obj => [
  {
    image: 'benefitBackground',
    width: '595',
    absolutePosition: { x: 0, y: 0 },
  },
  // header leasing-card title. Static
  {
    margin: [0, 50, 0, 2],
    columns: [
      {
        width: '60%',
        fontSize: 13,
        text: [
          {
            text: 'Комерційна пропозиція',
          },
          {
            text: ' BEST BENEFITS',
            color: '#d7514f',
            bold: true,
          },
        ],
        columnGap: 10,
        color: style.colors.darkGrey,
        bold: true,
        lineHeight: 1.3,
      },
    ],
  },
  {
    margin: [0, 0, 0, 0],
    columns: [
      {
        width: '70%',
        fontSize: 13,
        text: [
          {
            text: ` з фінансового лізингу від ${new Date(obj['updated_at'])
              .toLocaleString('ru-RU')
              .substring(0, 10)} №${obj?.requestId}`,
          },
        ],
        columnGap: 10,
        color: style.colors.darkGrey,
        bold: true,
        lineHeight: 1.3,
      },
    ],
  },
  {
    margin: [0, 5, 0, 2],
    text: [
      {
        text: 'Аванс:',
        margin: [0, 12, 0, 0],
        bolditalics: true,
      },
      {
        text: ` ${obj?.requestData?.advance}%`,
        margin: [0, 12, 0, 0],
        color: '#d7514f',
        bold: true,
      },
    ],
  },
  {
    margin: [0, 4],
    text: [
      {
        text: 'Термiн лізингу:',
        margin: [0, 12, 0, 0],
      },
      {
        text: ` ${obj?.requestData?.leasingTerm} мiс`,
        margin: [0, 12, 0, 0],
        bold: true,
        color: '#d7514f',
      },
    ],
  },
  {
    margin: [0, 4],
    text: [
      {
        text: `${
          obj.resultData['oper-leasing']
            ? 'Щомiсячний платiж'
            : 'Середньомiсячний платiж:'
        }`,
        margin: [0, 12, 0, 0],
      },
      {
        text: ` ${
          obj?.resultData[Object.keys(obj?.resultData)[0]][
            'offer-month-payment'
          ]
        }`,
        margin: [0, 12, 0, 0],
        bold: true,
        color: '#d7514f',
      }, // 215, 81, 79
    ],
  },
]

export { header, benefitHeader, fsnHeader }
