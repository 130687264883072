<template>
  <div>
    <div>
      <div>
        <v-row
          class="ma-0 mt-2 align-center"
          style="justify-content: space-between">
          <span style="font-weight: 700; color: #000; font-size: 14px"
            >Дані ідентифікації</span
          >
        </v-row>

        <v-row class="pt-1 justify-space-between">
          <v-col :cols="12" :md="6" class="pt-1 pb-0">
            <v-row>
              <v-col v-if="contactData.isInn" :cols="12" :md="6" class="pt-0">
                <span class="area-label">ІНН</span>
                <v-text-field
                  id="contact-inn"
                  v-model="contactData.inn"
                  :readonly="readonly"
                  hide-details
                  placeholder="10 цифр"
                  dense
                  @input="maxNumLength('contact-inn', 10)">
                </v-text-field>
              </v-col>
              <div class="pt-0 ml-3 mb-3 d-flex align-center">
                <v-checkbox
                  v-model="contactData.isInn"
                  :readonly="readonly"
                  color="red"
                  :true-value="false"
                  :false-value="true"
                  class="mt-0"
                  style="margin-top: 10px !important"
                  hide-details
                  dense>
                  <template #label>
                    <span style="font-size: 12px"> ІНН відсутній </span>
                  </template>
                </v-checkbox>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <FilesUploader
              v-model:documents="contactData.identificationNumber"
              :disabled="!contactData.isInn"
              :readonly="readonly"
              label="Довідка про присвоєння ідентифікаційного номера фізичної особи-платника податків"
              :list="false"
              @loaded="onLoadDocument" />
            <v-dialog v-model="signedDialog.dialog" width="300">
              <v-card>
                <div class="pa-3">
                  <span style="color: #000; font-size: 14px; font-weight: 700"
                    >Документ завірений?</span
                  >
                  <div
                    v-for="(doc, idx) in signedDialog.documents"
                    :key="idx"
                    class="mt-3">
                    <span
                      v-if="
                        doc.text.includes('.rar') || doc.text.includes('.zip')
                      ">
                      Архів неможливо завірити
                    </span>
                    <div v-else>
                      <span
                        style="font-size: 14px"
                        class="link"
                        @click="() => openDocument(doc)"
                        >{{ doc.text }}</span
                      >
                      <v-checkbox
                        v-model="signedDialog.documents[idx].isSigned"
                        style="margin-top: 0 !important"
                        dense
                        :false-value="false"
                        :true-value="true"
                        hide-details>
                        <template #label>
                          <span class="label">Так, завiрена копія</span>
                        </template>
                      </v-checkbox>
                    </div>
                    <v-card-actions
                      v-if="
                        !(
                          doc.text.includes('.rar') || doc.text.includes('.zip')
                        )
                      "
                      class="pl-0 mt-3">
                      <v-btn
                        color="primary"
                        variant="flat"
                        @click="handleSigned"
                        >Підтвердити</v-btn
                      >
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="pt-0">
          <v-col :cols="12" :md="3" class="pt-1 pb-0">
            <span class="area-label">Дата народження</span>
            <DatePicker
              v-model="contactData.birthday"
              :maxDate="
                new Date(new Date().setFullYear(new Date().getFullYear() - 15))
              "
              :disabled="readonly"
              single
              placeholder="Дата" />
          </v-col>
          <v-col class="pt-1">
            <span class="area-label">Місце народження</span>
            <v-text-field
              v-model="contactData.placeOfBirth"
              :disabled="readonly"
              hide-details
              placeholder="Введіть текст"
              :error-messages="placeOfBirthErr"
              @blur="$v.contactData.placeOfBirth.$touch()">
              <template #prepend-inner>
                <div>
                  <svg
                    class="mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none">
                    <rect
                      y="0.5"
                      width="18"
                      height="20"
                      rx="9"
                      fill="#6AC9FF" />
                    <path
                      d="M14.3751 13.4685H13.4849V8.79099C13.4847 8.69481 13.4643 8.59975 13.4252 8.51194C13.386 8.42412 13.3288 8.34551 13.2574 8.28116L9.30083 4.67772C9.17298 4.56327 9.00747 4.5 8.83594 4.5C8.66441 4.5 8.49889 4.56327 8.37104 4.67772L4.41447 8.28116C4.34305 8.34551 4.28591 8.42412 4.24672 8.51194C4.20754 8.59975 4.18718 8.69481 4.18697 8.79099V13.4685H3.29674C3.21804 13.4685 3.14256 13.4998 3.08691 13.5555C3.03126 13.6112 3 13.6867 3 13.7655C3 13.8443 3.03126 13.9198 3.08691 13.9755C3.14256 14.0312 3.21804 14.0625 3.29674 14.0625H14.3751C14.4538 14.0625 14.5293 14.0312 14.585 13.9755C14.6406 13.9198 14.6719 13.8443 14.6719 13.7655C14.6719 13.6867 14.6406 13.6112 14.585 13.5555C14.5293 13.4998 14.4538 13.4685 14.3751 13.4685ZM4.78046 8.79099C4.78004 8.77797 4.78248 8.76501 4.78761 8.75304C4.79273 8.74107 4.80042 8.73037 4.81013 8.72169L8.7667 5.11825C8.78493 5.10156 8.80875 5.0923 8.83346 5.0923C8.85818 5.0923 8.882 5.10156 8.90023 5.11825L12.8568 8.72169C12.868 8.72931 12.8771 8.73968 12.8832 8.75181C12.8892 8.76395 12.8921 8.77744 12.8914 8.79099V13.4685H10.3197V10.9936C10.3197 10.8099 10.2467 10.6336 10.1169 10.5036C9.987 10.3737 9.81089 10.3007 9.62725 10.3007H8.04462C7.86099 10.3007 7.68487 10.3737 7.55502 10.5036C7.42517 10.6336 7.35222 10.8099 7.35222 10.9936V13.4685H4.78046V8.79099ZM9.72617 13.4685H7.94571V10.9936C7.94571 10.9674 7.95613 10.9422 7.97468 10.9236C7.99323 10.9051 8.01839 10.8946 8.04462 10.8946H9.62725C9.65348 10.8946 9.67864 10.9051 9.69719 10.9236C9.71574 10.9422 9.72617 10.9674 9.72617 10.9936V13.4685Z"
                      fill="white" />
                  </svg>
                </div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <Passport
          :identityDocument="contactData.identityDocument"
          :readonly="readonly"
          :contactData="contactData"
          :contactId="contactData.id"
          @identity-document-updated="updateIdentityDocument" />
        <div style="margin-top: -15px">
          <FilesUploader
            v-if="
              contactData.identityDocument &&
              contactData.identityDocument.document
            "
            label="Документ"
            :documents="getSafe(() => contactData.identityDocument.document)"
            dense
            readonly
            :list="false">
          </FilesUploader>
        </div>
      </div>
    </div>
    <Representatives
      :representatives="contactData.representatives || []"
      :contactId="contactData.id"
      :readonly="readonly" />
    <v-fade-transition hide-on-leave>
      <div>
        <list-section
          :title="'Дані ФОП'"
          :addActionTitle="'Вказати дані'"
          :addAction="fopDialog"
          :addActionDisabled="readonly || contactData.fop">
          <template #body>
            <v-data-table
              :headers="fopHeaders"
              :items="contactData.fop ? [contactData.fop] : []"
              dense
              hide-default-footer
              class="custom-table head-line"
              :hide-default-header="
                !(contactData.fop && Object.keys(contactData.fop).length)
              ">
              <template #item.mainQued="{ item }">
                <span
                  >{{ item?.mainQued?.code || item?.main_qued?.code }}
                  {{ item?.mainQued?.title || item?.main_qued?.title }}</span
                >
              </template>
              <template #item.date="{ item }">
                {{ toFormatDate(item.date) }}
              </template>
              <template #item.isVatPayer="{ item }">
                <v-checkbox
                  v-model="item.isVatPayer"
                  size="x-small"
                  hide-details
                  color="red"
                  :true-value="true"
                  :false-value="false"
                  readonly></v-checkbox>
              </template>
              <template #item.queds="{ item }">
                <v-menu left offset-x>
                  <template #activator="{ props: menuProps }">
                    <div v-bind="menuProps">
                      <v-tooltip bottom text="Переглянути інші кведи">
                        <template #activator="{ props: tooltipProps }">
                          <div class="w-100 d-flex justify-center">
                            <v-btn
                              icon
                              size="small"
                              density="comfortable"
                              rounded
                              v-bind="tooltipProps">
                              <v-icon
                                size="18"
                                icon
                                rounded
                                small="small"
                                color="#FC7247"
                                >mdi-eye</v-icon
                              >
                            </v-btn>
                          </div>
                        </template>
                      </v-tooltip>
                    </div>
                  </template>
                  <v-card>
                    <v-card-text>
                      <div
                        v-for="qued in item.queds || []"
                        :key="qued?.code"
                        class="d-flex align-center">
                        <v-icon size="small" color="red">{{
                          'mdi-check'
                        }}</v-icon>
                        <span class="d-block ml-1">{{
                          `${qued.code} ${qued.title}`
                        }}</span>
                      </div>
                      <div v-if="!item.queds?.length" class="text-disabled">
                        Інші кведи відсутні
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
              <template #item.actions="{ item }">
                <div style="margin-right: -10px">
                  <TableCrud
                    :actionsArray="[
                      {
                        action: () => fopDialog('view', item),
                        text: 'Переглянути',
                        disabled: false,
                        icon: 'mdi-eye',
                      },
                      {
                        action: () => fopDialog('update', item),
                        text: 'Редагувати',
                        disabled: readonly,
                        icon: 'mdi-pencil',
                      },
                      {
                        action: () => deleteFop(),
                        text: 'Видалити',
                        icon: 'mdi-delete',
                        disabled: readonly,
                      },
                    ]">
                  </TableCrud>
                </div>
              </template>
            </v-data-table>
            <FilesUploader
              v-if="contactData.fop && contactData.fop.extractOrExcerpt"
              v-model:documents="contactData.fop.extractOrExcerpt"
              label="Виписка або витяг з ЄДРЮОб ФОП та ГФ"
              readonly />
          </template>
        </list-section>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import Passport from '../passport.vue'
import DatePicker from '@/components/DatePicker.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import listSection from '@/components/list-section.vue'
import Representatives from '../Representatives.vue'
import { mapState } from 'vuex'
import { maxNumLength } from '@/utils/masks'
import {
  urlContactFopDelete,
  urlDeleteContractorAddress,
} from '@/pages/request'
import {
  setErrHandler,
  openDocument,
  getSafe,
  toFormatDate,
} from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
import TableCrud from '@/components/table-crud.vue'
import { fopHeaders } from '@/utils/tableHeaders'

export default {
  components: {
    TableCrud,
    Passport,
    listSection,
    FilesUploader,
    DatePicker,
    Representatives,
  },
  props: {
    readonly: { type: Boolean },
    contact: { type: Object },
    queds: { type: Array },
    v: { type: Object },
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data() {
    return {
      fopHeaders,
      loading: false,
      signedDialog: {
        dialog: false,
        documents: [],
      },
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    contactData() {
      return this.contact
    },
    $v() {
      return this.v
    },
    birthdayErr() {
      return setErrHandler(this.$v?.contactData?.birthday)
    },
    placeOfBirthErr() {
      return setErrHandler(this.$v?.contactData?.placeOfBirth)
    },
    label() {
      const documents = this.contactData.identityDocument?.document
      if (!documents || documents.length === 0) {
        return 'Скан документу'
      }

      const signedDocuments = documents.filter(item => item.isSigned === true)
      const signedCount = signedDocuments.length

      if (signedCount === 0) {
        return 'Скан документу'
      } else {
        return `Скан документу (підписаних ${signedCount})`
      }
    },
  },
  methods: {
    toFormatDate,
    getSafe,
    maxNumLength,
    openDocument,
    urlDeleteContractorAddress,
    updateIdentityDocument(identityDocument) {
      if (identityDocument) {
        this.contactData.identityDocument = identityDocument
      }
    },
    deleteFop() {
      return this.$axios
        .delete(urlContactFopDelete(this.contactData.fop?.id))
        .then(res => {
          if (res) {
            this.$setSnackbar({ text: 'Дані по фоп видалено' })
            this.contactData.fop = null
            if ([4, 5].includes(this.contactData.segmentId)) {
              this.contactData.segmentId = null
              this.contactData.segment = null
            }
          }
        })
    },
    fopDialog(action, item) {
      console.log('item', item)
      this.$store.commit('setDialog', {
        title: 'Дані по ФОП',
        dialogItem: {
          ...item,
          mainQuedId: item?.mainQued?.id || item?.main_qued?.id || null,
          mainQued: item?.mainQued || item?.main_qued || null,
        },
        params: {
          readonly: this.readonly || action === 'view',
          $v: this.$v,
          contactId: this.contactData.id,
          isUpdate: action === 'update',
          dialogWidth: '700px',
          hideActionsButtons: this.readonly || action === 'view',
          cb: res => {
            if (res.data) {
              this.contactData.fop = res.data
              if ([4, 5].includes(this.contactData.segmentId)) {
                this.contactData.segmentId = null
                this.contactData.segment = null
              }
            }
          },
        },
        action: 'contact-fop',
      })
    },
    setSignedDialog(value) {
      this.signedDialog.dialog = value
      if (!value) {
        this.signedDialog.documents = []
      }
    },
    onLoadDocument(documents) {
      this.signedDialog.documents = Object.clone(documents)
      this.setSignedDialog(true)
    },
    handleSigned() {
      if (this.signedDialog && Array.isArray(this.signedDialog.documents)) {
        this.contactData.identificationNumber =
          this.contactData.identificationNumber.filter(doc => {
            return !this.signedDialog.documents
              .map(d => d.url)
              .includes(doc.url)
          })
        this.contactData.identificationNumber.push(
          ...this.signedDialog.documents
        )
        this.setSignedDialog(false)
      } else {
        this.$setSnackbar({
          text: 'signedDialog не є масивом',
          color: 'warning',
        })
      }
    },
  },
  watch: {
    loading(state) {
      state &&
        setTimeout(() => {
          this.loading = !state
        }, 2500)
    },
  },
}
</script>
