<template>
  <div>
    <SectionLoader v-if="isLoading" />
    <div v-else>
      <div v-if="addEvent || editEvent" class="cursor-default">
        <v-row>
          <v-col cols="12" md="4" sm="12" class="pt-0">
            <div class="general-block">
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <span class="label">Статус</span>
                <v-select
                  v-if="event.id"
                  v-model="event.statusId"
                  class="small-label"
                  :items="$directory.get('activityStatuses', event.status)"
                  :loading="$loading.isLoading('activityStatuses')"
                  :error-messages="statusErr"
                  item-title="name"
                  item-value="id"
                  placeholder="Оберіть зі списку"
                  hide-details
                  dense
                  readonly
                  @focus="$directory.fill('activityStatuses')"
                  @keyup.enter="submit()"
                  @blur="v$.event.statusId.$touch()">
                </v-select>
                <v-text-field
                  v-if="!event.id"
                  value="Новий"
                  placeholder="Введіть текст"
                  dense
                  readonly
                  hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <span class="label">Тип</span>
                <v-select
                  v-model="event.typeId"
                  :error-messages="categoryErr"
                  class="small-label"
                  :items="$directory.get('planActivityTypes', event.type)"
                  :loading="$loading.isLoading('planActivityTypes')"
                  placeholder="Оберіть зі списку"
                  :readonly="dialog.params.isCreateMeeting"
                  item-title="name"
                  item-value="id"
                  hide-details
                  dense
                  @focus="$directory.fill('planActivityTypes')"
                  @keyup.enter="submit()"
                  @blur="v$.event.typeId.$touch()">
                </v-select>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <span class="label">Дата проведення</span>
                <DatePicker
                  v-model="event.startDate"
                  style="z-index: 10000"
                  opens="center"
                  hide-details
                  placeholder="Оберіть дату"
                  :minDate="minDate"
                  single />
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <span class="label">Час початку</span>
                <TimePicker
                  v-model="timeStart"
                  style="z-index: 10000"
                  class="w-100"
                  :disabled="pageLoad"
                  placeholder="Оберіть час" />
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <span class="label">Час завершення</span>
                <TimePicker
                  v-model="timeEnd"
                  style="z-index: 10000"
                  class="w-100"
                  :disabled="pageLoad"
                  placeholder="Оберіть час" />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12" class="pl-0 pt-0">
            <div class="general-block">
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <span class="label">Заголовок</span>
                <v-text-field
                  v-model="event.title"
                  :error-messages="nameErr"
                  placeholder="Введіть текст"
                  class="block-label small-height mt-0"
                  hide-details
                  dense
                  @keyup.enter="submit()"
                  @blur="v$.event.title.$touch()">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <span class="label">Учасники</span>
                <v-autocomplete
                  v-model="event.invitations"
                  class="small-label"
                  placeholder="Оберіть зі списку"
                  :items="customItems"
                  :loading="$loading.isLoading('allUsers')"
                  :item-title="
                    u =>
                      getContactInitials(editEvent ? u?.invited || u : u, {
                        isResponsible: true,
                      })
                  "
                  item-value="invitedId"
                  multiple
                  deletable-chips
                  small-chips
                  dense
                  return-object
                  hide-details
                  @focus="$directory.fill('allUsers')"
                  @keyup.enter="submit()">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <div class="d-flex align-center">
                  <v-icon class="mr-1">{{ 'mdi-map-check-outline' }}</v-icon>
                  <span class="label">Місце проведення</span>
                </div>
                <div v-if="editEvent && event.address" class="ml-7">
                  <span class="link">{{ event.address }}</span>
                </div>
                <v-text-field
                  v-else
                  v-model="event.address"
                  class="block-label pt-0"
                  placeholder="Введіть текст"
                  hide-details
                  dense
                  @keyup.enter="submit()"></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0">
                <ActivityConnections
                  :v$="v$"
                  :event="event"
                  :isEdit="editEvent"
                  :selectedEntities="selectedEntities"
                  :contractorContacts="contractorContacts"
                  @push-to-selected-entities="pushToSelectedEntities"
                  @contractor-contacts="updateContractorContacts"
                  @update:selected-entities="selectedEntities = []" />
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0 pt-4">
                <FilesUploader
                  v-model:documents="event.files"
                  class="mt-4"
                  label="Додати файл(и)" />
              </v-col>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0">
            <div class="general-block">
              <span class="label">Опис завдання</span>
              <v-textarea
                v-model="event.description"
                :error-messages="descriptionErr"
                class="block-label"
                placeholder="Введіть текст"
                rows="3"
                hide-details
                dense
                @blur="v$.event.description.$touch()">
              </v-textarea>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="deleteEvent">
        <h2>Активність {{ dialog.dialogItem.title }} буде видалено</h2>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import TimePicker from '@/components/TimePicker.vue'
import { required } from '@vuelidate/validators'
import {
  backDate,
  contractorFilter,
  contactFilter,
  setErrHandler,
  getContactInitials,
} from '@/utils/helperFunc'
import {
  urlActivityCreate,
  urlGetActivities,
  urlDeleteActivity,
  urlUpdateEvent,
  urlGetDealerPointByContact,
  urlGetAllContractors,
  urlGetDealerOutlets,
  urlContactsGetAll,
  urlGetActivitySingle,
} from '@/pages/request.js'
import { useVuelidate } from '@vuelidate/core'
import SectionLoader from '@/components/section-loader.vue'
import ActivityConnections from '@/dialogs/components/activity/ActivityConnections.vue'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  components: {
    ActivityConnections,
    SectionLoader,
    FilesUploader,
    TimePicker,
    DatePicker,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    state: { type: Boolean },
    action: { type: String },
    dialog: { type: Object },
  },
  validations() {
    return { event: this.validationRules }
  },
  data: () => ({
    event: {
      address: null,
      typeId: null,
      dealerPointOfSaleId: null,
      contactId: null,
      startDate: null,
      endDate: null,
      invitations: [],
      description: null,
      contractorId: null,
      statusId: 1,
      resultId: null,
      documents: [],
      files: [],
    },
    isLoading: false,
    timeStart: null,
    timeEnd: null,
    selectedEntities: [],
    pageLoad: false,
    contractorContacts: [],
  }),
  computed: {
    validationRules() {
      const dealerPointOfSaleId = this.selectedEntities.includes(3)
        ? { required }
        : {}
      const contractorId = [1, 4].includes(this.selectedEntities)
        ? { required }
        : {}
      const activity =
        this.addEvent || this.editEvent
          ? {
              title: { required },
              typeId: { required },
              startDate: { required },
              endDate: { required },
              contactId: { required },
              statusId: { required },
              description: { required },
              dealerPointOfSaleId: dealerPointOfSaleId,
              contractorId: contractorId,
            }
          : {}

      return activity
    },
    customItems() {
      const items = this.$directory
        .get('allUsers', this.event.invitations)
        .filter(item => item?.isActive || item?.invited?.isActive)

      if (this.editEvent) {
        return items.map(item => ({
          ...item,
          invitedId: item.id,
        }))
      }
      return items
    },
    validation() {
      return { v$: this.v$.event, name: 'activity' }
    },
    nameErr() {
      return setErrHandler(this.v$.event.title)
    },
    categoryErr() {
      return setErrHandler(this.v$.event.typeId)
    },
    statusErr() {
      return setErrHandler(this.v$.event.statusId)
    },
    descriptionErr() {
      return setErrHandler(this.v$.event.description)
    },
    /* dates */
    // strings
    minDate() {
      const date = new Date()
      return new Date(date.setDate(date.getDate() - 1))
        .toISOString()
        .substring(0, 10)
    },
    currentDate() {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    currentTime() {
      return new Date().toString().substring(16, 21)
    },
    getStartTimeTS() {
      if (!this.event.startDate) return
      const format = this.event.startDate.substring(11, 16)
      return format
    },
    getEndTimeTS() {
      if (!this.event.endDate) return
      const format = this.event.endDate.substring(11, 16)
      return format
    },
    // dialog states
    addEvent() {
      return this.action === 'addEvent'
    },
    editEvent() {
      return this.action === 'editEvent'
    },
    deleteEvent() {
      return this.action === 'deleteEvent'
    },
  },
  methods: {
    getContactInitials,
    urlContactsGetAll,
    urlGetDealerOutlets,
    urlGetAllContractors,
    contactFilter,
    contractorFilter,
    backDate,
    urlActivityCreate,
    urlGetActivities,
    urlDeleteActivity,
    urlUpdateEvent,
    urlGetDealerPointByContact,
    setDate(date) {
      if (date && date.length >= 10 && date.includes('-')) {
        const [y, m, d] = date.substring(0, 10).split('-')
        return `${d}.${m}.${y}` + date.substring(10, 25)
      }
      return date
    },
    updateContractorContacts(arr) {
      this.contractorContacts.splice(0)
      this.contractorContacts.push(...arr)
    },
    pushToSelectedEntities(id) {
      !this.selectedEntities.includes(id) && this.selectedEntities.push(id)
    },
    editEventHandler() {
      this.isLoading = true
      const obj = this.event

      console.log('obj.startDate.length', obj.startDate.length)
      console.log('obj.startDate.length', obj.startDate)
      if (obj.startDate) {
        if (obj.startDate.length === 19) {
          obj.startDate = obj.startDate.substring(0, 10) + ' ' + this.timeStart
        } else if (obj.startDate.length === 25) {
          // Залишаємо дату и останні 5 символів часу
          obj.startDate = `${obj.startDate.substring(
            0,
            10
          )} ${obj.startDate.substring(20, 25)}`
        }
      } else {
        obj.startDate = obj.startDate + ' ' + this.timeStart
      }
      obj.endDate = obj.startDate.substring(0, 11) + this.timeEnd

      obj.invitations = obj.invitations.map(item =>
        item?.invitedId ? item?.invitedId : item?.id
      )

      console.log('fullObj', obj.startDate, obj.endDate)
      return this.$axios
        .post(this.urlUpdateEvent(this.event.id), obj)
        .then(res => {
          if (res?.data?.message) throw new Error(res?.data?.message)
          this.$setSnackbar({ text: 'Активність оновлено' })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(err => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            this.$setSnackbar({
              text: err.response.data.message,
              color: 'error',
            })
          }
          this.isLoading = false
          throw err
        })
    },
    createEventHandler() {
      this.isLoading = true
      const obj = this.event
      obj.startDate = obj.startDate.substring(0, 10) + ' ' + this.timeStart
      obj.endDate = obj.endDate.substring(0, 10) + ' ' + this.timeEnd

      const ids = obj.invitations.map(({ id }) => id).filter(Boolean)

      obj.invitations = ids

      return this.$axios
        .post(this.urlActivityCreate(), obj)
        .then(res => {
          if (res?.data?.message) {
            this.$setSnackbar({ text: res?.data?.message, color: 'warning' })
          }
          this.$setSnackbar({
            text: `Заплановано активність на ${obj.startDate}`,
          })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(err => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            this.$setSnackbar({
              text: err.response.data.message,
              color: 'error',
            })
          }
          this.isLoading = false
          throw err
        })
    },
    deleteEventHandler() {
      return this.$axios
        .delete(this.urlDeleteActivity(this.event.id))
        .then(() => {
          this.$setSnackbar({ text: 'активність видалено' })
          this.$store.commit('refreshPage')
        })
        .catch(this.$err)
    },
    getSingleActivity() {
      return this.$axios
        .get(urlGetActivitySingle(this.dialog.dialogItem?.id))
        .then(res => {
          if (res.data) {
            this.event = res.data
          }
        })
    },
    async prepareEvent(direction) {
      if (direction === 'toClient') {
        this.timeStart = this.currentTime
        this.timeEnd = this.currentTime
        if (this.action === 'addEvent') {
          this.event.startDate = this.currentDate
          this.event.endDate = this.currentDate
          this.event.typeId = 2
          this.event.invitations.splice(0)
          this.event.statusId = 1
        } else if (this.action === 'editEvent') {
          await this.getSingleActivity()
          console.log('this.event', this.event)
          if (
            !this.event.contractorId &&
            !this.event.dealerPointOfSaleId &&
            this.event.contactId
          ) {
            this.selectedEntities.push(2)
          }
          if (this.event.contractorId) {
            this.contractorContacts.push(this.event.contact)
          }
          if (this.event.contactId && this.event.contractorId) {
            this.selectedEntities.push(2)
            this.selectedEntities.push(4)
          }
          this.timeStart = this.getStartTimeTS
          this.timeEnd = this.getEndTimeTS

          if (this.event.dealerPointOfSaleId) {
            this.selectedEntities.push(3)
          }
        } else if (this.action === 'deleteEvent') {
          Object.assign(this.event, this.dialog.dialogItem)
        }
      }
    },
    submit() {
      this.prepareEvent('toServer')
      switch (true) {
        case this.addEvent:
          return this.createEventHandler()
        case this.editEvent:
          return this.editEventHandler()
        case this.deleteEvent:
          return this.deleteEventHandler()
        default:
          throw new Error('No handler')
      }
    },
  },
  watch: {
    'event.startDate': function (val) {
      if (val && !this.editEvent) {
        this.event.endDate = val
      }
    },
  },
  mounted() {
    if (!this.dialog?.params?.isCreateMeeting) {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 3000)
    }
  },
  async created() {
    this.$directory.get('planActivityTypes')
    this.$directory.fill('planActivityTypes')
    this.prepareEvent('toClient')
  },
}
</script>

<style scoped lang="scss">
.general-block {
  height: 100%;
  padding: 13px;
  background: #f2f2f2;
  border-radius: 13px;
  color: black;
  .label {
    color: black !important;
  }
}
</style>
