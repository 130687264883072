import { getGraphName } from '../get-description'
import { style } from './styles'
import { getBeautyNum } from '@/utils/helperFunc'

function buildTableBody(data, columns, obj) {
  const body = []

  const benefit = obj.requestData.leasingProgram === 34

  if (benefit) {
    body.push([
      {
        text: '№',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 6, 0, 0],
      },
      {
        text: 'Термiн оплати',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Оплата за обʼєкт, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Винагорода лізингодавця, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Компенсація винагороди лізингодавця, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Сума платежу,${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      }, // #ca5b5b
      {
        text: `Сума платежу з компенсацією,${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      }, // #ca5b5b
    ])

    data.graph.push({
      n: 'Всього',
      date: ' ',
      'payment-principal': data?.['total-payment-principal'],
      interest: data?.['total-interest'],
      payment: data?.['total-payment'],
      amountRewardUSAID: data?.['total-amount-rewardUSAID'], // компенсація винагороди
      promotionPaymentUSAID: data?.['total-promotion-paymentUSAID'], // Сума платежу з компенсацією
    })
  } else if (obj.programFSN) {
    const columns = [
      {
        text: '№',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 6, 0, 0],
      },
      {
        text: 'Термiн оплати',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Відшкодування (компенсація) частини вартості Об'єкта лізингу, ПДВ 20%, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Компенсаційна винагорода, за Програмою (5,7,9,11% річних), грн., без ПДВ ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Комісія за додаткові послуги, без ПДВ, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Базова винагорода (комісія) Індекс UIRD (З міс.) + 5,6,7 без ПДВ, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Розмір Лізингового платежу, який підлягає сплаті Лізингоодержувачем за Програмою ПДВ, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
    ]

    if (obj.requestData.hasMaintenance) {
      const servicePaymentColumn = {
        text: `Cервiсний платiж, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      }
      columns.splice(3, 0, servicePaymentColumn)
    }

    body.push(columns)

    const dataGraph = data?.graph

    // if (obj.requestData.hasMaintenance) {
    //   dataGraph.push({
    //     servicePayment: obj?.maintenance?.totalServicePayment,
    //   })
    // }

    dataGraph.push({
      n: 'Всього',
      date: ' ',
      servicePayment: obj?.requestData?.hasMaintenance
        ? obj?.maintenance?.totalServicePayment
        : undefined,
      leasingObjectCompensationFSN: data?.totalLeasingObjectCompensationFSN,
      programCompensationRewardFSN: data?.totalProgramCompensationRewardFSN,
      additionalServicesCommissionFSN:
        data?.totalAdditionalServicesCommissionFSN,
      baseRewardUirdIndexFSN: data?.totalBaseRewardUirdIndexFSN,
      paymentFSN: data?.paymentFSN,
    })
  } else {
    body.push([
      {
        text: '№',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 6, 0, 0],
      },
      {
        text: 'Термін оплати',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Оплата за авто, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Винагорода лізингодавця, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      // 'Cервiсний платiж, грн',
      {
        text: `Сума платежу,${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      }, // #ca5b5b
    ])

    // переводимо ['total-interest'] в Number і додаємо суму сертифікату
    const totalInterest = obj.requestData.isGiftCertificate
      ? getBeautyNum(
          parseInt(
            data?.['total-interest'].replace(/\s/g, '').replace('ГРН', ''),
            10
          ) + obj?.calcRequestData?.giftCertificate || 0
        )
      : data?.['total-interest']

    const totalPayment = obj.requestData.isGiftCertificate
      ? getBeautyNum(
          parseInt(
            data?.['total-payment'].replace(/\s/g, '').replace('ГРН', ''),
            10
          ) + obj?.calcRequestData?.giftCertificate || 0
        )
      : data?.['total-payment']

    data.graph.push({
      n: 'Всього',
      date: ' ',
      'payment-principal': data?.['total-payment-principal'],
      interest: totalInterest,
      payment: totalPayment,
    })
  }

  data.graph
    .filter(row => row.n !== 'Всього')
    .forEach(row => {
      const dataRow = []
      columns.forEach((column, index) => {
        dataRow.push({
          text: (row[column] || '').toString(),
          alignment: index <= 1 ? 'center' : 'right',
          bold: false,
        })
      })
      body.push(dataRow)
    })

  const totalRow = data.graph.find(row => row.n === 'Всього')
  if (totalRow) {
    const totalDataRow = []
    columns.forEach((column, index) => {
      totalDataRow.push({
        text: (totalRow[column] || '').toString(),
        alignment: index <= 1 ? 'center' : 'right',
        bold: true,
      })
    })
    body.push(totalDataRow)
  }

  if (obj.requestData.isGiftCertificate) {
    const giftCertificateRow = []
    columns.forEach((column, index) => {
      if (index === 0 || index === 3 || index === 4) {
        giftCertificateRow.push({
          text:
            index === 3 || index === 4
              ? `${getBeautyNum(obj?.calcRequestData?.giftCertificate)}`
              : 'Сертифікат',
          alignment: index <= 1 ? 'center' : 'right',
          bold: true,
        })
      } else {
        giftCertificateRow.push({
          text: '',
          alignment: 'center',
          bold: false,
        })
      }
    })

    body.push(giftCertificateRow)

    const giftCertificateRowWithDiscount = []
    columns.forEach((column, index) => {
      if (index === 0) {
        giftCertificateRowWithDiscount.push({
          text: 'Зі знижкою',
          alignment: index <= 1 ? 'center' : 'right',
          bold: true,
        })
      } else if (index === 3) {
        giftCertificateRowWithDiscount.push({
          text: data?.['total-interest'],
          alignment: 'right',
          bold: true,
        })
      } else if (index === 4) {
        giftCertificateRowWithDiscount.push({
          text: data?.['total-payment'],
          alignment: 'right',
          bold: true,
        })
      } else {
        giftCertificateRowWithDiscount.push({
          text: (totalRow[column] || '').toString(),
          alignment: 'right',
          bold: true,
        })
      }
    })
    body.push(giftCertificateRowWithDiscount)
  }

  return body
}

function table(data, columns, obj) {
  const giftCertificate = obj.requestData.isGiftCertificate
  const benefitWidth = ['7%', '10%', '13%', '15%', '20%', '15%', '20%']
  const programFsnWidth = obj.requestData.hasMaintenance
    ? ['8%', '8%', '14%', '14%', '14%', '14%', '14%', '14%']
    : ['8%', '10%', '16%', '16%', '16%', '17%', '17%']
  const standartWidth = giftCertificate
    ? ['16%', '21%', '21%', '21%', /* '15%' */ '21%']
    : ['8%', '23%', '23%', '23%', /* '15%' */ '23%']
  return {
    layout: {
      hLineColor: () => '#e9e8e8',
      vLineColor: () => '#e9e8e8',
    },
    fontSize: 8,
    table: {
      widths: obj.programFSN
        ? programFsnWidth
        : obj.requestData.leasingProgram === 34
        ? benefitWidth
        : standartWidth,
      headerRows: 1,
      body: buildTableBody(data, columns, obj),
    },
  }
}

const pageBreak = (obj, key, index) => {
  const data = obj.resultData
  const keys = Object.keys(data)
  return (index !== keys.length && data[key].graph.length <= 85) || index === 0
    ? 'after'
    : ''
}

const getProgramFSNGraphs = (obj, key) => {
  const resultData = obj.resultData

  if (obj.requestData.hasMaintenance) {
    resultData[key].graph.forEach((item, index) => {
      item.servicePayment = obj.maintenance?.graph[index]?.servicePayment
    })
    resultData[key].totalServicePayment = obj.maintenance?.totalServicePayment
  }

  const standartProgramFSN =
    obj.programFSN && obj.requestData.hasMaintenance
      ? table(
          resultData[key],
          [
            'n',
            'date',
            'leasingObjectCompensationFSN',
            'servicePayment',
            'programCompensationRewardFSN',
            'additionalServicesCommissionFSN',
            'baseRewardUirdIndexFSN',
            'paymentFSN',
          ],
          obj
        )
      : table(
          resultData[key],
          [
            'n',
            'date',
            'leasingObjectCompensationFSN',
            'programCompensationRewardFSN',
            'additionalServicesCommissionFSN',
            'baseRewardUirdIndexFSN',
            'paymentFSN',
          ],
          obj
        )

  return standartProgramFSN
}

const dd = obj => {
  const graphs = Object.keys(obj.resultData)
  if (graphs.includes('oper-leasing')) return []

  const getAnnuityValue = (engName, data) => {
    if (data[engName]) {
      return data[engName]['total-amount-compensationUSAID'] || ''
    }
    return ''
  }

  return graphs.map((key, index) => {
    if (obj.requestData.leasingProgram === 34) {
      return [
        {
          text: ' ',
          pageBreak: pageBreak(obj, key, index),
        },
        {
          text: `Графік платежів - ${getGraphName(key)}`,
          style: 'header',
          fontSize: 10,
          margin: [0, 0, 0, 5],
        },
        table(
          obj.resultData[key],
          [
            'n',
            'date',
            'payment-principal',
            'interest',
            'amountRewardUSAID',
            'payment',
            'promotionPaymentUSAID',
          ],
          obj
        ),
        {
          margin: [0, 25, 0, 15],
          width: '50%',
          alignment: 'right',
          stack: [
            {
              margin: [0, 20, 0, 15],
              alignment: 'right',
              layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
              },
              table: {
                widths: ['35%', '15%', '35%', '20%'],
                body: [
                  [
                    {},
                    {},
                    {
                      text: 'Компенсація винагороди (Економія по Договору)*',
                      fillColor: style.colors.mainRed,
                      color: 'white',
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                      margin: [0, 3, 0, 3],
                    },
                    {
                      text: getAnnuityValue(key, obj.resultData),
                      fillColor: style.colors.mainRed,
                      color: 'white',
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                      margin: [15, 3, 0, 3],
                    },
                  ],
                ],
                borderColor: 'white',
              },
            },
          ],
        },
        {
          text: 'Акція дійсна для малих та середніх підприємств:',
          bold: true,
          lineHeight: 1.2,
          fontSize: 11,
        },
        {
          lineHeight: 1.2,
          fontSize: 11,
          text: '- які постраждали від війни (переміщені підприємства, підприємства на раніше окупованій території);',
        },
        {
          fontSize: 11,
          lineHeight: 1.2,
          text: '- які є критично важливими для надання послуг в період кризи або для відбудови, включаючи сільське господарство, енергетику, відбудову і соціальні послуги;',
        },
        {
          lineHeight: 1.2,
          fontSize: 11,
          text: '- власниками яких є жінки та/або підприємства, які мають позитивний гендерний вплив (наприклад, робочі місця, створені спеціально для жінок, які обслуговують підприємства, якими володіють жінки, або надають необхідні жінкам послуги);',
        },
        {
          lineHeight: 1.2,
          fontSize: 11,
          text: '- які мають інший вплив на відновлення: як запропонований проєкт сприятиме ММСП збільшити продажі, вийти на нові ринки, створювати робочі місця, підвищувати ефективність виробництва і загалом підтримуватиме відновлення економіки України.',
        },
      ]
    } else
      return [
        {
          text: ' ',
          pageBreak: pageBreak(obj, key, index),
        },
        {
          text: `Графік платежів - ${getGraphName(key)}`,
          style: 'header',
          fontSize: 10,
          margin: [0, 0, 0, 5],
        },
        obj.programFSN
          ? getProgramFSNGraphs(obj, key, index)
          : table(
              obj.resultData[key],
              [
                'n',
                'date',
                'payment-principal',
                'interest',
                /* 'serv_paym' */ 'payment',
              ],
              obj
            ),
      ]
  })
}

export { dd }
