<template>
  <div>
    <assignment-list v-if="$route.name === 'assignment-list'"></assignment-list>
    <router-view v-else></router-view>
  </div>
</template>

<script lang="ts">
import AssignmentList from './AssignmentList.vue'
export default {
  name: 'AssignmentIndex',
  components: { AssignmentList },
}
</script>

<style scoped></style>
