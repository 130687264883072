<template>
  <div>
    <div v-if="!singleContactRoute">
      <div>
        <Filters :filters="filters" class="ml-2">
          <template #header-action>
            <v-btn
              data-readonly="hide"
              class="text-white mr-3"
              style="background: rgb(252, 114, 71); border-radius: 4px"
              @click="
                $store.commit('setDialog', {
                  title: 'Контакт',
                  dialogItem: {
                    surname: null,
                    name: null,
                    patronymic: null,
                    contractorId: null,
                  },
                  params: {
                    hideConnections: true,
                    hideContractor: true,
                    cb: () => {
                      updateList()
                    },
                  },
                  action: 'addContact',
                })
              ">
              <v-icon style="margin-right: 5px; color: #fff !important">
                {{ 'mdi-plus' }}
              </v-icon>
              Додати контакт
            </v-btn>
          </template>
        </Filters>
        <v-fade-transition hide-on-leave>
          <v-row v-show="!pageLoad">
            <v-col :cols="12">
              <div class="parties-content">
                <v-card-text ref="card" class="pl-0 pr-0 pt-0">
                  <v-data-table
                    :headers="contactHeaders"
                    :items="contacts"
                    class="calc-table --cust-cell-pd"
                    mobileBreakpoint="750"
                    dense
                    hide-default-footer
                    :hide-default-header="!contacts.length"
                    :custom-sort="tableDateSort()"
                    :items-per-page="-1">
                    <!-- eslint-disable-next-line -->
                    <template #item.actions="{ item }">
                      <TableCrud
                        :actionsArray="[
                          {
                            action: () => {
                              $router.push({
                                name: 'individuals-form',
                                params: { id: item.id },
                              })
                            },
                            text: 'Відкрити',
                            icon: 'mdi-folder-open-outline',
                          },
                        ]">
                      </TableCrud>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.fullName="{ item }">
                      <ALink
                        :text="item.fullName"
                        :route="{
                          name: 'individuals-form',
                          params: { id: item.id },
                        }">
                      </ALink>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.responsible="{ item }">
                      {{ item.responsible.fullName }}
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.status="{ item }">
                      {{
                        item.status && item.status.name
                          ? item.status.name
                          : '---'
                      }}
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.inn="{ item }">
                      {{ item.inn || '---' }}
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.type="{ item }">
                      {{
                        getSafe(
                          () => item.type.map(t => t.name).join(', '),
                          '---'
                        )
                      }}
                    </template>
                  </v-data-table>
                  <Pagination
                    :getItems="getContacts"
                    :trigger="paginationTrigger" />
                </v-card-text>
              </div>
            </v-col>
          </v-row>
        </v-fade-transition>
      </div>
    </div>
    <SectionLoader v-if="pageLoad" />
    <v-fade-transition v-if="singleContactRoute" hide-on-leave>
      <RouterView v-show="!pageLoad"> </RouterView>
    </v-fade-transition>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import {
  joinStr,
  tableDateSort,
  getContactInitials,
  getSafe,
} from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { urlGetContacts } from '@/pages/request'
import Filters from '@/components/Filters.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { contactHeaders } from '@/utils/tableHeaders'
import { fillDirectory } from '@/plugins/directory'
export default {
  components: {
    TableCrud,
    Filters,
    SectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'contactStatuses' },
      },
      contactType: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Тип контрагента', items: 'contactTypes' },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: () => fillDirectory('allUsers'),
      },
      isFop: { value: null, component: 'v-checkbox', attrs: { label: 'ФОП' } },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data: () => {
    return {
      contactHeaders,
      contacts: [],
      pageLoad: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    singleContactRoute() {
      return !!this.$route.params.id
    },
  },
  methods: {
    getSafe,
    joinStr,
    urlGetContacts,
    tableDateSort,
    getContactInitials,
    getContacts(page = 1) {
      this.pageLoad = true
      return this.$axios
        .get(this.urlGetContacts(page, this.filtersQueryObj))
        .then(res => {
          this.contacts.splice(0)
          this.contacts.push(...res.data.data)

          this.pageLoad = false

          return res
        })
        .catch(this.$err)
    },
  },
}
</script>
