<template>
  <div>
    <v-row v-if="actionCreateEducation || actionUpdateEducation">
      <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
        <span class="label">Освіта</span>
        <v-autocomplete
          v-model="education.typeId"
          :error-messages="educationTypeIdErr"
          placeholder="Оберіть зi списку"
          :items="$directory.get('educationTypes')"
          item-title="name"
          item-value="id"
          dense
          hide-details
          :loading="$loading.isLoading('educationTypes')"
          @keyup.enter="submit()"
          @blur="v$.education.typeId.$touch()"
          @focus="$directory.fill('educationTypes')">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pb-0">
        <span class="label">Назва навчального закладу</span>
        <v-text-field
          v-model="education.educationalInstitutionName"
          :error-messages="educationEducationalInstitutionNameErr"
          placeholder="Введіть текст"
          hide-details
          @blur="v$.education.educationalInstitutionName.$touch()"
          @keyup.enter="submit()">
        </v-text-field>
      </v-col>
    </v-row>
    <div v-if="actionDeleteEducation" style="font-size: 1rem">
      Освіту буде видалено
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { required } from '@vuelidate/validators'
import {
  urlCreateEducation,
  urlUpdateEducation,
  urlDeleteEducation,
} from '@/pages/request'
import { setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { fillDirectory } from '@/plugins/directory/index.ts'
export default {
  props: {
    state: { type: Boolean },
    action: { type: String },
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    const createEducation =
      this.actionCreateEducation || this.actionUpdateEducation
        ? {
            education: {
              contactId: { required },
              typeId: { required },
              educationalInstitutionName: { required },
            },
          }
        : {}

    return { ...createEducation }
  },
  data: () => ({
    education: {
      contactId: null,
      typeId: null,
      educationalInstitutionName: null,
      id: null,
    },
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      dialog: state => state.dialog,
    }),
    validation() {
      return { v$: this.v$.education, name: 'contactEducation' }
    },
    clear() {
      return !this.state && this.clearModel()
    },
    /* Model validations */
    educationContactIdErr() {
      return setErrHandler(this.v$.education.contactId)
    },
    educationTypeIdErr() {
      return setErrHandler(this.v$.education.typeId)
    },
    educationEducationalInstitutionNameErr() {
      return setErrHandler(this.v$.education.educationalInstitutionName)
    },
    /* Action state */
    actionCreateEducation() {
      return this.action === 'createEducation'
    },
    actionUpdateEducation() {
      return this.action === 'updateEducation'
    },
    actionDeleteEducation() {
      return this.action === 'deleteEducation'
    },
  },
  methods: {
    urlCreateEducation,
    urlUpdateEducation,
    urlDeleteEducation,
    clearModel() {
      this.education.contactId = null
      this.education.typeId = null
      this.education.educationalInstitutionName = null
    },
    getContactId() {
      if (this.dialog?.params?.contactId) {
        this.education.contactId = this.dialog.params.contactId
      }
    },
    assignToEdit() {
      const item = this.dialog?.dialogItem

      if (item?.id && this.actionUpdateEducation) {
        this.education.typeId = item.typeId
        this.education.educationalInstitutionName =
          item.educationalInstitutionName
        this.education.id = item.id
      }
    },
    submit() {
      switch (this.action) {
        case 'createEducation':
          return this.createEducation()
        case 'updateEducation':
          return this.updateEducation()
        case 'deleteEducation':
          return this.deleteEducation()
      }
    },
    createEducation() {
      return this.$axios
        .post(this.urlCreateEducation(), this.education)
        .then(() => this.$setSnackbar({ text: 'Створено освіту контакта' }))
        .catch(this.$err)
    },
    updateEducation() {
      return this.$axios
        .post(this.urlUpdateEducation(this.education.id), this.education)
        .then(() => this.$setSnackbar({ text: 'Освіту контакта оновлено' }))
        .catch(this.$err)
    },
    deleteEducation() {
      return this.$axios
        .delete(this.urlDeleteEducation(this.dialog?.params?.educationId))
        .then(() => this.$setSnackbar({ text: 'Освіту контакта видалено' }))
        .catch(this.$err)
    },
  },
  mounted() {
    this.assignToEdit()
    this.getContactId()
    fillDirectory('educationTypes')
  },
}
</script>
