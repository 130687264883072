export default {
  average: {
    category: "Тип об'єкта",
    mark: 'Марка',
    model: 'Модель',
    yearFrom: 'Рік від',
    yearTo: 'Рік до',
  },
  calculation: {
    addEquipmentSum: 'Сума',
    equipmentDiscount: 'Знижка на додаткове обладнання',
    leasingCurrencyCourse: 'Курс',
    leasedAssertMark: "Марка об'єкта лізинга",
    leasingProgram: 'Програма лізингу',
    leasedAssertModel: "Модель об'єкта лізинга",
    leasingObjectType: "Об'єкт лізингу",
    body: "Тип об'єкта",
    generation: 'Покоління',
    modification: 'Модифікація',
    specification: 'Специфікація',
    isNew: 'Новий/б.в',
    leasingObjectYear: "Рiк об'єкта лізингу",
    currency: 'Валюта фiнансування',
    leasingAmount: 'Cума фiнансування',
    leasingCurrency: 'Валюта за ДФЛ',
    leasingQuantity: 'Кiлькiсть ОЛ',
    advance: 'Аванс',
    leasingTerm: 'Термiн фiнансування (12 мiс. мiнiмальний термiн)',
    leasingRest: 'Залишкова вартiсть',
    assistService: 'Асистанс',
    leasingStartDate: 'Дата старту платежів',
    prenumerando: 'Пренумерандо',
    holidays: 'Канiкули',
    stock: 'Акцiя до страхування',
    insuranceProgram: 'Программа страхування',
    insuranceFranchise: 'Франшиза',
    insuranceCascoManipulate: 'Зміна тарифа Каско',
    insuranceTerm: 'КАСКО в авансi мiсяцiв',
    rateManipulate: 'Змiна ставки',
    infoDiscount: 'Знижка ДКП',
    rateReduction: 'Знижка. зниження ставки',
    commisionManipulate: 'Змiна комiсії',
    hiddenCommisionGr: 'Скрита комiсiя, дохiд грн',
    annualCommission: 'Щорічна комісія %',
    commissionLk: 'Скрита комiсiя, витрати грн',
    hiddenCommision: 'Скрита комiсiя, дохiд %',
    commissionLkPr: 'Скрита комiсiя, витрати %',
    agentCommision: 'Разова комiсiя агенту',
    gpsBeacon: 'GPS маячок',
    oneTimeCommission: 'Разова комiсiя',
    preFinancingDays: 'Попереднє фінансування днiв',
    preFinancingAmount: 'Попереднє фінансування сума',
    registration: 'Постановка на облiк',
    annualExpenses: 'Щорiчнi витрати',
    removalRegistration: 'Зняття з облiку',
    truckMass: 'Масса вантажного автомобiля',
    liftingCapacity: 'Вантажопідйомність вантажного автомобiля',
    isOver20Seats: 'Мiсць в автобусi',
    placeOfRegistration: 'Місце реєстрації',
    leasedAssertEngine: 'Об`єм двигуна',
    gainEvenGraphicMonths: 'Посилення класичного графiку (мiсяцi)',
    gainEvenGraphicPercent: 'Посилення класичного графiку (відсотки)',
    unsprMonth: 'УНСПР (мiсяцi)',
    unpga: 'УПНГА 1 рік',
    unpga2Y: 'УПНГА 2 роки',
    unpga3Y: 'УПНГА 3 роки',
    graphTypes: 'Тип графiку',
    customGraphicType: 'Iндивідуальний графiк',
    customUniversalOption: 'Посилення iндивідуальний графiку',
    customStepOptionFirst: 'Iндивідуальний графiк ступінчате посилення (1/3)',
    customStepOptionMiddle: 'Iндивідуальний графiк ступінчате посилення (2/3)',
    vehicleOwnerTax: 'Транспортний збiр',
    gpsTracker: 'GPS-traker',
    patrolCardsSupport: 'Паливна картка',
    gpsTrackerQuantity: 'X-Keeper',
    patrolCardQuantity: 'Кiлькiсть паливних карток',
    replacementCarClass: 'Підмiнний клас авто',
    numberDays: 'Кiлькiсть днiв підмiни в рiк',
    scheduledMileage: 'Пробiг в рiк (кiлометрiв)',
    tireSize: 'Розмiр гуми',
    tireClass: 'Клас шин',
    tireSets: 'Кiлькiсть шин',
    tireCost: 'Цiна шини за штуку',
    additionalCosts: 'Додаткові витрати',
    servicePackage: 'Сервісний пакет',
    tireFitting: 'Сезонний шиномонтаж',
    tireStorage: 'Зберiгання шин',
    adminDiscount: 'Знижка на адмiнiстрування',
    beaconAmount: 'GPS маячок (кiлькiсть)',
    'сomment-income-lk': 'Коментар до скритих доходiв',
    'comment-commission-lk': 'Коментар до скритих витрат',
    commentAB: 'Коментар АВ',
    additionalAb: 'Додаткова виплата АВ грн.',
    additionalAbPr: 'Додаткова виплата ВИ %',
    customerTypeObject: 'Тип клієнта',
    compensation: 'Розмiр компенсації',
    increaseAmount: 'Збільшення суми',
    agentId: 'Агент',
    dealerPointId: 'Диллер',
    workplaceType: 'Приріст робочих місць',
    uird: 'UIRM 3м',
    targetType: 'Цілі',
    newCreated: 'Новостворене підприємство',
  },
  act: {
    signedActOfProvisionOfServices: 'Акт агентської виплати',
    broker: 'Банкiвськi реквізити',
    status: 'Акт вже було подано на виплату',
  },
  signedActOfProvisionOfServices: {
    document: 'Акт агентської виплати',
  },
  identity_document: typeId => {
    const serial = (() => {
      switch (true) {
        case [1, 3, 5, 6, 7, 9].includes(typeId):
          return 'Серія'
        case [6].includes(typeId):
          return 'Номер запису'
        default:
          return 'Номер запису'
      }
    })()
    const number = (() => {
      switch (true) {
        case [1, 3].includes(typeId):
          return 'Номер'
        default:
          return 'Документ №'
      }
    })()
    const unzr = (() => {
      switch (true) {
        case [2, 4, 8, 9].includes(typeId):
          return 'Номер запису'
      }
    })()

    return {
      typeId: 'Тип',
      issueDate: 'Дата видачi',
      unzr: unzr,
      sellBy: 'Дійсний до',
      issuedBy: 'Орган що видав',
      serial: serial,
      number: number,
      document: 'Скан документу',
    }
  },
  representative: {
    documentTypeId: 'Тип документа представника',
    representativeId: 'Контакт представника',
    date: 'Дата видачi',
    endDate: 'Дата закінчення',
    documentInfo: 'Номер',
  },
  entity: {
    segment: 'Сегмент',
    industry: 'Галузь',
    declarationForInvoice: 'Податкова декларація платника єдиного податку',
    name: "Ім'я",
    surname: 'Прізвище',
    educations: 'Освіта',
    patronymic: 'По батькові',
    shortName: 'Назва компанії',
    companyFullName: 'Повна назва компанії',
    applicationForm: 'Заявка анкета',
    birthday: 'Дата народження',
    d: 'Дата запису про держ. реєстр',
    number: 'Номер запису',
    extractOrExcerpt: 'Виписка або витяг з ЄДРЮОб ФОП та ГФ',
    mainQuedId: 'Основний вид діяльності',
    nationality: 'Громадянство',
    nationalityId: 'Громадянство',
    marriedTo: 'Чоловік/дружина',
    marriedId: 'Чоловік/дружина',
    residentCountryId: 'Країна резидентства',
    representativeId: 'Контакт представника',
    representativeDocumentTypeId: 'Тип документа представника',
    representativeNumber: 'Номер',
    representativeDate: 'Дата',
    representativeEndDate: 'Дата закінчення',
    currentAccounts: 'Банківский рахунок',
    factAddress: 'Фактична адреса',
    urAddress: 'Адреса реєстрації',
    listQueds: 'Кведи',
    queds: 'Кведи',
    identity_document: 'Документ що засвідчує особу',
    identityDocument: 'Документ що засвідчує особу',
    placeOfBirth: 'Місце народження',
    registrationAddress: 'Адреса реєстрації',
    inn: 'ІПН',
    emails: 'Пошта',
    phones: 'Телефон',
    missingExtractOrExcerpt: 'Виписка або витяг з ЄДРЮОб ФОП та ГФ',
    formOwnershipCompany: 'Структура власності',
    collegium: 'Коллегiальна дирекцiя',
    stateRegistrationDate: 'Дата проведення держ. Реєстрації',
    stateRegistrationNumber: 'Номер проведення держ. Реєстрації',
    lesseeName: 'Повна назва компанії',
    companyGroupName: 'Найменування Групи компаній',
    companyGroupEdrpou: 'ЄДРПОУ Групи компаній',
    companyGroupNationalityId: 'Країна реєстрації',
    companyGroupConnectionId: 'Повязаності',
    basicQued: 'Основний квед',
    executiveAuthority: 'Виконавчий орган',
    founders: 'Засновники',
    director: 'Директор',
    deciders: 'Особа, що приймає рішення',
    kbv: 'Кінцеві бенефіціарні власники',
    webSite: 'Сайт компанії',
    signatories: 'Підписант',
    lesseeFullName: 'Повна назва компанії',
    statute: 'Статут (остання редакція)',
    codeAccessStatute: 'Опис документів із зазначенням коду доступу до Статуту',
    ownershipStructure: 'Структура власності у вигляді окремої схеми',
    extract: 'Виписка / витяг з ЄДРЮО, ФОП та ГФ',
    protocolFounders: 'Протокол засновників про обрання Керівника',
    orderAppointment: 'Наказ про призначення на посаду Керівника',
    copiesOfContracts:
      'Копії договорів, за якими передбачається отримання доходу в 2023 р',
    debtCertificate:
      'Довідка з банку/лізингової компанії/ін.кредитора про стан поточної заборгованості',
    incomeStatement: 'Довідка про доходи',
    marriageCertificate: 'Свідоцтво про укладення/розірвання шлюбу',
    propertyDocuments: 'Документи, які підтверджують право власності на майно',
    leasingObjectCountry: 'Країна виробник',
    staffQuantity: 'Кількість співробітників',
    identificationNumber:
      'Довідка про присвоєння ідентифікаційного номера фізичної особи-платника податків',
    generalTurnoverBalanceSheet: 'Загальна оборотно-сальдова відомість',
    balanceF1AndF2: 'Баланс - Ф1 і Ф2',
    declaration: 'Декларація за розрахунком податку на прибуток',
    turnoverBalanceSheet:
      'Оборотно-сальдові відомості по рахунку (36,37 і 63, 68 рах.)',
    gender: 'Стать',
    familyStatus: 'Сімейний статус',
    environmentalQuestionnaire: 'Анкета екологічної обачності',
    environmentalQuestionnaireSigned:
      'Анкета екологічної обачності (Підписана)',
    businessRegions: 'Регіон ведення підприємницької діяльності',
    registrationCountryId: 'Країна реєстрації',
    location: 'Адреса засновника',
    provedIncome: 'Середньомісячний підтверджений сукупний чистий дохід',
    unprovedIncome: 'Середньомісячний непідтверджений сукупний чистий дохід',
    dependentsNumber: 'Кількість непрацездатних членів сімї та утриманців',
    locationAddress: 'Адреса ведення бізнесу',
    code: 'ЭДРПОУ/ІПН',
    founderRoleId: 'Роль',
    amountPercent: 'Частка',
    amount: 'Сума капіталу',
    isInn: 'ІНН',
  },
  contactFop: {
    date: 'Дата запису про держ. реєстр',
    number: 'Номер запису',
    extractOrExcerpt: 'Виписка або витяг з ЄДРЮОб ФОП та ГФ',
    mainQuedId: 'Основний вид діяльності',
    queds: 'Кведи',
    innPayerVAT: 'ІПН фоп',
  },
  contact: {
    segmentId: 'Сегмент',
    declarationForInvoice: 'Податкова декларація платника єдиного податку',
    applicationForm: 'Заявка анкета',
    fop: {
      innPayerVAT: 'ІПН фоп',
      date: 'Дата запису про держ. реєстр',
      number: 'Номер запису',
      extractOrExcerpt: 'Виписка або витяг з ЄДРЮОб ФОП та ГФ',
      mainQuedId: 'Основний вид діяльності',
      queds: 'Кведи',
    },
    birthday: 'Дата народження',
    nationality: 'Громадянство',
    nationalityId: 'Громадянство',
    marriedTo: 'Чоловік/дружина',
    marriedId: 'Чоловік/дружина',
    representativeId: 'Контакт представника',
    responsibleId: 'Відповідальний',
    representativeDocumentTypeId: 'Тип документа представника',
    representativeNumber: 'Номер',
    representativeDate: 'Дата',
    representativeEndDate: 'Дата закінчення',
    currentAccounts: 'Банківский рахунок',
    factAddress: 'Фактична адреса',
    urAddress: 'Адреса реєстрації',
    listQueds: 'Кведи',
    identity_document: 'Документ що засвідчує особу',
    identityDocument: 'Документ що засвідчує особу',
    placeOfBirth: 'Місце народження',
    registrationAddress: 'Адреса реєстрації',
    inn: 'ІПН',
    emails: 'Пошта',
    phones: 'Телефон',
    missingExtractOrExcerpt: 'Виписка або витяг з ЄДРЮОб ФОП та ГФ',
    copiesOfContracts:
      'Копії договорів, за якими передбачається отримання доходу в 2023 р',
    formOwnershipCompany: 'Структура власності',
    provedIncome: 'Середньомісячний підтверджений сукупний чистий дохід',
    unprovedIncome: 'Середньомісячний непідтверджений сукупний чистий дохід',
    dependentsNumber: 'Кількість непрацездатних членів сімї та утриманців',
  },
  contractor: {
    segment: 'Сегмент',
    industry: 'Галузь',
    phones: 'Телефон',
    emails: 'Пошта',
    applicationForm: 'Заявка анкета',
    collegium: 'Коллегiальна дирекцiя',
    stateRegistrationDate: 'Дата проведення держ. Реєстрації',
    stateRegistrationNumber: 'Номер проведення держ. Реєстрації',
    lesseeName: 'Повна назва компанії',
    companyGroupName: 'Найменування Групи компаній',
    companyGroupEdrpou: 'ЄДРПОУ Групи компаній',
    companyGroupNationalityId: 'Країна реєстрації',
    companyGroupConnectionId: 'Повязаності',
    urAddress: 'Адреса реєстрації',
    factAddress: 'Фактична адреса',
    basicQued: 'Основний квед',
    listQueds: 'Кведи',
    currentAccounts: 'Банківский рахунок',
    executiveAuthority: 'Виконавчий орган',
    founders: 'Засновники',
    director: 'Директор',
    kbv: 'Кінцеві бенефіціарні власники',
    webSite: 'Сайт компанії',
    lesseeFullName: 'Повна назва компанії',
    statute: 'Статут (остання редакція)',
    codeAccessStatute: 'Опис документів із зазначенням коду доступу до Статуту',
    ownershipStructure: 'Структура власності у вигляді окремої схеми',
    extract: 'Виписка / витяг з ЄДРЮО, ФОП та ГФ',
    protocolFounders: 'Протокол засновників про обрання Керівника',
    orderAppointment: 'Наказ про призначення на посаду Керівника',
    formOwnershipCompany: 'Структура власності',
    inn: 'ІПН',
    responsibleId: 'Відповідальний',
  },
  agent: {
    contactId: 'Контакт',
    paymentThroughInn: 'не вірний IПН',
    paymentThroughIban: 'не вірний IBAN',
    paymentThroughBankName: 'Назва банку',
    paymentThroughMfo: 'МФО',
    paymentThroughCardNumber: 'Не вірний номер банківської картки',
    isAgentContract: 'Договір агентський (підписаний)',
    signedAgencyAgreement:
      'Договір агентський: -  [ Дата, номер договору, документ ]',
    number: 'Номер договору',
    date: 'Дата договору',
    doc: 'Агентський договір (підписаний)',
  },
  throughResponsible: {
    entityTypeId: 'Тип особи',
    entityId: 'Особа',
  },
  project: {
    projectNumber: 'Номер проекту',
    projectDate: 'Дата старту проекта',
    specificationText: 'Специфiкацiя',
    advanceDate: 'Планова дата авансу',
    analysisTypeId: 'Тип аналiзу',
    dateOfLeasing: 'Планова дата відвантаження',
    dateSigningContract: 'Планова дата підписання договору',
    deviationsProjectMessage: 'відхилення',
    overlappedDeviationsMessage: 'відхилення перекритi',
    dealerPointId: 'Диллер',
  },
  projectMiddle: {
    client: 'Клієнт',
    guarantors: 'Поручитель',
    subtenants: 'Суборендатор',
    providers: 'Постачальник',
    calculations: 'Калькуляція',
    founders: 'Засновник',
  },
  scoring: {
    financialDocument: 'Баланс - Ф1 і Ф2/Податкова декларація',
    passportDocument: 'Паспорт',
    innDocument: 'ІПН',
    employerOrCustomer: 'Роботодавець/Замовник',
    position: 'Посада/вид робіт',
    workExperience: 'Трудовий стаж на поточному місці роботи, міс',
    mainQuedId: 'Основний вид діяльності ФОП',
    termWork: 'Термін (активної) роботи ФОП, міс',
    incoming: {
      moreIncome: 'Інші доходи',
      lawIncome:
        'Заробітна плата, оплата по цивільно-правовим договорам іншого з подружжя',
      targetIncome: 'Заробітна плата, оплата по цивільно-правовим договорам',
      quedPayment: 'Від підприємницької діяльності згідно декларації',
      moreIncomeFop: 'Інші доходи',
      lawIncomeFop:
        'Заробітна плата, оплата по цивільно-правовим договорам другого із подружжя',
    },
    cost: {
      credit:
        'Погашення кредитів/ лізингу/ розстрочки/ % за кредитними картками',
      monthCost: "Сумарні щомісячні витрати (включаючи сім'ю)",
      businessIncome: "Пов'язані з підприємницькою діяльністю",
      payout: 'Виплати по кредитам/ лізингу/ розстрочки',
      homeIncome:
        "Ведення домашнього господарства (включаючи витрати на сім'ю)",
    },
  },
  dkp: {
    typeId: 'Тип договору',
    number: 'Номер договору',
    date: 'Дата старту договору',
    orderCalculationId: 'Порядок розрахунків за ДКП',
    buyerId: 'Покупець',
    signatoryId: 'Підписант покупця',
    providerId: 'Постачальник',
    providerSignatories: 'Підписант постачальника',
    dkpBuyerBasisId: 'Підстава (покупець)',
    quantityMonthGuarantees: 'К-сть місяців гарантії',
    signingTypeId: 'Вид підпису',
  },
  contract: {
    number: 'Номер договору',
    date: 'Дата старту договору',
    signatoryId: 'Підписант лізингодавця',
    lessorBasisId: 'Підстава лізингодавця',
    lesseeSignatories: 'Підписант лізингоотримувача',
    lessorSignatoryId: 'Підписант лізингодавця',
    signingTypeId: 'Вид підпису',
  },
  serviceContract: {
    number: 'Номер договору',
    date: 'Дата старту договору',
    signatoryId: 'Підписант лізингодавця',
    lessorBasisId: 'Підстава лізингодавця',
    lesseeSignatories: 'Підписант лізингоотримувача',
    lessorSignatoryId: 'Підписант лізингодавця',
    signingTypeId: 'Вид підпису',
  },
  extraAgreement: {
    number: 'Номер угоди',
    date: 'Дата старту угоди',
    typeId: 'Тип угоди',
    calculationId: 'ID калькуляції',
    lessorSignatoryId: 'Підписант лізингодавця',
    lessorBasisId: 'Підстава лізингодавця',
    lesseeSignatories: 'Підписант лізингоотримувача',
    signatoryId: 'Підписант покупця',
    buyerBasisId: 'Підстава покупця',
    providerSignatories: 'Підписант постачальника',
    editorship: 'Редакція',
    signingTypeId: 'Вид підпису',
  },
  suretyAgreement: {
    number: 'Номер договору',
    date: 'Дата старту договора',
    typeId: 'Тип поруки',
    creditorId: 'Кредитор',
    creditorSignatoryId: 'Підписант кредитора',
    creditorBasisId: 'Підстава кредитора',
    guarantorId: 'Контакт/Контрагент поручителя',
    guarantorSignatories: 'Підписант поручителя',
    debtorSignatories: 'Підписант боржника',
    signingTypeId: 'Вид підпису',
  },
  mortgagorAgreement: {
    number: 'Номер договору',
    date: 'Дата старту договору',
    pledgeTypeId: 'Тип договору',
    creditorContactPersonId: 'Контактна особа кредитора',
    creditorAgentId: 'Уповноважена особа кредитора',
    creditorBasisId: 'Підстава кредитора',
    mortgagorSignatories: 'Підписант заставодавця',
    mortgagorContactPersonId: 'Контактна особа заставодавця',
    mortgagorAgentId: 'Уповноважена особа заставодавця',
    mortgagorBasisId: 'Підстава заставодавця',
    signingTypeId: 'Вид підпису',
  },
  application: {
    sourceId: 'Джерело заявки',
    lesseeTypeId: 'Тип клієнта',
    name: 'Iм`я',
    surname: 'Прізвище',
    patronymic: 'По батькові',
    edrpou: 'ЄДРПОУ',
    inn: 'ІПН',
    companyName: 'Назва компанії',
    sourceTypeId: 'Тип джерела',
    applicationName: 'Назва заявки',
    clientNeedId: 'Потреба клієнта',
    responsibleId: 'Відповідальний',
    emails: 'Email',
    phones: 'Телефон',
    agentId: 'Агент',
    dealerPointId: 'Торгова точка',
  },
  serviceApplication: {
    typeId: 'Тип',
    sourceId: 'Джерело',
    serviceApplicationName: 'Назва заявки',
    name: "Ім'я",
  },
  lead: {
    phone: 'Телефон',
    name: 'Iм`я',
    email: 'Email',
    lesseeTypeId: 'Тип клієнта',
    clientNeedId: 'Потреба клієнта',
    sourceId: 'Джерело ліда',
    responsibleId: 'Відповідальний',
  },
  slider: {
    business_regions: 'Регіон ведення підприємницької діяльності',
    environmentalQuestionnaire: 'Анкета екологічної обачності',
    benefits_questionnaire: 'Опитувальник BEST BENEFITS',
    benefitsQuestionnaire: 'Опитувальник BEST BENEFITS',
    legalDocuments: 'Юридичнi документи',
    specificationDocuments: 'Специфiкацiя',
    applicationForm: 'Заявку анкета',
    questionnaire: 'Опитувальник',
    deviationsProjectMessage: 'відхилення',
    overlappedDeviationsMessage: 'Перекриття відхилень',
    commentByAc: 'Коментар',
    productStatusText: 'Данi про поставку',
    leasingObjectCountryId: 'Країна походження',
    comment: 'Коментар по проекту',
    legalGuarantorState: 'Поручитель - юридична особа',
    personGuarantorState: 'Поручитель - фiзична особа',
    legalSubLeseeState: 'Суборендатор - юридична особа',
    personSubLesseState: 'Суборендатор - фiзична особа',
    isAttorneyAbroad: 'Довіреність закордон',
    providerEmployeeId: 'Контактна особа постачальника',
    prepayToSupplier: 'Передоплата постачальнику',
    dateSigningContract: 'Планова дата підписання договору',
    dateOfLeasing: 'Планова дата відвантаження ОЛ',
    advanceDate: 'Планова дата авансу',
    vinCode: 'VIN код',
    inStock: 'Данi про поставку',
    providerId: 'Постачальник',
    analysisTypeId: 'Тип аналiзу',
    placeOfShipment: 'Мiсце відгрузки',
    missingPassport: 'Паспорт',
    missingCertificateOfInn:
      'Довідка про присвоєння ідентифікаційного номера фізичної особи-платника податків',
    // missingAttorneyAndOther: 'Довіреність та інші док-ти про повноваження',
    // missingMarriageCertificate: 'Свідоцтво про укладення/розірвання шлюбу',
    missingIncomeStatement: 'Довідка про доходи',
    missingDeclarationForInvoice:
      'Податкова декларація платника єдиного податку',
    missingPropertyOwnership:
      'Документи, які підтверджують право власності на майно',
    missingCurrentDebtCertificate:
      'Довідка з банку/лізингової компанії/ін.кредитора про стан поточної заборгованості',
    missingRegulations: 'Статут(остання редакція)',
    missingDescriptionOfDocuments:
      'Опис документів із зазначенням коду доступу до Статуту',
    ownershipStructure: 'Структура власності у вигляді окремої схеми',
    missingExtract: 'Виписка / витяг з ЄДРЮО, ФОП та ГФ',
    missingProtocolOfTheFounders: 'Протокол засновників про обрання Керівника',
    missingOrderHead: 'Наказ про призначення на посаду Керівника',
    missingBalance: 'Баланс - Ф1 і Ф2',
    missingDeclaration: 'Декларація за розрахунком податку на прибуток',
    missingGeneralInformation: 'Загальна оборотно-сальдова відомість',
    missingAccountInformation:
      'Оборотно-сальдові відомості по рахунку (36,37 і 63, 68 рах.)',
    missingCopiesOfContracts: 'Копії договорів',
    missingTurnoverCertificates:
      'Довідки про обороти по рахунках з обслуговуючих банків',
    missingReferencesOfObligations:
      "Довідки про наявність / відсутність зобов'язань",
    agreedMarketValuePL: 'Погоджена ринкова вартість ПЛ',
    leasingObjectPhotos: 'Фото обʼєкта',
    mapOfCrops: 'Карта посівів',
  },
  benefitsQuestionnaire: {
    questionnaire: 'Опитувальник',
    isParse: 'Розпарсити файл',
  },
  plan: {
    name: 'Назва',
    typeId: 'Тип',
    month: 'Місяць',
    value: 'Ціль',
    managerId: 'Менеджер',
    objectEntities: 'objectEntities',
    departmentId: 'Департамент',
  },
  sliderGuarantorsDocuments: {
    documents: 'Документи поручителя',
  },
  sliderSubtenants: {
    contractors: 'Контрагенти суборендатора',
    contacts: 'Контакти суборендатора',
  },
  sliderGuarantors: {
    contractors: 'Контрагенти поручителя',
    contacts: 'Контакти поручителя',
  },
  activity: {
    title: 'Заголовок',
    typeId: 'Тип зустрiчi',
    startDate: 'Дата старту',
    endDate: 'Дата завершення',
    contactId: 'Контакт',
    statusId: 'Статус',
    description: 'Опис завдання',
    dealerPointOfSaleId: 'Представник делера',
    contractorId: 'Контрагент',
  },
  finishEvent: {
    resultComment: 'Коментар до результату',
    resultId: 'Результат',
    resultFiles: 'Фотофіксація зустрічі',
  },
  selectPrintedForm: {
    curAccount: 'Розрахунковий рахунок',
  },
  legalDepartmentVerification: {
    guarantorResults:
      'Поручитель: Відомості про наявність або відсутність обмежень',
    subtenantResults:
      'Суборендатор: Відомості про наявність або відсутність обмежень',
    providersResults:
      'Постачальник: Відомості про наявність або відсутність обмежень',
    clientConclusion:
      'Клієнт: Відомості про наявність або відсутність обмежень',
    generalConclusion: 'Загальний висновок',
  },
  riskDepartmentAnalyze: {
    statusId: 'Статус перевірки',
  },
  financialDepartmentVerification: {
    financialRiskLevelId: 'Рівень ризику',
    clientSanctionDocument: 'Санкційні документи клієнта',
    providerSanctionDocument: 'Санкційні документи постачальника',
    financialRiskAssessmentNote: 'Коментарі',
    financialRiskAssessment: 'Виявлені критерії ризику',
  },
  departments: {
    department: 'Департамент',
  },
  administrationEmployee: {
    collegium: 'Колегiальна дирекцiя',
    tempPhoto: 'Фото',
    password: 'Пароль',
    password_confirmation: 'Підтвердження паролю',
    surname: 'Прізвище',
    name: 'Iм`я',
    patronymic: 'По батькові',
    phone: 'Телефон',
    email: 'email',
    roleId: 'Роль',
    genderId: 'Стать',
    birthDate: 'Дата народження',
    inn: 'Iдентифiкацiйний код',
    departmentId: 'Департамент',
    post: 'Посада',
  },
  contractorCrud: {
    postId: 'Посада',
    fullPostName: 'Повна назва посади',
    departmentId: 'Департамент',
    roleId: 'Роль',
    contractorId: 'Контрагент',
    edrpou: 'ЕДРПОУ',
    shortName: 'Назва контрагента',
    signatoryBases: 'На підставі чого підписант',
  },
  contactCrud: {
    inn: 'Iдентифiкацiйний номер',
    contractorId: 'Контрагент',
    fullPostName: 'Повна назва посади',
    departmentId: 'Департамент',
    postId: 'Посада',
    contactId: 'Контакт',
    surname: 'Прізвище',
    name: 'Iм`я',
    contactorPost: 'Посада',
    mainPhone: 'Телефон',
    phone: 'Телефон',
    email: 'email',
    signatoryBases: 'На підставі чого підписант',
  },
  planTask: {
    date: 'Дата',
    taskMessage: 'Нагадування',
    timeStart: 'Час',
    HH: 'Година',
    mm: 'Хвилина',
  },
  sendSms: {
    message: 'Повідомлення',
    title: 'Тема',
  },
  expensesMarket: {
    date: 'Мiсяць',
    amount: 'Витрата',
  },
  marketingCompany: {
    campaignName: 'Назва компанiї',
    utmSource: 'Джерело [Значення]',
    utmSourceTypeId: 'Джерело',
    utmMedium: 'Канал [Значення]',
    utmMediumTypeId: 'Канал',
    utmCampaign: 'Кампанія [Значення]',
    utmCampaignTypeId: 'Кампанія',
    utmContent: 'Ідентифікатор [Значення]',
    utmContentTypeId: 'Ідентифікатор',
    utmTerm: 'Ключове слово [Значення]',
    utmTermTypeId: 'Ключове слово',
    product: 'Продукт',
    slogan: 'Рекламне повідомлення ',
  },
  contactDialog: {
    postId: 'Посада',
    fullPostName: 'Повна назва посади',
    departmentId: 'Департамент',
    roleId: 'Роль',
    edrpou: 'ЕДРПОУ',
    name: 'Назва контрагента',
    bankName: 'Назва банку',
    mfo: 'МФО',
    accountNumber: 'Номер рахунку',
    type: 'Тип рахунку',
    currency: 'Валюта',
    contractorId: 'Контрагент',
  },
  contactEducation: {
    contactId: 'Контакт',
    typeId: 'Освіта',
    educationalInstitutionName: 'Назва навчального закладу',
  },
  template: {
    name: 'Назва',
    sign: 'Повідомлення',
  },
  address: {
    addressTypeId: 'Тип адреси',
    typeId: 'Тип адреси',
    apartmentTypeId: 'Тип помешкання',
    city: 'Мiсто',
    house: 'Iндекс',
  },
  contractorDialog: {
    mfo: 'МФО',
    bankName: 'Назва банку',
    accountNumber: 'Номер рахунку',
    type: 'Тип рахунку',
    currency: 'Валюта',
    percent: 'Відсоток',
    contactId: 'Контакт',
    name: 'Бенефіціар',
    fullName: 'Бенефіціар',
    amount: 'Сума',
    amountPercent: 'Відсоток',
    code: 'IНН/ЕДРПОУ',
    founderRoleId: 'Роль',
    residentCountryId: 'Країна резиденства',
    registrationCountryId: 'Країна реєстрації',
    location: 'Адреса',
  },
  contractorKbv: {
    surname: 'Прізвище',
    name: 'Iм`я',
    patronymic: 'По батькові',
    inn: 'IПН',
    birthday: 'Дата народження',
    percent: 'Відсоток володiння',
    contactId: 'Контакт',
    nationality: 'Громадянство',
    influence: 'Тип впливу',
    signKbv: 'Ознака КБВ',
  },
  contractorCollegiumParticipant: {
    contactId: 'Контакт',
    directorPostId: 'Посада',
  },
  contractorDirector: {
    directorPostId: 'Посада',
    surname: 'Прізвище',
    name: 'Iм`я',
    patronymic: 'По батькові',
    inn: 'Iдентифiкацiйний номер',
    birthday: 'Дата народження',
    directorContact: 'Контакт',
  },
  approveSlider: {
    calculationId: 'ID калькуляцiї',
  },
  setApproveCalculation: {
    kppTypeId: 'Тип КПП',
    patrolTypeId: 'Тип палива',
    carModificationTypeId: 'Модифiкацiя',
    graph: 'Графiк',
    providerId: 'Постачальник',
    dealerPointId: 'Дилер',
  },
  klp: {
    headId: 'Голова КЛП',
    members: 'Члени КЛП',
    invitations: 'Запрошенi до КЛП',
    solutionId: 'Рiшення',
  },
  adminDirectory: {
    tableName: 'Назва таблицi',
    name: 'Назва',
    field: 'Поле',
  },
  projectGuarantors: {
    guarantor: 'Поручитель',
    projectId: 'Проект',
  },
  projectMiddleManager: {
    responsibleId: 'Мідл менеджер',
  },
  companyGroup: {
    companyGroupId: 'Група компаній',
    name: 'Назва групи',
    roleId: 'Роль',
  },
  projectMortgagors: {
    mortgagor: 'Заставодавець',
    projectId: 'Проект',
    pledgeObject: 'Предмет застави',
    pledgeTypeId: 'Тип застави',
  },
  setRiskResponsible: {
    riskResponsibleId: 'Відповідальний',
  },
  frontSalesPlan: {
    plan: 'Вкажіть план на мiсяць',
  },
  frontActivitiesPlan: {
    plan: 'Вкажіть план на мiсяць',
  },
  dealerSeller: {
    providerTypeId: 'Тип постачальника',
    providerId: 'Постачальник',
  },
  dealer: {
    name: 'Назва',
    typeId: 'Тип',
    responsibleId: 'Відповідальний',
    contractorId: 'Юридична особа',
    approvedDiscountComment: 'Погоджена знижка відсотки',
    individualProgramsComment: 'Коментар до iндивідуальної програми',
    posTerminalsComment: 'Коментар до POS - матерiалiв',
    generalProgramComment: 'Коментар до спільної програми',
    categoryId: 'Категорiя',
    saleObjectTypes: "Тип (Марка) продаваних об'єктів",
    temporaryPhones: 'Телефон',
    email: 'Email',
    city: 'Адреса',
  },
  dealerInactivation: {
    deactivatedComment: 'Коментар',
  },
  contactAgent: {
    surname: 'Прізвище',
    name: 'Iм`я',
    patronymic: 'По батькові',
    postId: 'Посада',
    mainPhone: 'Телефон',
    mainEmail: 'email',
  },
  dealerContactPerson: {
    dealerContactMethod: 'Метод звязку',
    agentId: 'Агент',
    contactId: 'Контакт',
    surname: 'Прізвище',
    name: 'Iм`я',
    patronymic: 'По батькові',
    postId: 'Посада',
    mainPhone: 'Телефон',
    mainEmail: 'Email',
    dealerPointId: 'Торгова точка',
    fullPostName: 'Повна назва посади',
    departmentId: 'Департамент',
  },
  adminSpecifications: {
    name: 'Назва',
    modification: 'Модифiкацiя',
  },
  applicationRejection: {
    statusId: 'Статус',
    rejectionTypeId: 'Причина відмови',
    rejectionComments: 'Коментар',
    klpTypeId: 'Тип',
    projectComment: 'Коментар',
  },
  riskResult: {
    resultComment: 'Коментар (мін. 20 символів)',
    categoryId: 'Категорія',
  },
  faqPresentation: { name: 'Назва', document: 'Документ' },
  ticket: {
    themeId: 'Teмa',
    title: 'Назва',
    description: 'Опис',
    statusId: 'Статус',
  },
  leasingCommittee: {
    date: 'Дата',
  },
  klpLimitOfFinancing: {
    limitOfFinancing: 'Ліміт фінансування',
  },
  faqVideo: {
    typeId: 'Тип',
    name: 'Назва',
    url: 'Відео',
  },
  faqDocument: {
    name: 'Назва',
    file: 'Файл',
  },
  supervisor: {
    name: 'Назва',
    supervisorId: 'Супервайзер',
    middleManagerId: 'Мiдл менеджер',
  },
  supervisorFrontManager: {
    frontManagerId: 'Фронт менеджер',
  },
  guarantorData: {
    guarantorTypeId: 'Тип поручителя',
    id: 'Контакт/контрагент ',
  },
  phoneItem: {
    typeId: 'Тип номеру',
    phone: 'Номер',
  },
  emailItem: {
    typeId: 'Тип пошти',
    email: 'Email',
  },
  leadByCall: {
    name: 'Назва ліда',
  },
  carForSaleSelect: {
    selectedCar: 'Авто',
  },
  executiveAuthority: {
    employees: 'Не вказано Директора/Дирекцію',
    executiveAuthorityId: 'Виконавчий орган',
  },
  editorship: {
    manualEditorship: 'Пункт договору: ручне введення',
    selectedPoints: 'Пункти договору',
    currentAccountId: 'Розрахунковий рахунок',
  },
}
