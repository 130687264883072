import crm from '@/layout/crm.vue'
import main from '@/pages/dashboard/main.vue'
import ArchiveList from '@/pages/archive/ArchiveList.vue'
import ArchiveSingle from '@/pages/archive/ArchiveSingle.vue'
import departments from '@/pages/administration/departments.vue'
import employees from '@/pages/administration/employees/employees.vue'
import Employee from '@/pages/administration/employees/index.vue'
import administration from '@/pages/administration/administration.vue'
import ChangeResponsible from '@/pages/administration/change-responsible/Index.vue'
import newCalculation from '@/pages/calculations/new-calculation.vue'
import calculations from '@/pages/calculations/calculations.vue'
import LeasingObject from '@/pages/calculations/tabs/LeasingObject.vue'
import LeasingTerms from '@/pages/calculations/tabs/LeasingTerms.vue'
import AdditionalTerms from '@/pages/calculations/tabs/AdditionalTerms.vue'
import Service from '@/pages/calculations/tabs/Service.vue'
import applications from '@/pages/applications/Index.vue'
import application from '@/pages/applications/single/Application.vue'
import ApplicationCommonInfo from '@/pages/applications/single/tabs/common-info/CommonInfo.vue'
import ApplicationCalculations from '@/pages/applications/single/tabs/Calculations.vue'
import ApplicationCommunications from '@/pages/applications/single/tabs/communications/Communications.vue'
import Slider from '@/pages/applications/slider/Slider.vue'
import SliderMainInfo from '@/pages/applications/slider/MainInfo.vue'
import SliderClient from '@/pages/applications/slider/Client.vue'
import SliderLeasingObjects from '@/pages/applications/slider/leasing-objects/LeasingObjects.vue'
import SliderScoring from '@/pages/applications/slider/Scoring.vue'
import SliderAssociatedParties from '@/pages/applications/slider/associated-parties/AssociatedParties.vue'
import FAQ from '@/pages/FAQ/Index.vue'
import FaqVideos from '@/pages/FAQ/tabs/Videos/List.vue'
import FaqDocuments from '@/pages/FAQ/tabs/Documents/List.vue'
import FaqAdmin from '@/pages/administration/FAQ/Index.vue'
import FaqAdminVideos from '@/pages/administration/FAQ/tabs/Videos/List.vue'
import FaqAdminWatchVideo from '@/pages/administration/FAQ/tabs/Videos/WatchVideo.vue'
import FaqAdminDocuments from '@/pages/administration/FAQ/tabs/Documents/List.vue'
import FaqAdminOthers from '@/pages/administration/FAQ/tabs/Presentations/List.vue'
import FaqOthers from '@/pages/FAQ/tabs/Presentations/List.vue'
import Planning from '@/pages/planning/Planning.vue'
import CreateFrontSalesPlan from '@/pages/planning/tabs/CreateFrontSalesPlan.vue'
import CreateFrontActivityPlan from '@/pages/planning/tabs/CreateFrontActivityPlan.vue'
import paymentHistory from '@/components/payment-history.vue'
import activities from '@/pages/activities/activities.vue'
import myMeetings from '@/pages/activities/my-meetings.vue'
import myReminders from '@/pages/activities/my-reminders.vue'
import invitations from '@/pages/activities/invitations.vue'
import contacts from '@/pages/transaction-participants/contacts/main.vue'
import DealerList from '@/pages/transaction-participants/dealers/DealerList.vue'
import Dealer from '@/pages/transaction-participants/dealers/Index.vue'
import DealerMainTab from '@/pages/transaction-participants/dealers/tabs/Dealer.vue'
import DealerInfo from '@/pages/transaction-participants/dealers/tabs/info.vue'
import DealerParthership from '@/pages/transaction-participants/dealers/tabs/parthership.vue'
import individuals from '@/pages/transaction-participants/contacts/contact.vue'
import contractors from '@/pages/transaction-participants/contractors/main.vue'
import Contractor from '@/pages/transaction-participants/contractors/counterparty.vue'
import TransactionParticipants from '@/pages/transaction-participants/index.vue'
import profile from '@/pages/profile/profile.vue'
import ProjectsList from '@/pages/projects/ProjectsList.vue'
import Project from '@/pages/projects/Project.vue'
import ProjectLeasingObjectTab from '@/pages/projects/tabs/leasing-objects/LeasingObjects.vue'
import ProjectPlan from '@/pages/projects/tabs/ProjectPlan.vue'
import ProjectGuarantors from '@/pages/projects/tabs/guarantors/Guarantors.vue'
import ProjectSubtenants from '@/pages/projects/tabs/subtenants/Subtenants.vue'
import AdditionalSupport from '@/pages/projects/tabs/AdditionalSupport.vue'
import ProjectDocumentsTab from '@/pages/projects/tabs/documents/Documents.vue'
import ProjectAnalysisTab from '@/pages/projects/tabs/Analysis.vue'
import ProjectHistoryTab from '@/pages/projects/tabs/History.vue'
import ProjectAnalys from '@/pages/projects-verification/financial-department/tabs/Analyze.vue'
import ProjectRisk from '@/pages/projects-verification/financial-department/tabs/RiskLevel.vue'
import Contracts from '@/pages/contracts/Contracts.vue'
import DflContracts from '@/pages/contracts/DFL/DflContracts.vue'
import DflPartiesDetails from '@/pages/contracts/DFL/tabs/DflPartiesDetails.vue'
import DflLeasingObject from '@/pages/contracts/DFL/tabs/DflLeasingObject.vue'
import DflPaymentHistory from '@/pages/contracts/DFL/tabs/DflPaymentHistory.vue'
import DflAgreements from '@/pages/contracts/DFL/tabs/DflAgreements.vue'
import DflHistory from '@/pages/contracts/DFL/tabs/DflHistory.vue'
import DflСonnections from '@/pages/contracts/DFL/tabs/DflСonnections.vue'
import DflContract from '@/pages/contracts/DFL/DflContract.vue'
import DkpContracts from '@/pages/contracts/DKP/DkpContracts.vue'
import DKPContract from '@/pages/contracts/DKP/DKPContract.vue'
import DkpPartiesDetails from '@/pages/contracts/DKP/tabs/DkpPartiesDetails.vue'
import DkpSaleTerms from '@/pages/contracts/DKP/tabs/DkpSaleTerms.vue'
import DkpBuyBackSchedule from '@/pages/contracts/DKP/tabs/DkpBuyBackSchedule.vue'
import DkpAgreements from '@/pages/contracts/DKP/tabs/DkpAgreements.vue'
import DkpLinks from '@/pages/contracts/DKP/tabs/DkpLinks.vue'
import Directories from '@/pages/administration/directories/directories-list.vue'
import DirectorySingle from '@/pages/administration/directories/single.vue'
import AdditionalAgreements from '@/pages/contracts/ExtraAgreements/ExtraAgreements.vue'
import ExtraAgreementsIndex from '@/pages/contracts/ExtraAgreements/Index.vue'
// DFL
import Agreement from '@/pages/contracts/ExtraAgreements/dfl/Agreement.vue'
import ExtraAgreementPaymentHistory from '@/pages/contracts/ExtraAgreements/dfl/tabs/PaymentHistory.vue'
import PartiesRequisites from '@/pages/contracts/ExtraAgreements/dfl/tabs/PartiesRequisites.vue'
import DUAgreements from '@/pages/contracts/ExtraAgreements/dfl/tabs/Agreements.vue'
// DKP
import AgreementDkp from '@/pages/contracts/ExtraAgreements/dkp/Agreement.vue'
import PartiesRequisitesDkp from '@/pages/contracts/ExtraAgreements/dkp/tabs/PartiesRequisites.vue'
import DUAgreementsDkp from '@/pages/contracts/ExtraAgreements/dkp/tabs/Agreements.vue'

import SuretyAgreements from '@/pages/contracts/SuretyAgreements/Agreements.vue'
import SuretyAgreement from '@/pages/contracts/SuretyAgreements/Agreement.vue'
import SuretyPartiesDetails from '@/pages/contracts/SuretyAgreements/tabs/PartiesDetails.vue'
import SuretySubject from '@/pages/contracts/SuretyAgreements/tabs/SuretySubject.vue'
import SuretyDocuments from '@/pages/contracts/SuretyAgreements/tabs/Documents.vue'
import MortgagorAgreements from '@/pages/contracts/MortgagorAgreements/Agreements.vue'
import MortgagorAgreement from '@/pages/contracts/MortgagorAgreements/Agreement.vue'
import MortgagorPartiesDetails from '@/pages/contracts/MortgagorAgreements/tabs/PartiesDetails.vue'
import MortgagorPledgeProperty from '@/pages/contracts/MortgagorAgreements/tabs/PledgeProperty.vue'
import MortgagorDocuments from '@/pages/contracts/MortgagorAgreements/tabs/Documents.vue'
import Verification from '@/pages/projects-verification/Index.vue'
import SecurityServiceProjects from '@/pages/projects-verification/security-service/Index.vue'
import SecurityServiceVerification from '@/pages/projects-verification/security-service/project/Project.vue'
import SSClientVerification from '@/pages/projects-verification/security-service/tabs/Client.vue'
import SSProviderVerification from '@/pages/projects-verification/security-service/tabs/Provider.vue'
import SSLeasingObject from '@/pages/projects-verification/security-service/tabs/LeasingObject.vue'
import SSGuarantor from '@/pages/projects-verification/security-service/tabs/Guarantor.vue'
import SSSubtenant from '@/pages/projects-verification/security-service/tabs/Subtenant.vue'
import LegalDepartmentProjects from '@/pages/projects-verification/legal-department/Projects.vue'
import LegalDepartmentVerification from '@/pages/projects-verification/legal-department/Project.vue'
import LegalDepartmentClient from '@/pages/projects-verification/legal-department/tabs/Client.vue'
import LegalDepartmentProvider from '@/pages/projects-verification/legal-department/tabs/Provider.vue'
import LegalDepartmentLeasingObject from '@/pages/projects-verification/legal-department/tabs/LeasingObject.vue'
import LegalDepartmentGuarantor from '@/pages/projects-verification/legal-department/tabs/Guarantor.vue'
import LegalDepartmentSubtenant from '@/pages/projects-verification/legal-department/tabs/Subtenant.vue'
import LegalDepartmentAnalyze from '@/pages/projects-verification/legal-department/tabs/Analyze.vue'
import RiskDepartmentProjects from '@/pages/projects-verification/risk-department/Index.vue'
import RiskDepartmentProject from '@/pages/projects-verification/risk-department/Project.vue'
import RiskDepartmentClient from '@/pages/projects-verification/risk-department/tabs/Client.vue'
import RiskDepartmentHistory from '@/pages/projects-verification/risk-department/tabs/History.vue'
import RiskDepartmentProvider from '@/pages/projects-verification/risk-department/tabs/Provider.vue'
import RiskDepartmentLeasingObject from '@/pages/projects-verification/risk-department/tabs/LeasingObject.vue'
import RiskDepartmentGuarantor from '@/pages/projects-verification/risk-department/tabs/Guarantor.vue'
import RiskDepartmentSubtenant from '@/pages/projects-verification/risk-department/tabs/Subtenant.vue'
import FinancialDepartmentProjects from '@/pages/projects-verification/financial-department/Index.vue'
import LeasingComitteeProjects from '@/pages/projects-verification/leasing-committee/Projects.vue'
import LeasingComitteeProject from '@/pages/projects-verification/leasing-committee/Project.vue'
import LeasingCommitteeLO from '@/pages/projects-verification/leasing-committee/tabs/LeasingObject.vue'
import LeasingCommitteeMeeting from '@/pages/projects-verification/leasing-committee/tabs/Meeting.vue'
import LeasingCommitteeAnalyze from '@/pages/projects-verification/leasing-committee/tabs/Analyze.vue'
import LeasingCommitteeDocuments from '@/pages/projects-verification/leasing-committee/tabs/Documents.vue'
import FinancialDepartmentVerification from '@/pages/projects-verification/financial-department/Project.vue'
import FinancialDepartmentClientVerification from '@/pages/projects-verification/financial-department/tabs/Client.vue'
import FinancialDepartmentGuarantorVerification from '@/pages/projects-verification/financial-department/tabs/guarantor/Guarantor.vue'
import FinancialDepartmentSubtenantVerification from '@/pages/projects-verification/financial-department/tabs/subtenant/Subtenant.vue'
import FinancialDepartmentProviderVerification from '@/pages/projects-verification/financial-department/tabs/Provider.vue'
import Templates from '@/pages/administration/templates/Templates.vue'
import NewTemplate from '@/pages/administration/templates/NewTemplate.vue'
import commonInfo from '@/pages/transaction-participants/contacts/tabs/common-info.vue'
import legalData from '@/pages/transaction-participants/contacts/tabs/legal-data.vue'
import FinanceData from '@/pages/transaction-participants/contacts/tabs/finance-data.vue'
import careerEducation from '@/pages/transaction-participants/contacts/tabs/career-education.vue'
import links from '@/pages/transaction-participants/contacts/tabs/links.vue'
import financialMonitoring from '@/pages/transaction-participants/contacts/tabs/financial-monitoring.vue'
import Documents from '@/pages/transaction-participants/contacts/tabs/Documents.vue'
import ContactProjects from '@/pages/transaction-participants/contacts/tabs/Projects.vue'
import ContactAgreements from '@/pages/transaction-participants/contacts/tabs/Agreements.vue'
import ContactHistory from '@/pages/transaction-participants/contacts/tabs/History.vue'
import ContactChanges from '@/pages/transaction-participants/contacts/tabs/Changes.vue'
import ContactProvider from '@/pages/transaction-participants/contacts/tabs/Provider.vue'
import ContractoLegalData from '@/pages/transaction-participants/contractors/tabs/legal-data.vue'
import OrganizationStructure from '@/pages/transaction-participants/contractors/tabs/organization-structure/organization-structure.vue'
import ContractorLinks from '@/pages/transaction-participants/contractors/tabs/links.vue'
import Checking from '@/pages/transaction-participants/contractors/tabs/checking.vue'
import ContractorProvider from '@/pages/transaction-participants/contractors/tabs/Provider.vue'
import ContractorDocuments from '@/pages/transaction-participants/contractors/tabs/Documents.vue'
import ContractorAgreements from '@/pages/transaction-participants/contractors/tabs/Agreements.vue'
import ContractorProjects from '@/pages/transaction-participants/contractors/tabs/Projects.vue'
import ContractorHistory from '@/pages/transaction-participants/contractors/tabs/History.vue'
import ContractorChanges from '@/pages/transaction-participants/contractors/tabs/Changes.vue'
import SupportList from '@/pages/support/support-list.vue'
import SupportTicket from '@/pages/support/index.vue'
import AdminTicket from '@/pages/administration/tickets/index.vue'
import Tickets from '@/pages/administration/tickets/ticket-list.vue'
import BestLeasingTemplates from '@/pages/administration/BestLeasingTemplates.vue'
import AgentList from '@/pages/transaction-participants/agents/AgentList.vue'
import Agent from '@/pages/transaction-participants/agents/Index.vue'
import AgentMainTab from '@/pages/transaction-participants/agents/tabs/Agent.vue'
import PaymentParameters from '@/pages/transaction-participants/agents/tabs/PaymentParameters.vue'
import ApplicationsInfo from '@/pages/transaction-participants/agents/tabs/ApplicationsInfo.vue'
import Supervisors from '@/pages/administration/supervisors/List.vue'
import Supervisor from '@/pages/administration/supervisors/Supervisor.vue'
import LeasingObjectList from '@/pages/leasing-objects/LeasingObjectList.vue'
import LeasingObjectSingle from '@/pages/leasing-objects/LeasingObjectSingle.vue'
import LeasingObjectRegistrationData from '@/pages/leasing-objects/tabs/RegistrationData.vue'
import LeasingObjectDFLS from '@/pages/leasing-objects/tabs/DFLS.vue'
import LeasingObjectInsuranceContracts from '@/pages/leasing-objects/tabs/InsuranceContracts.vue'
import LeasingObjectInsuranceCases from '@/pages/leasing-objects/tabs/InsuranceCases.vue'
import LeasingObjectTechnicalAssistance from '@/pages/leasing-objects/tabs/TechnicalAssistance.vue'
import LeadsList from '@/pages/leads/LeadsList.vue'
import Lead from '@/pages/leads/Lead.vue'
import ProjectHistoryTable from '@/components/ProjectHistoryTable.vue'
import administrationPhones from '@/pages/administration/phones.vue'
import AdministrationLeadCalls from '@/pages/administration/leadCalls/LeadCallsList.vue'
import AdministrationLeadCallSingle from '@/pages/administration/leadCalls/LeadCallSingle.vue'
import LeadHistory from '@/pages/leads/tabs/LeadHistory.vue'
import LeadCommunication from '@/pages/leads/tabs/LeadCommunication.vue'
import LeadNotifications from '@/pages/leads/tabs/LeadNotifications.vue'
import LeadCallHistory from '@/pages/leads/tabs/LeadCallHistory.vue'
import LeadGeneralInfo from '@/pages/leads/tabs/LeadGeneralInfo.vue'
import RiskDepartmentCheck from '@/pages/projects-verification/risk-department/tabs/Analyze.vue'
import AdminLogging from '@/pages/administration/Logging/index.vue'
import LogDetails from '@/pages/administration/Logging/LogDetails.vue'
import Marketing from '@/pages/marketing/index.vue'
import MarketingCompany from '@/pages/marketing/marketingCompany/marketingList.vue'
import EditMarketing from '@/pages/marketing/marketingCompany/marketing.vue'
import MarketingGeneral from '@/pages/marketing/marketingCompany/tabs/MarketingGeneral.vue'
import MarketingExpenses from '@/pages/marketing/marketingCompany/tabs/Expenses.vue'
import ServiceIndex from '@/pages/services/Index.vue'
import ServiceApplicationList from '@/pages/services/applications/List.vue'
import ServiceApplicationSingle from '@/pages/services/applications/Single.vue'
import ServiceApplicationGeneralInfo from '@/pages/services/applications/tabs/GeneralInfo.vue'
import ServiceApplicationLeasingObject from '@/pages/services/applications/tabs/LeasingObject.vue'
import ServiceApplicationHistory from '@/pages/services/applications/tabs/History.vue'
import ScoringSingle from '@/pages/projects-verification/scoring/Single.vue'
import ScoringClient from '@/pages/projects-verification/scoring/tabs/Client.vue'
import ScoringProvider from '@/pages/projects-verification/scoring/tabs/Provider.vue'
import ScoringLeasingObject from '@/pages/projects-verification/scoring/tabs/LeasingObject.vue'
import ActPayments from '@/pages/transaction-participants/agents/tabs/ActPayments.vue'
import BookKeeping from '@/pages/book-keeping/index.vue'
import BookKeepingPayment from '@/pages/book-keeping/Payments/PaymentsList.vue'
import EditBookPayment from '@/pages/book-keeping/Payments/Single.vue'
import BookPaymentGeneral from '@/pages/book-keeping/Payments/tabs/PaymentGeneral.vue'
import LegalCase from '@/pages/legal-case/index.vue'
import DelayList from '@/pages/legal-case/DelayList/DelayList.vue'
import ScheduledList from '@/pages/legal-case/DelayList/ScheduledList.vue'
import HistoryPhones from '@/pages/transaction-participants/contacts/tabs/activities/HistoryPhones.vue'
import HistoryMeetings from '@/pages/transaction-participants/contacts/tabs/activities/HistoryMeetings.vue'
import HistoryScheduler from '@/pages/transaction-participants/contacts/tabs/activities/HistoryScheduler.vue'
import HistoryComment from '@/pages/transaction-participants/contacts/tabs/activities/HistoryComment.vue'
import HistoryCommentContactor from '@/pages/transaction-participants/contractors/tabs/activities/HistoryComment.vue'
import HistoryPhonesContractor from '@/pages/transaction-participants/contractors/tabs/activities/HistoryPhones.vue'
import HistorySchedulerContractor from '@/pages/transaction-participants/contractors/tabs/activities/HistoryScheduler.vue'
import DOLContracts from '@/pages/contracts/DOL/DOLContracts.vue'
import ServicesContracts from '@/pages/contracts/ServiceContracts/Services.vue'
import ServiceContracts from '@/pages/contracts/ServiceContracts/ServiceSingle.vue'
import ServicePartiesDetails from '@/pages/contracts/ServiceContracts/tabs/ServicePartiesDetails.vue'
import ServiceLeasingObject from '@/pages/contracts/ServiceContracts/tabs/ServiceLeasingObject.vue'
import ServicePaymentHistory from '@/pages/contracts/ServiceContracts/tabs/ServicePaymentHistory.vue'
import ServiceAgreements from '@/pages/contracts/ServiceContracts/tabs/ServiceAgreements.vue'
import ServiceHistory from '@/pages/contracts/ServiceContracts/tabs/ServiceHistory.vue'
import ServiceСonnections from '@/pages/contracts/ServiceContracts/tabs/ServiceСonnections.vue'
import EDocList from '@/pages/e-docs/EDocList.vue'
import EDocSingle from '@/pages/e-docs/EDocSingle.vue'
import EDocClient from '@/pages/e-docs/tabs/Client.vue'
import EDocContract from '@/pages/e-docs/tabs/Contract.vue'
import EDocLeasingObject from '@/pages/e-docs/tabs/LeasingObject.vue'
import EDocAnalysis from '@/pages/e-docs/tabs/Analysis.vue'
import RegistrationApplication from '@/pages/registrationApplication/index.vue'
import RegistrationApplicationSingle from '@/pages/registrationApplication/Single.vue'
import RegistrationApplicationGeneral from '@/pages/registrationApplication/tabs/general-info.vue'
import AdminPlanList from '@/pages/administration/plans/PlanList.vue'
import PlanList from '@/pages/plans/Index.vue'
import PlanSingle from '@/pages/plans/single/PlanSingle.vue'
import AveragePriceList from '@/pages/average-price/averagePriceList.vue'
import AverageCreateCalculation from '@/pages/average-price/pages/create-average-calculation.vue'
import DkpHistory from '@/pages/contracts/DKP/tabs/DkpHistory.vue'
import ExtraAgreementHistory from '@/pages/contracts/ExtraAgreements/dfl/tabs/ExtraAgreementHistory.vue'
import ExtraAgreementHistoryDkp from '@/pages/contracts/ExtraAgreements/dkp/tabs/ExtraAgreementHistory.vue'
import MortgagorHistory from '@/pages/contracts/MortgagorAgreements/tabs/MortgagorHistory.vue'
import SuretyHistory from '@/pages/contracts/SuretyAgreements/tabs/SuretyHistory.vue'
import CarForSaleIndex from '@/pages/car-sale/Index.vue'
import CarForSaleSingle from '@/pages/car-sale/CarForSaleSingle.vue'
import ExtraAgreementCalculation from '@/pages/contracts/ExtraAgreements/dfl/tabs/ExtraAgreementCalculation.vue'
import ExtraEditorships from '@/pages/contracts/ExtraAgreements/dfl/tabs/ExtraEditorships.vue'
import LeadDublicates from '@/pages/leads/tabs/LeadDublicates.vue'
import AssignmentList from '@/pages/assignment/Index.vue'
import AssignmentSingle from '@/pages/assignment/AssignmentSingle.vue'

export default [
  {
    path: '/crm',
    component: crm,
    name: 'crm',
    meta: {
      title: 'CRM',
    },
    redirect: 'crm/home',
    children: [
      /** Home */
      {
        path: 'home',
        component: main,
        name: 'main',
        meta: {
          title: 'Головна',
        },
      },
      /** Profile */
      {
        path: 'profile',
        component: profile,
        name: 'profile',
        meta: {
          title: 'Профiль',
        },
      },
      {
        path: 'planning',
        component: Planning,
        name: 'planning',
        meta: {
          title: 'Планування',
        },
        redirect: { name: 'front-sales-plan' },
        children: [
          {
            path: 'sales-plan',
            component: CreateFrontSalesPlan,
            name: 'front-sales-plan',
            meta: {
              title: 'План продаж',
            },
          },
          {
            path: 'activities-plan',
            component: CreateFrontActivityPlan,
            name: 'front-activities-plan',
            meta: {
              title: 'План зустрiч',
            },
          },
        ],
      },
      /* Leads */
      {
        path: 'leads',
        component: LeadsList,
        name: 'leads',
        meta: {
          title: 'Лiди',
        },
        children: [
          {
            path: ':id',
            component: Lead,
            name: 'edit-lead',
            meta: {
              title: 'Лiд',
            },
            redirect: { name: 'lead-general-info' },
            children: [
              {
                path: 'general-info',
                component: LeadGeneralInfo,
                name: 'lead-general-info',
                meta: {
                  title: 'Лiд',
                },
              },
              {
                path: 'communication',
                component: LeadCommunication,
                name: 'lead-communication',
                meta: {
                  title: 'Активнiсть',
                },
              },
              {
                path: 'history',
                component: LeadHistory,
                name: 'lead-history',
                meta: {
                  title: 'Iсторiя',
                },
              },
              {
                path: 'duplicates',
                component: LeadDublicates,
                name: 'lead-duplicates',
                meta: {
                  title: 'Дублікати',
                },
              },
              {
                path: 'notifications',
                component: LeadNotifications,
                name: 'lead-notifications',
                meta: {
                  title: 'Нагадування',
                },
              },
              {
                path: 'call-history',
                component: LeadCallHistory,
                name: 'lead-call-history',
                meta: {
                  title: 'Історія Дзвінків',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'assignment-list',
        name: 'assignment-list',
        component: AssignmentList,
        meta: {
          title: 'Переуспутка',
        },
        children: [
          {
            path: ':id',
            name: 'assignment-single',
            component: AssignmentSingle,
            meta: {
              title: 'Переуспутка',
            },
          },
        ],
      },
      /* Service */
      {
        path: 'service',
        component: ServiceIndex,
        name: 'service',
        meta: {
          title: 'Сервіс',
        },
        redirect: { name: 'service-application-list' },
        children: [
          {
            path: 'application-list',
            component: ServiceApplicationList,
            name: 'service-application-list',
            meta: {
              title: 'Сервісні заявки',
            },
          },
          {
            path: 'application-single/:id',
            component: ServiceApplicationSingle,
            name: 'service-application-single',
            meta: {
              title: 'Сервісна заявка',
            },
            redirect: { name: 'service-application-general-info' },
            children: [
              {
                path: 'general-info',
                component: ServiceApplicationGeneralInfo,
                name: 'service-application-general-info',
                meta: {
                  title: 'Загальна інформація',
                },
              },
              {
                path: 'leasing-object',
                component: ServiceApplicationLeasingObject,
                name: 'service-application-leasing-object',
                meta: {
                  title: 'Загальна інформація',
                },
              },
              {
                path: 'history',
                component: ServiceApplicationHistory,
                name: 'service-application-history',
                meta: {
                  title: 'Iсторiя',
                },
              },
            ],
          },
          {
            path: 'create-application',
            component: ServiceApplicationSingle,
            name: 'service-application-create',
            meta: {
              title: 'Створення заявку',
            },
          },
        ],
      },
      /* Project verification */
      {
        path: 'verification',
        component: Verification,
        name: 'verification',
        meta: {
          title: 'Проекти на перевірку',
        },
        redirect: {
          name: 'security-service-legal-department',
        },
        children: [
          {
            path: 'security-service/:tabState',
            component: SecurityServiceProjects,
            name: 'security-service-verification-list',
            meta: {
              title: 'Служба безпеки',
            },
            children: [
              {
                path: 'scoring-single/:id',
                component: ScoringSingle,
                name: 'security-scoring-single',
                meta: {
                  title: 'Попереднє рішення',
                },
                redirect: {
                  name: 'security-sc-client-verification',
                  tabState: 0,
                },
                children: [
                  {
                    path: 'client',
                    component: ScoringClient,
                    name: 'security-sc-client-verification',
                    meta: {
                      title: 'Клієнт',
                    },
                  },
                  {
                    path: 'provider/:providerIdx',
                    component: ScoringProvider,
                    name: 'security-sc-provider-verification',
                    meta: {
                      title: 'Верифiкацiя постачальника',
                      service: 'ss',
                    },
                    children: [
                      {
                        path: 'leasing-object/:calculationIdx',
                        component: ScoringLeasingObject,
                        name: 'security-sc-leasing-object',
                        meta: {
                          title: 'Обʼєкт лізингу',
                        },
                      },
                    ],
                  },
                  {
                    path: 'leasing-object/:calculationIdx',
                    component: ScoringLeasingObject,
                    name: 'ss-general-sc-leasing-object',
                    meta: {
                      title: 'Обʼєкт лізингу',
                    },
                  },
                ],
              },
              {
                path: 'single/:id',
                component: SecurityServiceVerification,
                name: 'ss-project-verification',
                meta: {
                  title: 'Верифiкацiя проекту',
                },
                redirect: { name: 'ss-client-verification' },
                children: [
                  {
                    path: 'client',
                    component: SSClientVerification,
                    name: 'ss-client-verification',
                    meta: {
                      title: 'Верифiкацiя клієнта',
                    },
                  },
                  {
                    path: 'provider/:providerIdx',
                    component: SSProviderVerification,
                    name: 'ss-provider-verification',
                    meta: {
                      title: 'Верифiкацiя постачальника',
                    },
                    children: [
                      {
                        path: 'leasing-object/:calculationIdx',
                        component: SSLeasingObject,
                        name: 'ss-leasing-object',
                        meta: {
                          title: 'Обʼєкт лізингу',
                        },
                      },
                    ],
                  },
                  {
                    path: 'guarantor/:idx',
                    component: SSGuarantor,
                    name: 'ss-guarantor',
                    meta: {
                      title: 'Поручитель',
                    },
                  },
                  {
                    path: 'subtenant/:idx',
                    component: SSSubtenant,
                    name: 'ss-subtenant',
                    meta: {
                      title: 'Суборендатор',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'risk-department/:tabState',
            component: RiskDepartmentProjects,
            name: 'risk-department',
            meta: {
              title: 'Ризик департамент',
            },
            children: [
              {
                path: 'single/:id',
                component: ScoringSingle,
                name: 'risk-scoring-single',
                meta: {
                  title: 'Попереднє рішення',
                },
                redirect: { name: 'risk-sc-client-verification' },
                children: [
                  {
                    path: 'client',
                    component: ScoringClient,
                    name: 'risk-sc-client-verification',
                    meta: {
                      title: 'Клієнт',
                    },
                  },
                  {
                    path: 'provider/:providerIdx',
                    component: ScoringProvider,
                    name: 'risk-sc-provider-verification',
                    meta: {
                      title: 'Верифiкацiя постачальника',
                    },
                    children: [
                      {
                        path: 'leasing-object/:calculationIdx',
                        component: ScoringLeasingObject,
                        name: 'risk-sc-leasing-object',
                        meta: {
                          title: 'Обʼєкт лізингу',
                        },
                      },
                    ],
                  },
                  {
                    path: 'leasing-object/:calculationIdx',
                    component: ScoringLeasingObject,
                    name: 'risk-general-sc-leasing-object',
                    meta: {
                      title: 'Обʼєкт лізингу',
                    },
                  },
                ],
              },
              {
                path: ':id',
                component: RiskDepartmentProject,
                name: 'rd-project-verification',
                redirect: { name: 'rd-client-verification' },
                meta: {
                  title: 'Верифiкацiя проекту',
                },
                children: [
                  {
                    path: 'client',
                    component: RiskDepartmentClient,
                    name: 'rd-client-verification',
                    meta: {
                      title: 'Верифiкацiя клієнта',
                    },
                  },
                  {
                    path: 'provider/:idx',
                    component: RiskDepartmentProvider,
                    name: 'rd-provider-verification',
                    meta: {
                      title: 'Верифiкацiя постачальника',
                    },
                  },
                  {
                    path: 'history',
                    component: RiskDepartmentHistory,
                    name: 'rd-history',
                    meta: {
                      title: 'Історія проекта',
                    },
                  },
                  {
                    path: 'result-analyze',
                    component: RiskDepartmentCheck,
                    name: 'rd-analyze',
                    meta: {
                      title: 'Аналіз',
                    },
                  },
                  {
                    path: 'leasing-object/:idx',
                    component: RiskDepartmentLeasingObject,
                    name: 'rd-leasing-object',
                    meta: {
                      title: 'Обʼєкт лізингу',
                    },
                    children: [
                      {
                        path: 'calculation/:calculationId/:applicationId',
                        component: newCalculation,
                        name: 'risk-department-calculation',
                        meta: {
                          title: 'Калькуляцiя проекту',
                        },
                      },
                      {
                        path: 'slider/:applicationId',
                        component: Slider,
                        name: 'risk-department-slider',
                        meta: {
                          title: 'Бiгунок',
                        },
                      },
                    ],
                  },
                  {
                    path: 'guarantor/:idx',
                    component: RiskDepartmentGuarantor,
                    name: 'rd-guarantor-verification',
                    meta: {
                      title: 'Верифiкацiя поручителя',
                    },
                  },
                  {
                    path: 'subtenant/:idx',
                    component: RiskDepartmentSubtenant,
                    name: 'rd-subtenant-verification',
                    meta: {
                      title: 'Верифiкацiя суборендатора',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'legal-department',
            component: LegalDepartmentProjects,
            name: 'security-service-legal-department',
            meta: {
              title: 'Юридичний департамент',
            },
            children: [
              {
                path: ':id',
                component: LegalDepartmentVerification,
                name: 'ld-project-verification',
                meta: {
                  title: 'Верифiкацiя проекту',
                },
                redirect: { name: 'ld-client-verification' },
                children: [
                  {
                    path: 'client',
                    component: LegalDepartmentClient,
                    name: 'ld-client-verification',
                    meta: {
                      title: 'Верифiкацiя клієнта',
                    },
                  },
                  {
                    path: 'provider/:providerIdx',
                    component: LegalDepartmentProvider,
                    name: 'ld-provider-verification',
                    meta: {
                      title: 'Верифiкацiя постачальника',
                    },
                    children: [
                      {
                        path: 'leasing-object/:calculationIdx',
                        component: LegalDepartmentLeasingObject,
                        name: 'ld-leasing-object',
                        meta: {
                          title: 'Верифiкацiя Обʼєкта лізингу',
                        },
                      },
                    ],
                  },
                  {
                    path: 'guarantor/:idx',
                    component: LegalDepartmentGuarantor,
                    name: 'ld-guarantor-verification',
                    meta: {
                      title: 'Верифiкацiя поручителя',
                    },
                  },
                  {
                    path: 'subtenant/:idx',
                    component: LegalDepartmentSubtenant,
                    name: 'ld-subtenant-verification',
                    meta: {
                      title: 'Верифiкацiя суборендатора',
                    },
                  },
                  {
                    path: 'analyze',
                    component: LegalDepartmentAnalyze,
                    name: 'ld-analyze',
                    meta: {
                      title: 'Аналіз',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'financial-department/:tabState',
            component: FinancialDepartmentProjects,
            name: 'financial-department',
            meta: {
              title: 'Фінансовий моніторинг',
            },
            children: [
              {
                path: 'single/:id',
                component: ScoringSingle,
                name: 'fin-scoring-single',
                meta: {
                  title: 'Попереднє рішення',
                },
                redirect: { name: 'fin-sc-client-verification' },
                children: [
                  {
                    path: 'client',
                    component: ScoringClient,
                    name: 'fin-sc-client-verification',
                    meta: {
                      title: 'Клієнт',
                    },
                  },
                  {
                    path: 'provider/:providerIdx',
                    component: ScoringProvider,
                    name: 'fin-sc-provider-verification',
                    meta: {
                      title: 'Верифiкацiя постачальника',
                    },
                    children: [
                      {
                        path: 'leasing-object/:calculationIdx',
                        component: ScoringLeasingObject,
                        name: 'fin-sc-leasing-object',
                        meta: {
                          title: 'Обʼєкт лізингу',
                        },
                      },
                    ],
                  },
                  {
                    path: 'leasing-object/:calculationIdx',
                    component: ScoringLeasingObject,
                    name: 'fin-general-sc-leasing-object',
                    meta: {
                      title: 'Обʼєкт лізингу',
                    },
                  },
                ],
              },
              {
                path: ':id',
                component: FinancialDepartmentVerification,
                name: 'fd-project-verification',
                meta: {
                  title: 'Верифiкацiя проекту',
                },
                redirect: { name: 'fd-client-verification' },
                children: [
                  {
                    path: 'client',
                    component: FinancialDepartmentClientVerification,
                    name: 'fd-client-verification',
                    meta: {
                      title: 'Верифiкацiя клієнта',
                    },
                  },
                  {
                    path: 'history',
                    component: ProjectHistoryTable,
                    name: 'fd-history',
                    meta: {
                      title: 'Історія проекта',
                    },
                  },
                  {
                    path: 'analyze',
                    component: ProjectAnalys,
                    name: 'fd-analyze',
                    meta: {
                      title: 'Аналiз',
                    },
                  },
                  {
                    path: 'risk',
                    component: ProjectRisk,
                    name: 'fd-risk',
                    meta: {
                      title: 'Рівень ризику',
                    },
                  },
                  {
                    path: 'provider/:idx',
                    component: FinancialDepartmentProviderVerification,
                    name: 'fd-provider-verification',
                    meta: {
                      title: 'Постачальник',
                    },
                  },
                  {
                    path: 'guarantor/:idx',
                    component: FinancialDepartmentGuarantorVerification,
                    name: 'fd-guarantor-verification',
                    meta: {
                      title: 'Верифiкацiя поручителя',
                    },
                  },
                  {
                    path: 'subtenant/:idx',
                    component: FinancialDepartmentSubtenantVerification,
                    name: 'fd-subtenant-verification',
                    meta: {
                      title: 'Верифiкацiя Суборендатора',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'leasing-committee',
            component: LeasingComitteeProjects,
            name: 'leasing-committee-projects',
            meta: {
              title: 'Комітет по лізинговим проектам',
            },
            children: [
              {
                path: ':id',
                component: LeasingComitteeProject,
                name: 'leasing-committee-verification',
                meta: {
                  title: 'Верифiкацiя проекту',
                },
                children: [
                  {
                    path: 'leasing-object/:idx',
                    component: LeasingCommitteeLO,
                    name: 'leasing-committee-leasing-object',
                    meta: {
                      title: "Об'єкт лізингу",
                    },
                  },
                  {
                    path: 'meeting',
                    component: LeasingCommitteeMeeting,
                    name: 'leasing-committee-meeting',
                    meta: {
                      title: 'Засiдання',
                    },
                  },
                  {
                    path: 'analyze',
                    component: LeasingCommitteeAnalyze,
                    name: 'leasing-committee-analyze',
                    meta: {
                      title: 'Аналiз',
                    },
                  },
                  {
                    path: 'documents',
                    component: LeasingCommitteeDocuments,
                    name: 'leasing-committee-documents',
                    meta: {
                      title: 'Документи',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      /* Endorsement */
      /* Legal-case */
      {
        path: 'legalcase',
        component: LegalCase,
        name: 'legalcase',
        meta: {
          title: 'Legal Case',
        },
        redirect: { name: 'delayList' },
        children: [
          {
            path: 'delayList',
            component: DelayList,
            name: 'delayList',
            meta: {
              title: 'Прострочені платежі',
            },
          },
          {
            path: 'scheduledList',
            component: ScheduledList,
            name: 'scheduledList',
            meta: {
              title: 'Планові платежі',
            },
          },
        ],
      },
      {
        path: 'registration-application',
        component: RegistrationApplication,
        name: 'registration-application',
        meta: {
          title: 'Заявки',
        },
        redirect: { name: 'registration-application-single' },
        children: [
          {
            path: ':id',
            component: RegistrationApplicationSingle,
            name: 'registration-application-single',
            meta: {
              title: 'Заявка на реєстрацію',
            },
            redirect: { name: 'ra-general-info' },
            children: [
              {
                path: 'ra-general-info',
                component: RegistrationApplicationGeneral,
                name: 'ra-general-info',
                meta: {
                  title: 'Загальна інформація',
                },
              },
            ],
          },
        ],
      },
      // /* Contracts */
      {
        path: 'contracts',
        component: Contracts,
        name: 'contracts',
        meta: {
          title: 'Договори',
        },
        redirect: { name: 'dkp-contracts' },
        children: [
          {
            path: 'dkp-contracts',
            component: DkpContracts,
            name: 'dkp-contracts',
            meta: {
              title: 'Договори купівлi продажу',
            },
            children: [
              {
                path: ':id',
                component: DKPContract,
                name: 'dkp-contract',
                meta: {
                  title: 'Договір ДКП',
                },
                redirect: { name: 'parties-details' },
                children: [
                  {
                    path: 'parties-details',
                    component: DkpPartiesDetails,
                    name: 'parties-details',
                    meta: {
                      title: 'Реквізити сторiн',
                    },
                  },
                  {
                    path: 'sale-terms',
                    component: DkpSaleTerms,
                    name: 'sale-terms',
                    meta: {
                      title: 'Умови продажу',
                    },
                  },
                  {
                    path: 'buyback-schedule',
                    component: DkpBuyBackSchedule,
                    name: 'buyback-schedule',
                    meta: {
                      title: 'Графiк зворотнього викупу',
                    },
                  },
                  {
                    path: 'agreements',
                    component: DkpAgreements,
                    name: 'agreements',
                    meta: {
                      title: 'Договори',
                    },
                  },
                  {
                    path: 'links',
                    component: DkpLinks,
                    name: 'links',
                    meta: {
                      title: 'Зв`язки',
                    },
                  },
                  {
                    path: 'history',
                    component: DkpHistory,
                    name: 'dkp-history',
                    meta: {
                      title: 'Icторiя',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'dfl-contracts',
            component: DflContracts,
            name: 'dfl-contracts',
            meta: {
              title: 'Договори фiнансового лізингу',
            },
            children: [
              {
                path: ':id',
                component: DflContract,
                name: 'dfl-contract',
                meta: {
                  title: 'Договір ДФЛ',
                },
                redirect: { name: 'dfl-parties-details' },
                children: [
                  {
                    path: 'parties-details',
                    component: DflPartiesDetails,
                    name: 'dfl-parties-details',
                    meta: {
                      title: 'Реквізити сторін',
                    },
                  },
                  {
                    path: 'leasing-object',
                    component: DflLeasingObject,
                    name: 'leasing-object',
                    meta: {
                      title: 'Обьект лізингу',
                    },
                  },
                  {
                    path: 'dfl-payment-history',
                    component: DflPaymentHistory,
                    name: 'dfl-payment-history',
                    meta: {
                      title: 'Графік платежів',
                    },
                  },
                  {
                    path: 'agreements',
                    component: DflAgreements,
                    name: 'dfl-agreements',
                    meta: {
                      title: 'Договори',
                    },
                  },
                  {
                    path: 'history',
                    component: DflHistory,
                    name: 'dfl-history',
                    meta: {
                      title: 'Icторiя',
                    },
                  },
                  {
                    path: 'connections',
                    component: DflСonnections,
                    name: 'connections',
                    meta: {
                      title: 'Зв`язки',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'dol-contracts',
            component: DOLContracts,
            name: 'dol-contracts',
            meta: {
              title: 'Договори оперативного лізингу',
            },
          },
          {
            path: 'additional-agreements',
            component: ExtraAgreementsIndex,
            name: 'additional-agreements',
            meta: {
              title: 'Додаткові угоди',
            },
            redirect: {
              name: 'additional-agreements-dfl',
            },
            children: [
              {
                path: 'dfl',
                component: AdditionalAgreements,
                name: 'additional-agreements-dfl',
                meta: {
                  title: 'Додаткові угоди ДФЛ',
                },
                children: [
                  {
                    path: ':id',
                    component: Agreement,
                    name: 'du-dfl-agreement',
                    meta: {
                      title: 'Угода',
                    },
                    redirect: { name: 'du-dfl-parties-requisites' },
                    children: [
                      {
                        path: 'payment-history',
                        component: ExtraAgreementPaymentHistory,
                        name: 'du-dfl-payment-history',
                        meta: {
                          title: 'Исторiя платежів',
                        },
                      },
                      {
                        path: 'parties-requisites',
                        component: PartiesRequisites,
                        name: 'du-dfl-parties-requisites',
                        meta: {
                          title: 'Реквізити сторiн',
                        },
                      },
                      {
                        path: 'calculation-info',
                        component: ExtraAgreementCalculation,
                        name: 'du-dfl-calculation-info',
                        meta: {
                          title: 'Калькуляція',
                        },
                      },
                      {
                        path: 'du-agreements',
                        component: DUAgreements,
                        name: 'du-dfl-agreements',
                        meta: {
                          title: 'Договори',
                        },
                      },
                      {
                        path: 'du-editorships',
                        component: ExtraEditorships,
                        name: 'du-dfl-editorships',
                        meta: {
                          title: 'Редакція договору',
                        },
                      },
                      {
                        path: 'du-history',
                        component: ExtraAgreementHistory,
                        name: 'du-dfl-history',
                        meta: {
                          title: 'Історія',
                        },
                      },
                      {
                        path: 'du-calculation/:applicationId/:calculationId',
                        component: newCalculation,
                        name: 'du-dfl-calculation',
                        meta: {
                          title: 'Розрахунок',
                        },
                        redirect: {
                          name: 'du-dfl-edit-calculator-leasing-object',
                        },
                        children: [
                          {
                            path: 'leasing-object',
                            component: LeasingObject,
                            name: 'du-dfl-edit-calculator-leasing-object',
                            meta: {
                              title: 'Предмет лізингу',
                            },
                          },
                          {
                            path: 'leasing-terms',
                            component: LeasingTerms,
                            name: 'du-dfl-edit-calculator-leasing-terms',
                            meta: {
                              title: 'Умови лізингу',
                            },
                          },
                          {
                            path: 'additional-terms',
                            component: AdditionalTerms,
                            name: 'du-dfl-edit-calculator-additional-terms',
                            meta: {
                              title: 'Додаткові умови',
                            },
                          },
                          {
                            path: 'service',
                            component: Service,
                            name: 'du-dfl-edit-calculator-service',
                            meta: {
                              title: 'Сервіс',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'dkp',
                component: AdditionalAgreements,
                name: 'additional-agreements-dkp',
                meta: {
                  title: 'Додаткові угоди ДКП',
                },
                children: [
                  {
                    path: ':id',
                    component: AgreementDkp,
                    name: 'du-dkp-agreement',
                    meta: {
                      title: 'Угода',
                    },
                    redirect: { name: 'du-dkp-parties-requisites' },
                    children: [
                      {
                        path: 'parties-requisites',
                        component: PartiesRequisitesDkp,
                        name: 'du-dkp-parties-requisites',
                        meta: {
                          title: 'Реквізити сторін',
                        },
                      },
                      {
                        path: 'du-agreements',
                        component: DUAgreementsDkp,
                        name: 'du-dkp-agreements',
                        meta: {
                          title: 'Договори',
                        },
                      },
                      {
                        path: 'du-editorships',
                        component: ExtraEditorships,
                        name: 'du-dkp-editorships',
                        meta: {
                          title: 'Редакція договору',
                        },
                      },
                      {
                        path: 'du-history',
                        component: ExtraAgreementHistoryDkp,
                        name: 'du-dkp-history',
                        meta: {
                          title: 'Історія',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'surety-agreements',
            component: SuretyAgreements,
            name: 'surety-agreements',
            meta: {
              title: 'Договори поруки',
            },
            children: [
              {
                path: ':id',
                component: SuretyAgreement,
                name: 'surety-agreement',
                meta: {
                  title: 'Договір поруки',
                },
                redirect: { name: 'surety-parties-details' },
                children: [
                  {
                    path: 'parties-details',
                    component: SuretyPartiesDetails,
                    name: 'surety-parties-details',
                    meta: {
                      title: 'Реквізити сторін',
                    },
                  },
                  {
                    path: 'subject',
                    component: SuretySubject,
                    name: 'surety-subject',
                    meta: {
                      title: 'Предмет поруки',
                    },
                  },
                  {
                    path: 'documents',
                    component: SuretyDocuments,
                    name: 'surety-documents',
                    meta: {
                      title: 'Документи',
                    },
                  },
                  {
                    path: 'history',
                    component: SuretyHistory,
                    name: 'surety-history',
                    meta: {
                      title: 'Документи',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'mortgagor-agreements',
            component: MortgagorAgreements,
            name: 'mortgagor-agreements',
            meta: {
              title: 'Договори застави',
            },
            children: [
              {
                path: ':id',
                component: MortgagorAgreement,
                name: 'mortgagor-agreement',
                meta: {
                  title: 'Договір застави',
                },
                redirect: { name: 'mortgagor-parties-details' },
                children: [
                  {
                    path: 'parties-details',
                    component: MortgagorPartiesDetails,
                    name: 'mortgagor-parties-details',
                    meta: {
                      title: 'Деталі сторiн',
                    },
                  },
                  {
                    path: 'pledge-property',
                    component: MortgagorPledgeProperty,
                    name: 'mortgagor-pledge-property',
                    meta: {
                      title: 'Застава майна',
                    },
                  },
                  {
                    path: 'documents',
                    component: MortgagorDocuments,
                    name: 'mortgagor-documents',
                    meta: {
                      title: 'Договори',
                    },
                  },
                  {
                    path: 'history',
                    component: MortgagorHistory,
                    name: 'mortgagor-history',
                    meta: {
                      title: 'Історія',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'services-contracts',
            component: ServicesContracts,
            name: 'services-contracts',
            meta: {
              title: 'Договори сервісу',
            },
            children: [
              {
                path: ':id',
                component: ServiceContracts,
                name: 'service-contract',
                meta: {
                  title: 'Договір сервісу',
                },
                redirect: { name: 'service-parties-details' },
                children: [
                  {
                    path: 'service-parties-details',
                    component: ServicePartiesDetails,
                    name: 'service-parties-details',
                    meta: {
                      title: 'Реквізити сторiн',
                    },
                  },
                  {
                    path: 'service-leasing-object',
                    component: ServiceLeasingObject,
                    name: 'service-leasing-object',
                    meta: {
                      title: 'Обьект лізингу',
                    },
                  },
                  {
                    path: 'service-payment-history',
                    component: ServicePaymentHistory,
                    name: 'service-payment-history',
                    meta: {
                      title: 'Графiк платежів',
                    },
                  },
                  {
                    path: 'service-agreements',
                    component: ServiceAgreements,
                    name: 'service-agreements',
                    meta: {
                      title: 'Договори',
                    },
                  },
                  {
                    path: 'service-history',
                    component: ServiceHistory,
                    name: 'service-history',
                    meta: {
                      title: 'Icторiя',
                    },
                  },
                  {
                    path: 'service-connections',
                    component: ServiceСonnections,
                    name: 'service-connections',
                    meta: {
                      title: 'Зв`язки',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      // Car for sale
      {
        path: 'car-for-sale',
        name: 'car-for-sale-list',
        component: CarForSaleIndex,
        meta: {
          title: 'Автопарк',
        },
        children: [
          {
            path: ':id',
            name: 'car-for-sale-single',
            component: CarForSaleSingle,
            meta: {
              title: 'Авто',
            },
          },
          {
            path: 'create',
            name: 'car-for-sale-create',
            component: CarForSaleSingle,
            meta: {
              title: 'Додати авто',
            },
          },
        ],
      },
      // // Leasing object
      {
        path: 'leasing-objects',
        component: LeasingObjectList,
        name: 'leasing-object-list',
        meta: {
          title: "Об'єкти лізингу",
        },
      },
      {
        path: 'leasing-object/:id',
        component: LeasingObjectSingle,
        name: 'leasing-object-single',
        meta: {
          title: "Об'єкт лізингу",
        },
        redirect: { name: 'lo-registration-data' },
        children: [
          {
            path: 'registration-data',
            name: 'lo-registration-data',
            component: LeasingObjectRegistrationData,
            meta: {
              title: 'Реєстрація',
            },
          },
          {
            path: 'dfls',
            name: 'lo-dfls',
            component: LeasingObjectDFLS,
            meta: {
              title: 'Договори фінансового лізингу',
            },
          },
          {
            path: 'insurance-contracts',
            name: 'lo-insurance-contracts',
            component: LeasingObjectInsuranceContracts,
            meta: {
              title: 'Страхові договори',
            },
          },
          {
            path: 'insurance-cases',
            name: 'lo-insurance-cases',
            component: LeasingObjectInsuranceCases,
            meta: {
              title: 'Страхові випадки',
            },
          },
          {
            path: 'technical-assistance',
            name: 'lo-technical-assistance',
            component: LeasingObjectTechnicalAssistance,
            meta: {
              title: 'Технічний асистанс',
            },
          },
        ],
      },
      // // ** Average Cost ** //
      {
        path: 'average-price',
        component: AveragePriceList,
        name: 'average-price',
        meta: {
          title: 'Оцінка середньої вартості',
        },
        children: [
          {
            path: 'create-calculation/',
            component: AverageCreateCalculation,
            name: 'average-price-create-calculation',
            meta: {
              title: 'Новий розрахунок',
            },
          },
          {
            path: 'edit-calculation/:id',
            component: AverageCreateCalculation,
            name: 'average-price-edit-calculation',
            meta: {
              title: 'Розрахунок',
            },
          },
        ],
      },
      // // ** EDocs ** //
      {
        path: 'e-docs',
        component: EDocList,
        name: 'e-docs',
        meta: {
          title: 'E-Docs',
        },
        children: [
          {
            path: 'e-docs-single/:id',
            component: EDocSingle,
            name: 'e-docs-single',
            meta: {
              title: 'Картка E-Docs ',
            },
            redirect: { name: 'e-client' },
            children: [
              {
                path: 'e-client',
                component: EDocClient,
                name: 'e-client',
                meta: {
                  title: 'Клієнт',
                },
              },
              {
                path: 'e-contract',
                component: EDocContract,
                name: 'e-contract',
                meta: {
                  title: 'Договір',
                },
              },
              {
                path: 'e-leasing-object',
                component: EDocLeasingObject,
                name: 'e-leasing-object',
                meta: {
                  title: 'Обʼєкт лізингу',
                },
              },
              {
                path: 'e-analysis',
                component: EDocAnalysis,
                name: 'e-analysis',
                meta: {
                  title: 'Аналіз',
                },
              },
            ],
          },
        ],
      },
      // // ** BookKeeping ** //
      {
        path: 'book-keeping',
        component: BookKeeping,
        name: 'bookkeeping',
        meta: {
          title: 'Бухгалтерiя',
        },
        redirect: { name: 'book-keeping-payments' },
        children: [
          {
            path: 'payments',
            component: BookKeepingPayment,
            name: 'book-keeping-payments',
            meta: {
              title: 'Виплати',
            },
          },
          {
            path: ':id/edit-payments',
            component: EditBookPayment,
            name: 'edit-payments',
            meta: {
              title: 'Акт на виплату',
            },
            redirect: { name: 'book-payment-general' },
            children: [
              {
                path: 'book-payment-general',
                component: BookPaymentGeneral,
                name: 'book-payment-general',
                meta: {
                  title: 'Основна iнформацiя',
                },
              },
            ],
          },
        ],
      },
      // // ** Marketing */
      {
        path: 'marketing',
        component: Marketing,
        name: 'marketing',
        meta: {
          title: 'Маркетинг',
        },
        redirect: { name: 'marketing-company' },
        children: [
          {
            path: 'marketing-company',
            component: MarketingCompany,
            name: 'marketing-company',
            meta: {
              title: 'Рекламнi кампанії',
            },
          },
          {
            path: ':id/edit-marketing',
            component: EditMarketing,
            name: 'edit-marketing',
            meta: {
              title: 'Компанiя',
            },
            redirect: { name: 'marketing-general' },
            children: [
              {
                path: 'marketing-general',
                component: MarketingGeneral,
                name: 'marketing-general',
                meta: {
                  title: 'Основна iнформацiя',
                },
              },
              {
                path: 'marketing-expenses',
                component: MarketingExpenses,
                name: 'marketing-expenses',
                meta: {
                  title: 'Витрати',
                },
              },
            ],
          },
        ],
      },
      // /** Archive */
      {
        path: 'archive-list',
        component: ArchiveList,
        name: 'archive-list',
        meta: {
          title: 'Архівні дані',
        },
      },
      {
        path: 'archive/:id',
        component: ArchiveSingle,
        name: 'archive-single',
        meta: {
          title: 'Архівні дані',
        },
      },
      {
        path: 'plan',
        component: PlanList,
        name: 'plan-list',
        meta: {
          title: 'Плани',
        },
        children: [
          {
            path: ':id',
            component: PlanSingle,
            name: 'plan-single',
            meta: {
              title: 'План',
            },
          },
        ],
      },
      // /** Administration */
      {
        path: 'administration',
        component: administration,
        name: 'administrIndex',
        meta: {
          title: 'Адмiнiстрування',
        },
        redirect: { name: 'admin-plan-list' },
        children: [
          {
            path: 'plan',
            name: 'admin-plan-list',
            component: AdminPlanList,
            meta: {
              title: 'Плани',
            },
          },
          {
            path: 'templates',
            component: Templates,
            name: 'tiny-templates',
            meta: {
              title: 'Шаблони',
            },
            children: [
              {
                path: 'new-template',
                component: NewTemplate,
                name: 'new-template',
                meta: {
                  title: 'Створити шаблон',
                },
              },
            ],
          },
          {
            path: 'best-leasing-templates',
            component: BestLeasingTemplates,
            name: 'best-leasing-templates',
            meta: {
              title: 'Шаблони ДКП Бест Лізинг',
            },
          },
          {
            path: 'logging',
            component: AdminLogging,
            name: 'logging',
            meta: {
              title: 'Логування',
            },
            children: [
              {
                path: ':id/logging-details',
                component: LogDetails,
                name: 'logging-details',
                meta: {
                  title: 'Деталі по логуванню',
                },
              },
            ],
          },
          {
            path: 'admin-calls-phones',
            component: administrationPhones,
            name: 'admin-calls-phones',
            meta: {
              title: 'Телефонi дзвінки',
            },
          },
          {
            path: 'admin-lead-calls',
            component: AdministrationLeadCalls,
            name: 'admin-lead-calls',
            meta: {
              title: 'Дзвінки по лідам',
            },
            children: [
              {
                path: ':id/admin-lead-call-single',
                component: AdministrationLeadCallSingle,
                name: 'admin-lead-call-single',
                meta: {
                  title: 'Деталі по дзвінку',
                },
              },
            ],
          },
          {
            path: 'change-responsible',
            component: ChangeResponsible,
            name: 'change-responsible',
            meta: {
              title: 'Перезакріплення',
            },
          },
          {
            path: 'employees',
            component: employees,
            name: 'employees',
            meta: {
              title: 'Співробiтники',
            },
            children: [
              {
                path: 'create',
                component: Employee,
                name: 'employee-create',
                meta: {
                  title: 'Додати співробiтника',
                },
              },
              {
                path: ':id/edit',
                component: Employee,
                name: 'employee-edit',
                meta: {
                  title: 'Редагувати співробiтника',
                },
              },
            ],
          },
          {
            path: 'departments',
            component: departments,
            name: 'departments',
            meta: {
              title: 'Департаменти',
            },
          },
          {
            path: 'directories',
            component: Directories,
            name: 'administration-directories',
            meta: {
              title: 'Довідники',
            },
            children: [
              {
                path: ':id/directory-single',
                component: DirectorySingle,
                name: 'directory-single',
                meta: {
                  title: 'Деталі по Довіднику',
                },
              },
            ],
          },
          {
            path: 'tickets',
            component: Tickets,
            name: 'administration-tickets',
            meta: {
              title: 'Тiкети',
            },
            children: [
              {
                path: ':id',
                component: AdminTicket,
                name: 'admin-ticket',
                meta: {
                  title: 'Тiкет',
                },
              },
            ],
          },
          {
            path: 'faq',
            component: FaqAdmin,
            name: 'admin-faq',
            meta: {
              title: 'Часті питання',
            },
            redirect: 'faq/documents',
            children: [
              {
                path: 'videos',
                component: FaqAdminVideos,
                name: 'admin-faq-videos',
                meta: {
                  title: 'Відеоматерiали',
                },
                children: [
                  {
                    path: ':id',
                    name: 'admin-watch-faq-video',
                    component: FaqAdminWatchVideo,
                    meta: {
                      title: 'Відео',
                    },
                  },
                ],
              },
              {
                path: 'documents',
                component: FaqAdminDocuments,
                name: 'admin-faq-documents',
                meta: {
                  title: 'Документи',
                },
              },
              {
                path: 'presentations',
                component: FaqAdminOthers,
                name: 'admin-faq-presentations',
                meta: {
                  title: 'Презентації',
                },
              },
            ],
          },
          {
            path: 'supervisors',
            component: Supervisors,
            name: 'admin-supervisor-list',
            meta: {
              title: 'Супервайзери',
            },
            children: [
              {
                path: ':id',
                component: Supervisor,
                name: 'supervisor-form',
                meta: {
                  title: 'Супервайзер',
                },
              },
            ],
          },
        ],
      },
      /** Calculations */
      {
        path: 'calculations',
        component: calculations,
        name: 'user-calculations',
        meta: {
          title: 'Мої розрахунки',
        },
        children: [
          {
            path: 'payment-plan/:calculationId',
            component: paymentHistory,
            name: 'payment-history',
            meta: {
              title: 'Графiк виплат',
            },
          },
        ],
      },
      // /** Applications */
      {
        path: 'applications',
        component: applications,
        name: 'applications',
        meta: {
          title: 'Мої заявки',
        },
        children: [
          {
            path: 'create',
            component: application,
            name: 'new-application',
            meta: {
              title: 'Створити заявку',
            },
            redirect: { name: 'create-application-common-info' },
            children: [
              {
                path: 'common-info',
                component: ApplicationCommonInfo,
                name: 'create-application-common-info',
                meta: {
                  title: 'Основна інформація',
                },
              },
            ],
          },
          {
            path: ':applicationId',
            component: application,
            name: 'edit-application',
            meta: {
              title: 'Редагувати заявку',
            },
            redirect: { name: 'application-common-info' },
            children: [
              {
                path: 'common-info',
                component: ApplicationCommonInfo,
                name: 'application-common-info',
                meta: {
                  title: 'Основна iнформацiя',
                },
              },
              {
                path: 'calculations',
                component: ApplicationCalculations,
                name: 'application-calculations',
                meta: {
                  title: 'Розрахунки',
                },
              },
              {
                path: 'communication',
                component: ApplicationCommunications,
                name: 'application-comunication',
                meta: {
                  title: 'Активність',
                },
              },
              {
                path: 'create-calculation/',
                component: newCalculation,
                name: 'application-create-calculation',
                meta: {
                  title: 'Новий розрахунок',
                },
                redirect: 'create-calculation/leasing-object',
                children: [
                  {
                    path: 'leasing-object',
                    component: LeasingObject,
                    name: 'create-calculator-leasing-object',
                    meta: {
                      title: 'Предмет лізингу',
                    },
                  },
                  {
                    path: 'leasing-terms',
                    component: LeasingTerms,
                    name: 'create-calculator-leasing-terms',
                    meta: {
                      title: 'Умови лізингу',
                    },
                  },
                  {
                    path: 'additional-terms',
                    component: AdditionalTerms,
                    name: 'create-calculator-additional-terms',
                    meta: {
                      title: 'Додаткові умови',
                    },
                  },
                  {
                    path: 'service',
                    component: Service,
                    name: 'create-calculator-service',
                    meta: {
                      title: 'Сервіс',
                    },
                  },
                  {
                    path: 'payment-plan/:calculationId',
                    component: paymentHistory,
                    name: 'application-new-payment-plan',
                    meta: {
                      title: 'Графiк виплат',
                    },
                  },
                ],
              },
              {
                path: 'slider/:sliderId',
                component: Slider,
                name: 'application-slider',
                meta: {
                  title: 'Бігунок',
                },
                redirect: { name: 'slider-main-info' },
                children: [
                  {
                    path: 'main-info',
                    component: SliderMainInfo,
                    name: 'slider-main-info',
                    meta: {
                      title: 'Дані по запиту',
                    },
                  },
                  {
                    path: 'client',
                    component: SliderClient,
                    name: 'slider-client',
                    meta: {
                      title: 'Клієнт',
                    },
                  },
                  {
                    path: 'leasing-objects',
                    component: SliderLeasingObjects,
                    name: 'slider-leasing-objects',
                    meta: {
                      title: 'Обʼєкти лізингу',
                    },
                  },
                  {
                    path: 'scoring',
                    component: SliderScoring,
                    name: 'slider-scoring',
                    meta: {
                      title: 'Отримати рiшення',
                    },
                  },
                  {
                    path: 'guarantors',
                    component: SliderAssociatedParties,
                    name: 'slider-guarantors',
                    meta: {
                      title: 'Поручителі',
                    },
                  },
                  {
                    path: 'subtenants',
                    component: SliderAssociatedParties,
                    name: 'slider-subtenants',
                    meta: {
                      title: 'Суборендатори',
                    },
                  },
                ],
              },
              {
                path: 'edit-calculation/:calculationId',
                component: newCalculation,
                name: 'application-edit-calculation',
                meta: {
                  title: 'Розрахунок',
                },
                redirect: {
                  name: 'edit-calculator-leasing-object',
                },
                children: [
                  {
                    path: 'leasing-object',
                    component: LeasingObject,
                    name: 'edit-calculator-leasing-object',
                    meta: {
                      title: 'Предмет лізингу',
                    },
                  },
                  {
                    path: 'leasing-terms',
                    component: LeasingTerms,
                    name: 'edit-calculator-leasing-terms',
                    meta: {
                      title: 'Умови лізингу',
                    },
                  },
                  {
                    path: 'additional-terms',
                    component: AdditionalTerms,
                    name: 'edit-calculator-additional-terms',
                    meta: {
                      title: 'Додаткові умови',
                    },
                  },
                  {
                    path: 'service',
                    component: Service,
                    name: 'edit-calculator-service',
                    meta: {
                      title: 'Сервіс',
                    },
                  },
                ],
              },
              {
                path: 'payment-plan/:calculationId',
                component: paymentHistory,
                props: { showExport: calc => calc.isAgreed },
                name: 'application-payment-plan',
                meta: {
                  title: 'Графiк виплат',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'faq',
        component: FAQ,
        name: 'help',
        meta: {
          title: 'Допомога',
        },
        redirect: { name: 'faq-videos' },
        children: [
          {
            path: 'videos',
            component: FaqVideos,
            name: 'faq-videos',
            meta: {
              title: 'Відеоматерiали',
            },
          },
          {
            path: 'documents',
            component: FaqDocuments,
            name: 'faq-documents',
            meta: {
              title: 'Документи',
            },
          },
          {
            path: 'presentations',
            component: FaqOthers,
            name: 'faq-presentations',
            meta: {
              title: 'Презентації',
            },
          },
        ],
      },
      // /* Project */
      {
        path: 'projects',
        component: ProjectsList,
        name: 'projects',
        meta: {
          title: 'Проекти',
        },
        children: [
          {
            path: ':projectId',
            component: Project,
            name: 'project',
            meta: {
              title: 'Проект',
            },
            redirect: { name: 'project-leasing-object' },
            children: [
              {
                path: 'leasing-object',
                component: ProjectLeasingObjectTab,
                name: 'project-leasing-object',
                meta: {
                  title: "Об'єкт лізингу",
                },
              },
              {
                path: 'project-plan',
                component: ProjectPlan,
                name: 'project-plan',
                meta: {
                  title: 'План реалiзацii',
                },
              },
              {
                path: 'project-guarantors',
                component: ProjectGuarantors,
                name: 'project-guarantors',
                meta: {
                  title: 'Поручителі',
                },
              },
              {
                path: 'project-subtenants',
                component: ProjectSubtenants,
                name: 'project-subtenants',
                meta: {
                  title: 'Суборендатори',
                },
              },
              {
                path: 'additional-support',
                component: AdditionalSupport,
                name: 'additional-support',
                meta: {
                  title: 'Додаткове забезпечення',
                },
              },
              {
                path: 'documents',
                component: ProjectDocumentsTab,
                name: 'project-documents',
                meta: {
                  title: 'Документи',
                },
              },
              {
                path: 'analysis',
                component: ProjectAnalysisTab,
                name: 'project-analysis',
                meta: {
                  title: 'Аналiз',
                },
              },
              {
                path: 'history',
                component: ProjectHistoryTab,
                name: 'project-history',
                meta: {
                  title: 'Історія',
                },
              },
            ],
          },
        ],
      },
      // /* Activities */
      {
        path: 'activity',
        component: activities,
        name: 'activity',
        meta: {
          title: 'Активностi',
        },
        redirect: { name: 'my-meetings' },
        children: [
          {
            path: 'my-meetings',
            component: myMeetings,
            name: 'my-meetings',
            meta: {
              title: 'Мої зустрічі',
            },
          },
          {
            path: 'my-reminders',
            component: myReminders,
            name: 'my-reminder',
            meta: {
              title: 'Мої нагадування',
            },
          },
          {
            path: 'invitations',
            component: invitations,
            name: 'invitations',
            meta: {
              title: 'Запрошення',
            },
          },
        ],
      },
      // /** Contacts */
      {
        path: 'contacts',
        component: TransactionParticipants,
        name: 'contacts',
        meta: {
          title: 'Контакти',
        },
        redirect: { name: 'individuals' },
        children: [
          {
            path: 'individuals',
            component: contacts,
            name: 'individuals',
            meta: {
              title: 'Фiзичнi особи та ФОП',
            },
            children: [
              {
                path: ':id',
                component: individuals,
                name: 'individuals-form',
                meta: {
                  title: 'Фiзичнi особи та ФОП',
                },
                redirect: { name: 'contact-common-info' },
                children: [
                  {
                    path: 'common-info',
                    component: commonInfo,
                    name: 'contact-common-info',
                    meta: {
                      title: 'Загальна iнформацiя',
                    },
                  },
                  {
                    path: 'legal-data',
                    component: legalData,
                    name: 'contact-legal-data',
                    meta: {
                      title: 'Юридичні дані',
                    },
                  },
                  {
                    path: 'finance-data',
                    component: FinanceData,
                    name: 'contact-finance-data',
                    meta: {
                      title: 'Фінансові дані',
                    },
                  },
                  {
                    path: 'career-education',
                    component: careerEducation,
                    name: 'contact-careers-education',
                    meta: {
                      title: "Кар'єра / Освіта",
                    },
                  },
                  {
                    path: 'provider',
                    component: ContactProvider,
                    name: 'contact-provider',
                    meta: {
                      title: 'Дилер',
                    },
                  },
                  {
                    path: 'links',
                    component: links,
                    name: 'contact-links',
                    meta: {
                      title: 'Зв`язки з лiдами',
                    },
                  },
                  {
                    path: 'fin-monitoring',
                    component: financialMonitoring,
                    name: 'contact-fin-monitoring',
                    meta: {
                      title: 'Фiнансовий монiторинг',
                    },
                  },
                  {
                    path: 'documents',
                    component: Documents,
                    name: 'contact-documents',
                    meta: {
                      title: 'Документи',
                    },
                  },
                  {
                    path: 'projects',
                    component: ContactProjects,
                    name: 'contact-projects',
                    meta: {
                      title: 'Проекти',
                    },
                  },
                  {
                    path: 'agreements',
                    component: ContactAgreements,
                    name: 'contact-agreements',
                    meta: {
                      title: 'Договори',
                    },
                  },
                  {
                    path: 'history',
                    component: ContactHistory,
                    name: 'contact-activity',
                    meta: {
                      title: 'Активностi',
                    },
                    redirect: { name: 'contact-history-phone' },
                    children: [
                      {
                        path: 'history-phone',
                        component: HistoryPhones,
                        name: 'contact-history-phone',
                        meta: {
                          title: 'Iсторiя дзвінкiв',
                        },
                      },
                      {
                        path: 'history-meetings',
                        component: HistoryMeetings,
                        name: 'contact-history-meetings',
                        meta: {
                          title: 'Зустрiчi',
                        },
                      },
                      {
                        path: 'history-scheduler',
                        component: HistoryScheduler,
                        name: 'contact-history-scheduler',
                        meta: {
                          title: 'Нагадування',
                        },
                      },
                      {
                        path: 'history-comment',
                        component: HistoryComment,
                        name: 'contact-history-comment',
                        meta: {
                          title: 'Коментар',
                        },
                      },
                    ],
                  },
                  {
                    path: 'changes',
                    component: ContactChanges,
                    name: 'contact-changes',
                    meta: {
                      title: 'Iсторiя змiн',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'contractors',
            component: contractors,
            name: 'contractors',
            meta: {
              title: 'Контрагенти',
            },
            children: [
              {
                path: ':id',
                component: Contractor,
                name: 'contractors-form',
                meta: {
                  title: 'Контрагент',
                },
                redirect: { name: 'contractor-requisites' },
                children: [
                  {
                    path: 'requisites',
                    component: ContractoLegalData,
                    name: 'contractor-requisites',
                    meta: {
                      title: 'Реквізити',
                    },
                  },
                  {
                    path: 'organization-structure',
                    component: OrganizationStructure,
                    name: 'contractor-organization-structure',
                    meta: {
                      title: 'Структура організації',
                    },
                  },
                  {
                    path: 'documents',
                    component: ContractorDocuments,
                    name: 'contractor-documents',
                    meta: {
                      title: 'Документи',
                    },
                  },
                  {
                    path: 'links',
                    component: ContractorLinks,
                    name: 'contractor-links',
                    meta: {
                      title: 'Зв`язки',
                    },
                  },
                  {
                    path: 'verification',
                    component: Checking,
                    name: 'contractor-verification',
                    meta: {
                      title: 'Перевірка',
                    },
                  },
                  {
                    path: 'suplier',
                    component: ContractorProvider,
                    name: 'contractor-suplier',
                    meta: {
                      title: 'Дилер',
                    },
                  },
                  {
                    path: 'agreements',
                    component: ContractorAgreements,
                    name: 'contractor-agreements',
                    meta: {
                      title: 'Договори',
                    },
                  },
                  {
                    path: 'projects',
                    component: ContractorProjects,
                    name: 'contractor-projects',
                    meta: {
                      title: 'Проекти',
                    },
                  },
                  {
                    path: 'history',
                    component: ContractorHistory,
                    name: 'contractor-activity',
                    meta: {
                      title: 'Активностi',
                    },
                    redirect: { name: 'contractor-history-phone' },
                    children: [
                      {
                        path: 'history-phone',
                        component: HistoryPhonesContractor,
                        name: 'contractor-history-phone',
                        meta: {
                          title: 'Iсторiя дзвінкiв',
                        },
                      },
                      {
                        path: 'contractor-scheduler',
                        component: HistorySchedulerContractor,
                        name: 'contractor-history-scheduler',
                        meta: {
                          title: 'Нагадування',
                        },
                      },
                      {
                        path: 'contractor-comment',
                        component: HistoryCommentContactor,
                        name: 'contractor-history-comment',
                        meta: {
                          title: 'Коментар',
                        },
                      },
                    ],
                  },
                  {
                    path: 'changes',
                    component: ContractorChanges,
                    name: 'contractor-changes',
                    meta: {
                      title: 'Iсторiя змiн',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'agents',
            component: AgentList,
            name: 'agent-list',
            meta: {
              title: 'Агенти',
            },
            children: [
              {
                path: ':id',
                component: Agent,
                name: 'agent-form',
                meta: {
                  title: 'Агент',
                },
                redirect: { name: 'agent' },
                children: [
                  {
                    path: 'agent',
                    component: AgentMainTab,
                    name: 'agent',
                    meta: {
                      title: 'Агент',
                    },
                  },
                  {
                    path: 'payment-parameters',
                    component: PaymentParameters,
                    name: 'payment-parameters',
                    meta: {
                      title: 'Договір (агентський)',
                    },
                  },
                  {
                    path: 'applications-info',
                    component: ApplicationsInfo,
                    name: 'applications-info',
                    meta: {
                      title: 'Заявки',
                    },
                  },
                  {
                    path: 'agent-payment',
                    component: ActPayments,
                    name: 'agent-payment',
                    meta: {
                      title: 'Виплати',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'dealer-point-of-sale',
            component: DealerList,
            name: 'dealer-list',
            meta: {
              title: 'Торгові точки',
            },
            children: [
              {
                path: ':id',
                component: Dealer,
                name: 'dealer-form',
                meta: {
                  title: 'Торгова точка',
                },
                redirect: { name: 'dealer-main' },
                children: [
                  {
                    path: 'dealer',
                    component: DealerMainTab,
                    name: 'dealer-main',
                    meta: {
                      title: 'Дилер',
                    },
                  },
                  {
                    path: 'contacts',
                    component: DealerInfo,
                    name: 'dealer-info',
                    meta: {
                      title: 'Контакти',
                    },
                  },
                  {
                    path: 'parthership',
                    component: DealerParthership,
                    name: 'dealer-parthership',
                    meta: {
                      title: 'Партнерство',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'support-tickets',
        component: SupportList,
        name: 'support',
        meta: {
          title: 'Підтримка',
        },
        children: [
          {
            path: ':id',
            component: SupportTicket,
            name: 'support-ticket',
            meta: {
              title: 'Тiкет',
            },
          },
        ],
      },
    ],
  },
]
