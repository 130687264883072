<template>
  <div>
    <FieldsColumnBlock class="mt-2" title="Проекти">
      <template #default>
        <NoData v-if="!contactProjects" />
        <ProjectCard
          v-for="project in filteredProjects"
          :key="project.id"
          :project="project"
          :entityTypeId="1" />
      </template>
    </FieldsColumnBlock>
  </div>
</template>
<script>
import ProjectCard from '@/components/ProjectCard.vue'
import NoData from '@/components/no-table-data.vue'
import { mapState } from 'vuex'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'
export default {
  components: {
    FieldsColumnBlock,
    ProjectCard,
    NoData,
  },
  props: { contact: { type: Object } },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    id() {
      return this.$route?.params?.id
    },
    contactProjects() {
      return this.contact?.projects
    },
    filteredProjects() {
      if (!this.filterDate) {
        return this.contact.projects
      }
      return this.contact.projects.filter(project => {
        const createdAt = new Date(project.created_at)
        return createdAt >= this.filterDate
      })
    },
  },
}
</script>
