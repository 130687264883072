import type { DataTableHeader } from 'best-modules/components'

const applicationListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'statusId',
    align: 'start',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Назва',
    key: 'applicationName',
    align: 'start',
    sortable: false,
    width: '20%',
  },
  {
    title: 'Контактна особа',
    key: 'name',
    align: 'start',
    sortable: false,
    width: '20%',
  },
  {
    title: 'Джерело',
    key: 'sourceType.name',
    align: 'start',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Дата створення',
    key: 'created_at',
    align: 'start',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
    width: '15%',
  },
  {
    title: '',
    key: 'actions',
    align: 'center',
    sortable: false,
    width: '1%',
  },
]

const applicationServiceHeaders: DataTableHeader[] = [
  { title: 'ID заявки', key: 'id', sortable: false },
  { title: 'Статус', key: 'status.name', sortable: false },
  {
    title: 'Назва заявки',
    key: 'serviceApplicationName',
    sortable: false,
  },
  { title: 'Джерело', key: 'source.name', sortable: false },
  { title: 'Відповідальний', key: 'responsible', sortable: false },
  { title: 'Дата створення', key: 'created_at', sortable: false },
]

const leadListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status',
    align: 'start',
    sortable: false,
    width: '10%',
  },
  { title: 'Назва', key: 'leadName', sortable: false, width: '20%' },
  {
    title: 'Контактна особа',
    key: 'name',
    align: 'start',
    sortable: false,
    width: '15%',
  },
  {
    title: 'Коментар',
    key: 'comment',
    align: 'center',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Джерело',
    key: 'source',
    align: 'start',
    sortable: false,
    width: '20%',
  },
  {
    title: 'Дата створення',
    key: 'created_at',
    align: 'center',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'center',
    sortable: false,
    width: '15%',
  },
  { title: '', key: 'actions', width: '1%' },
]

const calculationListHeaders: DataTableHeader[] = [
  {
    title: '№ Розрахунку',
    key: 'requestId',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'center', sortable: true },
  {
    title: 'Назва заявки',
    key: 'applicationName',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Марка',
    key: 'leasedAssertMark.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Модель',
    key: 'leasedAssertModel.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Цiна',
    key: 'leasingAmount',
    align: 'center',
    sortable: false,
  },
  { title: 'Аванс', key: 'advance', align: 'center', sortable: false },
  {
    title: 'Строк',
    key: 'leasingTerm',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Валюта',
    key: 'currency',
    align: 'center',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'center', sortable: false },
]

const adminLeadCallHeaders: DataTableHeader[] = [
  { title: 'Тип', key: 'type_call', sortable: false, align: 'start' },
  {
    title: 'Дата створення',
    key: 'date',
    sortable: false,
    align: 'start',
  },
  { title: 'Телефон', key: 'phone', sortable: false, align: 'start' },
  {
    title: 'Відповідальна особа',
    key: 'user',
    sortable: false,
    align: 'start',
  },
  { title: 'Дії', key: 'action', sortable: false, align: 'end' },
]

const contactHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: 'ПІБ', key: 'fullName', align: 'start', sortable: true },
  { title: 'ІПН', key: 'inn', align: 'start', sortable: true },
  { title: 'Тип', key: 'type', align: 'start', sortable: false },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const contractorHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  {
    title: 'Назва компанії',
    key: 'shortName',
    align: 'start',
    sortable: false,
  },
  { title: 'ЕДРПОУ', key: 'edrpou', align: 'center', sortable: false },
  { title: 'Тип', key: 'type', align: 'start', sortable: false },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dealerHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Назва', key: 'name', align: 'start', sortable: false },
  { title: 'Тип', key: 'type', align: 'start', sortable: false },
  {
    title: 'Контрагент',
    key: 'contractor',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsibleId',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const agentHeaders: DataTableHeader[] = [
  { title: 'ПIБ', key: 'fullName', align: 'start', sortable: false },
  { title: 'Статус', key: 'isActive', align: 'start', sortable: false },
  {
    title: 'Торгова точка',
    key: 'dealerPoint',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const projectHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  {
    title: '№ проекту',
    key: 'projectNumber',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоотримувач',
    key: 'lessee',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Тип аналізу',
    key: 'analysisType',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний Фронт',
    key: 'frontManagerFio',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний Мідл',
    key: 'middleManagerFio',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'projectDate', sortable: false, align: 'start' },
  { title: 'Дії', key: 'actions', align: 'center', sortable: false },
]

const legalCaseHeaders: DataTableHeader[] = [
  {
    title: 'Клієнт',
    key: 'clientName',
    align: 'start',
    sortable: false,
    width: '500px',
  },
  {
    title: '№ ДФЛ',
    key: 'contract',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний фронт',
    key: 'responsibleFrontFullName',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний юрист',
    key: 'responsibleJuristFullName',
    align: 'start',
    sortable: false,
  },
  { title: 'Сума', key: 'delayAmount', align: 'start', sortable: false },
  { title: 'Днів', key: 'delayTerm', align: 'start', sortable: false },
]

const historyLeadCallHeaders: DataTableHeader[] = [
  {
    title: 'Дата дзвінка',
    key: 'startTime',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Тип',
    key: 'typeCall.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Статус',
    key: 'statusCall.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Номер',
    key: 'phone',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'user',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Тривалість, сек',
    key: 'duration',
    align: 'start',
    sortable: false,
  },
]

const paymentListHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', sortable: false },
  { title: 'Клієнт', key: 'lessee', sortable: false },
  {
    title: '№ договору',
    key: 'contractLessee',
    sortable: false,
    align: 'start',
  },
  {
    title: "Об'єкт лізингу",
    key: 'leasingObjectName',
    sortable: false,
    align: 'start',
  },
  { title: 'Сума ОЛ', key: 'amountOl', sortable: false },
  { title: 'Сума АВ', key: 'amountAB', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const averagePriceListHeaders: DataTableHeader[] = [
  {
    title: 'Категорія',
    key: 'category.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Марка',
    key: 'mark.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Модель',
    key: 'model.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Роки',
    key: 'years',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Дата створення',
    key: 'date',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', width: '1%' },
]

const archiveListHeaders: DataTableHeader[] = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'ДФЛ', key: 'contract', sortable: false },
  { title: 'Клієнт', key: 'lessee', sortable: false },
  {
    title: 'Номер архівної справи ',
    key: 'archiveCode',
    sortable: false,
  },
]

/* activities */
const activitiesMeetingsListHeaders: DataTableHeader[] = [
  { title: 'Заголовок', key: 'title', sortable: false },
  {
    title: 'Категорія',
    key: 'type.name',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Менеджер',
    key: 'responsible',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Контрагент',
    key: 'contractor',
    align: 'center',
    sortable: false,
  },
  {
    title: 'ТТ',
    key: 'dealer_point_of_sale',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Контакт',
    key: 'contact',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Дата старту',
    key: 'startDate',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Статус',
    key: 'customStatus',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Дiї',
    key: 'actions',
    sortable: false,
    align: 'center',
  },
]

const activitiesRemindersListHeaders: DataTableHeader[] = [
  {
    title: 'Тема',
    key: 'taskMessage',
    sortable: false,
    align: 'start',
    width: '230px',
  },
  {
    title: 'Статус',
    key: 'isDone',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Назва',
    key: 'from',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Відповідальний',
    key: 'user',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Заплановано на:',
    key: 'date',
    align: 'start',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Дiї',
    key: 'actions',
    sortable: false,
    align: 'center',
  },
]

/* contracts */
const dflListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  {
    title: '№ договору',
    key: 'number',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Дата',
    key: 'agreementDate',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоодержувач',
    key: 'lesseeShortName',
    align: 'start',
    sortable: false,
  },
  {
    title: "Об'єкт",
    key: 'leasingObjectName',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dkpListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  {
    title: '№ договору',
    key: 'number',
    align: 'center',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'center', sortable: false },
  {
    title: 'Постачальник',
    key: 'provider',
    align: 'center',
    sortable: false,
    width: '440px',
  },
  {
    title: 'Тип ПЛ',
    key: 'objectType',
    align: 'center',
    sortable: false,
  },
  { title: 'Валюта', key: 'currency', align: 'center', sortable: false },
  { title: 'Вартість', key: 'amount', align: 'center', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dolListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  {
    title: '№ договору',
    key: 'number',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Дата',
    key: 'agreementDate',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоодержувач',
    key: 'lesseeShortName',
    align: 'start',
    sortable: false,
  },
  {
    title: "Об'єкт",
    key: 'leasingObjectName',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dflExtraAgreementListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Номер ДУ', key: 'number', align: 'start', sortable: false },
  { title: 'Дата ДУ', key: 'date', align: 'start', sortable: false },
  {
    title: 'Лізингоотримувач',
    key: 'lessee',
    align: 'start',
    sortable: false,
  },
  { title: 'ДФЛ', key: 'contract', align: 'start', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]
const dkpExtraAgreementListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Номер ДУ', key: 'number', align: 'start', sortable: false },
  { title: 'Дата ДУ', key: 'date', align: 'start', sortable: false },
  {
    title: 'Постачальник',
    key: 'provider',
    align: 'start',
    sortable: false,
  },
  { title: 'ДКП', key: 'dkp', align: 'start', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dflConnectionsHeaders: DataTableHeader[] = [
  {
    title: 'Тип',
    key: 'type',
  },
  {
    title: 'Номер',
    key: 'number',
  },
  {
    title: 'Статус',
    key: 'status.name',
  },
  {
    title: 'Дата',
    key: 'date',
    align: 'end',
  },
]
const dkpConnectionsHeaders: DataTableHeader[] = [
  {
    title: 'Тип',
    key: 'type',
  },
  {
    title: 'Номер',
    key: 'number',
  },
  {
    title: 'Статус',
    key: 'status.name',
  },
  {
    title: 'Дата',
    key: 'date',
    align: 'end',
  },
]

const mortgagorListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'start', sortable: false },
  { title: 'Номер', key: 'number', align: 'start', sortable: false },
  {
    title: 'Тип застави',
    key: 'type.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Заставодавець',
    key: 'mortgagor',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const serviceListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  {
    title: '№ договору',
    key: 'number',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Дата',
    key: 'agreementDate',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоодержувач',
    key: 'lessee',
    align: 'start',
    sortable: false,
  },
  {
    title: "Об'єкт",
    key: 'leasingObject',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const suretyAgreementListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'start', sortable: false },
  { title: 'Номер', key: 'number', align: 'start', sortable: false },
  {
    title: 'Тип поруки',
    key: 'type.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Поручитель',
    key: 'guarantor',
    align: 'start',
    sortable: false,
  },
  { title: 'Боржник', key: 'debtor', align: 'start', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const eDocsListHeaders: DataTableHeader[] = [
  {
    title: 'Коротка назва',
    key: 'lesseeName',
    align: 'start',
    sortable: false,
  },
  { title: 'ЄДРПОУ', key: 'lesseeCode', align: 'start', sortable: false },
  {
    title: '№ ДФЛ',
    key: 'contractNumber',
    align: 'start',
    sortable: false,
  },
  { title: 'Дiї', key: 'actions', align: 'center', sortable: false },
]

const leasingObjectListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'ОбъектЛизинга_Статус',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Реєстраційний номер',
    key: 'ОбъектЛизинга_Name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоотримувач',
    key: 'Контрагент_Name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Договір лізингу',
    key: 'Договор_Name',
    align: 'start',
    sortable: false,
  },
  { title: '', key: 'actions' },
]

const marketingListHeaders: DataTableHeader[] = [
  {
    title: 'Назва кампанії',
    key: 'campaignName',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Кампанія(utmCampaign)',
    key: 'utmCampaign.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Джерело(utmSource)',
    key: 'utmSource.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Канал(utmMedium)',
    key: 'utmMedium.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Ідентифікатор(utmContent)',
    key: 'utmContent.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Статус', key: 'isActive', align: 'start', sortable: false },
  { title: 'Дiї', key: 'actions', align: 'center', sortable: false },
]

const planListHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'name' },
  { title: 'Тип', key: 'type.name' },
]

/* project-verification */

const financialListHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  {
    title: 'Лізингоотримувач',
    key: 'lessee',
    align: 'start',
    sortable: false,
  },
  { title: 'ЄДРПОУ/ІПН', key: 'code', align: 'start', sortable: false },
  {
    title: 'Фронт',
    key: 'frontManager',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Підтверджено',
    key: 'isConfirmation',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Рівень ризику',
    key: 'financialRiskLevel',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Тип аналiзу',
    key: 'analysisType',
    align: 'center',
    sortable: false,
    width: '80px',
  },
  { title: 'Дії', key: 'actions', align: 'center', sortable: false },
]

const leasingCommitteeListHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: 'Дата', key: 'date', align: 'start', sortable: false },
  { title: 'Клієнт', key: 'lessee', align: 'start', sortable: false },
  {
    title: 'Тип аналізу',
    key: 'analysisType',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Рiшення',
    key: 'result',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Ризик менеджер',
    key: 'riskManager',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Фронт менеджер',
    key: 'frontManager',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const legalDepartListHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: 'Дата', key: 'created_at', align: 'start', sortable: false },
  { title: 'Клієнт', key: 'lessee', align: 'start', sortable: false },
  {
    title: 'Тип аналізу',
    key: 'analysisType',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const riskDepartmentListHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'created_at', align: 'start', sortable: false },
  { title: 'Клієнт', key: 'lessee', align: 'start', sortable: false },
  {
    title: 'Тип аналізу',
    key: 'analysisType',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Результат',
    key: 'result',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Фронт',
    key: 'frontManagerFio',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Мідл',
    key: 'middleManagerFio',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Ризик',
    key: 'riskManagerFio',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Перевірки',
    key: 'serviceChecks',
    align: 'center',
    sortable: false,
  },
  {
    title: "Об'єкт",
    key: 'objectLeasings',
    align: 'center',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const scoringListHeaders: DataTableHeader[] = [
  { title: 'Дата', key: 'created_at', align: 'start', sortable: false },
  {
    title: 'Клієнт',
    key: 'lessee',
    align: 'start',
    sortable: false,
    width: '320px',
  },
  {
    title: 'Фронт менеджер',
    key: 'frontManagerResponsible',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Рішення', key: 'isAgree', align: 'start', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const securityListHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: 'Дата', key: 'created_at', align: 'start', sortable: false },
  { title: 'Клієнт', key: 'lessee', align: 'start', sortable: false },
  {
    title: 'Тип аналізу',
    key: 'analysisType',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Рішення (по клиенту)',
    key: 'clientResult',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const supportListHeaders: DataTableHeader[] = [
  // { text: 'Тікет', value: 'theme.name', align: 'start', sortable: false },
  { title: 'id', key: 'id', align: 'center', sortable: false },
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Назва', key: 'title', align: 'start', sortable: false },
  { title: 'Дата', key: 'created_at', sortable: false, align: 'center' },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const leasingObjectLogHeaders: DataTableHeader[] = [
  {
    title: 'Дата',
    key: 'dateTo',
    sortable: false,
    align: 'start',
    width: '120px',
  },
  { title: 'Поле', key: 'field', sortable: true, align: 'start' },
  {
    title: 'Тип події',
    key: 'changes',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Відповідальний',
    key: 'userName',
    sortable: false,
    align: 'start',
  },
]

const scoringChangesHeaders: DataTableHeader[] = [
  { title: 'Тип', key: 'record', sortable: false, width: '100px' },
  { title: 'Поле', key: 'field', sortable: false, width: '100px' },
  {
    title: 'Минуле значення',
    key: 'oldValue',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Нове значення',
    key: 'newValue',
    sortable: false,
    width: '150px',
  },
  { title: 'Текст', key: 'text', sortable: false, width: '100px' },
]

const contactApplicationLinkHeaders: DataTableHeader[] = [
  { title: 'Назва заявки', key: 'applicationName', sortable: false },
  { title: 'Статус', key: 'status', sortable: false },
  { title: 'Дата створення', key: 'created_at', sortable: false },
  {
    title: 'Потреба',
    key: 'clientNeed.name',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Джерело заявки',
    key: 'sourceType.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'center',
    sortable: false,
  },
]

const fopHeaders: DataTableHeader[] = [
  { title: 'Платник ПДВ', key: 'isVatPayer' },
  { title: 'ІПН', key: 'innPayerVAT' },
  { title: 'Дата реєстрації', key: 'date' },
  { title: 'Номер реєстрації', key: 'number' },
  { title: 'Основний квед', key: 'mainQued' },
  { title: 'Інші кведи', key: 'queds' },
  { title: 'Дії', key: 'actions', align: 'end' },
]

const edrChangeTableHeaders: DataTableHeader[] = [
  { title: 'Група', key: 'factorGroup', sortable: false },
  { title: 'Подія', key: 'text', sortable: false },
  {
    title: 'Iндикатор',
    key: 'indicator',
    sortable: false,
    align: 'center',
  },
  {
    title: 'JSON',
    key: 'json',
    sortable: false,
    align: 'center',
  },
]

// project verification [Analyze] headers --start
const analyzeSecurityHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'name', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    sortable: false,
    width: '130px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Результат перевірки',
    key: 'result',
    align: 'end',
    sortable: false,
    width: '25%',
  },
]

const analyzeRiskHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'name', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    align: 'start',
    sortable: false,
    width: '115px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Рішення',
    key: 'conclusion',
    align: 'end',
    sortable: false,
    width: '25%',
  },
]

const analyzeLegalHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'name', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    sortable: false,
    width: '115px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Висновок',
    key: 'conclusion',
    sortable: false,
    align: 'end',
    width: '25%',
  },
]

const analyzeFinHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'name', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    align: 'start',
    sortable: false,
    width: '115px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Рівень ризику',
    key: 'conclusion',
    sortable: false,
    align: 'end',
    width: '25%',
  },
]

const analyzeKlpHeaders: DataTableHeader[] = [
  { title: 'Номер', key: 'number', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    align: 'start',
    sortable: false,
    width: '115px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Рішення',
    key: 'conclusion',
    align: 'end',
    sortable: false,
    width: '25%',
  },
]
// project verification [Analyze] headers --end
const adminSupervisorHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'name', align: 'start', sortable: true },
  {
    title: 'Супервайзер',
    key: 'supervisor',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Мiдл',
    key: 'middle_manager',
    sortable: false,
    align: 'center',
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const applicationCalculationHeaders: DataTableHeader[] = [
  {
    title: '№ розрахунку',
    key: 'requestId',
    align: 'center',
    sortable: false,
  },
  { title: 'Дата', key: 'created_at', align: 'center', sortable: false },
  {
    title: 'Марка',
    key: 'requestData.leasedAssertMark.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Модель',
    key: 'requestData.leasedAssertModel.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Цiна',
    key: 'requestData.leasingAmount',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Аванс',
    key: 'requestData.advance',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Строк, міс',
    key: 'requestData.leasingTerm',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Валюта',
    key: 'requestData.currency',
    align: 'center',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const agentPaymentHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status', sortable: false },
  { title: 'Клієнт', key: 'lessee', sortable: false },
  {
    title: '№ договору',
    key: 'contract',
    sortable: false,
    align: 'start',
  },
  {
    title: "Об'єкт лізингу",
    key: 'leasingObjectName',
    sortable: false,
    align: 'start',
  },
  { title: 'Сума ОЛ', key: 'amountOl', sortable: false },
  { title: 'Сума АВ', key: 'amountAB', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const carForSaleHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'status.name',
    sortable: false,
  },
  {
    title: 'Тип',
    key: 'category.name',
    sortable: false,
  },
  {
    title: 'Марка',
    key: 'mark.name',
    sortable: false,
  },
  {
    title: 'Модель',
    key: 'model.name',
    sortable: false,
  },
]

const carForSaleSelectHeaders: DataTableHeader[] = [
  {
    title: 'Тип',
    key: 'category.name',
  },
  {
    title: 'Марка',
    key: 'mark.name',
  },
  {
    title: 'Модель',
    key: 'model.name',
  },
  {
    title: 'Модифікація',
    key: 'modification',
  },
  {
    title: 'Держ. номер',
    key: 'carNumber',
    sortable: false,
  },
]

const entityAgreementsHeaders: DataTableHeader[] = [
  { title: 'Тип', key: 'type', align: 'start', sortable: false, width: 190 },
  { title: 'Клієнт', key: 'lessee', align: 'start', sortable: false },
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: '№ договору', key: 'number', align: 'start', sortable: false },
  {
    title: "Об'єкт",
    key: 'leasingObjectName',
    align: 'start',
    sortable: false,
  },
]

const educationHeaders: DataTableHeader[] = [
  {
    title: 'Освіта',
    key: 'type.name',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Навчальний заклад',
    key: 'educationalInstitutionName',
    sortable: false,
    align: 'start',
  },
  { title: 'Дiї', key: 'actions', sortable: false, align: 'end' },
]

const careerHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    key: 'isActive',
  },
  {
    title: 'Компанія',
    key: 'contractor',
    sortable: false,
  },
  {
    title: 'Посада',
    key: 'employee.post.name',
    sortable: false,
  },
  {
    title: 'Повна назва посади',
    key: 'employee.fullPostName',
    sortable: false,
  },
  {
    title: 'Департамент',
    key: 'employee.department.name',
    sortable: false,
  },
  { title: 'Роль', key: 'employee.role.name', sortable: false },
  {
    title: 'Підписант',
    key: 'isSignatureRight',
  },
  {
    title: '',
    key: 'actions',
    sortable: false,
  },
]
const beneficiaryConnectionHeaders: DataTableHeader[] = [
  {
    title: 'Контрагент',
    key: 'contractor',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Тип володіння',
    key: 'influence.name',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Ознака КБВ',
    key: 'signKbv.name',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Доля в статутному капіталі (%)',
    key: 'percent',
    sortable: false,
    align: 'center',
  },
]
const employeesHeaders: DataTableHeader[] = [
  { title: 'ПIБ', key: 'contact', sortable: false },
  { title: 'Посада', key: 'post', sortable: false },
  { title: 'Департамент', key: 'department', sortable: false },
  { title: 'Роль', key: 'role', sortable: false },
  {
    title: 'Підписант',
    key: 'isSignatureRight',
    sortable: false,
    align: 'center',
  },
  { title: 'Дiї', key: 'actions', sortable: false, align: 'end' },
]
const executiveAuthoritiesHeaders: DataTableHeader[] = [
  { title: 'ПIБ', key: 'contact.fullName' },
  { title: 'Повна назва посади', key: 'fullPostName', sortable: false },
  {
    title: '',
    key: 'actions',
    sortable: false,
    align: 'end',
  },
]

const guaranteeDflsHeaders: DataTableHeader[] = [
  { title: '№ Договору', key: 'number', sortable: false },
  {
    title: 'Статус',
    key: 'status.name',
    align: 'center',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'center', sortable: false },
]

const mortgagorPledgePropertyHeaders: DataTableHeader[] = [
  { title: '№', key: 'id', align: 'start', sortable: false },
  { title: 'Номер', key: 'number', align: 'center', sortable: false },
  {
    title: "Об'єкт Лізингу",
    key: 'leasingObjectName',
    align: 'center',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'center', sortable: false },
  { title: 'Статус', key: 'isActive', align: 'center', sortable: false },
]

const ExtraAgreementEditorshipHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'editorship', align: 'start', sortable: false },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    align: 'end',
  },
]

const faqPresentationHeaders: DataTableHeader[] = [
  {
    title: 'Назва',
    key: 'name',
    align: 'start',
    sortable: false,
    width: '70%',
  },
  { title: 'Дата', key: 'created_at', align: 'center', sortable: true },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const klpInvitedTHeaders: DataTableHeader[] = [
  { title: 'ПIБ', key: 'invited', align: 'start', sortable: false },
  { title: 'Посада', key: 'post', align: 'center', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const applicationSendOffers: DataTableHeader[] = [
  { title: 'Статус', key: 'viberStatusId', sortable: false },
  { title: 'Дата', key: 'created_at', sortable: false, align: 'center' },
  { title: 'Спосіб відправки', key: 'sendType', sortable: false },
  {
    title: 'Телефон / Email',
    key: 'sendPhone',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Тема',
    key: 'messageSubject',
    sortable: false,
    align: 'center',
  },
  { title: 'Дiї', key: 'actions', sortable: false, align: 'center' },
]

const companyGroupHeaders: DataTableHeader[] = [
  { title: 'Група', key: 'companyGroup.name', sortable: false },
  { title: 'Роль', key: 'role.name', sortable: false },
  { title: 'Дії', key: 'actions', sortable: false, align: 'end' },
]

const relatedEmployeesHeaders: DataTableHeader[] = [
  { title: 'Контрагент', key: 'contractor', sortable: false, width: '250px' },
  { title: 'Департамент', key: 'department', sortable: false },
  { title: 'Посада', key: 'post', sortable: false },
]

const relatedBeneficiarsHeaders: DataTableHeader[] = [
  { title: 'Контрагент', key: 'contractor', sortable: false },
  { title: 'Ознака КБВ', key: 'signKbv.name', sortable: false },
  { title: 'Тип впливу', key: 'influence.name', sortable: false },
  { title: 'Доля володіння', key: 'percent', sortable: false },
]

const assignmentListHeaders: DataTableHeader[] = [
  { title: 'Статус', key: 'status.name ' },
  { title: 'assignor', key: 'assignor.shortName' },
  { title: 'lessee', key: 'lessee.shortName' },
  { title: 'responsible', key: 'responsible.shortName' },
]

export {
  applicationListHeaders,
  applicationServiceHeaders,
  leadListHeaders,
  calculationListHeaders,
  adminLeadCallHeaders,
  contactHeaders,
  contractorHeaders,
  dealerHeaders,
  agentHeaders,
  agentPaymentHeaders,
  projectHeaders,
  legalCaseHeaders,
  historyLeadCallHeaders,
  paymentListHeaders,
  averagePriceListHeaders,
  archiveListHeaders,
  activitiesMeetingsListHeaders,
  activitiesRemindersListHeaders,
  dflListHeaders,
  dkpListHeaders,
  dolListHeaders,
  dflExtraAgreementListHeaders,
  dkpExtraAgreementListHeaders,
  dflConnectionsHeaders,
  dkpConnectionsHeaders,
  mortgagorListHeaders,
  serviceListHeaders,
  suretyAgreementListHeaders,
  eDocsListHeaders,
  leasingObjectListHeaders,
  marketingListHeaders,
  planListHeaders,
  financialListHeaders,
  leasingCommitteeListHeaders,
  legalDepartListHeaders,
  riskDepartmentListHeaders,
  scoringListHeaders,
  securityListHeaders,
  supportListHeaders,
  leasingObjectLogHeaders,
  scoringChangesHeaders,
  contactApplicationLinkHeaders,
  fopHeaders,
  edrChangeTableHeaders,
  analyzeSecurityHeaders,
  analyzeRiskHeaders,
  analyzeLegalHeaders,
  analyzeFinHeaders,
  analyzeKlpHeaders,
  adminSupervisorHeaders,
  applicationCalculationHeaders,
  carForSaleHeaders,
  carForSaleSelectHeaders,
  entityAgreementsHeaders,
  educationHeaders,
  careerHeaders,
  beneficiaryConnectionHeaders,
  employeesHeaders,
  executiveAuthoritiesHeaders,
  guaranteeDflsHeaders,
  mortgagorPledgePropertyHeaders,
  ExtraAgreementEditorshipHeaders,
  faqPresentationHeaders,
  klpInvitedTHeaders,
  applicationSendOffers,
  companyGroupHeaders,
  relatedEmployeesHeaders,
  relatedBeneficiarsHeaders,
  assignmentListHeaders,
}
