<template>
  <div>
    <div style="min-height: 200px">
      <FilesUploader
        v-model:documents="projectData.signedKlp"
        label="Протокол КЛП(підписаний)"
        icon="mdi-file-document-multiple-outline"
        :readonly="![RISK_MANAGER, RISK_HEAD, ADMIN].includes(userRoleId)">
      </FilesUploader>
      <FilesUploader
        v-model:documents="projectData.additionallyDocuments"
        label="Додаткові матеріали"
        icon="mdi-file-document-multiple-outline"
        :readonly="readonly">
      </FilesUploader>
      <FilesUploader
        v-model:documents="applicationFormSigned"
        label="Заявка анкета(підписаний)"
        icon="mdi-file-document-multiple-outline"
        :readonly="readonly">
      </FilesUploader>
      <FilesUploader
        v-for="(guarantor, index) in project?.project?.slider?.guarantors || []"
        :key="guarantor.entityId || index"
        v-model:documents="guarantor.guarantorApplicationFormSigned"
        :label="`Заявка анкета поручителя (підписаний) ${
          guarantor?.guarantorName ? `- ${guarantor.guarantorName}` : ''
        }`"
        icon="mdi-file-document-multiple-outline"
        readonly />
    </div>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import { RISK_MANAGER, RISK_HEAD, ADMIN } from '@/roles'

export default {
  components: { FilesUploader },
  props: {
    project: { type: Object },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      RISK_MANAGER,
      RISK_HEAD,
      ADMIN,
    }
  },
  computed: {
    ...mapState({
      userRoleId: state => state.user.roleId,
    }),
    applicationFormSigned() {
      if (
        this.projectData &&
        this.projectData.project &&
        this.projectData.project.applicationFormSigned
      ) {
        return this.projectData.project.applicationFormSigned[0]?.doc || null
      }
      return null
    },
    projectData() {
      return this.project
    },
  },
}
</script>
