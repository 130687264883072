<template>
  <div>
    <edr-contractor-mapper :contractor="contractor" />
  </div>
</template>

<script>
import edrContractorMapper from '@/pages/transaction-participants/contractors/edr-contractor-mapper.vue'
import { computed } from 'vue'

export default {
  components: { edrContractorMapper },
  props: {
    dialog: { type: Object },
  },
  setup(props) {
    const contractor = computed(() => props.dialog.dialogItem?.ODContractor)

    const submit = () => {
      return Promise.resolve()
    }

    return {
      contractor,
      submit,
    }
  },
}
</script>

<style scoped></style>
