<template>
  <div>
    <filters :filters="filters">
      <template #header-action>
        <v-btn
          data-readonly="hide"
          variant="flat"
          color="#fc7247"
          class="mr-3"
          @click="createAssignment">
          <v-icon class="mr-2">
            {{ 'mdi-plus' }}
          </v-icon>
          <span>Створити переуступку</span>
        </v-btn>
      </template>
    </filters>
    <div v-show="!$loading.isLoading('getAssignmentList')">
      <v-data-table
        :headers="assignmentListHeaders"
        :items="assignmentList"
        item-value="id"></v-data-table>
      <pagination
        :getItems="getAssignmentList"
        :trigger="paginationTrigger"></pagination>
    </div>
    <b-section-loader
      v-if="$loading.isLoading('getAssignmentList')"></b-section-loader>
  </div>
</template>

<script>
import { assignmentListHeaders } from '@/utils/tableHeaders'
import { reactive, ref } from 'vue'
import { useFilter } from '@/utils/mixins/useFilter'
import axios from '@/plugins/axios.js'
import { urlAssignmentList } from '@/pages/request'
import Pagination from '@/components/Pagination.vue'
import Filters from '@/components/Filters.vue'
import { getContactInitials } from '@/utils/helperFunc'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { BSectionLoader } from 'best-modules/components'
import { handleAsync } from 'best-modules/plugins'

export default {
  name: 'AssignmentList',
  components: { Filters, Pagination, BSectionLoader },
  data() {
    return {
      assignmentListHeaders,
    }
  },
  setup() {
    const { commit } = useStore()
    const router = useRouter()
    const assignmentList = ref([])

    const filters = reactive({
      search: { value: null },
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'assignmentStatuses' },
      },
      assignorTypeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Тип первісного кредитора',
          items: 'assignmentLesseeTypes',
        },
      },
      lesseeTypeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Тип нового кредитора',
          items: 'assignmentLesseeTypes',
        },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'allUsers',
        },
      },
    })

    const filter = useFilter(filters)

    const getAssignmentList = (page = 1) => {
      return handleAsync('getAssignmentList', () => {
        return axios
          .get(urlAssignmentList({ page, ...filter.filtersQueryObj.value }))
          .then(res => {
            assignmentList.value = res?.data?.data || []
          })
      })
    }
    const createAssignment = () => {
      commit('setDialog', {
        title: 'Переуступка',
        params: {
          cb: assignment => {
            return router.push({
              name: 'assignment-single',
              params: { id: assignment.id },
            })
          },
        },
        action: 'assignmentCreate',
      })
    }

    return {
      assignmentList,
      ...filter,
      filters,
      getAssignmentList,
      createAssignment,
    }
  },
}
</script>

<style scoped></style>
