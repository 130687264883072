<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ props }">
        <div class="d-flex align-center">
          <div class="d-flex align-center">
            <v-icon class="mr-1" width="20px" height="20px">{{
              'mdi-account-multiple'
            }}</v-icon>
            <span class="label text-black">Зв`язки</span>
          </div>
          <v-btn v-show="activityLinks.length" icon size="small" v-bind="props">
            <v-icon>{{ 'mdi-plus' }}</v-icon>
          </v-btn>
          <v-tooltip text="Очистити">
            <template #activator="{ props }">
              <v-btn
                v-show="selectedEntities.length"
                icon
                size="small"
                v-bind="props"
                @click="removeAllConnections">
                <v-icon>{{ 'mdi-minus' }}</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
      </template>
      <v-card>
        <v-card-text class="pt-0 pb-0 pl-0 pr-0">
          <v-list-item
            v-for="(item, key) in activityLinks"
            :key="key"
            :disabled="!!(isEdit && selectedEntities.length)"
            @click="pushSelect(item.id)">
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-menu>

    <!--  select entity connection  -->
    <v-row>
      <v-col
        v-if="
          selectedEntities.includes(4) ||
          (selectedEntities.includes(2) && !eventData.contractorId)
        "
        cols="12"
        class="pb-0">
        <span class="label">Контакт</span>
        <b-server-autocomplete
          v-model="eventData.contact"
          :error-messages="managerErr"
          :url="{
            url: urlContactsGetAll(),
            query: {
              addSelect: ['dealerPoint', 'contractor'],
            },
          }"
          :default-item="eventData.contact"
          :search-min-length="3"
          return-object
          class="small-label"
          item-title="fullName"
          item-value="id"
          :readonly="isEdit && !editRightAccess"
          :menu-props="{ zIndex: 1000000000, maxWidth: 300 }"
          placeholder="Введіть текст (мін. 3 символи)"
          hide-details
          dense
          @update:model-value="
            $event => {
              eventData.contactId = $event.id
              localContactContractors.splice(0)
              localContactContractors = $event?.contractors
              v$.$reset()
            }
          "
          @blur="v$.event.contactId.$touch()">
        </b-server-autocomplete>
      </v-col>
      <v-col
        v-if="
          selectedEntities.includes(4) ||
          (selectedEntities.includes(2) &&
            selectedEntities.includes(4) &&
            !eventData.contractorId)
        "
        cols="12"
        class="pb-0">
        <span class="label">Контрагент пов'язаний з контактом</span>
        <v-autocomplete
          v-model="eventData.contractor"
          :items="localContactContractors"
          :error-messages="contractorIdErr"
          class="small-label"
          return-object
          :item-title="item => item.name || item.shortName"
          item-value="id"
          hide-details
          :readonly="isEdit && !editRightAccess"
          placeholder="Оберіть зі списку"
          dense
          @update:model-value="
            $event => {
              eventData.contractorId = $event?.id
            }
          "
          @blur="v$.event.contractorId.$touch()">
        </v-autocomplete>
      </v-col>
      <!--      contractor contact-->
      <v-col
        v-if="selectedEntities.includes(1) && eventData.contractorId"
        cols="12"
        class="pb-0">
        <span class="label">Контакт від контрагента</span>
        <v-autocomplete
          v-model="eventData.contact"
          :items="contractorContacts"
          :error-messages="managerErr"
          class="small-label"
          :item-title="
            item => (item.contact ? item.contact.fullName : item.name)
          "
          item-value="contactId"
          hide-details
          return-object
          :readonly="isEdit && !editRightAccess"
          placeholder="Оберіть зі списку"
          dense
          @update:model-value="
            $event => {
              eventData.contactId = $event?.id
            }
          "
          @blur="v$.event.contactId.$touch()">
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="
          eventData.dealerPointOfSaleId &&
          selectedEntities.includes(3) &&
          !editRightAccess
        "
        cols="12"
        class="pb-0">
        <span class="label">Торгова точка</span>
        <v-autocomplete
          v-model="eventData.dealer_point_of_sale"
          :items="[]"
          class="small-label"
          item-title="name"
          hide-details
          return-object
          item-value="id"
          placeholder="Оберіть зі списку"
          dense
          readonly
          disabled>
        </v-autocomplete>
      </v-col>
      <v-col v-if="eventData.dealerPointOfSaleId" cols="12" class="pb-0">
        <span class="label">Контакт торгової точки</span>
        <v-autocomplete
          v-model="eventData.contact"
          :items="localContactDealerPoint"
          :error-messages="managerErr"
          class="small-label"
          :item-title="item => (item.surname ? getPersonName(item) : item.name)"
          item-value="id"
          hide-details
          return-object
          :readonly="isEdit && !editRightAccess"
          placeholder="Оберіть зі списку"
          dense
          @update:model-value="
            $event => {
              eventData.contactId = $event?.id
            }
          "
          @blur="v$.event.contactId.$touch()">
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="
          selectedEntities.includes(3) &&
          !selectedEntities.includes(2) &&
          (!isEdit || editRightAccess)
        "
        cols="12"
        class="pb-0">
        <span class="label">Торгова точка</span>
        <b-server-autocomplete
          v-model="eventData.dealerPointOfSale"
          :url="{
            url: urlGetDealerOutlets(),
            query: {
              addSelect: ['employee'],
            },
          }"
          :search-min-length="3"
          :default-item="eventData.dealer_point_of_sale"
          :error-messages="dealerPointOfSaleIdErr"
          class="w-100 small-label"
          item-value="id"
          item-title="name"
          return-object
          :menu-props="{ zIndex: 1000000000, maxWidth: 300 }"
          placeholder="Введіть текст (мін. 3 символи)"
          hide-details
          :readonly="isEdit && !editRightAccess"
          @update:model-value="
            $event => {
              eventData.dealerPointOfSaleId = $event?.id
              localContactDealerPoint = $event?.employees || []
            }
          "
          @blur="v$.event.dealerPointOfSaleId.$touch()">
        </b-server-autocomplete>
      </v-col>
      <v-col v-if="selectedEntities.includes(1)" cols="12" class="pb-0 pt-0">
        <span class="label">Контрагент</span>
        <b-server-autocomplete
          v-model="eventData.contractor"
          :url="{
            url: urlGetAllContractors(),
            query: {
              addSelect: ['dealerPoint', 'employee'],
            },
          }"
          :search-min-length="3"
          :default-item="eventData.contractor"
          class="small-label"
          item-title="shortName"
          item-value="id"
          :readonly="isEdit && !editRightAccess"
          return-object
          placeholder="Введіть текст (мін. 3 символи)"
          hide-details
          :menu-props="{ zIndex: 1000000000, maxWidth: 300 }"
          dense
          @update:model-value="
            $event => {
              eventData.contractorId = $event?.id
              eventData.dealerPointOfSaleId = null
              eventData.contactId = null
              localContactDealerPoint.splice(0)
              pushSelect(2)
              updateContractorContacts([...$event?.employees])
              v$.$reset()
            }
          ">
        </b-server-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { BServerAutocomplete } from 'best-modules/components'
import {
  urlContactsGetAll,
  urlGetAllContractors,
  urlGetDealerOutlets,
} from '@/pages/request'
import { setErrHandler } from '@/utils/helperFunc'
import { getPersonName } from 'best-modules/utils'
export default {
  name: 'ActivityConnections',
  components: {
    BServerAutocomplete,
  },
  emits: [
    'pushToSelectedEntities',
    'update:selectedEntities',
    'contractorContacts',
  ],
  props: {
    v$: { type: Object },
    selectedEntities: { type: Array },
    contractorContacts: { type: Array },
    event: { type: Object },
    isEdit: { type: Boolean },
  },
  data: () => ({
    links: [
      { id: 1, text: 'Контрагент', icon: 'mdi-text-account' },
      { id: 2, text: 'Контакт', icon: 'mdi-account' },
      { id: 3, text: 'Торгова точка', icon: 'mdi-account-box-outline' },
      {
        id: 4,
        text: "Контрагент пов'язаний з контактом",
        icon: 'mdi-text-account',
      },
    ],
    localContactContractors: [],
    localContactDealerPoint: [],
    editRightAccess: false,
  }),
  computed: {
    eventData() {
      return this.event
    },
    activityLinks() {
      let filteredLinks = [...this.links]

      if (!this.selectedEntities.includes(2)) {
        filteredLinks = filteredLinks.filter(item => item.id !== 4)
      }

      const contractor = this.selectedEntities.includes(1)
      const contact = this.selectedEntities.includes(2)
      const dealer = this.selectedEntities.includes(3)

      const filter = arr => filteredLinks.filter(item => !arr.includes(item.id))

      switch (true) {
        case contact && contractor:
          return filter([1, 2, 3, 4])
        case contractor:
          return filter([1, 3, 4])
        case contact:
          return filter([1, 2, 3])
        case dealer:
          return filter([1, 2, 3, 4])
        default:
          return filter(this.selectedEntities)
      }
    },
    dealerPointOfSaleIdErr() {
      return setErrHandler(this.v$.event.dealerPointOfSaleId)
    },
    managerErr() {
      return setErrHandler(this.v$.event.contactId)
    },
    contractorIdErr() {
      return setErrHandler(this.v$.event.contractorId)
    },
  },
  methods: {
    getPersonName,
    urlContactsGetAll,
    urlGetAllContractors,
    urlGetDealerOutlets,
    pushSelect(itemId) {
      this.$emit('pushToSelectedEntities', itemId)
    },
    updateContractorContacts(arr) {
      this.$emit('contractorContacts', arr || [])
    },
    removeAllConnections() {
      this.localContactContractors.splice(0)
      this.eventData.dealerPointOfSaleId = null
      this.eventData.contractorId = null
      this.eventData.contractor = null
      this.eventData.contactId = null
      this.eventData.contact = null
      this.$emit('update:selectedEntities', [])
      this.updateContractorContacts()
    },
  },
  watch: {
    selectedEntities: {
      // Для редагування заблокованих полів, при очищенні selectedEntities
      handler(newValue, oldValue) {
        if (
          oldValue &&
          oldValue.length > 0 &&
          (!newValue || newValue.length === 0)
        ) {
          this.editRightAccess = true
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
