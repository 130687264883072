<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-if="choosedList">
        <v-data-table
          :headers="tHead"
          :items="formatedEvents"
          :hide-default-header="!formatedEvents.length"
          hide-default-footer
          :items-per-page="-1"
          class="pb-2 pt-1"
          :custom-sort="tableDateSort('created_at')"
          :search="string"
          mobile-breakpoint="960"
          dense>
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.contact="{ item }">
            <ALink
              v-if="item.contact"
              :route="{
                name: 'individuals-form',
                params: { id: item.contact.id },
              }"
              :text="item?.contact?.fullName">
            </ALink>
            <span v-else>---</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.customStatus="{ item }">
            <span style="font-size: 13px">{{
              item?.result ? item?.result?.name : item?.status?.name
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.startDate="{ item }">
            <span style="font-size: 13px">{{
              toFormatDate(item.startDate)
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.contractor="{ item }">
            <ALink
              v-if="item.contractor"
              style="font-size: 13px !important"
              :text="item.contractor.shortName"
              :route="{
                name: 'contractors-form',
                params: { id: item.contractor.id },
              }">
            </ALink>
            <span v-else>{{ '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.dealer_point_of_sale="{ item }">
            <ALink
              v-if="item.dealer_point_of_sale"
              style="font-size: 13px !important"
              :text="item.dealer_point_of_sale.name"
              :route="{
                name: 'dealer-form',
                params: { id: item.dealer_point_of_sale.id },
              }">
            </ALink>
            <span v-else>{{ '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.responsible="{ item }">
            <span style="font-size: 13px">{{
              getResponsible(item.responsible)
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.title="{ item }">
            <ALink
              style="font-size: 13px"
              :text="item.title"
              @click="e => openCard(item, e)">
            </ALink>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              data-readonly="hide"
              :actionsArray="[
                {
                  action: () =>
                    openDialog('editEvent', item, { dialogWidth: 1000 }),
                  text: 'Редагувати',
                  icon: 'mdi-pencil',
                  disabled: item.status.id === 2,
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
        <v-menu
          v-model="selectedOpen"
          :activator="selectedElement"
          :close-on-content-click="false"
          offset-y>
          <EventCard
            :selectedEvent="selectedEvent"
            :selectedOpen="selectedOpen"
            @close="selectedOpen = false"
            @event-delete="openDialog('deleteEvent', $event)"
            @event-edit="openDialog('editEvent', $event, { dialogWidth: 1000 })"
            @change-selected-open="selectedOpen = $event">
          </EventCard>
        </v-menu>
      </div>
    </v-fade-transition>
    <v-fade-transition hide-on-leave class="mb-10">
      <TaskCalendar
        v-if="choosedCalendar"
        :tasks="formatedEvents"
        @event-edit="openDialog('editEvent', $event, { dialogWidth: 1000 })"
        @event-delete="openDialog('deleteEvent', $event)">
      </TaskCalendar>
    </v-fade-transition>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'
import noData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
import TaskCalendar from '@/pages/activities/task-calendar.vue'
import EventCard from '@/pages/activities/event-card.vue'
import { tableDateSort, toFormatDate } from '@/utils/helperFunc'
export default {
  components: { EventCard, TaskCalendar, TableCrud, noData, ALink },
  props: {
    activityView: { type: Number },
    tHead: { type: Array, default: () => [] },
    events: { type: Array, default: () => [] },
  },
  data: () => ({
    selectedOpen: false,
    selectedElement: null,
    selectedEvent: {},
    string: null,
  }),
  computed: {
    formatedEvents() {
      return this.events.map(event => this.modifyEvent(event))
    },
    choosedCalendar() {
      return this.activityView === 2
    },
    choosedList() {
      return this.activityView === 1
    },
  },
  methods: {
    tableDateSort,
    toFormatDate,
    getResponsible(user) {
      return user.surname + ' ' + user.name
    },
    openCard(item, e) {
      this.selectedElement = e.target
      this.selectedEvent = item
      setTimeout(() => {
        this.selectedOpen = true
      }, 0)
    },
    openDialog(action, item = {}, params = {}, e) {
      e && e.preventDefault()

      this.$store.commit('setDialog', {
        title: 'Подія',
        dialogItem: item,
        params: params,
        action: action,
      })
    },
    modifyEvent(event) {
      if (!event) return {}
      const modifiedEvent = { ...event }
      modifiedEvent.typeId = event?.type?.id
      modifiedEvent.contactId = event?.contact?.id
      modifiedEvent.statusId = event?.status?.id
      modifiedEvent.sourceInvitations = event?.invitations
      return modifiedEvent
    },
  },
}
</script>
