<template>
  <div>
    <b-section-loader :loading="$loading.isLoading('getAssignmentSingle')">
      <pre>{{ assignment }}</pre>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import { computed, reactive } from 'vue'
import { BSectionLoader } from 'best-modules/components'
import { useRoute } from 'vue-router'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios.js'
import { urlAssignmentSingle } from '@/pages/request'

export default {
  name: 'AssignmentSingle',
  components: { BSectionLoader },
  setup() {
    const route = useRoute()
    const assignmentId = computed(() => route.params.id)
    const assignment = reactive({})

    const getAssignment = () => {
      return handleAsync('getAssignmentSingle', () => {
        return axios.get(urlAssignmentSingle(assignmentId.value)).then(res => {
          Object.assign(assignment, res.data)
        })
      })
    }
    getAssignment()

    return { assignment }
  },
}
</script>

<style scoped></style>
