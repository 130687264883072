<template>
  <v-card variant="flat" style="background: #efefef !important">
    <v-card-text>
      <div
        v-if="
          hasApprovedCalculation ||
          application.statusId === 5 ||
          application.slider
        "
        class="d-flex justify-space-between">
        <div>
          <v-btn
            v-if="application.statusId === 5"
            class="mx-3"
            style="
              background: #ffffff;
              border: 1px solid #08487a;
              border-radius: 4px;
              text-transform: uppercase;
            "
            @click="
              $router.push({
                name: 'project',
                params: {
                  projectId: application.slider?.project?.id,
                },
              })
            ">
            <LinkIcon class="mr-2" />
            Проект
          </v-btn>
        </div>
        <div>
          <v-btn
            v-if="hasApprovedCalculation || application.slider"
            class="text-white mx-3"
            style="background: #08487a; border-radius: 4px"
            :loading="$loading.isLoading('createSlider')"
            :disabled="!!application.readOnly || !!application.$hasChanges"
            @click="handleSlider()">
            <v-icon icon="mdi-plus" class="mr-2" />
            <span class="ml-1">{{
              application.slider ? 'Оновити бігунок' : 'Створити бігунок'
            }}</span>
          </v-btn>
        </div>
      </div>
      <status-bar
        :currentStatus="application.statusId"
        :statuses="statusList"
        :submit="toggleStatus"
        :color="application.statusId === 6 ? '#E14545' : null"
        spread-items
        size="small">
      </status-bar>
      <div data-readonly="hide" class="d-flex gap-3 ml-3">
        <v-btn
          v-tooltip:bottom="'Відправити повідомлення на Whatsapp'"
          size="x-small"
          icon="best:whats-up"
          @click="
            () =>
              $setSnackbar({
                text: 'Функціонал тимчасово недоступний',
                color: 'warning',
              })
          ">
        </v-btn>
        <v-btn
          v-tooltip:bottom="'Відправити лист на пошту'"
          class="mr-6"
          size="x-small"
          icon="best:email"
          @click="openCreateSmsDialog(2)">
        </v-btn>
        <v-btn
          v-tooltip:bottom="'Відправити повідомлення на Viber'"
          size="x-small"
          icon
          @click="openCreateSmsDialog(3)">
          <ViberIcon />
        </v-btn>
        <v-btn
          v-tooltip:bottom="'Відправити повідомлення на телефон'"
          class="mr-6"
          size="x-small"
          icon
          @click="openCreateSmsDialog(1)">
          <MessageIcon />
        </v-btn>
        <v-btn
          v-tooltip:bottom="'Створити нагадування'"
          size="x-small"
          icon
          @click="openCreateNotificationDialog()">
          <NotifIcon />
        </v-btn>
      </div>
      <ActivityNotification
        :local-data="application"
        :entityTypeId="3"
        @update-local-data="
          updated => (application.taskSchedulers = updated)
        " />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import {
  applicationKey,
  updateApplicationObjectKey,
  hasApprovedCalculationKey,
} from './injectionKeys'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Application } from '@/utils/types/application'
import MessageIcon from '@/assets/svg/social/message-icon.vue'
import LinkIcon from '@/assets/svg/link-icon.vue'
import { useRouter } from 'vue-router'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { urlApplicationUpdateStatus, urlCreateSlider } from '@/pages/request'
import axios from '@/plugins/axios.js'
import StatusBar from '@/components/StatusBar.vue'
import { getDirectory } from '@/plugins/directory'
import { useStore } from 'vuex'
import NotifIcon from '@/assets/svg/social/notif-icon.vue'
import ViberIcon from '@/assets/svg/social/viber-icon.vue'
import {
  getApplicationContacts,
  getContactInitials,
  toFormatDate,
} from '@/utils/helperFunc'
import ActivityNotification from '@/components/notifications/activity-notifications/ActivityNotification.vue'

export default {
  name: 'ApplicationHeader',
  components: {
    ActivityNotification,
    ViberIcon,
    NotifIcon,
    MessageIcon,
    StatusBar,
    LinkIcon,
  },
  methods: { getContactInitials, toFormatDate },
  setup() {
    const router = useRouter()
    const { commit } = useStore()
    const hasApprovedCalculation = inject(hasApprovedCalculationKey)

    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    const openSlider = () => {
      return router.push({
        name: 'application-slider',
        params: { sliderId: application.value.slider.id },
      })
    }
    const createSlider = () => {
      return handleAsync('createSlider', () => {
        return axios
          .post(urlCreateSlider(), { applicationId: application.value.id })
          .then(res => {
            application.value.slider = {
              id: res.data.id,
              project: null,
            }
            return openSlider()
          })
      })
    }

    const handleSlider = (): Promise<any> => {
      if (application.value.slider) {
        return openSlider()
      } else {
        return createSlider()
      }
    }

    const statusList = computed(() => {
      if (application.value.statusId === 6) {
        return getDirectory('applicationStatuses')
          .map(s => {
            if (s.id === 6) {
              return { id: 6, name: application.value.rejection.type.name }
            } else {
              return s
            }
          })
          .filter(s => s.id !== 5)
      } else if (application.value.statusId === 5) {
        return getDirectory('applicationStatuses').filter(s => s.id !== 6)
      } else {
        return getDirectory('applicationStatuses').filter(s => s.id !== 5)
      }
    })
    const changeStatus = (payload): Promise<any> => {
      return axios
        .post(urlApplicationUpdateStatus(application.value.id), payload)
        .then(res => {
          if (res.data.message) {
            return setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          } else {
            setSnackbar({ text: 'Статус успішно змінено' })
            commit('refreshPage')
            return res
          }
        })
    }
    const toggleStatus = (statusId: number): void => {
      if (application.value.statusId === 5) return

      // rejection
      if (statusId === 6) {
        return commit('setDialog', {
          title: 'Відмова',
          dialogItem: { statusId },
          params: {
            changeStatus: changeStatus,
          },
          action: 'rejectApplication',
          disabled: false,
        })
      }
    }

    const openCreateSmsDialog = (type: number): void => {
      const phones = getApplicationContacts(application.value, 'phones')
      const emails = getApplicationContacts(application.value, 'emails')
      if (type === 2 && !emails.length)
        return setSnackbar({
          text: '[Email] - повинен бути заповнений',
          color: 'warning',
        })
      if ([1, 3].includes(type) && !phones.length) {
        return setSnackbar({
          text: '[Телефон] - повинен бути заповнений',
          color: 'warning',
        })
      }

      return commit('setDialog', {
        title: 'Відправка повідомлення',
        dialogItem: {
          entityId: application.value.id,
          emails: emails,
          phones: phones,
          resEmail: application.value.responsible.email || '---', // TODO
          photo: application.value.responsible.photo, // TODO
          name: application.value.responsible.name,
        },
        params: {
          type: type,
          entityTypeId: 3,
          dialogWidth: 500,
          submitText: 'Відправити',
          rejectText: 'Скасувати',
          cb: () => commit('refreshPage'),
        },
        action: 'send-sms-dialog',
      })
    }
    const openCreateNotificationDialog = () => {
      commit('setDialog', {
        title: 'Деталі по нагадуванню',
        dialogItem: {
          entityId: application.value.id,
          entityTypeId: 3,
        },
        params: {
          dialogHeight: 700,
          dialogWidth: 600,
          cb: () => commit('refreshPage'),
          overflow: { y: 'hidden' },
        },
        action: 'task-plan-dialog',
      })
    }

    return {
      application,
      hasApprovedCalculation,
      handleSlider,
      statusList,
      toggleStatus,
      openCreateSmsDialog,
      openCreateNotificationDialog,
    }
  },
}
</script>
