<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="area-label">№ рахунку (IBAN)</span>
      <v-text-field
        id="cont-bank-num"
        v-model="bankAccount.accountNumber"
        v-mask:[ibanMask]
        :error-messages="getErrorMessages(v$.accountNumber)"
        placeholder="Введіть номер"
        dense
        hide-details
        @keyup.enter="submit()"
        @blur="v$.accountNumber.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0">
      <span class="area-label">МФО</span>
      <v-text-field
        id="contractor-account-mfo"
        v-model="bankAccount.mfo"
        :error-messages="getErrorMessages(v$.mfo)"
        placeholder="6 цифр"
        dense
        hide-details
        readonly
        @keyup.enter="submit()"
        @blur="v$.mfo.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0">
      <span class="area-label">Банк</span>
      <v-text-field
        id="contractor-account-name"
        v-model="bankAccount.bankName"
        :error-messages="getErrorMessages(v$.bankName)"
        placeholder="Банк"
        dense
        hide-details
        readonly
        @keyup.enter="submit()"
        @blur="v$.bankName.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0">
      <span class="area-label">Тип рахунку</span>
      <v-select
        v-model="bankAccount.type.id"
        :error-messages="getErrorMessages(v$.type)"
        :items="$directory.get('bankAccountTypes', bankAccount.type)"
        item-title="name"
        item-value="id"
        placeholder="Тип рахунку"
        :loading="$loading.isLoading('bankAccountTypes')"
        dense
        hide-details
        @keyup.enter="submit()"
        @update:model-value="bankAccount.isBasic = false"
        @blur="v$.type.$touch()"
        @focus="$directory.fill('bankAccountTypes')">
        <template #append-item>
          <loader v-if="$loading.isLoading('bankAccountTypes')"></loader>
        </template>
      </v-select>
    </v-col>
    <v-col v-if="currentAccount" cols="12" md="12" sm="12" class="pb-0">
      <v-checkbox
        v-model="bankAccount.isBasic"
        class="mt-0 mb-3"
        color="rgb(210, 74, 67)"
        hide-details
        dense
        @keyup.enter="submit()">
        <template #label>
          <span style="font-size: 0.8rem"> Основний рахунок </span>
        </template>
      </v-checkbox>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0">
      <span class="area-label">Валюта</span>
      <v-select
        v-model="bankAccount.currency.id"
        :error-messages="getErrorMessages(v$.currency)"
        placeholder="Оберіть зi списку"
        :items="$directory.get('currencies', bankAccount.currency)"
        item-title="name"
        item-value="id"
        dense
        hide-details
        @keyup.enter="submit()"
        @blur="v$.currency.$touch()">
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import Loader from '@/components/Loader.vue'
import { computed, ref, watch } from 'vue'
import axios from '@/plugins/axios'
import { ibanMask, trimExceededLength } from '@/utils/masks'
import { minLength, required } from '@vuelidate/validators'
import { useSelect } from '@/utils/mixins/useSelect'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import { getMfoFromIban } from '@/utils/helperFunc'
import { urlCreateBankAccount, urlUpdateBankAccount } from '@/pages/request'
import { setSnackbar } from 'best-modules/plugins'
import { fillDirectory } from '@/plugins/directory'

export default {
  components: { Loader },
  props: {
    dialog: { type: Object },
  },
  computed: {
    validation() {
      return { v$: this.v$, name: 'contractorDialog' }
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const bankAccount = ref({
      typeId: null,
      accountNumber: null,
      bankName: null,
      mfo: null,
      currency: {
        id: null,
      },
      isBasic: 0,
      type: {
        id: null,
      },
    })
    const addBankAccount = computed(
      () => props.dialog.dialogItem.action === 'addBankAccount'
    )
    const editBankAccount = computed(
      () => props.dialog.dialogItem.action === 'editBankAccount'
    )
    const currentAccount = computed(() => bankAccount.value?.type?.id === 1)

    const rules = {
      bankName: { required },
      mfo: { required },
      accountNumber: { required, minLength: minLength(29) },
      type: {
        id: { required },
      },
      currency: {
        id: { required },
      },
    }

    const v$ = useVuelidate(rules, bankAccount)

    const getBank = async event => {
      trimExceededLength('cont-bank-num', 29)
      const { mfo = null, name = null } = await getMfoFromIban(event)
      bankAccount.value.bankName = name
      bankAccount.value.mfo = mfo
    }

    const handleRequest = (method, url) => {
      const req = {
        ...bankAccount.value,
        typeId: bankAccount.value.type.id,
        currencyId: bankAccount.value.currency.id,
        entityId: props.dialog.dialogItem.entityId,
        entityTypeId: props.dialog.dialogItem.entityTypeId,
      }
      delete req.action
      return axios[method](url, req).then(res => {
        setSnackbar({ text: 'Дані оновлено' })
        return res
      })
    }

    const submit = async () => {
      if (addBankAccount.value) {
        // create
        return handleRequest('post', urlCreateBankAccount())
      } else {
        // update
        return handleRequest('post', urlUpdateBankAccount(bankAccount.value.id))
      }
    }

    watch(
      () => bankAccount.value.accountNumber,
      newVal => {
        getBank(newVal)
      }
    )

    return {
      ...useSelect(),
      addBankAccount,
      editBankAccount,
      bankAccount,
      currentAccount,
      ibanMask,
      submit,
      v$,
    }
  },
  created() {
    fillDirectory('bankAccountTypes')
    Object.assign(this.bankAccount, Object.clone(this.dialog?.dialogItem))
  },
}
</script>

<style scoped></style>
