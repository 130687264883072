<template>
  <div>
    <div>
      <v-card-title v-show="deleteBeneficiary">
        Засновника буде видалено!
      </v-card-title>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <span class="label">Назва компаниї/ПІБ</span>
          <v-text-field
            v-model="beneficiaryData.name"
            :error-messages="getErrorMessages(v$.name)"
            maxLength="255"
            dense
            placeholder="Введіть назву"
            hide-details
            :disabled="deleteBeneficiary"
            @blur="v$.name.$touch()"
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="pt-0">
          <span class="label">Тип володіння</span>
          <v-text-field
            :model-value="getSafe(() => beneficiaryData.founder_role.name)"
            :items="selectItems.founderRoles"
            item-title="name"
            item-value="id"
            dense
            hide-details
            disabled
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="pt-0">
          <span class="label">Доля в статутному капіталі. грн</span>
          <v-text-field
            id="cp-d-ben-am"
            v-model="beneficiaryData.amount"
            :error-messages="getErrorMessages(v$.amount)"
            dense
            hide-details
            placeholder="Введіть суму"
            :disabled="deleteBeneficiary"
            @input="inputNumber($event, { float: 2, minValue: 1 })"
            @blur="v$.amount.$touch()"
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="pt-0">
          <span class="label">Доля в статутному капіталі. %</span>
          <v-text-field
            id="cp-d-ben-am-per"
            v-model="beneficiaryData.amountPercent"
            :error-messages="getErrorMessages(v$.amountPercent)"
            dense
            placeholder="Введіть відсоток"
            hide-details
            :disabled="deleteBeneficiary"
            @blur="
              event => {
                inputNumber(event, { float: 6 })
                v$.amountPercent.$touch()
              }
            "
            @input="
              $event => {
                inputNumber($event, {
                  float: 6,
                  maxValue: dialog?.dialogItem?.maxPercent,
                })
                v$.amountPercent.$touch()
              }
            "
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pt-0">
          <span class="label">ЭДРПОУ/ІПН</span>
          <v-text-field
            id="cp-d-ben-code"
            v-model="beneficiaryData.code"
            :error-messages="getErrorMessages(v$.code)"
            dense
            hide-details
            placeholder="Введіть номер"
            :disabled="deleteBeneficiary"
            @input="maxNumLength('cp-d-ben-code', 10)"
            @blur="v$.code.$touch()"
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pt-0">
          <span class="label">Країна резидентства</span>
          <v-autocomplete
            v-model="beneficiaryData.residentCountryId"
            :items="
              $directory.get('nationalityList', beneficiaryData.residentCountry)
            "
            :loading="$loading.isLoading('nationalityList')"
            item-title="name"
            item-value="id"
            placeholder="Оберіть зі списку"
            dense
            hide-details
            :error-messages="getErrorMessages(v$.residentCountryId)"
            :disabled="deleteBeneficiary"
            @blur="v$.registrationCountryId.$touch()">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pt-0">
          <span class="label">Країна реєстрації</span>
          <v-autocomplete
            v-model="beneficiaryData.registrationCountryId"
            :items="
              $directory.get(
                'nationalityList',
                beneficiaryData.registrationCountry
              )
            "
            :loading="$loading.isLoading('nationalityList')"
            item-title="name"
            item-value="id"
            placeholder="Оберіть зі списку"
            dense
            hide-details
            :disabled="deleteBeneficiary"
            :error-messages="getErrorMessages(v$.registrationCountryId)"
            @blur="v$.registrationCountryId.$touch()">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pt-0">
          <span class="label">Адреса</span>
          <v-text-field
            id="cp-d-ben-code"
            v-model="beneficiaryData.location"
            dense
            hide-details
            placeholder="Введіть адресу"
            :disabled="deleteBeneficiary"
            :error-messages="getErrorMessages(v$.location)"
            @blur="v$.location.$touch()"
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { getBeautyNum, getSafe } from '@/utils/helperFunc'
import { minLength, minValue, required } from '@vuelidate/validators'
import { inputNumber, keepDigit } from '@/utils/formatFunc'
import { setSnackbar } from 'best-modules/plugins/index'
import {
  urlCreateFounder,
  urlDeleteFounder,
  urlUpdateFounder,
} from '@/pages/request'
import axios from '@/plugins/axios'
import { useStore } from 'vuex'
import { maxNumLength } from '@/utils/masks'
import { fillDirectory } from '@/plugins/directory'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { getErrorMessages } from 'best-modules/utils'

export default {
  props: {
    dialog: { type: Object },
  },
  computed: {
    validation() {
      return { v$: this.v$, name: 'contractorDialog' }
    },
  },
  methods: { getErrorMessages, maxNumLength, inputNumber, getSafe },
  setup(props) {
    const store = useStore()
    const beneficiaryData = ref({
      name: null,
      founder_role: { id: 2, name: 'Засновник' },
      founderRoleId: 2,
      amount: null,
      amountPercent: null,
      contractorId: null,
      residentCountryId: 1,
      registrationCountryId: 1,
      location: null,
      code: null,
    })
    const addBeneficiary = computed(() => {
      return props.dialog.dialogItem.action === 'addBeneficiary'
    })
    const editBeneficiary = computed(() => {
      return props.dialog.dialogItem.action === 'editBeneficiary'
    })
    const deleteBeneficiary = computed(() => {
      return props.dialog.dialogItem.action === 'deleteBeneficiary'
    })
    const rules = {
      name: { required },
      amount: { required },
      amountPercent: {
        required,
        minValue: deleteBeneficiary.value ? minValue(0) : minValue(0.01),
      },
      code: deleteBeneficiary.value ? {} : { minLength: minLength(8) },
      founderRoleId: { required },
      registrationCountryId: deleteBeneficiary.value ? {} : { required },
      residentCountryId: deleteBeneficiary.value ? {} : { required },
      location: deleteBeneficiary.value ? {} : { required },
    }

    const v$ = useVuelidate(rules, beneficiaryData)

    const selectItems = computed(() => {
      return store.state.selectItems
    })

    const handleRequest = (method, url, params) => {
      return axios[method](url, params).then(res => {
        setSnackbar({ text: 'Дані оновлено' })
        return res
      })
    }

    const submit = () => {
      if (addBeneficiary.value) {
        beneficiaryData.value.contractorId =
          props.dialog?.dialogItem?.contractorId
        beneficiaryData.value.code =
          beneficiaryData.value.code && beneficiaryData.value.code !== ''
            ? String(beneficiaryData.value.code)
            : null
        beneficiaryData.value.amount = Number(
          keepDigit(beneficiaryData.value.amount) || 0
        ).toFixed(4)
        beneficiaryData.value.amountPercent = Number(
          keepDigit(beneficiaryData.value.amountPercent) || 0
        ).toFixed(6)
        return handleRequest('post', urlCreateFounder(), beneficiaryData.value)
      } else if (editBeneficiary.value) {
        beneficiaryData.value.contractorId =
          props.dialog?.dialogItem?.contractorId
        beneficiaryData.value.code =
          beneficiaryData.value.code && beneficiaryData.value.code !== ''
            ? String(beneficiaryData.value.code)
            : null
        beneficiaryData.value.amount = Number(
          keepDigit(beneficiaryData.value.amount) || 0
        ).toFixed(4)
        beneficiaryData.value.amountPercent = Number(
          keepDigit(beneficiaryData.value.amountPercent) || 0
        ).toFixed(6)
        return handleRequest(
          'post',
          urlUpdateFounder(beneficiaryData.value?.id),
          beneficiaryData.value
        )
      } else if (deleteBeneficiary.value) {
        return handleRequest(
          'delete',
          urlDeleteFounder(beneficiaryData.value?.id)
        )
      }
    }

    return {
      submit,
      beneficiaryData,
      addBeneficiary,
      editBeneficiary,
      deleteBeneficiary,
      selectItems,
      v$,
      ...useSelect(),
    }
  },
  created() {
    if (this.editBeneficiary || this.deleteBeneficiary) {
      Object.assign(this.beneficiaryData, this.dialog?.dialogItem)
      this.beneficiaryData.amount = getBeautyNum(this.beneficiaryData.amount, {
        float: 4,
      })
    }
    if (this.addBeneficiary || this.editBeneficiary || this.deleteBeneficiary) {
      fillDirectory('nationalityList')
    }
  },
}
</script>

<style scoped></style>
