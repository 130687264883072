<template>
  <div class="pr-3 pl-3">
    <SectionLoader v-if="pageLoad"></SectionLoader>
    <div v-if="!pageLoad" class="dealer-block">
      <v-fade-transition hide-on-leave>
        <v-row>
          <CommonCard :dealer="dealerData" :readOnly="readOnly" :v="v$">
          </CommonCard>
          <v-col :cols="12" :md="9" class="pl-0">
            <v-card class="mb-2" :elevation="0">
              <v-card-text class="pt-0 pl-0 pr-0" style="min-height: 350px">
                <div class="d-flex align-center justify-space-between">
                  <v-tabs
                    v-model="currentTab"
                    slider-color="#FC7247"
                    :direction="
                      $vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'
                    "
                    :height="$vuetify.breakpoint?.xs ? null : 38"
                    show-arrows
                    color="white"
                    dense>
                    <v-tab
                      replace
                      :to="{
                        name: 'dealer-main',
                        params: { id: $route.params.id },
                      }">
                      Дилер
                    </v-tab>
                    <v-tab
                      replace
                      :to="{
                        name: 'dealer-parthership',
                        params: { id: $route.params.id },
                      }">
                      Партнерство
                    </v-tab>
                    <v-tab
                      replace
                      :to="{
                        name: 'dealer-info',
                        params: { id: $route.params.id },
                      }">
                      Контакти
                    </v-tab>
                  </v-tabs>
                  <div class="pr-3 d-flex align-center">
                    <v-btn
                      variant="text"
                      size="small"
                      style="text-transform: none"
                      @click="toggleActiveDealer">
                      <v-icon
                        style="font-size: 21px"
                        class="mr-1"
                        color="#fc7247"
                        >{{
                          dealerData.statusId === 2
                            ? 'mdi-check-circle-outline'
                            : 'mdi-store-remove'
                        }}</v-icon
                      >
                      <span>{{
                        dealerData.statusId === 2
                          ? 'Активувати'
                          : 'Деактивувати'
                      }}</span>
                    </v-btn>
                  </div>
                </div>
                <v-card-text
                  class="pb-0 pt-1"
                  style="
                    padding-left: 5px !important;
                    padding-right: 5px !important;
                  ">
                  <keep-alive>
                    <RouterView
                      :notOfficialDealer="notOfficialDealer"
                      :commisionPlatform="commisionPlatform"
                      :officialDealer="officialDealer"
                      :pageLoad="pageLoad"
                      :dealer="dealerData"
                      :readOnly="readOnly"
                      :v="v$"
                      @refresh-data="getDealerById"
                      @set-cache="
                        cache = setCache([dealerData], ['0.responsibleId'])
                      ">
                    </RouterView>
                  </keep-alive>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
          <ActionButtons
            :confirm="submit"
            :cancel="getDealerById"
            :cancelDisable="
              dealerCache === cache || loading || (readOnly && !isSupervisor)
            "
            :confirmDisable="
              dealerCache === cache || (readOnly && !isSupervisor)
            ">
          </ActionButtons>
        </v-row>
      </v-fade-transition>
    </div>
  </div>
</template>
<script>
import CommonCard from './CommonCard.vue'
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import { urlGetDealer, urlDealerUpdate } from '@/pages/request'
import { setCache, v$Notify } from '@/utils/helperFunc'
import { mapState } from 'vuex'

import { ADMIN, SALES_DIRECTOR, SUPERVISOR } from '@/roles'
import { isObject } from '@/utils/type'
import { required, minLength } from '@vuelidate/validators'
import { dealer as D } from '@/type-ids'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { CommonCard, ActionButtons, SectionLoader },
  emits: ['dataChanged'],
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const dealer = this.dealerData.typeId === D.type.DEALER
    // const official = this.dealerData.categoryId === D.category.OFFICIAL

    const posTerminalsComment = this.dealerData.posTerminals ? { required } : {}
    const generalProgramComment = this.dealerData.generalProgram
      ? { required }
      : {}
    const approvedDiscountComment = this.dealerData.approvedDiscount
      ? { required }
      : {}
    const individualProgramsComment = this.dealerData.individualPrograms
      ? { required }
      : {}
    const categoryId = dealer ? { required } : {}

    const saleObjectTypes = dealer
      ? {
          required,
          minLength: minLength(1),
          $each: {
            isValid: item => {
              if (!item) {
                return false
              }
              const isValidMarks =
                ![1, 2, 6, 7].includes(item.id) || !!item.marks.length
              const isValidTypes =
                !![1, 2, 6, 7].includes(item.id) ||
                (Array.isArray(item.types) &&
                  item.types.length > 0 &&
                  item.types.reduce(
                    (acc, t) => (acc ? !!t.marks?.length : false),
                    true
                  ))

              return isValidMarks && isValidTypes
            },
          },
        }
      : {}

    return {
      dealerData: {
        typeId: { required },
        responsibleId: { required },
        posTerminalsComment: posTerminalsComment,
        generalProgramComment: generalProgramComment,
        approvedDiscountComment: approvedDiscountComment,
        individualProgramsComment: individualProgramsComment,
        categoryId: categoryId,
        saleObjectTypes: saleObjectTypes,
      },
    }
  },
  data: () => ({
    dealerData: {},
    pageLoad: false,
    cache: null,
    loading: false,
    currentTab: 0,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    isSupervisor() {
      return SUPERVISOR === this.user.roleId
    },
    dealerCache() {
      return this.setCache([this.dealerData], ['0.responsibleId'])
    },
    readOnly() {
      return (
        this.dealerData?.responsible?.id !== this.user?.id &&
        ![ADMIN, SALES_DIRECTOR].includes(this.user.roleId)
      )
    },
    notOfficialDealer() {
      return this.dealerData?.categoryId === 2
    },
    commisionPlatform() {
      return this.dealerData?.categoryId === 3
    },
    officialDealer() {
      return this.dealerData?.categoryId === 1
    },
  },
  methods: {
    setCache,
    getDealerById() {
      this.pageLoad = true
      return this.$axios
        .get(urlGetDealer(this.$route.params.id))
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
            this.$router.push({ name: 'dealer-list' })
          }
          if (
            isObject(res.data?.saleObjectTypes) ||
            !res.data?.saleObjectTypes
          ) {
            res.data.saleObjectTypes = []
          }
          Object.assign(this.dealerData, res.data)
          this.setBreadscrumb()
          this.cache = this.setCache([this.dealerData], ['0.responsibleId'])
          this.pageLoad = false
          return res
        })
        .catch(err => {
          if (err) {
            if (window.history.length > 1) {
              return this.$router.go(-1)
            } else {
              return this.$router.push('/')
            }
          }
        })
    },
    setBreadscrumb() {
      this.$store.commit('setBreadScrumbPart', [
        null,
        null,
        this.dealerData.name,
        null,
      ])
    },
    toggleActiveDealer() {
      this.$store.commit('setDialog', {
        title: `${
          this.dealerData.statusId === 2
            ? 'Активувати дилера'
            : 'Деактивувати дилера'
        }`,
        dialogItem: {
          statusId: this.dealerData.statusId === 1 ? 2 : 1,
        },
        action: 'dealer-inactivation',
        params: {
          submitText: 'Підтвердити',
          cb: res => Object.assign(this.dealerData, res.data),
        },
      })
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        return v$Notify(
          {
            ...this.v$.dealerData,
          },
          'dealer'
        )
      }

      this.loading = true
      delete this.dealerData.responsibleId
      delete this.dealerData.responsible
      return this.$axios
        .post(urlDealerUpdate(this.$route.params.id), this.dealerData)
        .then(res => {
          Object.assign(this.dealerData, res.data)
          this.$setSnackbar({ text: 'Дані оновлено' })
          this.getDealerById()
          this.loading = false
          this.$emit('dataChanged')
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadscrumb()
    },
    'dealerData.instagram': function (val) {
      if (typeof val === 'string' && val.length === 0) {
        this.dealerData.instagram = null
      }
    },
    'dealerData.facebook': function (val) {
      if (typeof val === 'string' && val.length === 0) {
        this.dealerData.facebook = null
      }
    },
    'dealerData.site': function (val) {
      if (typeof val === 'string' && val.length === 0) {
        this.dealerData.site = null
      }
    },
    categoryId: function () {
      if (!this.commisionPlatform) {
        this.dealerData.contractor = null
        this.dealerData.contractorId = null
      }
    },
  },
  created() {
    this.$store.dispatch('addLeasingObjectTypes')
    this.getDealerById()
  },
}
</script>
<style scoped>
.dealer-block {
  position: relative;
  align-items: start;
}
.v-tab {
  color: #838282;
  font-size: 14px;
}
.v-tab-item--selected.v-tab {
  color: #08487a !important;
}
</style>
