<template>
  <v-container style="padding-top: 0">
    <v-row v-if="leadData" class="ma-0">
      <v-col :cols="12" :md="3" class="pa-0">
        <v-fade-transition hide-on-leave>
          <v-row>
            <v-col :cols="12" :md="11" style="padding-top: 8px !important">
              <v-row>
                <v-col class="white-block" :cols="12" :sm="12">
                  <v-row class="pl-3 mt-2">
                    <div class="text-field">
                      <span class="subtitle">Назва</span>
                      <span class="text-desc">{{
                        leadData.leadName || 'Не вказано'
                      }}</span>
                    </div>
                  </v-row>
                  <v-row
                    v-if="
                      leadData &&
                      leadData.source_type &&
                      leadData.source_type.id === 2
                    ">
                    <v-col :cols="12" :md="12" class="pb-0">
                      <span class="label">Джерело</span>
                      <v-select
                        v-model="leadData.sourceId"
                        placeholder="Оберіть джерело"
                        :error-messages="sourceIdErr"
                        :readonly="readonly"
                        :items="
                          $directory.get(
                            'applicationOnlineSourceTypes',
                            leadData.source
                          )
                        "
                        hide-details
                        item-title="name"
                        item-value="id"
                        :loading="
                          $loading.isLoading('applicationOnlineSourceTypes')
                        "
                        @focus="$directory.fill('applicationOnlineSourceTypes')"
                        @blur="v$.leadData.sourceId.$touch()"
                        @update:model-value="handleSource($event)">
                        <template #no-data><Loader /></template>
                      </v-select>
                      <!--                      <div class="text-field">-->
                      <!--                        <span class="subtitle">Джерело</span>-->
                      <!--                        <span-->
                      <!--                          class="text-desc"-->
                      <!--                          style="display: flex !important; align-items: center">-->
                      <!--                          <WebSiteIcon />-->
                      <!--                          <span class="ml-1">{{ sourceType }}</span>-->
                      <!--                        </span>-->
                      <!--                      </div>-->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" :md="12" class="pb-0">
                      <span class="subtitle">Тип клієнта</span>
                      <v-select
                        v-model="leadData.lesseeTypeId"
                        placeholder="Оберіть тип клієнта"
                        :error-messages="clientTypeIdErr"
                        :readonly="readonly"
                        :items="
                          $directory.get('lesseeTypes', leadData.lessee_type)
                        "
                        hide-details
                        item-title="name"
                        item-value="id"
                        :loading="$loading.isLoading('lesseeTypes')"
                        @focus="$directory.fill('lesseeTypes')"
                        @blur="v$.leadData.lesseeTypeId.$touch()">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" :md="12" class="pb-0">
                      <span class="subtitle">Потреба клієнта</span>
                      <v-select
                        v-model="leadData.clientNeedId"
                        placeholder="Оберіть потреба клієнта"
                        :items="
                          $directory.get('clientNeeds', leadData.client_need)
                        "
                        hide-details
                        :error-messages="clientNeedIdErr"
                        :readonly="readonly"
                        item-title="name"
                        item-value="id"
                        :loading="$loading.isLoading('clientNeeds')"
                        @focus="$directory.fill('clientNeeds')"
                        @blur="v$.leadData.clientNeedId.$touch()">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" :md="12" class="pb-0">
                      <ChangeResponsible
                        v-model:responsibleId="leadData.responsibleId"
                        :read-only="leadData.readOnly"
                        :responsible="leadData.responsible" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" :md="12" class="pt-0">
                      <div class="text-field">
                        <span class="subtitle">Дата створення</span>
                        <span class="text-desc">{{
                          toFormatDate(leadData.created_at, false, true) ||
                          'Не вказано'
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-col>

      <v-col :cols="12" :md="9" style="padding: 0">
        <div
          style="
            background: #efefef;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07);
            border-radius: 4px;
            margin-bottom: -15px;
          ">
          <div>
            <StatusBar
              class="mt-2"
              :currentStatus="leadData.status.id"
              :statuses="statusBarInfo"
              :subStatuses="{
                done:
                  leadData.status.id < 5
                    ? $directory
                        .get('leadStatuses')
                        .filter(s => [5, 6, 7, 8, 9].includes(s.id))
                    : [
                        {
                          ...$directory
                            .get('leadStatuses', null, { type: 'collection' })
                            .getItem(4),
                          select: 'done',
                          arrow: true,
                        },
                      ],
              }"
              :submit="toggleStatus"
              spread-items
              size="small">
            </StatusBar>
          </div>
          <div class="social-wrapper" style="padding: 0 0 15px 15px">
            <div class="social" style="display: flex; align-items: center">
              <v-tooltip bottom text="Відправити повідомлення на Whatsapp">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    style="margin-right: 12px"
                    v-bind="props"
                    @click="() => $setSnackbar({ text: 'to be continued..' })">
                    <TellIcon />
                  </v-btn>
                </template>
                <span>Відправити повідомлення на Whatsapp</span>
              </v-tooltip>
              <v-tooltip bottom text="Відправити лист на пошту">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    style="margin-right: 35px"
                    :loading="loading"
                    v-bind="props"
                    @click="openCreateSmsDialog(2)">
                    <EmailIcon />
                  </v-btn>
                </template>
                <span>Відправити лист на пошту</span>
              </v-tooltip>
              <v-tooltip bottom text="Відправити повідомлення на Viber">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    style="margin-right: 12px"
                    :loading="loading"
                    v-bind="props"
                    @click="openCreateSmsDialog(3)">
                    <ViberIcon />
                  </v-btn>
                </template>
                <span>Відправити повідомлення на Viber</span>
              </v-tooltip>
              <v-tooltip bottom text="Відправити повідомлення на телефон">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    style="margin-right: 35px"
                    :loading="loading"
                    v-bind="props"
                    @click="openCreateSmsDialog(1)">
                    <MessageIcon />
                  </v-btn>
                </template>
                <span>Відправити повідомлення на телефон</span>
              </v-tooltip>
              <v-tooltip bottom text="Створити нагадування">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    v-bind="props"
                    @click="openCreateNotificationDialog()">
                    <NotifIcon />
                  </v-btn>
                </template>
                <span>Створити нагадування</span>
              </v-tooltip>
              <v-tooltip
                v-if="leadData.application"
                bottom
                text="Перейти в заявку">
                <template #activator="{ props }">
                  <v-btn
                    class="ml-4"
                    size="small"
                    icon
                    v-bind="props"
                    @click="navigateToApplication">
                    <v-icon color="#636363">{{
                      'mdi-link-box-variant-outline'
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>Перейти в заявку</span>
              </v-tooltip>
            </div>
          </div>
          <ActivityNotification
            :local-data="leadData"
            :entityTypeId="4"
            @update-local-data="
              updated => (leadData.task_schedulers = updated)
            " />
        </div>

        <v-tabs
          v-model="currentTab"
          class="mb-5 mt-8"
          slider-color="#FC7247"
          show-arrows
          :height="38">
          <v-tab
            replace
            :to="{
              name: 'lead-general-info',
              params: { id: $route.params.id },
            }">
            Основна інформація
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'lead-communication',
              params: { id: $route.params.id },
            }">
            Активності
          </v-tab>
          <v-tab
            v-if="lead.duplicate_leads && lead.duplicate_leads.length"
            replace
            :to="{ name: 'lead-duplicates', params: { id: $route.params.id } }">
            Дублікати лідів
          </v-tab>
          <v-tab
            v-if="lead.histories && lead.histories.length"
            replace
            :to="{ name: 'lead-history', params: { id: $route.params.id } }">
            Історія лідів
          </v-tab>
        </v-tabs>

        <RouterView
          v-if="leadData"
          v-model:lead="leadData"
          style="min-height: 390px"
          :cb="() => $store.commit('refreshPage')"
          :v="v$"
          :readonly="readonly"
          @update-lead="getLead().then(() => (loading = false))">
        </RouterView>

        <v-row v-if="!readonly">
          <ActionButtons
            :confirm="updateLead"
            :cancel="resetLeadData"
            :confirmLoading="loading"
            :readonly="readonly"
            :confirmDisable="!isChanged"
            :cancelDisable="!isChanged || loading">
          </ActionButtons>
        </v-row>
      </v-col>
    </v-row>
    <SectionLoader v-if="loading"></SectionLoader>
  </v-container>
</template>

<script>
import ViberIcon from '@/assets/svg/social/viber-icon.vue'
import MessageIcon from '@/assets/svg/social/message-icon.vue'
import EmailIcon from '@/assets/svg//social/email-icon.vue'
import NotifIcon from '@/assets/svg//social/notif-icon.vue'
import TellIcon from '@/assets/svg/social/tell-icon.vue'
import StatusBar from '@/components/StatusBar.vue'
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import { mapState, mapGetters } from 'vuex'
import { v$Notify } from '@/utils/helperFunc'
import { required, minLength, email } from '@vuelidate/validators'
import {
  toFormatDate,
  setErrHandler,
  getResponsibleShortName,
  getContactInitials,
} from '@/utils/helperFunc'
import ChangeResponsible from '@/components/ChangeResponsible.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'
import Loader from '@/components/Loader.vue'
import ActivityNotification from '@/components/notifications/activity-notifications/ActivityNotification.vue'

export default {
  components: {
    ActivityNotification,
    Loader,
    ChangeResponsible,
    StatusBar,
    SectionLoader,
    ActionButtons,
    TellIcon,
    // WebSiteIcon,
    NotifIcon,
    EmailIcon,
    MessageIcon,
    ViberIcon,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    const leadData = {
      name: { required },
      phone: this.leadData?.phone ? { required, minLength: minLength(23) } : {},
      email: { email },
      lesseeTypeId: { required },
      clientNeedId: { required },
      sourceId: { required },
      responsibleId: { required },
    }
    return {
      leadData,
    }
  },
  data: () => {
    return {
      currentTab: 0,
      loading: false,
      leadData: null,
      sourceLoading: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      lead: state => state.leads.curLead,
      user: state => state.user,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    readonly() {
      if (this.user.roleId === 1) {
        return false
      }
      if (this.leadData.responsibleId) {
        return !(
          this.leadData.statusId === 2 &&
          (this.lead.responsibleId === this.user.id || this.user.roleId === 1)
        )
      } else return false
    },
    sourceType() {
      const utmMedium = this.leadData.utmMedium || ''
      const sourceName = utmMedium === 'cpc' ? 'Реклама' : 'Органічний трафік'
      const source = this.leadData.source?.name || '---'

      return `${source} (${sourceName})`
    },
    statusBarInfo() {
      return getDirectory('leadStatuses').length
        ? [
            getDirectory('leadStatuses')[0],
            getDirectory('leadStatuses')[1],
            getDirectory('leadStatuses')[2],
            this.doneStatus,
          ]
        : []
    },
    doneStatus() {
      if ([5, 6, 7, 8, 9].includes(this.leadData.status.id)) {
        return { ...this.leadData.status, select: 'done', arrow: true }
      } else {
        return {
          ...getDirectory('leadStatuses', null, { type: 'collection' }).getItem(
            4
          ),
          select: 'done',
          arrow: true,
        }
      }
    },
    isChanged() {
      return JSON.stringify(this.leadData) !== JSON.stringify(this.lead)
    },
    nameErr() {
      return setErrHandler(this.v$.leadData.name)
    },
    clientTypeIdErr() {
      return setErrHandler(this.v$.leadData.lesseeTypeId)
    },
    sourceIdErr() {
      return setErrHandler(this.v$?.leadData?.sourceId)
    },
    clientNeedIdErr() {
      return setErrHandler(this.v$.leadData.clientNeedId)
    },
  },
  methods: {
    setErrHandler,
    toFormatDate,
    getResponsibleShortName,
    getContactInitials,
    handleSource(sourceId) {
      this.sourceLoading = true
      this.$store
        .dispatch('addApplicationSourceLinks', sourceId)
        .finally(() => (this.sourceLoading = false))
    },
    // handleSourceLink() {
    //   this.$store
    //     .dispatch('addApplicationSourceLinks', this.leadData?.sourceId)
    //     .finally(() => (this.sourceLoading = false))
    // },
    navigateToApplication() {
      this.$router.push({
        name: 'edit-application',
        params: { applicationId: this.leadData?.application?.id },
      })
    },
    openCreateNotificationDialog() {
      this.$store.commit('setDialog', {
        title: 'Деталі по нагадуванню',
        dialogItem: {
          entityId: this.leadData.id,
          entityTypeId: 4,
        },
        params: {
          dialogHeight: 700,
          dialogWidth: 600,
          cb: () =>
            this.getLead(this.leadData.id).then(() => (this.loading = false)),
        },
        action: 'task-plan-dialog',
      })
    },
    openCreateSmsDialog(type) {
      if (type === 2 && !this.leadData.email)
        return this.$setSnackbar({
          text: '[Email] - повинен бути заповнений',
          color: 'warning',
        })
      if ([1, 3].includes(type) && this.leadData.phone === null) {
        return this.$setSnackbar({
          text: '[Телефон] - повинен бути заповнений',
          color: 'warning',
        })
      }
      if (!this.leadData.responsible) {
        return this.$setSnackbar({
          text: 'Відповідальна особа у лiда не знайдена',
          color: 'warning',
        })
      }
      this.loading = true
      this.$store.commit('setDialog', {
        title: 'Відправка повідомлення',
        dialogItem: {
          entityId: this.leadData.id,
          emails: this.leadData.email,
          phones: this.leadData.phone,
          resEmail: this.leadData.responsible.email,
          photo: this.leadData.responsible.photo,
          name: this.leadData.responsible.name,
        },
        params: {
          dialogWidth: 500,
          submitText: 'Відправити',
          rejectText: 'Скасувати',
          type: type,
          entityTypeId: 4,
          cb: () =>
            this.getLead(this.leadData.id).then(() => (this.loading = false)),
        },
        action: 'send-sms-dialog',
      })
      this.loading = false
    },
    async toggleStatus(id) {
      this.v$.leadData.$touch()
      if (this.leadData.statusId === 4) return
      if (this.leadData.statusId === 1 && id > 2)
        return this.$setSnackbar({
          text: 'Потрібно взяти лід в роботу',
          color: 'error',
        })
      if (id === 4 && this.leadData.responsibleId === null) {
        return this.$setSnackbar({
          text: 'Відсутня відповідальна особа за лідом',
          color: 'error',
        })
      }

      this.loading = true
      if (id === 4) {
        this.v$.$touch()
        if (this.v$.$invalid) return
        await this.createApplication().catch(() => {
          this.loading = false
        })
        this.loading = false
      }
      try {
        await this.$store.dispatch('updateLeadStatus', {
          id: this.leadData.id,
          statusId: id,
        })
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$setSnackbar({
            text: error.response.data.message,
            color: 'warning',
          })
        }
      } finally {
        this.loading = false
      }
      this.loading = false
    },
    async createApplication() {
      const { data } = await this.$store.dispatch(
        'createApplicationFromLead',
        this.leadData.id
      )
      if (data.status === 200) {
        this.$setSnackbar({ text: 'Заявку створено' })
        this.$router.push({ name: 'leads' })
      } else if (data.status === 400) {
        this.$setSnackbar({ text: 'Заявка вже існує' })
      } else if (data.status === 403) {
        this.loading = false
      }
    },
    async updateLead() {
      this.v$.leadData.$touch()
      if (!this.v$.$invalid) {
        this.loading = true
        delete this.leadData?.responsible
        delete this.leadData?.source
        const res = await this.$store.dispatch('updateLead', this.leadData)
        if (res.message) {
          this.loading = false
          this.resetLeadData()
          return this.$setSnackbar({ text: res.message, color: 'warning' })
        }
        this.leadData = JSON.parse(JSON.stringify(this.lead))
        this.$setSnackbar({ text: 'Лід оновлено' })
        this.loading = false
      } else v$Notify(this.v$.leadData, 'lead')
    },
    resetLeadData() {
      this.leadData = JSON.parse(JSON.stringify(this.lead))
    },
    async getLead() {
      this.loading = true
      await this.$store.dispatch('getSingleLead', this.$route.params.id)
      this.leadData = JSON.parse(JSON.stringify(this.lead))
      this.loading = true
    },
  },
  watch: {
    '$route.params.id': async function () {
      await this.getLead()
      this.$store.commit('setBreadScrumbPart', [null, this.leadData.leadName])

      this.loading = false
    },
    leadData: {
      handler(data) {
        Object.entries(data).forEach(field => {
          if (field[1] === '') data[field[0]] = null
        })
      },
      deep: true,
    },
    lead: {
      handler(data) {
        this.leadData = JSON.parse(JSON.stringify(data))
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getLead()
    fillDirectory('leadStatuses')
    this.$store.commit('setBreadScrumbPart', [null, this.leadData.leadName])
    this.$store
      .dispatch('addApplicationSourceLinks', this.leadData?.sourceId)
      .finally(() => (this.sourceLoading = false))
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
.res-post {
  display: block;
  width: 215px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
}
.res-block {
  margin-bottom: 15px;
  &:hover .res-change {
    display: block !important;
  }
  .res-change {
    display: none;
    cursor: pointer;
    color: #09487a;
    font-weight: 500;
    font-size: 13px;
  }
}

.social-icon svg {
  height: 24px;
  width: 24px;
}
.v-tab {
  padding: 0 !important;
  margin-right: 25px;
}
.border-right {
  border-right: 1px solid #ccc;
}
.field-link:hover {
  cursor: pointer !important;
}
a.v-tab {
  font-weight: 700;
  font-size: 14px;
  color: #838282 !important;
}
a.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  font-size: 14px;
  color: #08487a !important;
}
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}

.text-desc {
  font-weight: 400;
  font-size: 13px;
  color: #1c1d21;
  display: block;
}

.crm-layout .crm .crm-content .v-data-table-header {
  background: transparent !important;
  border-bottom: none !important;
}
</style>
