<template>
  <div>
    <v-row class="d-flex justify-space-between">
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <v-row class="mt-3 mb-5">
          <div class="projectRow">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none">
              <path
                d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM6 14H4V7H6V14ZM10 14H8V4H10V14ZM14 14H12V10H14V14Z"
                fill="#08487A" />
            </svg>
            <span class="projectTitle">Знижки</span>
          </div>
        </v-row>
        <v-row class="align-center mb-2">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.rateReduction }">
              Знижка на вартiсть
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-row>
              <v-col :cols="6" class="pt-0 pb-0">
                <span class="label">Знижка ДКП</span>
                <v-text-field
                  id="discount-t-p"
                  v-model="calculationData.dkpDiscount"
                  :error-messages="infoDiscountErr"
                  class="pt-0 mt-0"
                  placeholder="0"
                  readonly
                  dense
                  hide-details
                  @input="maxNum('discount-t-p', 100, { positive: true })"
                  @blur="$v.calculation.infoDiscount.$touch()">
                  <template #append-inner>
                    <span class="append-text">%</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col :cols="6" class="pt-0 pb-0">
                <span class="label">З них на зниж. ставки</span>
                <v-text-field
                  id="disc-rate-reduct"
                  v-model="calculationData.rateReduction"
                  :error-messages="rateReductionErr"
                  class="pt-0 mt-0"
                  placeholder="%"
                  dense
                  hide-details
                  @input="
                    maxNum(
                      'disc-rate-reduct',
                      parseFloat(calculationData.dkpDiscount || 0),
                      { positive: true }
                    )
                  "
                  @focusout="Input.restoreData('disc-rate-reduct')"
                  @focusin="Input.hideData('disc-rate-reduct')"
                  @blur="$v.calculation.rateReduction.$touch()">
                  <template #append-inner>
                    <span class="append-text">%</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.rateManipulate }">
              Змiна ставки
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="disc-rate-man"
              v-model="calculationData.rateManipulate"
              class="mt-0 pt-0"
              :class="calculationData.programFSN ? 'blur' : null"
              :error-messages="rateManipulateErr"
              placeholder="%"
              dense
              :disabled="calculationData.programFSN"
              hide-details
              @input="
                () => {
                  maxNum('disc-rate-man', 100)
                  minNum('disc-rate-man', -100)
                }
              "
              @focusout="Input.restoreData('disc-rate-man')"
              @focusin="Input.hideData('disc-rate-man')"
              @blur="$v.calculation.rateManipulate.$touch()">
              <template #append-inner>
                <span class="append-text">%</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{
                'required-value': !!!calculationData.commisionManipulate,
              }">
              Змiна комiсії
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="disc-com-man"
              v-model="calculationData.commisionManipulate"
              class="mt-0 pt-0"
              :class="calculationData.programFSN ? 'blur' : null"
              :disabled="calculationData.programFSN"
              :error-messages="commisionManipulateErr"
              placeholder="%"
              dense
              hide-details
              @input="
                () => {
                  maxNum('disc-com-man', 100)
                  minNum('disc-com-man', -100)
                }
              "
              @focusout="Input.restoreData('disc-com-man')"
              @focusin="Input.hideData('disc-com-man')"
              @blur="$v.calculation.commisionManipulate.$touch()">
              <template #append-inner>
                <span class="append-text">%</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!withoutInsurance" class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0">
            <span
              class="card-label calc"
              :class="{
                'required-value': !!!calculationData.insuranceCascoManipulate,
              }">
              {{
                commercialProperty || equipment
                  ? 'Зміна тарифа страхування:'
                  : 'Зміна тарифа Каско:'
              }}
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="ins-casc-manip"
              v-model="calculationData.insuranceCascoManipulate"
              class="mt-0 pt-0"
              :class="calculationData.programFSN ? 'blur' : null"
              :disabled="calculationData.programFSN"
              :error-messages="insuranceCascoManipulateErr"
              dense
              hide-details
              @input="
                () => {
                  maxNum('ins-casc-manip', 300)
                  minNum('ins-casc-manip', -100)
                }
              "
              @focusout="Input.restoreData('ins-casc-manip')"
              @focusin="Input.hideData('ins-casc-manip')"
              @blur="$v.calculation.insuranceCascoManipulate.$touch()">
              <template #append-inner>
                <span class="append-text">%</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0">
            <span
              class="card-label calc"
              :class="{
                'required-value': !!!calculationData.annualCommission,
              }">
              Щорічна комісія
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="ins-casc-annualCommission"
              v-model="calculationData.annualCommission"
              class="mt-0 pt-0"
              :class="calculationData.programFSN ? 'blur' : null"
              :disabled="calculationData.programFSN"
              :error-messages="annualCommissionErr"
              dense
              hide-details
              @input="
                () => {
                  maxNum('ins-casc-annualCommission', 100)
                  minNum('ins-casc-annualCommission', 0)
                }
              "
              @focusout="Input.restoreData('ins-casc-annualCommission')"
              @focusin="Input.hideData('ins-casc-annualCommission')"
              @blur="$v.calculation.annualCommission.$touch()">
              <template #append-inner>
                <span class="append-text">%</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-center mb-2">
          <v-col :cols="12" :sm="5" class="pb-0">
            <span
              class="card-label calc"
              :class="{
                'required-value':
                  !!!calculationData.hiddenCommisionGr ||
                  !!!calculationData.hiddenCommision,
              }">
              Скрита комiсiя, дохiд
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-row class="mb-2">
              <v-col
                :cols="incomeLkPresent ? 5 : 6"
                class="pb-0"
                style="position: relative">
                <v-text-field
                  id="disc-hidd-comm"
                  v-model="calculationData.hiddenCommisionGr"
                  :class="
                    calculationData.leasingProgram === 34 ||
                    calculationData.programFSN
                      ? 'blur'
                      : null
                  "
                  :error-messages="hiddenCommisionGrErr"
                  dense
                  hide-details
                  :disabled="
                    calculationData.programFSN ||
                    calculationData.leasingProgram === 34
                  "
                  @input="
                    changeInputVal('disc-hidd-comm', 'input', [
                      keepDigit,
                      formutSum,
                    ])
                  "
                  @blur="$v.calculation.hiddenCommisionGr.$touch()">
                  <template #append-inner>
                    <span class="append-text">грн</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                :cols="incomeLkPresent ? 5 : 6"
                class="pb-0"
                style="position: relative">
                <v-text-field
                  id="disc-hidd-commis"
                  v-model="calculationData.hiddenCommision"
                  :class="
                    calculationData.leasingProgram === 34 ||
                    calculationData.programFSN
                      ? 'blur'
                      : null
                  "
                  :error-messages="hiddenCommisionErr"
                  dense
                  hide-details
                  :disabled="
                    calculationData.programFSN ||
                    calculationData.leasingProgram === 34
                  "
                  @input="maxNum('disc-hidd-commis', 100, { positive: true })"
                  @blur="$v.calculation.hiddenCommision.$touch()">
                  <template #append-inner>
                    <span class="append-text">%</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-fade-transition hide-on-leave>
                <v-col
                  v-if="incomeLkPresent"
                  style="z-index: 7"
                  :cols="2"
                  class="pt-0 pb-0">
                  <v-tooltip bottom text="Коментар">
                    <template #activator="{ props }">
                      <v-btn
                        class="mt-2"
                        icon
                        small
                        v-bind="props"
                        @click="$emit('update:editIncomeLk', !editIncomeLk)">
                        <v-icon :size="21">
                          {{ 'mdi-comment-question' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Коментар</span>
                  </v-tooltip>
                </v-col>
              </v-fade-transition>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end"></v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-scroll-x-transition>
              <div v-show="editIncomeLk">
                <span class="label">Поясніть доходи</span>
                <v-textarea
                  v-model="calculationData['сomment-income-lk']"
                  style="z-index: 100000"
                  :error-messages="commentIncomeLkErr"
                  :readonly="readOnly"
                  dense
                  :rows="3"
                  @blur="$v.calculation['сomment-income-lk'].$touch()">
                </v-textarea>
              </div>
            </v-scroll-x-transition>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0">
            <span
              class="card-label calc"
              :class="{
                'required-value':
                  !!!calculationData.commissionLk ||
                  !!!calculationData.commissionLkPr,
              }">
              Скрита комiсiя, витрати
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-row>
              <v-col
                :cols="commisionLkPresent ? 5 : 6"
                class="pt-0 pb-0"
                style="position: relative">
                <v-text-field
                  id="disc-com-lk"
                  v-model="calculationData.commissionLk"
                  :class="
                    calculationData.leasingProgram === 34 ||
                    calculationData.programFSN
                      ? 'blur'
                      : null
                  "
                  :error-messages="commissionLkErr"
                  dense
                  hide-details
                  :disabled="
                    calculationData.programFSN ||
                    calculationData.leasingProgram === 34
                  "
                  @input="
                    changeInputVal('disc-com-lk', 'input', [
                      keepDigit,
                      formutSum,
                    ])
                  "
                  @blur="$v.calculation.commissionLk.$touch()">
                  <template #append-inner>
                    <span class="append-text">грн</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                :cols="commisionLkPresent ? 5 : 6"
                class="pt-0 pb-0"
                style="position: relative">
                <v-text-field
                  id="disc-com-lk-pr"
                  v-model="calculationData.commissionLkPr"
                  :class="
                    calculationData.leasingProgram === 34 ||
                    calculationData.programFSN
                      ? 'blur'
                      : null
                  "
                  :error-messages="commissionLkPrErr"
                  dense
                  hide-details
                  :disabled="
                    calculationData.programFSN ||
                    calculationData.leasingProgram === 34
                  "
                  @input="maxNum('disc-com-lk-pr', 100, { positive: true })"
                  @blur="$v.calculation.commissionLkPr.$touch()">
                  <template #append-inner>
                    <span class="append-text">%</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-fade-transition hide-on-leave>
                <v-col
                  v-if="commisionLkPresent"
                  style="z-index: 7"
                  :cols="2"
                  class="pt-0 pb-0">
                  <v-tooltip bottom text="Коментар">
                    <template #activator="{ props }">
                      <v-btn
                        class="mt-2"
                        icon
                        small
                        v-bind="props"
                        @click="
                          $emit('update:editCommisionLk', !editCommisionLk)
                        ">
                        <v-icon :size="21">
                          {{ 'mdi-comment-question' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Коментар</span>
                  </v-tooltip>
                </v-col>
              </v-fade-transition>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="5" class="pb-0 flex-end"></v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-scroll-x-transition>
              <div v-show="editCommisionLk">
                <span class="label">Поясніть витрати</span>
                <v-textarea
                  v-model="calculationData['comment-commission-lk']"
                  style="z-index: 100000"
                  :error-messages="commentCommisionLkErr"
                  :readonly="readOnly"
                  dense
                  :rows="3"
                  @blur="$v.calculation['comment-commission-lk'].$touch()">
                </v-textarea>
              </div>
            </v-scroll-x-transition>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <v-col
                :cols="12"
                :md="5"
                style="display: flex; align-items: center">
                <span class="card-label calc mr-3">Наявність агента</span>
                <v-checkbox
                  v-model="calculationData.isAgent"
                  style="margin-top: -5px"
                  dense
                  color="red"
                  hide-details
                  :readonly="readOnly || application.sourceTypeId === 1"
                  @update:model-value="
                    () => {
                      calculationData.agentId = null
                      calculationData.additionalAb = '0'
                      calculationData.additionalAbPr = '0'
                      calculationData.agentCommision = '0'
                    }
                  ">
                </v-checkbox>
              </v-col>
              <v-col
                v-if="!!calculationData.isAgent"
                :cols="12"
                :md="7"
                style="padding-bottom: 0">
                <b-server-autocomplete
                  v-model="calculationData.agentId"
                  :url="urlGetAllAgents()"
                  :search-min-length="3"
                  :default-item="defAgent"
                  item-title="fullName"
                  item-value="id"
                  :menu-props="{ offset: '5px', zIndex: 1000000000 }"
                  placeholder="Введіть текст (мін. 3 символи)"
                  hide-details
                  dense
                  :readonly="readOnly || application.sourceTypeId === 1"
                  :error-messages="agentErr"
                  @blur="$v.calculation.agentId.$touch()">
                </b-server-autocomplete>
              </v-col>
            </v-row>
            <div v-if="!!calculationData.isAgent">
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0">
                  <span
                    class="card-label calc pt-2"
                    :class="{
                      'required-value': !!!calculationData.agentCommision,
                    }">
                    З разової вiддаємо агенту
                  </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-text-field
                    id="disc-agent-comm"
                    v-model="calculationData.agentCommision"
                    :error-messages="agentCommisionErr"
                    placeholder="%"
                    dense
                    hide-details
                    @input="
                      maxNum(
                        'disc-agent-comm',
                        calculationData.programFSN ? 1 : 100,
                        { positive: true }
                      )
                    "
                    @focusout="Input.restoreData('disc-agent-comm')"
                    @focusin="Input.hideData('disc-agent-comm')"
                    @blur="$v.calculation.agentCommision.$touch()">
                    <template #append-inner>
                      <span class="append-text">%</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="align-center mt-5">
                <v-col :cols="12" :sm="5" class="pb-0">
                  <span
                    class="card-label calc"
                    :class="{
                      'required-value':
                        !!!calculationData.additionalAb ||
                        !!!calculationData.additionalAbPr,
                    }">
                    Додаткова виплата АВ
                  </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-row>
                    <v-col
                      :cols="12"
                      :sm="commisionAbPresent ? 5 : 6"
                      class="pt-0 pb-0"
                      style="position: relative">
                      <v-text-field
                        id="add-ab"
                        v-model="calculationData.additionalAb"
                        :class="
                          calculationData.leasingProgram === 34 ||
                          calculationData.programFSN
                            ? 'blur'
                            : null
                        "
                        :error-messages="additionalAbErr"
                        dense
                        :disabled="calculationData.leasingProgram === 34"
                        hide-details
                        @input="
                          changeInputVal('add-ab', 'input', [
                            keepDigit,
                            formutSum,
                          ])
                        "
                        @blur="$v.calculation.additionalAb.$touch()">
                        <template #append-inner>
                          <span class="append-text">грн</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      :cols="12"
                      :sm="commisionAbPresent ? 5 : 6"
                      class="pt-0 pb-0"
                      style="position: relative">
                      <v-text-field
                        id="add-ab-pr"
                        v-model="calculationData.additionalAbPr"
                        :class="
                          calculationData.leasingProgram === 34 ||
                          calculationData.programFSN
                            ? 'blur'
                            : null
                        "
                        :error-messages="additionalAbPrErr"
                        dense
                        hide-details
                        :disabled="calculationData.leasingProgram === 34"
                        @input="maxNum('add-ab-pr', 100, { positive: true })"
                        @focusout="Input.restoreData('add-ab-pr')"
                        @focusin="Input.hideData('add-ab-pr')"
                        @blur="$v.calculation.additionalAbPr.$touch()">
                        <template #append-inner>
                          <span class="append-text">%</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-fade-transition hide-on-leave>
                      <v-col
                        v-if="commisionAbPresent"
                        style="z-index: 7"
                        :cols="12"
                        :sm="2"
                        class="pt-0 pb-0">
                        <v-tooltip bottom text="Коментар">
                          <template #activator="{ props }">
                            <v-btn
                              class="mt-2"
                              icon
                              small
                              v-bind="props"
                              @click="
                                $emit(
                                  'update:editCommisionAb',
                                  !editCommisionAb
                                )
                              ">
                              <v-icon :size="21">
                                {{ 'mdi-comment-question' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Коментар</span>
                        </v-tooltip>
                      </v-col>
                    </v-fade-transition>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12" :sm="5" class="pb-0"></v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-scroll-x-transition>
                    <div v-show="editCommisionAb">
                      <span class="label">Коментар АВ</span>
                      <v-textarea
                        v-model="calculationData.commentAB"
                        style="z-index: 100000"
                        :error-messages="commentABErr"
                        :readonly="readOnly"
                        dense
                        :rows="3"
                        @blur="$v.calculation.commentAB.$touch()">
                      </v-textarea>
                    </div>
                  </v-scroll-x-transition>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <!-- Right Column -->
      <v-col :cols="12" :md="6" class="pt-0 pb-0 pl-3 pl-lg-10">
        <v-row class="mt-3 mb-5">
          <div class="projectRow">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none">
              <path
                d="M17.4967 1.51067C17.28 0.871504 16.6733 0.416504 15.9583 0.416504H4.04167C3.32667 0.416504 2.73083 0.871504 2.50333 1.51067L0.369167 7.65317C0.293333 7.88067 0.25 8.119 0.25 8.36817V16.1248C0.25 17.024 0.975833 17.7498 1.875 17.7498C2.77417 17.7498 3.5 17.024 3.5 16.1248V15.5832H16.5V16.1248C16.5 17.0132 17.2258 17.7498 18.125 17.7498C19.0133 17.7498 19.75 17.024 19.75 16.1248V8.36817C19.75 8.12984 19.7067 7.88067 19.6308 7.65317L17.4967 1.51067ZM4.04167 12.3332C3.1425 12.3332 2.41667 11.6073 2.41667 10.7082C2.41667 9.809 3.1425 9.08317 4.04167 9.08317C4.94083 9.08317 5.66667 9.809 5.66667 10.7082C5.66667 11.6073 4.94083 12.3332 4.04167 12.3332ZM15.9583 12.3332C15.0592 12.3332 14.3333 11.6073 14.3333 10.7082C14.3333 9.809 15.0592 9.08317 15.9583 9.08317C16.8575 9.08317 17.5833 9.809 17.5833 10.7082C17.5833 11.6073 16.8575 12.3332 15.9583 12.3332ZM2.41667 6.9165L3.7925 2.77817C3.94417 2.34484 4.35583 2.0415 4.82167 2.0415H15.1783C15.6442 2.0415 16.0558 2.34484 16.2075 2.77817L17.5833 6.9165H2.41667Z"
                fill="#08487A" />
            </svg>
            <span class="projectTitle">Додаткові послуги</span>
          </div>
        </v-row>
        <v-row
          v-if="
            special ||
            agricultural ||
            waterTransport ||
            airTransport ||
            equipment
          "
          class="align-center">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0">
            <span
              class="card-label calc"
              :class="{
                'required-value':
                  !!!calculationData.placeOfRegistration ||
                  (calculationData.placeOfRegistration ===
                    T.registrationPlaceType.MANUAL &&
                    (!!!calculationData.registration ||
                      !!!calculationData.annualExpenses ||
                      !!!calculationData.removalRegistration)),
              }">
              Місце реєстрації
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-select
              v-model="calculationData.placeOfRegistrationType"
              :error-messages="placeOfRegistrationErr"
              return-object
              item-title="name"
              :items="
                waterTransport
                  ? $directory
                      .get(
                        'placeOfRegistration',
                        calculationData.placeOfRegistrationType
                      )
                      .filter(i => i.value === T.registrationPlaceType.MANUAL)
                  : $directory
                      .get(
                        'placeOfRegistration',
                        calculationData.placeOfRegistrationType
                      )
                      .filter(i => [2, 3, 4].includes(i.value))
              "
              item-value="value"
              placeholder="оберіть місце реєстрації"
              hide-details
              :loading="$loading.isLoading('placeOfRegistration')"
              @focus="$directory.fill('placeOfRegistration')"
              @blur="$v.calculation.placeOfRegistration.$touch()">
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="car || truck || busShort || moto" class="align-center">
          <v-col :cols="6" :sm="5" class="pt-0 pb-0">
            <span class="card-label calc">Аутсорсинг (ТСЦ):</span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-0 pb-0 align-center">
            <v-checkbox
              v-model="calculationData.placeOfRegistration"
              style="margin-top: 0"
              :error-messages="placeOfRegistrationErr"
              :false-value="1"
              :true-value="7"
              hide-details
              dense
              color="red"
              @update:model-value="placeOfRegistrationHandler">
            </v-checkbox>
            <v-tooltip
              top
              text="Реєстрація ОЛ відбувається аутсорсінговою компанією">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    size="17"
                    class="pb-3"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span> Реєстрація ОЛ відбувається аутсорсінговою компанією</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="customPlaceOfReg" class="pt-1">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0">
            <span class="card-label calc"></span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-text-field
              id="registration"
              v-model="calculationData.registration"
              :error-messages="startRegDateErr"
              label="Постановка на облiк"
              hide-details
              @input="
                changeInputVal('registration', 'input', [keepDigit, formutSum])
              "
              @focusout="Input.restoreData('registration')"
              @focusin="Input.hideData('registration')"
              @blur="$v.calculation.registration.$touch()">
              <template #append-inner>
                <span>грн</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="customPlaceOfReg" class="pt-1">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
            <span class="card-label calc"></span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-text-field
              id="lo-an-spend"
              v-model="calculationData.annualExpenses"
              :error-messages="annualSpendingErr"
              label="Щорiчнi витрати"
              hide-details
              @input="
                changeInputVal('lo-an-spend', 'input', [keepDigit, formutSum])
              "
              @focusout="Input.restoreData('lo-an-spend')"
              @focusin="Input.hideData('lo-an-spend')"
              @blur="$v.calculation.annualExpenses.$touch()">
              <template #append-inner>
                <span>грн</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="customPlaceOfReg" class="pt-1">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
            <span class="card-label calc"></span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-text-field
              id="removalRegistration"
              v-model="calculationData.removalRegistration"
              :error-messages="endRegDateErr"
              label="Зняття з облiку"
              hide-details
              @input="
                changeInputVal('removalRegistration', 'input', [
                  keepDigit,
                  formutSum,
                ])
              "
              @focusout="Input.restoreData('removalRegistration')"
              @focusin="Input.hideData('removalRegistration')"
              @blur="$v.calculation.removalRegistration.$touch()">
              <template #append-inner>
                <span>грн</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="6" :sm="5" class="pt-0 pb-0">
            <span
              class="card-label calc"
              :class="{
                'required-value':
                  !!calculationData.preliminaryFinancing &&
                  !!!calculationData.preFinancingDays,
              }">
              Попереднє фінансування
            </span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-0 pb-0">
            <v-checkbox
              v-model="calculationData.preliminaryFinancing"
              :error-messages="holidaysErr"
              class="mt-0"
              color="red"
              hide-details
              dense
              @update:model-value="clearPreliminaryFinancing">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-scroll-x-transition hide-on-leave>
          <div v-show="calculationData.preliminaryFinancing">
            <v-row class="align-center">
              <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                <span class="card-label calc"> </span>
              </v-col>
              <v-col :cols="12" :sm="7">
                <v-row>
                  <v-col :cols="12" :sm="6" class="mt-0">
                    <v-text-field
                      id="gt-pre-finance-d"
                      v-model="calculationData.preFinancingDays"
                      :error-messages="preFinancingDaysErr"
                      class="mt-0 pt-0"
                      label="Днiв"
                      dense
                      hide-details
                      @input="maxNum('gt-pre-finance-d', 720)"
                      @focusout="Input.restoreData('gt-pre-finance-d')"
                      @focusin="Input.hideData('gt-pre-finance-d')"
                      @blur="$v.calculation.preFinancingDays.$touch()">
                    </v-text-field>
                  </v-col>
                  <v-col :cols="12" :sm="6" class="mt-0">
                    <v-text-field
                      id="gt-pre-fin-amount"
                      v-model="calculationData.preFinancingAmount"
                      :error-messages="preFinancingAmountErr"
                      class="mt-0 pt-0"
                      label="Сума"
                      dense
                      hide-details
                      @input="
                        changeInputVal('gt-pre-fin-amount', 'input', [
                          keepDigit,
                          formutSum,
                        ])
                      "
                      @focusout="Input.restoreData('gt-pre-fin-amount')"
                      @focusin="Input.hideData('gt-pre-fin-amount')"
                      @blur="$v.calculation.preFinancingAmount.$touch()">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>
        <v-row class="align-center">
          <v-col :cols="6" :sm="5" class="pt-2 pb-0">
            <span class="card-label calc">Канікули</span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-2 pb-0 align-center">
            <v-checkbox
              v-model="calculationData.holidays"
              :error-messages="holidaysErr"
              class="mt-0"
              :false-value="2"
              :true-value="1"
              hide-details
              dense
              color="red">
            </v-checkbox>
            <v-tooltip
              color="grey darken-3"
              top
              text="3 місяці в графіку без оплат">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    size="17"
                    class="pb-3"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="giftRules" class="align-center">
          <v-col :cols="6" :sm="5" class="pt-0 pb-0">
            <span class="card-label calc">Подарунковий сертифікат</span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-0 pb-0 align-center">
            <v-checkbox
              v-model="calculationData.isGiftCertificate"
              class="mt-0"
              :false-value="false"
              :true-value="true"
              hide-details
              :disabled="!calculationData.stock === 1"
              dense
              color="red"
              @update:model-value="
                $event => {
                  if (!!$event) {
                    calculationData.stock = 1
                  }
                }
              ">
            </v-checkbox>
            <v-tooltip
              color="grey darken-3"
              top
              text="Компенсація яка розподіляється на останні лізингові платежі починаючи від останнього">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    size="17"
                    class="pb-3"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="6" :sm="5" class="pt-0 pb-0">
            <span class="card-label calc">Пренумерандо</span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-0 pb-0 align-center">
            <v-checkbox
              v-model="calculationData.prenumerando"
              :error-messages="prenumerandoErr"
              :false-value="2"
              :true-value="1"
              class="mt-0"
              dense
              color="red"
              hide-details>
            </v-checkbox>
            <v-tooltip top text="оплата щомісячного платежу на початку періоду">
              <template #activator="{ props }">
                <span style="font-size: 12px">
                  <v-icon
                    style="z-index: 7"
                    size="17"
                    class="pb-3"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span>оплата щомісячного платежу на початку періоду</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-show="commercialProperty" class="align-center">
          <v-col :cols="6" :sm="5" class="pt-0 pb-0">
            <span class="card-label calc">Нотаріальне завірення ДФЛ</span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-0 pb-0">
            <v-checkbox
              v-model="calculationData.notarized"
              class="mt-0"
              :false-value="2"
              :true-value="1"
              hide-details
              color="red"
              dense>
              <template #label>
                <div style="position: relative">
                  <v-tooltip
                    color="grey darken-3"
                    top
                    text="В графік платежів буде закладено відповідний %, затрат
                      нотаріуса">
                    <template #activator="{ props }">
                      <span style="font-size: 12px">
                        <v-icon
                          style="z-index: 7"
                          size="17"
                          class="pb-3"
                          v-bind="props">
                          {{ 'mdi-information' }}
                        </v-icon>
                      </span>
                    </template>
                    <span
                      >В графік платежів буде закладено відповідний %, затрат
                      нотаріуса.</span
                    >
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="car" class="align-center">
          <v-col :cols="6" :sm="5" class="pt-1 pb-0">
            <span class="card-label calc">Транспортний податок</span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-0 pb-0">
            <v-checkbox
              v-model="calculationData.vehicleOwnerTax"
              :error-messages="vehicleOwnerTaxErr"
              class="mt-0"
              :false-value="2"
              :true-value="1"
              dense
              color="red"
              hide-details
              @blur="$v.calculation.vehicleOwnerTax.$touch()">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row
          v-show="
            !special &&
            !equipment &&
            !commercialProperty &&
            !railwayTransport &&
            !airTransport &&
            !waterTransport
          "
          class="align-center">
          <v-col :cols="6" :sm="5" class="pt-2 pb-0">
            <span
              class="card-label calc"
              :class="{
                'required-value':
                  calculation.patrolCardsSupport ===
                    T.patrolCardsSupport.ENABLED &&
                  !!!calculationData.patrolCardQuantity,
              }">
              Паливна картка
            </span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-2 pb-0">
            <v-row class="align-center">
              <v-col :cols="2" class="pt-0 pb-0">
                <v-checkbox
                  v-model="calculationData.patrolCardsSupport"
                  style="height: 35px"
                  :error-messages="fuelCardErr"
                  class="mt-0 pt-0 align-center"
                  :false-value="2"
                  :true-value="1"
                  dense
                  color="red"
                  hide-details
                  @update:model-value="trackPatrolCard"
                  @blur="$v.calculation.patrolCardsSupport.$touch()">
                </v-checkbox>
              </v-col>
              <v-col
                v-show="
                  calculation.patrolCardsSupport ===
                  T.patrolCardsSupport.ENABLED
                "
                :cols="10"
                class="pt-0 pb-0 align-center">
                <v-text-field
                  id="patrol-card-quantity"
                  v-model="calculationData.patrolCardQuantity"
                  :error-messages="patrolCardQuantityErr"
                  class="pt-0 mt-0"
                  label="Кiлькiсть"
                  hide-details
                  dense
                  @blur="$v.calculation.patrolCardQuantity.$touch()"
                  @input="maxNum('patrol-card-quantity', 500)"
                  @focusout="Input.restoreData('patrol-card-quantity')"
                  @focusin="Input.hideData('patrol-card-quantity')">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pt-1">
          <v-col :cols="6" :sm="5" class="pt-0 pb-0 flex-end">
            <span class="card-label calc">Комісія в аванс</span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pt-0 pb-0 align-center">
            <v-checkbox
              v-model="calculationData.oneTimeCommission"
              :error-messages="oneTimeCommissionErr"
              class="mt-0"
              :false-value="0"
              :true-value="1"
              hide-details
              dense
              color="red"
              @input="$v.calculation.oneTimeCommission.$touch()">
            </v-checkbox>
            <v-tooltip
              top
              text="При виборі, одноразова комісія відображається в КП, а в ДФЛ
                    відображається в авансі по тілу">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    size="17"
                    class="pb-3"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span
                >При виборі, одноразова комісія відображається в КП, а в ДФЛ
                відображається в авансі по тілу</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="pt-1 align-center">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.assistService }">
              Асистанс
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-select
              v-model="calculationData.assistService"
              class="mt-1 pt-0"
              :error-messages="assistServiceErr"
              :items="[
                { text: 'Класік +', value: 1 },
                { text: 'Ultra All', value: 2 },
              ]"
              item-title="text"
              item-value="value"
              placeholder="оберіть опцію"
              dense
              hide-details
              @blur="$v.calculation.assistService.$touch()">
            </v-select>
          </v-col>
        </v-row>
        <v-row class="align-center mt-5">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0">
            <span
              class="card-label calc"
              :class="{
                'required-value': !!!calculationData.leasingStartDate,
              }">
              Дата передачi
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0 pt-0">
            <DatePicker
              v-model="calculationData.leasingStartDate"
              class="mt-0 pt-0"
              hide-details
              single />
          </v-col>
        </v-row>
        <v-row class="pt-2 align-center">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.unsprMonth }">
              <span>УНСПР</span>
              <v-tooltip
                color="grey darken-3"
                top
                text="Період розподілу некоригованих сум">
                <template #activator="{ props }">
                  <span style="font-size: 14px; color: black">
                    <v-icon
                      style="z-index: 7"
                      size="17"
                      class="pb-3"
                      v-bind="props">
                      {{ 'mdi-information' }}
                    </v-icon>
                  </span>
                </template>
                <span>Період розподілу некоригованих сум</span>
              </v-tooltip>
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-text-field
              id="gt-unspr-month"
              v-model="calculationData.unsprMonth"
              :error-messages="unsprMonthErr"
              class="pt-0 mt-0"
              dense
              :readonly="readOnly"
              hide-details
              @input="maxNum('gt-unspr-month', 72)"
              @blur="
                $v.calculation.unsprMonth && $v.calculation.unsprMonth.$touch()
              ">
              <template #append-inner>
                <span class="append-text">мiс</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-show="!commercialProperty"
          class="mt-5 mb-5"
          style="padding-left: 0">
          <div class="projectRow">
            <GpsIcon class="mr-2" />
            <span class="projectTitle">GPS монiторинг</span>
          </div>
        </v-row>
        <v-row v-if="hasGpsTracker" class="align-center mb-2">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
            <span class="card-label calc">GPS-tracker</span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-select
              v-model="calculationData.gpsTrackerType"
              return-object
              :error-messages="gpsTrackerErr"
              placeholder="оберіть опцію"
              :items="filteredGpsTracker"
              item-title="name"
              item-value="id"
              dense
              :readonly="readOnly"
              hide-details
              :loading="$loading.isLoading('gpsTrackerSelect')"
              @focus="$directory.fill('gpsTrackerSelect')"
              @blur="$v.calculation.gpsTracker.$touch()">
            </v-select>
          </v-col>
        </v-row>
        <v-row v-show="!commercialProperty" class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{
                'required-value':
                  calculationData.gpsBeacon !== 0 &&
                  !!!calculation.beaconAmount,
              }">
              GPS маячок
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-row>
              <v-col
                :cols="[1, 2].includes(calculationData.gpsBeacon) ? 8 : 12"
                class="pt-0 pb-0">
                <v-select
                  v-model="calculationData.gpsBeaconType"
                  :error-messages="gpsBeaconErr"
                  placeholder="оберіть опцію"
                  :items="
                    $directory.get('gpsBeacons', calculationData.gpsBeaconType)
                  "
                  item-title="name"
                  item-value="value"
                  dense
                  :readonly="readOnly"
                  hide-details
                  return-object
                  :loading="$loading.isLoading('gpsBeacons')"
                  @update:model-value="checkBeaconState"
                  @focus="$directory.fill('gpsBeacons')"
                  @blur="$v.calculation.gpsBeacon.$touch()">
                </v-select>
              </v-col>
              <v-scroll-x-transition hide-on-leave>
                <v-col
                  v-show="[1, 2].includes(calculationData.gpsBeacon)"
                  :cols="4"
                  class="pt-0 pb-0">
                  <v-text-field
                    id="beacon-amount"
                    v-model="calculationData.beaconAmount"
                    :error-messages="beaconAmountErr"
                    placeholder="Кiлькiсть"
                    hide-details
                    dense
                    @blur="$v.calculation.beaconAmount.$touch()"
                    @input="
                      () => {
                        maxNum('beacon-amount', 500)
                        minNum('beacon-amount', 1)
                      }
                    "
                    @focusout="Input.restoreData('beacon-amount')"
                    @focusin="Input.hideData('beacon-amount')">
                  </v-text-field>
                </v-col>
              </v-scroll-x-transition>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import GpsIcon from '@/assets/svg/gps-icon.vue'
import DatePicker from '@/components/DatePicker.vue'
import {
  maxNum,
  minNum,
  formutSum,
  keepDigit,
  changeInputVal,
} from '@/utils/formatFunc'
import { setErrHandler, Input, getBeautyNum } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { calculation as T } from '@/type-ids'
import { useSelect } from '@/utils/mixins/useSelect'
import { getDirectory } from '@/plugins/directory'
import { urlGetAllAgents } from '@/pages/request'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  emits: [
    'update:editIncomeLk',
    'update:editCommisionLk',
    'update:editCommisionAb',
  ],
  components: { DatePicker, GpsIcon, BServerAutocomplete },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    readOnly: { type: Boolean, default: false },
    calculation: { type: Object },
    calculationGeneralObject: { type: Object },
    application: { type: Object },
    hasGpsTracker: { type: Boolean, default: false },
    canDisableGpsTracker: { type: Boolean, default: false },
    truck: { type: Boolean, default: false },
    agricultural: { type: Boolean, default: false },
    car: { type: Boolean, default: false },
    busShort: { type: Boolean, default: false },
    special: { type: Boolean, default: false },
    equipment: { type: Boolean, default: false },
    commercialProperty: { type: Boolean },
    railwayTransport: { type: Boolean },
    airTransport: { type: Boolean },
    airDron: { type: Boolean },
    waterTransport: { type: Boolean },
    editCommisionLk: { type: Boolean },
    editIncomeLk: { type: Boolean },
    editCommisionAb: { type: Boolean },
    commisionLkPresent: { type: Boolean, default: false },
    incomeLkPresent: { type: Boolean, default: false },
    commisionAbPresent: { type: Boolean, default: false },
    moto: { type: Boolean },
    withoutInsurance: { type: Boolean },
    totalPriceSum: { type: [String, Number] },
    v: { type: Object },
  },
  data: () => ({
    Input: new Input(),
    T: T, // calculation types
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    calculationData() {
      return this.calculation
    },
    $v() {
      return this.v
    },
    defAgent() {
      return this.calculationGeneralObject?.agent
        ? {
            id: this.calculationGeneralObject.agent.id,
            fullName: this.calculationGeneralObject.agent.contact.fullName,
          }
        : null
    },
    agentErr() {
      return setErrHandler(this.$v.calculation.agentId)
    },
    customPlaceOfReg() {
      return (
        this.calculationData.placeOfRegistration ===
        this.T.registrationPlaceType.MANUAL
      )
    },
    rateManipulateErr() {
      return setErrHandler(this.$v.calculation.rateManipulate)
    },
    infoDiscountErr() {
      return setErrHandler(this.$v.calculation.infoDiscount)
    },
    rateReductionErr() {
      return setErrHandler(this.$v.calculation.rateReduction)
    },
    commisionManipulateErr() {
      return setErrHandler(this.$v.calculation.commisionManipulate)
    },
    hiddenCommisionGrErr() {
      return setErrHandler(this.$v.calculation.hiddenCommisionGr)
    },
    annualCommissionErr() {
      return setErrHandler(this.$v.calculation.annualCommission)
    },
    commissionLkErr() {
      return setErrHandler(this.$v.calculation.commissionLk)
    },
    commentIncomeLkErr() {
      return setErrHandler(this.$v.calculation['сomment-income-lk'])
    },
    commentCommisionLkErr() {
      return setErrHandler(this.$v.calculation['comment-commission-lk'])
    },
    commentABErr() {
      return setErrHandler(this.$v.calculation.commentAB)
    },
    hiddenCommisionErr() {
      return setErrHandler(this.$v.calculation.hiddenCommision)
    },
    commissionLkPrErr() {
      return setErrHandler(this.$v.calculation.commissionLkPr)
    },
    agentCommisionErr() {
      return setErrHandler(this.$v.calculation.agentCommision)
    },
    gpsTrackerErr() {
      return setErrHandler(this.$v.calculation.gpsTracker)
    },
    gpsTrackerQuantityErr() {
      return setErrHandler(this.$v.calculation.gpsTrackerQuantity)
    },
    gpsBeaconErr() {
      return setErrHandler(this.$v.calculation.gpsBeacon)
    },
    holidaysErr() {
      return setErrHandler(this.$v.calculation.holidays)
    },
    prenumerandoErr() {
      return setErrHandler(this.$v.calculation.prenumerando)
    },
    vehicleOwnerTaxErr() {
      return setErrHandler(this.$v.calculation.vehicleOwnerTax)
    },
    fuelCardErr() {
      return setErrHandler(this.$v.calculation.patrolCardsSupport)
    },
    patrolCardQuantityErr() {
      return setErrHandler(this.$v.calculation.patrolCardQuantity)
    },
    oneTimeCommissionErr() {
      return setErrHandler(this.$v.calculation.oneTimeCommission)
    },
    assistServiceErr() {
      return setErrHandler(this.$v.calculation.assistService)
    },
    startPaymentsDateErr() {
      return setErrHandler(this.$v.calculation.leasingStartDate)
    },
    insuranceCascoManipulateErr() {
      return setErrHandler(this.$v.calculation.insuranceCascoManipulate)
    },
    preFinancingDaysErr() {
      return setErrHandler(this.$v.calculation.preFinancingDays)
    },
    preFinancingAmountErr() {
      return setErrHandler(this.$v.calculation.preFinancingAmount)
    },
    placeOfRegistrationErr() {
      return setErrHandler(this.$v.calculation.placeOfRegistration)
    },
    startRegDateErr() {
      return setErrHandler(this.$v.calculation.registration)
    },
    annualSpendingErr() {
      return setErrHandler(this.$v.calculation.annualExpenses)
    },
    endRegDateErr() {
      return setErrHandler(this.$v.calculation.removalRegistration)
    },
    beaconAmountErr() {
      return setErrHandler(this.$v.calculation.beaconAmount)
    },
    unsprMonthErr() {
      return setErrHandler(this.$v.calculation.unsprMonth)
    },
    additionalAbErr() {
      return setErrHandler(this.$v.calculation.additionalAb)
    },
    additionalAbPrErr() {
      return setErrHandler(this.$v.calculation.additionalAbPr)
    },
    giftRules() {
      const leasingAmount = String(this.totalPriceSum)
        .replace(/\s/g, '')
        .split('.')[0]

      const leasingTerm = this.calculationData.leasingTerm

      return leasingAmount >= 1000000 && leasingTerm >= 36 && leasingTerm <= 60
    },
    filteredGpsTracker() {
      const arrGpsTrackers = getDirectory(
        'gpsTrackerSelect',
        this.calculationData.gpsTrackerType
      )
      const currentGpsTrackerId = this.calculation.gpsTracker

      return arrGpsTrackers
        .filter(g => g.id === currentGpsTrackerId || g.isActual)
        .map(item => ({
          ...item,
          name:
            item.isActual === true ? item.name : `${item.name}(неактуальний)`,
          props: {
            disabled: !item.isActual,
          },
        }))
    },
  },
  methods: {
    getBeautyNum,
    urlGetAllAgents,
    maxNum,
    minNum,
    formutSum,
    keepDigit,
    changeInputVal,
    trackPatrolCard(state) {
      if (state === this.T.patrolCardsSupport.DISABLED) {
        this.calculationData.patrolCardQuantity = '0'
      } else {
        this.calculationData.patrolCardQuantity = '1'
      }
    },
    clearPreliminaryFinancing() {
      this.calculationData.preFinancingDays = '0'
      this.calculationData.preFinancingAmount = '0'
    },
    checkBeaconState(e) {
      switch (0) {
        case e:
          return (this.calculationData.beaconAmount = '0')
        default:
          return
      }
    },
    placeOfRegistrationHandler(val) {
      console.log(val, 'e')
      if (!val) return

      const registrationTypes = {
        default: { id: 2, name: 'ТСЦ', value: 1 },
        outsourced: { id: 8, name: 'Аутсорсингом в регіоні', value: 7 },
      }

      if (val === 7) {
        this.calculationData.placeOfRegistrationType =
          registrationTypes.outsourced
      }
      if (val === 1) {
        this.calculationData.placeOfRegistrationType = registrationTypes.default
      }
    },
    // placeOfRegistrationTypeHandler(val) {
    //   console.log('valType1', val)
    //   if (!val) return
    //   this.calculationData.placeOfRegistration = val?.value
    // },
  },
  watch: {
    'calculationData.placeOfRegistrationType': function (val) {
      this.calculationData.placeOfRegistration = val?.value
    },
    'calculationData.placeOfRegistration': function (val) {
      if (!val) return

      const registrationTypes = {
        default: { id: 2, name: 'ТСЦ', value: 1 },
        outsourced: { id: 8, name: 'Аутсорсингом в регіоні', value: 7 },
      }

      if (val === 7) {
        this.calculationData.placeOfRegistrationType =
          registrationTypes.outsourced
      }
      if (val === 1) {
        this.calculationData.placeOfRegistrationType = registrationTypes.default
      }
    },
  },
  created() {
    this.calculationData.placeOfRegistration =
      this.calculationData.placeOfRegistrationType?.value
  },
}
</script>

<style scoped>
.projectRow {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.projectTitle {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
}

.absolute-screen {
  top: 0;
  left: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 500;

  .blur {
    filter: blur(0.1rem);
  }
}
</style>
