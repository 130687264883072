<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Тип</span>
        <v-select
          v-model="address.typeId"
          :error-messages="getErrorMessages(v$.typeId)"
          :items="filteredAddressTypes"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          dense
          hide-details
          :loading="
            !filteredAddressTypes.length &&
            $loading.isLoading([
              'contactAddressTypes',
              'contractorAddressTypes',
              'fopAddressTypes',
            ])
          "
          :readonly="editAddress || isDealerPoint"
          @blur="v$.typeId.$touch()">
        </v-select>
      </v-col>
    </v-row>

    <v-checkbox
      v-if="address.typeId === 4"
      v-model="address.canBeEmpty"
      class="mt-2"
      dense
      hide-details>
      <template #label>
        <span style="font-size: 12px">Адреса відсутня</span>
      </template>
    </v-checkbox>

    <div v-show="!address.canBeEmpty">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <span class="label">Країна</span>
          <v-autocomplete
            v-model="address.countryId"
            :items="$directory.get('countryList', address.country) as any[]"
            item-title="name"
            item-value="id"
            placeholder="Оберіть зі списку"
            dense
            hide-details
            :loading="$loading.isLoading('countryList')"
            @update:model-value="clear('city')"
            @focus="$directory.fill('countryList')">
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="address.countryId && address.countryId === 1">
        <city
          v-model="address.city"
          :error-messages="getErrorMessages(v$?.city.City)"
          @update:model-value="clear('street')"></city>
        <region v-model:city="address.city"></region>
        <Area v-model:city="address.city"></Area>
        <street
          v-model="address.street"
          :street-moniker="address.city.st_moniker"
          @update:model-value="clear('house')"></street>
        <house
          v-model="address.house"
          :house-moniker="address.street.house_moniker"></house>
        <apartment
          v-model:apartment-type-id="address.apartmentTypeId"
          v-model:apartment-type="address.apartmentType"
          v-model:apartment-num="address.house.apartmentNum"
          :address="address"></apartment>

        <HouseIndex
          v-model="address.house.Index_"
          :error-messages="getErrorMessages(v$?.house?.Index_)"
          @blur="v$.address?.house?.Index_.$touch()"></HouseIndex>
      </v-row>
      <v-row v-else>
        <v-col v-if="address.city" class="pt-0" cols="12" md="12" sm="12">
          <span class="label">Місто/Село</span>
          <v-text-field
            v-model="address.city.City"
            placeholder="Введіть текст"
            dense
            hide-details>
          </v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12" md="12" sm="12">
          <span class="label">Вулиця/Проспект/Провулок</span>
          <v-text-field
            v-model="address.street.Street"
            placeholder="Введіть текст"
            dense
            hide-details>
          </v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12" md="12" sm="12">
          <span class="label">Будинок</span>
          <v-text-field
            v-model="address.house.HouseNum"
            placeholder="Введіть текст"
            dense
            hide-details></v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12" md="12" sm="12">
          <span class="label">Квартира/офic</span>
          <v-text-field
            v-model="address.house.apartmentNum"
            placeholder="Введіть текст"
            dense
            hide-details>
          </v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12" md="12" sm="12">
          <span class="label">Індекс</span>
          <v-text-field
            v-model="address.house.Index_"
            v-mask:[internationalAddressIndexMask]
            placeholder="Введіть текст"
            hide-details
            :error-messages="getErrorMessages(v$.house?.Index_)"
            dense>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import City from './address-parts/City.vue'
import Street from './address-parts/Street.vue'
import House from './address-parts/House.vue'
import HouseIndex from './address-parts/HouseIndex.vue'
import Apartment from './address-parts/Apartment.vue'
import Region from './address-parts/Region.vue'
import Area from './address-parts/Area.vue'
import { useAddress } from './address'
import { computed } from 'vue'
import { getErrorMessages, Nullable } from 'best-modules/utils'
import { getAddressTypes, removeExistAddressTypes } from './utils'
import { fillDirectory } from '@/plugins/directory'
import { cloneDeep } from 'lodash'
import { Address } from '@/utils/types'
import axios from '@/plugins/axios.js'
import { urlAddressCreate, urlAddressUpdate } from '@/pages/request.js'
import { handleAsync } from 'best-modules/plugins'
import { getCities, getStreets } from '@/utils/addressApi'
import { internationalAddressIndexMask } from '@/utils/masks.js'

export default {
  name: 'SelectAddress',
  components: {
    Region,
    City,
    Street,
    House,
    HouseIndex,
    Apartment,
    Area,
  },
  props: {
    dialog: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return { internationalAddressIndexMask }
  },
  methods: { getErrorMessages },
  setup(props) {
    const { address, fullAddress, v$ } = useAddress()

    address.entityTypeId = props.dialog.dialogItem.entityTypeId
    address.entityId = props.dialog.dialogItem.entityId

    const editAddress = computed(() => {
      return props.dialog.action === 'editAddress'
    })
    const isDealerPoint = computed(() => {
      return props.dialog.dialogItem.entityTypeId === 5
    })

    const filteredAddressTypes = computed(() => {
      const addressTypes = getAddressTypes(
        props.dialog.dialogItem.entityTypeId,
        props.dialog.dialogItem.fop
      )

      const filtered = removeExistAddressTypes(
        addressTypes,
        props.dialog.dialogItem.existAddresses
      )

      if (editAddress.value && !filtered.some(t => t.id === address.typeId)) {
        addressTypes.push(address.type)
        return addressTypes
      }

      return filtered
    })

    const submit = () => {
      const req: Nullable<Address> = cloneDeep(address) as Nullable<Address>

      // заявка на реєстрацію відсутня
      if (req.canBeEmpty) {
        Object.keys(req).forEach(key => {
          if (
            key !== 'canBeEmpty' &&
            key !== 'typeId' &&
            key !== 'entityTypeId' &&
            key !== 'entityId'
          ) {
            req[key] = null
          }
        })
        req.countryId = null
      }
      if (req.house?.HouseNumAdd) {
        req.house.HouseNum =
          req.house?.HouseNum?.replace(req.house.HouseNumAdd, '')?.trim() || ''
      }

      const url = editAddress.value
        ? urlAddressUpdate(address.id)
        : urlAddressCreate()

      return axios.post(url, req).then(res => {
        return res.data
      })
    }

    const getMonikers = () => {
      return handleAsync('getMonikers', async () => {
        if (address.city?.Id) {
          const cities = await getCities(address.city.City)
          const city = cities.find(c => +c.Id === +address.city.Id)
          if (city) {
            address.city.st_moniker = city.st_moniker
          }
        }

        if (address.street?.StreetId) {
          const streets = await getStreets(
            address.street.Street,
            address.city.st_moniker
          )
          const street = streets.find(
            s => +s.StreetId === +address.street.StreetId
          )
          if (street) {
            address.street.house_moniker = street.house_moniker
          }
        }
      })
    }

    const clear = (part: 'city' | 'street' | 'house') => {
      const clearCity = () => {
        for (const key in address.city) {
          address.city[key] = null
        }
        address.apartmentType = null
        address.apartmentTypeId = null
        address.house.apartmentNum = null
      }
      const clearStreet = () => {
        for (const key in address.street) {
          address.street[key] = null
        }
      }
      const clearHouse = () => {
        for (const key in address.house) {
          if (key !== 'apartmentNum') {
            address.house[key] = null
          }
        }
      }
      switch (part) {
        case 'city':
          clearHouse()
          clearStreet()
          return clearCity()
        case 'street':
          clearHouse()
          return clearStreet()
        case 'house':
          return clearHouse()
      }
    }

    if (!editAddress.value) {
      fillDirectory('countryList')

      if (props.dialog.dialogItem.fop && address.entityTypeId === 1) {
        fillDirectory('fopAddressTypes')
      } else if (address.entityTypeId === 1) {
        fillDirectory('contactAddressTypes')
      } else {
        fillDirectory('contractorAddressTypes')
      }

      if (isDealerPoint.value) {
        address.typeId = 2
      }
    } else {
      Object.assign(address, cloneDeep(props.dialog.dialogItem.address))
      getMonikers()
    }

    return {
      address,
      fullAddress,
      v$,
      filteredAddressTypes,
      editAddress,
      isDealerPoint,
      submit,
      clear,
    }
  },
}
</script>

<style scoped></style>
