<template>
  <div data-readonly="hide" class="notify-wrapper">
    <div v-for="item in notifications" :key="item.id" class="notify-block">
      <div class="notify-block__header">
        <span class="notify-title">{{ item.taskMessage }}</span>
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.587 19.912C6.979 20.304 7.45 20.5 8 20.5C8.54934 20.5007 9.02 20.305 9.412 19.913C9.804 19.521 10 19.05 10 18.5H6C5.99934 19.0493 6.195 19.52 6.587 19.912Z"
            fill="#FC7247" />
          <path
            d="M0.287004 17.212C0.479004 17.404 0.71667 17.5 1 17.5H15C15.2827 17.5007 15.52 17.405 15.712 17.213C15.904 17.021 16 16.7833 16 16.5C16.0007 16.2173 15.905 15.98 15.713 15.788C15.521 15.596 15.2833 15.5 15 15.5H14V13.6C12.7173 13.1007 11.6883 12.3133 10.913 11.238C10.1377 10.1627 9.75 8.91667 9.75 7.5C9.75067 6.73334 9.87167 6.00834 10.113 5.325C10.3543 4.64167 10.7167 4.00834 11.2 3.425C10.9673 3.259 10.705 3.11334 10.413 2.988C10.121 2.86267 9.81667 2.76667 9.5 2.7V2C9.50067 1.584 9.355 1.23 9.063 0.938002C8.771 0.646002 8.41667 0.500002 8 0.500002C7.584 0.499336 7.23 0.645002 6.938 0.937002C6.646 1.229 6.5 1.58334 6.5 2V2.7C5.2 3.03267 4.125 3.72834 3.275 4.787C2.425 5.84567 2 7.08334 2 8.5V15.5H1C0.717337 15.4993 0.480004 15.595 0.288004 15.787C0.0960036 15.979 3.4602e-06 16.2167 3.4602e-06 16.5C-0.000663206 16.7827 0.0950036 17.02 0.287004 17.212Z"
            fill="#FC7247" />
          <path
            d="M20 7.5C20 9.70914 18.2091 11.5 16 11.5C13.7909 11.5 12 9.70914 12 7.5C12 5.29086 13.7909 3.5 16 3.5C18.2091 3.5 20 5.29086 20 7.5Z"
            fill="#62C14A" />
        </svg>
      </div>
      <div class="notify-block__footer">
        <span class="notify-date">{{ toFormatDate(item.date) }}</span>
        <span class="notify-user">{{ getContactInitials(item.user) }}</span>
        <v-btn
          class="notify-btn"
          size="x-small"
          :loading="$loading.isLoading('completeTask')"
          @click="completeTask(item)">
          Завершити
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { handleAsync, setSnackbar } from 'best-modules/plugins/index'
import axios from '@/plugins/axios'
import { urlTaskPlanTaskUpdate } from '@/pages/request'
import { computed } from 'vue'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'

export default {
  emits: ['update-local-data'],
  props: {
    localData: { type: Object, required: true },
    entityTypeId: { type: Number, required: true },
  },
  methods: { getContactInitials, toFormatDate },
  setup(props, { emit }) {
    const emptyArray = computed(() => {
      return props.entityTypeId === 3 ? 'taskSchedulers' : 'task_schedulers'
    })

    const completeTask = item => {
      return handleAsync('completeTask', () => {
        item.isDone = true
        return axios.post(urlTaskPlanTaskUpdate(item.id), item).then(() => {
          setSnackbar({ text: 'Нагадування завершено' })
          const updatedData = props.localData[emptyArray.value].map(t =>
            t.id === item.id ? { ...t, isDone: true } : t
          )
          emit('update-local-data', updatedData)
        })
      })
    }

    const notifications = computed(() => {
      return Object.clone(props.localData[emptyArray.value] || [])
        .filter(t => !t.isDone)
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .slice(0, 3)
    })

    return {
      completeTask,
      notifications,
    }
  },
}
</script>

<style scoped lang="scss">
.notify-wrapper {
  gap: 5px;
  padding: 0 15px 10px 15px;
  display: flex;
  flex-wrap: wrap;
  .notify-block {
    background: #ffffff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
    border-radius: 4px;
    width: 265px;
    height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    transition: all 0.3s;
    &:hover {
      background-color: #e2e2e2;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .notify-title {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 215px;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .notify-date {
        font-size: 12px;
      }
      .notify-user {
        font-size: 12px;
      }
      .notify-btn {
        background: #fc7247 !important;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04) !important;
        border-radius: 4px !important;
        color: #fff !important;
      }
    }
  }
}
</style>
